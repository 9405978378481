//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import { useMutation } from "@apollo/client";
import { Col, Form, Input, Modal, Row } from "antd";
import MaskedInput from "antd-mask-input";
import { Mutations } from "api/mutations";
import React, { useState } from "react";
import { VoidFunction } from "types";

//------------------------------------------------------------------------------------------
// Interfaces/Props
//------------------------------------------------------------------------------------------

const { TextArea } = Input;

//------------------------------------------------------------------------------------------
// Interfaces/Props
//------------------------------------------------------------------------------------------

interface ContactInformation {
  name: string;
  email: string;
  phoneNumber: string;
  listingAddress: string;
}

interface ContactAgentProps {
  isOpen: boolean;
  close: VoidFunction;
  contactInformation: ContactInformation;
}

//------------------------------------------------------------------------------------------

const ContactAgent = ({
  isOpen,
  close,
  contactInformation,
}: ContactAgentProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to hooks
  //------------------------------------------------------------------------------------------

  const [form] = Form.useForm();
  const [contactAgent] = useMutation(Mutations.CONTACT_AGENT);
  const [contactingAgent, setContactingAgent] = useState(false);

  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const submitContact = (): void => {
    setContactingAgent(true);

    const formValues = form.getFieldsValue();

    contactAgent({
      variables: {
        contactData: {
          userEmail: formValues.email,
          userPhoneNumber: formValues.phoneNumber,
          userName: formValues.name,
          userMessage: formValues.message,
          agentEmail: contactInformation.email,
          listingAddress: contactInformation.listingAddress,
        },
      },
    })
      .then(() => {
        setContactingAgent(false);
        form.resetFields();
        close();
        Modal.success({
          title: "Your message has been sent to the listing agent!",
        });
      })
      .catch(() => {
        setContactingAgent(false);
      });
  };

  const closeModal = (): void => {
    form.resetFields();
    close();
  };

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <Modal
      visible={isOpen}
      onCancel={closeModal}
      getContainer={false}
      title={(
        <Row justify="center">
          <Col>
            <h3>
              <b>Contact {contactInformation.name}</b>
            </h3>
          </Col>
        </Row>
      )}
      onOk={() => {
        form.validateFields().then(submitContact);
      }}
      okButtonProps={{ loading: contactingAgent }}
      okText="Send"
      cancelButtonProps={{ danger: true }}
    >
      <Form form={form}>
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please provide your name" }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please provide your email",
            },
            {
              type: "email",
              message: "Please provide a valid email",
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input type="email" placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          rules={[{ required: true, message: "Please enter phone number" }]}
          validateTrigger="onBlur"
        >
          <MaskedInput
            style={{
              textAlign: "left",
              width: "100%",
              marginTop: "0.25rem",
            }}
            mask="(111) 111-1111"
            name="card"
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item
          name="message"
          rules={[{ required: true, message: "Please provide a message" }]}
        >
          <TextArea placeholder="Your message here" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ContactAgent;
