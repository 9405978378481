import { Button, Layout, Result } from "antd";
import React, { Component, ErrorInfo, ReactNode } from "react";
import { Link } from "react-router-dom";
import Logger from "utils/logging/Logger";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const errorReport = {
      message: error.message,
      stack: error.stack,
      cause: error.cause,
      errorInfo,
    };

    Logger.error(JSON.stringify(errorReport));
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return (
        <Layout style={{ marginTop: "64px" }}>
          <Result
            status="error"
            title="Uh oh.."
            subTitle="Sorry, something unexpected happened. This error will be automatically reported to our team."
            extra={(
              <Link to="/">
                <Button type="primary">Back Home</Button>
              </Link>
            )}
          />
        </Layout>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
