//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Form, Input, Row, Select } from "antd";
import React, { useState } from "react";
import { HomeFeaturesConfig } from "utils/constants/HomeFeatures";
import { HomeTypes } from "utils/constants/HomeTypes";
import { PersonalPropertyConfig } from "utils/constants/PersonalProperty";
import ListingFormLabel from "../ListingFormLabel";
import { sliders } from "./configs";

//------------------------------------------------------------------------------------------
// Component Wide Constants
//------------------------------------------------------------------------------------------

const { Item: FormItem } = Form;
const { TextArea } = Input;

//------------------------------------------------------------------------------------------
// Props Definitions
//------------------------------------------------------------------------------------------

interface HomeInformationProps {
  hoa?: string;
}

//------------------------------------------------------------------------------------------

const HomeInformation = ({ hoa }: HomeInformationProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to hookes
  //------------------------------------------------------------------------------------------

  const [hasHoa, setHasHoa] = useState(hoa ? hoa : "");

  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const homeTypeOptions = HomeTypes.map((homeType) => (
    <Select.Option key={homeType} value={homeType}>
      {homeType}
    </Select.Option>
  ));

  const numInputs: React.ReactElement[] = [];
  for (let i = 0; i < sliders.length; i += 2) {
    const newRow = (
      <Row justify="center" key={`key-for-${i}`}>
        <Col xs={20} md={20} lg={16}
          xl={12} xxl={12}>
          <Row justify="start">
            <Col style={{ marginRight: "36px" }}>
              <FormItem
                rules={[
                  { required: true, message: sliders[i].requiredMessage },
                ]}
                label={
                  <ListingFormLabel required text={sliders[i].labelText} />
                }
                name={sliders[i].name}
              >
                <Input
                  type="number"
                  min={0}
                  step={sliders[i].step}
                  style={{ width: "200px" }}
                  autoComplete="new-password"
                />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                rules={[
                  { required: true, message: sliders[i + 1].requiredMessage },
                ]}
                label={
                  <ListingFormLabel required text={sliders[i + 1].labelText} />
                }
                name={sliders[i + 1].name}
              >
                <Input
                  type="number"
                  min={0}
                  step={sliders[i + 1].step}
                  style={{ width: "200px" }}
                  autoComplete="new-password"
                />
              </FormItem>
            </Col>
          </Row>
        </Col>
      </Row>
    );
    numInputs.push(newRow);
  }

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <>
      <Row justify="center">
        <Col
          xs={20}
          md={20}
          lg={16}
          xl={12}
          xxl={12}
          style={{
            marginTop: "2rem",
          }}
        >
          <h2
            style={{
              fontWeight: "bolder",
            }}
          >
            Home Details
          </h2>
        </Col>
      </Row>
      <div id="home-details">
        <Row justify="center">
          <Col xs={20} md={20} lg={16}
            xl={12} xxl={12}>
            <Divider
              style={{
                marginTop: 0,
              }}
            />
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={20} md={20} lg={16}
            xl={12} xxl={12}>
            <Row justify="start">
              <Col style={{ marginRight: "36px" }}>
                <FormItem
                  style={{ width: "200px" }}
                  rules={[
                    { required: true, message: "Please choose home type" },
                  ]}
                  label={<ListingFormLabel required text="Home Type" />}
                  name="homeType"
                >
                  <Select placeholder="Home Type">{homeTypeOptions}</Select>
                </FormItem>
              </Col>
              <Col>
                <FormItem
                  rules={[{ required: true, message: "Please set year" }]}
                  label={<ListingFormLabel required text="Year Built" />}
                  name="yearBuilt"
                >
                  <Input
                    type="number"
                    style={{ width: "150px" }}
                    placeholder="Year Built"
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={20} md={20} lg={16}
            xl={12} xxl={12}>
            <Row justify="start">
              <Col style={{ marginRight: "36px" }}>
                <FormItem
                  rules={[{ required: true, message: "Please set home size" }]}
                  label={<ListingFormLabel required text="Home Size" />}
                  name="houseSizeInSquareFeet"
                >
                  <Input
                    type="number"
                    suffix="sqft"
                    style={{ width: "200px" }}
                    placeholder="Home Size"
                    autoComplete="new-password"
                  />
                </FormItem>
              </Col>
              <Col>
                <FormItem
                  rules={[{ required: true, message: "Please set lot size" }]}
                  label={<ListingFormLabel required text="Lot Size" />}
                  name="lotSizeInSquareFeet"
                >
                  <Input
                    type="number"
                    suffix="sqft"
                    style={{ width: "200px" }}
                    placeholder="Lot Size"
                    autoComplete="new-password"
                  />
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormItem
                  label={(
                    <ListingFormLabel
                      required
                      text="Is this property part of an HOA?"
                    />
                  )}
                  name="hasHoa"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please specify whether or not this property has an HOA.",
                    },
                    () => ({
                      validator(_, value) {
                        setHasHoa(value);
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Select placeholder="HOA">
                    <Select.Option value="Y">Yes</Select.Option>
                    <Select.Option value="N">No</Select.Option>
                  </Select>
                </FormItem>
              </Col>
            </Row>
            {hasHoa === "Y" && (
              <Row gutter={36} justify="start">
                <Col>
                  <FormItem
                    rules={[
                      {
                        required: false,
                        message: "Please provide the starting price",
                      },
                    ]}
                    label={<ListingFormLabel required text="HOA Costs" />}
                    name="hoaMonthlyCost"
                  >
                    <Input
                      style={{ width: "200px" }}
                      type="number"
                      prefix="$"
                      suffix="USD / month"
                      autoComplete="new-password"
                    />
                  </FormItem>
                </Col>
              </Row>
            )}
            <Row justify="start">
              <Col>
                <FormItem
                  rules={[
                    {
                      required: false,
                      message: "Please enter Title Company",
                    },
                  ]}
                  label={<ListingFormLabel text="Title Company" />}
                  name="titleLink"
                >
                  <Input
                    style={{ width: "750px" }}
                    placeholder="Title Company"
                    autoComplete="new-password"
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>
        {numInputs}
        <Row justify="center">
          <Col xs={20} md={20} lg={16}
            xl={12} xxl={12}>
            <FormItem
              rules={[
                { required: true, message: "Please add your home description" },
              ]}
              label={<ListingFormLabel required text="Description" />}
              name="propertyDescription"
            >
              <TextArea style={{ width: "800px" }} rows={8} />
            </FormItem>
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xs={20}
            md={20}
            lg={16}
            xl={12}
            xxl={12}
            style={{
              marginTop: "2rem",
            }}
          >
            <ListingFormLabel text="Home Features" />
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={20} md={20} lg={16}
            xl={12} xxl={12}>
            <Row
              gutter={16}
              justify="start"
              style={{
                marginTop: "1rem",
              }}
            >
              {Array.from(HomeFeaturesConfig.keys()).map((field) => (
                <Col key={field}>
                  <FormItem valuePropName="checked" name={field}>
                    <Checkbox style={{ fontSize: "16px" }}>
                      <b>{HomeFeaturesConfig.get(field)!.displayString}</b>
                    </Checkbox>
                  </FormItem>
                </Col>
              ))}
            </Row>
            <Row justify="start">
              <Col>
                <Form.List name="otherHomeFeatures">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name }) => {
                        return (
                          <Row key={key} style={{ marginBottom: "10px" }}>
                            <Col style={{ minWidth: "1000px" }}>
                              <Row justify="start">
                                <Col span={8}>
                                  <FormItem
                                    name={[name, "feature"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Provide the other home feature or delete this item",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Other Home Feature"
                                      autoComplete="new-password"
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={1}>
                                  <Row
                                    justify="start"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <MinusCircleOutlined
                                      className="dynamic-delete-button"
                                      onClick={() => remove(name)}
                                    />
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        );
                      })}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add Other Home Features
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xs={20}
            md={20}
            lg={16}
            xl={12}
            xxl={12}
            style={{
              marginTop: "2rem",
            }}
          >
            <ListingFormLabel text="Personal Property Included" />
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={20} md={20} lg={16}
            xl={12} xxl={12}>
            <Row
              gutter={16}
              justify="start"
              style={{
                marginTop: "1rem",
              }}
            >
              {Array.from(PersonalPropertyConfig.keys()).map((field) => (
                <Col key={field}>
                  <FormItem valuePropName="checked" name={field}>
                    <Checkbox style={{ fontSize: "16px" }}>
                      <b>{PersonalPropertyConfig.get(field)!.displayString}</b>
                    </Checkbox>
                  </FormItem>
                </Col>
              ))}
            </Row>
            <Row justify="start">
              <Col>
                <Form.List name="otherPersonalProperty">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name }) => {
                        return (
                          <Row key={key} style={{ marginBottom: "10px" }}>
                            <Col style={{ minWidth: "1000px" }}>
                              <Row justify="start">
                                <Col span={8}>
                                  <FormItem
                                    name={[name, "property"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Provide the other property or delete this item",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Other Property"
                                      autoComplete="new-password"
                                    />
                                  </FormItem>
                                </Col>
                                <Col span={1}>
                                  <Row
                                    justify="start"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <MinusCircleOutlined
                                      className="dynamic-delete-button"
                                      onClick={() => remove(name)}
                                    />
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        );
                      })}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add Other Personal Property
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default HomeInformation;
