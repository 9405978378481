//-----------------------------------------------------------------------------------------
// Imports
//-----------------------------------------------------------------------------------------

import { Avatar, Col, Modal, Row } from "antd";
import { UserContext } from "context/UserContext/UserContext";
import React, { useContext, useState } from "react";
import AvatarEdit from "react-avatar-edit";
import { VoidFunction } from "types";
import ContextError from "utils/errors/ContextError";
import Logger from "utils/logging/Logger";
import S3Buyer from "utils/storage/S3Buyer";

//-----------------------------------------------------------------------------------------
// Component Wide Constants
//-----------------------------------------------------------------------------------------

const buyerS3 = new S3Buyer();

//-----------------------------------------------------------------------------------------
// Interfaces/Props
//-----------------------------------------------------------------------------------------

interface UploadProfilePictureProps {
  isOpen: boolean;
  close: VoidFunction;
}

//-----------------------------------------------------------------------------------------

const UploadProfilePicture = ({
  isOpen,
  close,
}: UploadProfilePictureProps): React.ReactElement => {
  const userContext = useContext(UserContext);
  if (userContext === undefined)
    throw new ContextError(ContextError.CONTEXT_UNDEFINED);

  const [imagePreview, setImagePreview] = useState("");
  const [imageUploading, setImageUploading] = useState(false);

  const { user, setUserImage } = userContext;
  if (user === undefined)
    throw new Error("For some reason the user does not exist");

  const onCrop = (preview: string) => {
    setImagePreview(preview);
  };

  const saveImage = (): void => {
    setImageUploading(true);
    buyerS3
      .uploadUserProfilePicture(imagePreview, user.storageBucketId)
      .then((res) => {
        buyerS3
          .getSingleUrlFromS3(res.$response.request.params.Key)
          .then((image) => {
            setUserImage(image);
          });
        setImageUploading(false);
        close();
      })
      .catch((err) => {
        Modal.error({ title: "Something went wrong" });
        Logger.error(err);
      });
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={close}
      title="Upload Profile Picture"
      onOk={saveImage}
      confirmLoading={imageUploading}
      width={"75%"}
    >
      <Row justify="center">
        <Col sm={24} lg={12}>
          <AvatarEdit width="100%" height={500} onCrop={onCrop} />
        </Col>
        <Col lg={{ span: 8, offset: 2 }} style={{ marginTop: "10px" }}>
          <Avatar src={imagePreview} size={100} />
        </Col>
      </Row>
    </Modal>
  );
};

export default UploadProfilePicture;
