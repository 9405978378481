import React from "react";
import ReactGa from "react-ga4";
import { useLocation } from "react-router";

interface GoogleAnalyticsProps {
  initialized: boolean;
  children: React.PropsWithChildren<any>;
}

const GoogleAnalytics = (props: GoogleAnalyticsProps): React.ReactElement => {
  const location = useLocation();

  React.useEffect(() => {
    if (props.initialized) {
      ReactGa.send({ hitType: "pageview", page: location.pathname });
    }
  }, [props.initialized, location]);

  return props.children;
};

export default GoogleAnalytics;
