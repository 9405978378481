//------------------------------------------------------------------------------------------------------------

import { useEffect, useState } from "react";

//------------------------------------------------------------------------------------------------------------

export function useBreakpoint(): "xs" | "sm" | "md" | "lg" | "xl" {
  const [breakpoint, setBreakpoint] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = () =>
      setBreakpoint(getDeviceConfig(window.innerWidth));

    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  });

  return breakpoint;
}

//------------------------------------------------------------------------------------------------------------

const getDeviceConfig = (width: number): "xs" | "sm" | "md" | "lg" | "xl" => {
  if (width < 576) {
    return "xs";
  } else if (width >= 576 && width < 768) {
    return "sm";
  } else if (width >= 768 && width < 992) {
    return "md";
  } else if (width >= 992 && width < 1200) {
    return "lg";
  } else {
    return "xl";
  }
};
