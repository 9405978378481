//-----------------------------------------------------------------------------------------
// Imports
//-----------------------------------------------------------------------------------------

import { Modal } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import Logger from "utils/logging/Logger";
import { notEmpty } from "utils/utils";
import S3Access from ".";

//-----------------------------------------------------------------------------------------
// Types
//-----------------------------------------------------------------------------------------

export interface BuyerDocsResponse {
  fileName: string;
  file: Blob;
}

export interface BuyerUrlsResponse {
  fileName: string;
  fileUrl: string;
}

//-----------------------------------------------------------------------------------------

export default class S3Buyer extends S3Access {
  //-----------------------------------------------------------------------------------------

  async getBuyerDocuments(
    userFolder: string
  ): Promise<Array<BuyerDocsResponse>> {
    const params = {
      Bucket: this.bucket,
      Prefix: `users/${userFolder}/docs/`,
    };

    const response = await this.s3.listObjects(params).promise();

    const files =
      response.Contents !== undefined
        ? await Promise.all(
            response.Contents.map(async (obj) => {
              const blob = await this.getObjectAsBlob(obj.Key!);
              if (blob && obj.Size !== 0) {
                return {
                  fileName: obj.Key!.split("/")[3].split(".")[0],
                  file: blob,
                };
              } else {
                return null;
              }
            })
          )
        : [];

    return files.filter(notEmpty);
  }

  //-----------------------------------------------------------------------------------------

  async getAllBuyerDocUrls(
    userFolder: string
  ): Promise<Array<BuyerUrlsResponse>> {
    const params = {
      Bucket: this.bucket,
      Prefix: `users/${userFolder}/docs`,
    };

    const response = await this.s3.listObjects(params).promise();

    const urls: Array<BuyerUrlsResponse | null> =
      response.Contents !== undefined
        ? await Promise.all(
            response.Contents.map(async (obj) => {
              const url = await this.getSingleUrlFromS3(obj.Key!);
              if (url && obj.Size !== 0) {
                return {
                  fileName: obj.Key!.split("/")[3].split(".")[0],
                  fileUrl: url,
                };
              } else {
                return null;
              }
            })
          )
        : [];

    return urls.filter(notEmpty);
  }

  //-----------------------------------------------------------------------------------------

  async uploadBuyerFiles(
    files: Array<UploadFile<any>>,
    userFolder: string
  ): Promise<void> {
    files.forEach(async (file) => {
      const key = `users/${userFolder}/docs/${file.name}`;
      const params = {
        Body: file.originFileObj,
        Bucket: this.bucket,
        Key: key,
      };

      this.s3
        .putObject(params)
        .promise()
        .catch((err) =>
          Logger.error(
            `Error uploading preapproval for user bucket [${userFolder}]: ` +
              err
          )
        );
    });
  }

  //-----------------------------------------------------------------------------------------

  async uploadUserProfilePicture(
    imageStr: string,
    userFolder: string
  ): Promise<any> {
    const key = `users/${userFolder}/profilePicture.png`;

    const buffer = Buffer.from(
      imageStr.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );

    const params = {
      Key: key,
      Body: buffer,
      Bucket: this.bucket,
      ContentEncoding: "base64",
      ContentType: "image/png",
    };

    return await this.s3.putObject(params).promise();
  }

  //-----------------------------------------------------------------------------------------

  async deleteUserDoc(userBucket: string, documentName: string): Promise<void> {
    const params = {
      Bucket: this.bucket,
      Key: `users/${userBucket}/docs/${documentName}.pdf`,
    };

    await this.s3.deleteObject(params).promise();
  }

  //-----------------------------------------------------------------------------------------

  static noUserDocs(): void {
    Modal.error({
      title: "No docs for user",
      content: "Sorry this user currently does not have any documents to view.",
    });
  }
}
