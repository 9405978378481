//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { useMutation } from "@apollo/client";
import { Button, Col, Form, Modal, Row, Select } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { Mutations } from "api/mutations";
import AddressInformation from "components/ListingFormItems/AddressInformation";
import HomeInformation from "components/ListingFormItems/HomeInformation";
import ListingFormLabel from "components/ListingFormItems/ListingFormLabel";
import OfferInformation from "components/ListingFormItems/OfferInformation";
import SellerTermsInformation from "components/ListingFormItems/SellerTermsInformation";
import UploadDocInformation from "components/ListingFormItems/UploadDocInformation";
import UploadImageInformation from "components/ListingFormItems/UploadImageInformation";
import { Listing } from "model/Listing";
import { UpdateListingInput } from "model/Listing/inputs";
import ListingUtils from "model/Listing/ListingUtils";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Timezone, TimezoneMap } from "utils/constants/Timezone";
import { UploadDocType } from "utils/constants/UploadDocType";
import Logger from "utils/logging/Logger";
import S3Listing from "utils/storage/S3Listing";
import { createFileFromUrl } from "utils/utils";

//------------------------------------------------------------------------------------------
// Component Wide Constants
//------------------------------------------------------------------------------------------

const listingS3 = new S3Listing();

//------------------------------------------------------------------------------------------
// Interfaces and Props
//------------------------------------------------------------------------------------------

interface EditListingFormProps {
  listingData: Listing;
}

//------------------------------------------------------------------------------------------

const EditListingForm = ({
  listingData,
}: EditListingFormProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  /// Calls to hooks
  //------------------------------------------------------------------------------------------

  const [form] = Form.useForm();
  const [formIsSaving, setFormSaving] = useState(false);
  const [imageFileList, setImageFileList] = useState<UploadFile<any>[]>([]);

  const [inspectionDocs, setInspectionDocs] = useState<
    string | null | undefined
  >("");
  const [disclosureDocs, setDisclosureDocs] = useState<
    string | null | undefined
  >("");
  const [miscDocs, setMiscDocs] = useState<string | null | undefined>("");
  const [updateListing] = useMutation(Mutations.UPDATE_LISTING);

  const initialFormValues = ListingUtils.createDefaultFormValues(listingData);

  useEffect(() => {
    moment.tz.setDefault(TimezoneMap[listingData.timezone]?.value);

    listingS3
      .getUrlOfDocuments(listingData.listingPicturesBucketId, [
        UploadDocType.DISCLOSURE,
        UploadDocType.INSPECTION,
        UploadDocType.MISC,
      ])
      .then((docs) => {
        setDisclosureDocs(docs.disclosure);
        setInspectionDocs(docs.inspection);
        setMiscDocs(docs.misc);
      });

    listingS3
      .downloadImages(listingData.listingPicturesBucketId)
      .then(async (images) => {
        const files: UploadFile<any>[] = await Promise.all(
          images.map(async (image) => {
            const name = image.imageKey.split("/")[3];

            const fileObj = await createFileFromUrl(image.imageUrl, name);

            const uploadFile: UploadFile<any> = {
              url: image.imageUrl,
              uid: image.imageKey,
              name: name,
              originFileObj: fileObj,
            };

            return uploadFile;
          })
        );

        setImageFileList(files);
      });
  }, [listingData.listingPicturesBucketId, listingData.timezone]);

  //------------------------------------------------------------------------------------------
  // Helpers and Handlers
  //------------------------------------------------------------------------------------------

  /**
   * Save all of the documents to the S3 bucket
   * @param parentBucketId the s3 bucket for the listing
   */
  const uploadDocuments = (parentBucketId: string): void => {
    const docItems = [
      {
        key: "disclosureDocuments",
        docType: UploadDocType.DISCLOSURE,
      },
      {
        key: "miscDocuments",
        docType: UploadDocType.MISC,
      },
      {
        key: "inspectionDocuments",
        docType: UploadDocType.INSPECTION,
      },
    ];

    for (const doc of docItems) {
      if (
        form.getFieldValue(doc.key) &&
        form.getFieldValue(doc.key)?.fileList.length === 0
      ) {
        listingS3.deleteListingDocument(parentBucketId, doc.docType);
      } else {
        form
          .getFieldValue(doc.key)
          ?.fileList.forEach((document: UploadFile<any>) =>
            listingS3.documentUpload(document, parentBucketId, doc.docType)
          );
      }
    }
  };

  const saveImages = async (parentBucketId: string): Promise<string> => {
    let featureImageKey = "";
    const listingFolder = `listings/${parentBucketId}`;
    await listingS3.emptySpecificFolder(listingFolder, "images");

    await Promise.all(
      imageFileList.map(async (imageFile: UploadFile<any>, index) => {
        const imageKey = await listingS3.imagesUpload(
          imageFile,
          parentBucketId,
          index
        );
        if (index === 0) featureImageKey = imageKey;
      })
    );

    return featureImageKey;
  };

  const submitForm = async (): Promise<void> => {
    if (imageFileList.length < 1) {
      Modal.error({
        title: "Please upload at least 1 image.",
      });
      document.getElementById("photo-upload")?.scrollIntoView();

      return;
    }

    setFormSaving(true);
    uploadDocuments(listingData.listingPicturesBucketId);

    const featureImageKey = await saveImages(
      listingData.listingPicturesBucketId
    );

    const updateListingInput: UpdateListingInput =
      ListingUtils.updateListingInputFromForm(
        form.getFieldsValue(),
        featureImageKey
      );
    updateListing({
      variables: {
        listingId: parseFloat(listingData.id),
        updatedListingData: updateListingInput,
      },
    })
      .then(() => {
        Modal.success({
          title: "Updates made successfully!",
        });
        setFormSaving(false);
      })
      .catch((err) => {
        setFormSaving(false);
        Modal.error({
          title: "Something went wrong. Try again.",
        });
        Logger.error(JSON.stringify(err, null, 2));
      });
  };

  const resetForm = () => {
    form.resetFields();
  };

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <Form
      hideRequiredMark
      initialValues={initialFormValues}
      layout="vertical"
      form={form}
      id="editListingForm"
      onFinish={submitForm}
      scrollToFirstError={{
        behavior: (actions) => {
          actions.forEach(({ top }) => {
            window.scrollTo(0, top - 128);
          });
        },
      }}
    >
      <Row justify="center">
        <Col xs={14} sm={12} md={10}>
          <Form.Item
            name="timezone"
            label={
              <ListingFormLabel text="Choose timezone to manage this listing" />
            }
          >
            <Select placeholder="Timezone" disabled>
              <Select.Option value={Timezone.PACIFIC}>Pacific</Select.Option>
              <Select.Option value={Timezone.MOUNTAIN}>Mountain</Select.Option>
              <Select.Option value={Timezone.CENTRAL}>Central</Select.Option>
              <Select.Option value={Timezone.EASTERN}>Eastern</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <AddressInformation />
      <OfferInformation form={form} />
      <SellerTermsInformation
        defaultStartEscrowRange={Number(
          initialFormValues.closeOfEscrow?.startRange
        )}
      />
      <UploadImageInformation
        fileList={imageFileList}
        setFileList={setImageFileList}
      />
      <UploadDocInformation
        inspectionDocs={inspectionDocs}
        disclosureDocs={disclosureDocs}
        miscDocs={miscDocs}
      />
      <HomeInformation hoa={initialFormValues.hasHoa} />
      <Row justify="center" gutter={16} style={{ marginBottom: "100px" }}>
        <Col xs={8} lg={7} xl={5}
          xxl={3}>
          <Button
            type="primary"
            htmlType="submit"
            shape="round"
            size="large"
            loading={formIsSaving}
            style={{
              display: "center",
              width: "100%",
              marginTop: "1.5rem",
            }}
          >
            Save
          </Button>
        </Col>
        <Col xs={8} lg={7} xl={5}
          xxl={3}>
          <Button
            disabled={formIsSaving}
            danger
            shape="round"
            size="large"
            onClick={resetForm}
            style={{
              display: "center",
              width: "100%",
              marginTop: "1.5rem",
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EditListingForm;
