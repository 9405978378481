import { Col, Layout, Row, Typography } from "antd";
import React from "react";
import Color from "utils/constants/Color";

const { Title } = Typography;

const VendorsInformation = (): React.ReactElement => {
  return (
    <Layout
      style={{
        background: Color.WHITE,
      }}
    >
      <Row>
        <Col
          span={24}
          style={{
            textAlign: "center",
          }}
        >
          <Title level={1} style={{ marginTop: "2rem", fontWeight: "bolder" }}>
            Vendor Information
          </Title>
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            textAlign: "center",
          }}
        >
          <Title level={2} style={{ marginTop: "2rem", fontWeight: "bolder" }}>
            {" "}
            Step 1:{" "}
          </Title>{" "}
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            textAlign: "center",
          }}
        >
          <Title level={2} style={{ marginTop: "2rem", fontWeight: "bolder" }}>
            {" "}
            Step 2:{" "}
          </Title>{" "}
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            textAlign: "center",
          }}
        >
          <Title level={2} style={{ marginTop: "2rem", fontWeight: "bolder" }}>
            {" "}
            Step 3:{" "}
          </Title>{" "}
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            textAlign: "center",
          }}
        >
          <Title level={2} style={{ marginTop: "2rem", fontWeight: "bolder" }}>
            {" "}
            Step 4:{" "}
          </Title>{" "}
        </Col>
      </Row>
    </Layout>
  );
};

export default VendorsInformation;
