import { Form, Input, Modal } from "antd";
import React, { useState } from "react";
import { VoidFunction } from "types";
import AuthUtils from "utils/auth/AuthUtils";

const authUtils = new AuthUtils();

interface PasswordChangeModalProps {
  isOpen: boolean;
  onCancel: VoidFunction;
}

const { Item: FormItem } = Form;

const PasswordChangeModal = ({
  isOpen,
  onCancel,
}: PasswordChangeModalProps): React.ReactElement => {
  const [form] = Form.useForm();
  const [resettingPassword, setResettingPassword] = useState(false);

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 12, offset: 1 },
  };

  const closeModal = (): void => {
    form.resetFields();
    onCancel();
  };

  const submitForm = async () => {
    form
      .validateFields()
      .then((values) => {
        setResettingPassword(true);
        authUtils
          .updatePassword(values.currentPassword, values.newPassword)
          .then((response) => {
            setResettingPassword(false);
            if (response.success) {
              Modal.success({
                title: response.message,
              });
              closeModal();
            } else {
              Modal.error({
                title: response.message,
              });
            }
          });
      })
      .catch();
  };

  return (
    <Modal
      okText="Change"
      onOk={submitForm}
      title="Change Password"
      visible={isOpen}
      onCancel={closeModal}
      confirmLoading={resettingPassword}
    >
      <Form {...layout} form={form}>
        <FormItem
          name="currentPassword"
          label="Current Password"
          rules={[
            { required: true, message: "Your current password is required" },
          ]}
        >
          <Input type="password" />
        </FormItem>
        <FormItem
          name="newPassword"
          label="New Password"
          rules={[
            { required: true, message: "Please provide a new password" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword").length >= 8) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Password must be at least 8 characters")
                );
              },
            }),
          ]}
        >
          <Input type="password" />
        </FormItem>
        <FormItem
          name="confirmNewPassword"
          label="Confirm New Password"
          rules={[
            { required: true, message: "Please confirm your password" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Password do not match"));
              },
            }),
          ]}
        >
          <Input type="password" />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default PasswordChangeModal;
