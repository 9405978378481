import { Col, Layout, Row, Typography } from "antd";
import React from "react";
import Color from "utils/constants/Color";

const { Title, Paragraph } = Typography;

const PrivacyInformation = (): React.ReactElement => {
  return (
    <Layout
      style={{
        background: Color.WHITE,
      }}
    >
      <Row justify="center" style={{ marginTop: "5rem", marginBottom: "5rem" }}>
        <Col xs={20} md={20} lg={16}
          xl={12} xxl={12}>
          <Title>Yivado Privacy Policy</Title>
          <Paragraph>
            <em>Last Updated: 03/05/21</em>
          </Paragraph>

          <Paragraph>
            Yivado, Inc., together with our subsidiaries and affiliates
            (collectively, <b>“Yivado” “we” “us”</b> or <b>“our”</b>) care about
            your privacy and are committed to protecting your personal
            information. At Yivado, we want you to contribute to and use our
            websites, mobile and desktop applications, and/or our related
            services (collectively the <b>“Services”</b>). We pledge to be
            transparent about the personal information we process and collect
            from you. You are encouraged to read this Privacy Policy to
            understand what we do with the personal information we collect from
            you. This Privacy Policy applies to any personal information you
            provide to us, or that we collect through our Services. By accessing
            or using any part of our Services, you agree to the collection and
            use of your personal information according to the terms set forth in
            this Privacy Policy.
          </Paragraph>

          <Title level={2}>Personal Information Collected</Title>
          <Paragraph>
            While visiting our websites and applications we may collect the
            following personal information from you:
          </Paragraph>
          <ul>
            <li>
              <b>Internet Data: </b>
              IP address, device ID, browser type, browsing history, website
              interactions, cookies and web beacons.
            </li>
            <li>
              <b>Geolocation Data:</b>
              IP address and geo-location.
            </li>
          </ul>
          <Paragraph>
            Additionally, when you create a Yivado account or use our Services,
            we may collect the following additional personal information from
            you:
          </Paragraph>
          <ul>
            <li>
              <b>Personal Identifiers:</b>
              Full name, email address, phone number, physical address, and
              mailing address.
            </li>
            <li>
              <b>Mobile Device and Mobile Browser.</b>
              You may adjust settings on your mobile device and mobile browser
              regarding cookies and sharing of certain information, such as your
              mobile device model or the language your mobile device uses, by
              adjusting the privacy and security settings on your mobile device.
              &nbsp;Please refer to the instructions provided by your mobile
              service provider or mobile device manufacturer.
            </li>
            <li>
              <b>Commercial Information:</b>
              Home search history, home information, home tours, offer history,
              co-buyer information, home listing details, and credit card
              information.
            </li>
            <li>
              <b>Inferences:</b>
              Social network profiles and inferences from those profiles.
            </li>
          </ul>
          <Paragraph>
            This information may be collected from you in the following ways.
          </Paragraph>
          <ul>
            <li>
              <b>Interactions with Our Websites &amp; Applications:</b> You
              provide us with personal information when you create or login to
              your account via our websites or applications. Additionally, we
              automatically collect data based on how you use our websites and
              applications utilizing cookies and other analytics tools.
            </li>
            <li>
              <b>Direct Communications with Us:</b> You provide us with personal
              information when you contact our customer service representatives
              through email or phone.
            </li>
            <li>
              <b>Real Estate &amp; Financial Transactions:</b> You provide us
              with personal information when you buy or sell a home, complete
              loan documentation, or engage in other Yivado Services.{" "}
            </li>
            <li>
              <b>Other Sources:</b> Information which may be received from other
              sources such as social media platforms and third party providers.
            </li>
          </ul>
          <Title level={2}>Cookies and Similar Technologies</Title>
          <Paragraph>
            Cookies are bits of electronic information that can be transferred
            to your computer or other electronic device to uniquely identify
            your browser. When you use the Services, we may place one or more
            cookies on your computer or other electronic device. Yivado&apos;s
            third-party ad servers, ad network providers, and third-party
            advertisers (“Yivado Ad Providers”) may also use cookies, web
            beacons, or similar technologies (“Ad Technologies”) to provide you
            with advertisements that you may see on the Services or on other
            affiliated websites. We have broken up our cookies into categories
            to help you understand what they do.
          </Paragraph>
          <ul>
            <li>
              <b>Essential:</b>
              These cookies are required to use our Services. For example, they
              are necessary to access our website and login to your account.
            </li>
            <li>
              <b>Performance:</b>
              These cookies track how you interact with our website. For
              example, these cookies let us know which pages on our website load
              slowly or are not optimized for mobile use. We use this
              information to make improvements to our user experience.
            </li>
            <li>
              <b>Functionality:</b>
              These cookies track information about you to make using our
              website easier. For example, we may retain information such as
              your browser and language preferences.
            </li>
            <li>
              <b>Marketing and Analytics:</b>
              These cookies help us create targeted advertising experiences on
              our website. For example, third-parties may use cookies to deliver
              advertisements to you based on your visit to Yivado’s websites and
              use of our Services.
            </li>
          </ul>
          <Paragraph>
            Some browsers have a “do not track” feature that lets you tell
            websites that you do not want to have your online activities
            tracked. We currently do not respond to browser “do not track”
            signals. However, you may be able to change the preferences on your
            browser or device to prevent or limit your device’s acceptance of
            cookies, but this may prevent you from taking advantage of some of
            our features. For this reason we recommend you keep cookies
            activated. For more information about our use of cookies please see
            our Cookie Notice.
          </Paragraph>
          <Title level={2}>Usage of Personal Information</Title>
          <Paragraph>
            Yivado uses the personal information collected from you for a
            variety of purposes, including but not limited to the following:
          </Paragraph>
          <ul>
            <li>To provide you with Services and customer support;</li>
            <li>
              To operate our business, which includes, without limitation, using
              your information (i) to process transactions, (ii) to manage and
              enforce contracts with you or with third parties, and (iii) to
              manage our corporate governance, compliance and auditing
              practices;
            </li>
            <li>For internal marketing purposes;</li>
            <li>
              To monitor, analyze, improve and develop our Services and to
              create and test new Service features;
            </li>
            <li>To provide a more customized user experience;</li>
            <li>
              To validate user information provided to us for fraud and risk
              detection purposes;
            </li>
            <li>
              To prevent, identify and address fraudulent or other illegal
              activity and security issues;
            </li>
            <li>To comply with laws, rules and regulations; and</li>
            <li>
              For any other purpose for which you expressly authorize us to use
              your information.
            </li>
          </ul>
          <Title level={2}>Disclosure of Personal Information</Title>
          <Paragraph>
            Yivado does not rent or sell your personal information to third
            parties. However, we may disclose your personal information to our
            subsidiaries, affiliates, service providers, business partners, and
            other third-party processors to provide you with Services. For
            example, if you choose to contact a homeowner to tour or make an
            offer on a home through the Services, your personal information may
            be transmitted to the homeowner to complete the task you requested
            through the Services. Further, if you advertise a home for sale
            through the Services, Yivado may provide your personal information
            to individuals interested in touring or making offers on your home.{" "}
            <b>
              Yivado CANNOT GUARANTEE THAT ALL HOME TOUR REQUESTS OR OFFERS ARE
              GENUINE. YOU HEREBY CONSENT TO THE RELEASE OF YOUR PERSONAL
              INFORMATION IN ACCORDANCE WITH ALL HOME TOUR REQUESTS OR OFFERS,
              AND FURTHER AGREE TO HOLD Yivado HARMLESS FROM ANY LIABILITY OR
              DAMAGES YOU MAY SUFFER AS A RESULT OF SUCH RELEASE
            </b>
            .
          </Paragraph>
          <Paragraph>
            We may also share your information with third parties when necessary
            to comply with any requirement of law, regulation, legal process, or
            enforceable governmental request, enforce or investigate a potential
            violation of the Terms of Use, exercise our legal rights or defend
            against legal claims, detect, prevent, or otherwise respond to
            fraud, security or technical concerns, support auditing and
            compliance functions, protect the rights, property, or safety of
            Yivado, its users, or the public against harm, facilitate the
            financing or insuring of our business, complete a merger,
            acquisition, or any form of transfer or sale of our business or
            assets, or as otherwise permitted or required by law.
          </Paragraph>
          <Paragraph>
            Please be aware that the information that you choose to post to a
            public area of the Services, including Home Advertisements, will not
            be protected by this Privacy Policy because you have made an active
            choice to make that information public.{" "}
            <b>
              Yivado IS NOT RESPONSIBLE FOR PROTECTING ANY PERSONAL INFORMATION
              YOU CHOOSE TO SUBMIT IN ANY PUBLIC AREA OF THE SERVICES
            </b>
            .
          </Paragraph>
          <Title level={2}>Real Estate Information</Title>
          <Paragraph>
            Yivado strives to provide access to and improve real estate
            information available to the general public. For example, we make
            certain details of your real estate transactions with Yivado
            available to other Yivado users in an anonymous fashion to assist
            our users with information-backed decision making. Where third-party
            service providers assist us in providing you with Services, we may
            allow you to comment on the quality of service provided by these
            third parties through ratings and reviews. Yivado may post, remove,
            or edit any reviews submitted by you at its sole discretion.
          </Paragraph>
          <Title level={2}>Email Communications </Title>
          <Paragraph>
            You may receive emails and text messages (collectively,
            “Communications”) from Yivado for a variety of reasons. For example,
            you may receive an email or text from Yivado if you took an action
            through the Services such as advertising a home for sale or
            requesting to tour or make an offer on a home. You can manage your
            receipt of certain communication by following the instructions
            included in communication or by modifying your notification
            preferences under Account Settings. Please note that, even if you
            unsubscribe from certain communications, we may still contact you
            with important transactional or administrative information.
          </Paragraph>
          <Title level={2}>Third-Party Websites</Title>
          <Paragraph>
            Our Services may contain links to other sites that are not operated
            by us. If you click on a link to a third-party site, you will be
            directed to their site.{" "}
            <b>
              WE ASSUME NO RESPONSIBILITY FOR THE CONTENT, PRIVACY POLICIES OR
              PRACTICES OF ANY THIRD PARTY SITES OR SERVICES AND WE STRONGLY
              ADVISE YOU TO REVIEW THE PRIVACY POLICY OF EVERY WEBSITE YOU
              VISIT.
            </b>
          </Paragraph>
          <Title level={2}>
            Storage, Security and Retention of Information
          </Title>
          <Paragraph>
            Yivado stores your personal information on servers located in the
            United States of America, but may transfer your personal information
            to other countries outside of the United States for the purposes
            described in this Privacy Policy. We will take all the steps
            reasonably necessary to ensure that your data is treated securely
            and in accordance with this Privacy Policy and no transfer of
            personal data will take place to an organization or a country unless
            there are adequate controls in place including the security of data
            and other personal data.
          </Paragraph>
          <Paragraph>
            We maintain physical, electronic, and procedural safeguards
            reasonably necessary to ensure the protection of your personal
            information against loss, misuse, disclosure, and alteration. Our
            employees are educated on security protocols to ensure the safe and
            proper handling of your personal information. Only authorized
            employees are allowed access to stored personal information, and
            only for the explicit uses described in this Privacy Policy.{" "}
          </Paragraph>
          <Paragraph>
            Although we do our best to protect your personal information, no
            data transmission over the Internet or any wireless network can be
            guaranteed to be 100% secure. As a result, while we strive to
            protect your personal identification information, you acknowledge
            that: (a) there are security and privacy limitations inherent to the
            Internet which are beyond our control; and (b) the security,
            integrity and privacy of any and all information and data exchanged
            between you and us through our website cannot be guaranteed. Please
            do your part in protecting your personal information against
            unauthorized use. Keep your account password safe and confidential,
            and be sure to sign off when finished using a shared computer. Also
            scan your computer regularly for viruses, malware, and spyware.{" "}
          </Paragraph>
          <Paragraph>
            By using our Services, you agree that we may communicate with you
            electronically regarding security, privacy, and administrative
            issues relating to your use of our Services. If we learn of a
            security breach involving your personal information, we may attempt
            to notify you electronically by sending an email to you. To withdraw
            your consent to receive electronic notice of a data breach, please
            email us at{" "}
            <a href="mailto:privacy@Yivado.com">privacy@Yivado.com</a>
          </Paragraph>
          <Paragraph>
            Yivado will retain your personal information for as long as
            necessary to fulfil the purposes described in this Privacy Policy,
            unless otherwise required by contract or law. Anonymous and
            aggregated information may be stored indefinitely.
          </Paragraph>
          <Title level={2}>Children’s Privacy</Title>
          <Paragraph>
            Yivado does not knowingly collect personal information from any
            person under the age of 16. If you use our Services, you represent
            that you are at least the age of majority under the laws of the
            jurisdiction of your place of residence. If you believe your child
            has provided us with personal information, please alert us at
            privacy@Yivado.com. If we learn that we have collected personal
            information from a minor, we will promptly take steps to delete such
            information and terminate the minor’s information.
          </Paragraph>
          <Title level={2}>Your Privacy Rights</Title>
          <Paragraph>
            If you are a resident of certain states or countries, you may have
            the right to:
          </Paragraph>
          <ul>
            <li>
              Obtain information regarding the collection, processing, and
              sharing of your personal information;
            </li>
            <li>
              Access the personal information we have obtained about you, if
              any;
            </li>
            <li>Correct your personal information;</li>
            <li>Delete your personal information;</li>
            <li>
              Request an electronic copy of your personal information; and/or
            </li>
            <li>
              Object to or opt out of the use of your personal information for
              certain purposes.
            </li>
          </ul>
          <Paragraph>
            To submit a privacy subject request, please contact us at
            privacy@Yivado.com. Any request you submit to us is subject to an
            identification and verification process. We will make commercially
            reasonable efforts to identify personal information that we collect,
            process, store, disclose, and otherwise use and to respond to your
            applicable privacy rights requests. If we cannot comply with a
            request, we will explain the reasons in our response. We will not
            discriminate against individuals who exercise these rights.
          </Paragraph>
          <Title level={2}>Changes to this Privacy Policy</Title>
          <Paragraph>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            You should check back for the most current version before relying on
            any of the provisions in this Privacy Policy. If we make any
            material changes to how we treat your personal information, we will
            notify you by email to the primary email address specified in your
            account and/or through a notice on our website’s home page. Your
            continued use of our Services after such modifications will
            constitute your acknowledgment of the modified Privacy Policy and
            your agreement to abide and be bound by the modified Privacy Policy.
            Changes to this Privacy Policy are effective when they are posted on
            this page.
          </Paragraph>
          <Title level={2}>Contacting Yivado</Title>
          <Paragraph>
            If you have any questions about this Privacy Policy or Yivado’s
            privacy practices, please contact us at: Yivado, Inc. (Address)
            (Phone Number)
            <a href="mailto:privacy@yivado.com">privacy@yivado.com</a>
          </Paragraph>
        </Col>
      </Row>
    </Layout>
  );
};

export default PrivacyInformation;
