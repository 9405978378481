import { Col, Collapse, Layout, Row, Typography } from "antd";
import React from "react";
import ApplicationFooter from "../../../../components/ApplicationFooter";
import Color from "../../../../utils/constants/Color";

const { Panel } = Collapse;

const { Title } = Typography;

const FAQPage = (): React.ReactElement => {
  return (
    <Layout style={{ overflowX: "hidden", background: Color.WHITE }}>
      <Row
        justify="center"
        style={{
          backgroundColor: "#1DA57A",
          paddingTop: "120px",
          paddingBottom: "140px",
        }}
      >
        <Col
          xs={20}
          md={20}
          lg={14}
          xl={12}
          xxl={12}
          style={{
            backgroundColor: "#FFFFFF",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          <Row justify="center" style={{ marginTop: "60px" }}>
            <Col
              span={24}
              style={{
                textAlign: "center",
                marginBottom: "50px",
                marginTop: "20px",
              }}
            >
              <Title
                level={1}
                style={{
                  backgroundColor: "#FFFFFF",
                  marginTop: "20px",
                  fontWeight: "bolder",
                  fontSize: "50px",
                }}
              >
                Frequently Asked Questions
              </Title>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="What is Yivado?"
                  key="1"
                >
                  <p>
                    Yivado is a convenient real estate offer platform designed
                    to help buyers and sellers get their transactions finished
                    faster, with less paperwork, and with more clarity. It
                    allows both buyers and sellers to see all offers on any
                    property that is listed using Yivado.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="What is the benefit of using Yivado for buyers?"
                  key="2"
                >
                  <p>
                    Yivado minimizes the risk of losing a client because it
                    speeds up the whole home buying and home selling process.
                    The platform saves realtors time and money as well as
                    providing more information to all parties and simplifying
                    communication.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="What is the benefit of using Yivado for realtors?"
                  key="3"
                >
                  <p>
                    Yivado minimizes the risk of losing a client because it
                    speeds up the whole home buying and home selling process.
                    The platform saves realtors time and money as well as
                    providing more information to all parties and simplifying
                    communication.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="Real estate is so competitive right now, will my offer even be seen?"
                  key="4"
                >
                  <p>
                    In traditional real estate transactions, a listing agent can
                    pick and choose which offers to present to the seller.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              Is Yivado hard to use?"
                  key="5"
                >
                  <p>
                    No, we’ve designed Yivado to be ultra user-friendly. Your
                    real estate agent should also be able to guide you through
                    the steps if you get stuck.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              What is the pricing for Yivado?"
                  key="5"
                >
                  <p>
                    Yivado has zero out-of-pocket fees. 0.5% is paid directly to
                    Yivado through the Seller proceeds at closing.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              What does Yivado cost on top of the usual closing fees?"
                  key="6"
                >
                  <p>
                    While this may differ from transaction to transaction, the
                    seller’s agent has a fee that is the equivalent of 3% of the
                    home purchase for their services and the buyer’s agent also
                    has a 3% fee. <br />
                    <br />
                    With the clarity Yivado provides, the agents have to put in
                    significantly less time than they usually would, so 0.5%
                    goes to Yivado and 2.5% goes to the agent.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              Do I need a real estate agent?"
                  key="7"
                >
                  <p>
                    Homebuying is a complex process. Although agents charge fees
                    for their services, they will almost always get you a better
                    price and much better terms. <br />
                    <br />
                    Using Yivado requires that you have an agent to represent
                    you. This makes it fair for all parties and ensures that
                    there are no major delays due to self-representation. <br />
                    <br />
                    You can use your own Realtor or if you do not have one, a
                    Yivado certified agent will help you get to the finish line.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              Will my offer be seen by other buyers?"
                  key="8"
                >
                  <p>
                    Yes, all offers can be seen by everyone. You will also have
                    access to view which other offers there are on the table at
                    any given time. So, no more writing blind offers.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              Is it hard to submit an offer?"
                  key="9"
                >
                  <p>
                    Using Yivado is easy. All you have to do is to create your
                    account, agree to the terms and one of our representatives
                    will get you set up right away. Your agent can also submit
                    offers for you.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              Why is the starting offer price so low?"
                  key="1"
                >
                  <p>
                    Starting at a lower offer price allows the market to
                    determine what the highest offer will be.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              What happens after submitting a winning offer?"
                  key="2"
                >
                  <p>
                    After the seller accepts your offer, everything proceeds as
                    it would in a typical home purchase. The listing agent will
                    contact you and your realtor to request your offer in
                    writing using the appropriate contract for the state you are
                    purchasing the home in. You will have 24 hours to submit
                    your written offer.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              When are the terms and conditions of an offer presented?"
                  key="2"
                >
                  <p>
                    Just like a regular home purchase, the buyer and seller
                    negotiate the terms and conditions (such as inspections,
                    timing, repairs and more) before the offer is accepted by
                    either party. Once terms and conditions are agreed, the
                    buyer and seller enter into contract.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              What does the seller do once they’ve received my offer?"
                  key="2"
                >
                  <p>
                    The seller has the right to accept, counter or reject any
                    offer.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              If I have the winning contract, do I get to do inspections?"
                  key="2"
                >
                  <p>
                    This is the same as the traditional home-buying procedure in
                    that you and the seller must have agreed to allow
                    inspections in the terms and conditions. The terms and
                    conditions are accepted by the seller when your offer wins.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              How do I get Yivado certified as a real estate agent?"
                  key="2"
                >
                  <p>
                    Please <a href="/contact">contact us</a>, if you’re looking
                    to become certified on Yivado.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              How is Yivado different from an online property auction?"
                  key="3"
                >
                  <p>
                    An auction generally focuses on distressed properties. Our
                    platform has all available properties that have been listed
                    using Yivado, regardless of what shape they’re in.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              How long will I have to submit my written offer?"
                  key="4"
                >
                  <p>
                    If yours is the winning offer, you will have 24 hours to
                    submit a written offer after the seller has accepted your
                    proposal.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              If I have my own agent, can I still use Yivado?"
                  key="5"
                >
                  <p>
                    Yes, using your agent or one of our certified agents is
                    recommended.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              Why is there such an emphasis on transparency?"
                  key="6"
                >
                  <p>
                    We want you to be more certain when making offers. By
                    cutting out the guesswork, the process goes more smoothly
                    and helps you get into the home you want quickly.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              What is a buyer's premium?"
                  key="7"
                >
                  <p>
                    In any real estate transaction, whether on Yivado or not,
                    the buyer typically pays a premium. This Buyer’s Premium is
                    rolled into the purchase price. The percentage of the
                    Buyer’s Premium can change with fluctuations in the market.
                    With Yivado, we can easily change who pays for what
                    depending on agreed-upon terms as well as the current
                    market.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              Do I have to pay Yivado separately once the home purchase has gone through?"
                  key="7"
                >
                  <p>
                    There are no upfront costs for Yivado. The (typically 3%)
                    buyer’s premium is the same as it would be in any regular
                    transaction. If you are financing, this fee is usually
                    rolled into your loan. Yivado takes a 0.5% commission from
                    the seller’s agent so they will typically get 2.5% on the
                    transaction instead of 3%.
                    <br /> <br />
                    Time is money and using Yivado significantly reduces the
                    time that both buyer’s agents and seller’s agents have to
                    commit to the home buying process.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              Does Yivado offer an automatic offer feature?"
                  key="8"
                >
                  <p>
                    Yes there is. You will be able to set up automatic offers
                    and set a limit so that you will not pay more than you were
                    planning on.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              Will I get any notifications if someone else submits a higher offer?"
                  key="9"
                >
                  <p>
                    Yes, you’ll get an email alerting you or you can stay
                    up-to-date by keeping track of the listing website.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              Is a non-refundable earnest deposit required?"
                  key="1"
                >
                  <p>
                    No, as with all real estate transactions, this isn’t
                    necessary. However, the seller reserves the right to request
                    a non-refundable earnest deposit. If they do, you will have
                    the option to accept or decline their terms.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              If I want to lock in the winning contract, do I have to sacrifice other buyers’ protections?"
                  key="1"
                >
                  <p>
                    No, you don’t have to give those up. This proceeds as a
                    normal real estate transaction would.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              Can you reserve or buy it now?"
                  key="1"
                >
                  <p>
                    Unfortunately, you are unable to because the seller has the
                    right to accept, reject, or counter any of the offers.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
          <Row justify="center" style={{ marginBottom: "200px" }}>
            <Col
              span={20}
              style={{
                textAlign: "left",
              }}
            >
              <Collapse>
                <Panel
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                  header="
              If I have any other questions, who can I ask?"
                  key="1"
                >
                  <p>
                    We recommend that you contact your agent for any additional
                    information.
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </Col>
      </Row>
      <ApplicationFooter />
    </Layout>
  );
};

export default FAQPage;
