import { useQuery } from "@apollo/client";
import { Col, ColProps, Row, Skeleton, Typography } from "antd";
import { Queries } from "api/queries";
import { ListingResponse } from "api/types/ResponseTypes";
import React from "react";
import { OfferForUser } from "types";
import { calculateBuyersPremium, determineMaxOffer } from "utils/utils";

const { Text } = Typography;

interface CurrentOfferInfoProps {
  listingId: string;
}

const CurrentOfferInfo = ({
  listingId,
}: CurrentOfferInfoProps): React.ReactElement => {
  const { loading, data } = useQuery<ListingResponse>(
    Queries.GET_OFFER_HEADER_INFO,
    {
      variables: { listingId: parseFloat(listingId) },
      pollInterval: 1000,
    }
  );

  const currentMaxOffer =
    data !== undefined
      ? determineMaxOffer(
          data.listing.auction.startingPrice,
          data.listing.auction.bids
        )
      : ({} as OfferForUser);

  const buyersPremiumAmount =
    data !== undefined
      ? calculateBuyersPremium(
          currentMaxOffer.offer,
          data.listing.buyersPremium / 100
        )
      : 0;

  //------------------------------------------------------------------------------------------
  // Private Components
  //------------------------------------------------------------------------------------------

  const contentLayoutColumnSpans: ColProps = {
    xs: { span: 12 },
    sm: { span: 12 },
    md: { span: 6 },
    lg: { span: 6 },
    xl: { span: 6 },
    xxl: { span: 6 },
  };

  const Content: React.FC = ({ children }) => {
    return <Col {...contentLayoutColumnSpans}>{children}</Col>;
  };

  return (
    <Col>
      {loading || data === undefined ? (
        <Skeleton />
      ) : (
        <Row justify="center">
          <Content>
            <OfferInfoSection
              title="Current Offer"
              text={`$${currentMaxOffer.offer.toLocaleString()}`}
            />
          </Content>
          <Content>
            <OfferInfoSection
              title="Buyer's Premium"
              text={`${data.listing.buyersPremium}%`}
            />
          </Content>
          <Content>
            <OfferInfoSection
              title="Purchase Price"
              text={`$${(
                buyersPremiumAmount + currentMaxOffer.offer
              ).toLocaleString()}`}
            />
          </Content>
          <Content>
            <OfferInfoSection
              title="Offers"
              text={data.listing.auction.bids.length}
            />
          </Content>
        </Row>
      )}
    </Col>
  );
};

export default CurrentOfferInfo;

interface OfferInfoSectionProps {
  title: string;
  text: number | string;
}

const OfferInfoSection = ({
  title,
  text,
}: OfferInfoSectionProps): React.ReactElement => (
  <Col
    style={{
      marginLeft: "auto",
      textAlign: "center",
      height: "fit-content",
    }}
  >
    <Text style={{ color: "rgba(50, 50, 50, .6)", fontSize: "18px" }} strong>
      {title}
    </Text>
    <br />
    <Text style={{ fontSize: "18px" }} strong>
      {text}
    </Text>
  </Col>
);
