import { useState } from "react";

/**
 * Forcing a component to re-render. Necessary for things like modals, etc.
 *
 * @returns function that will call setState to force an update
 */
export function useForceUpdate(): VoidFunction {
  const [value, setState] = useState(true);
  return () => setState(!value);
}
