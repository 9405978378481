//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import { gql, useQuery } from "@apollo/client";
import { Button, Result, Spin } from "antd";
import { ListingResponse } from "api/types/ResponseTypes";
import { UserContext } from "context/UserContext/UserContext";
import React, { useContext } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { YivadoRoute } from "utils/constants/YivadoRoute";
import ContextError from "utils/errors/ContextError";
import { useTitle } from "utils/hooks";
import SingleListingPageContent from "./SingleListingPageContent";

//------------------------------------------------------------------------------------------
// Props definitions
//------------------------------------------------------------------------------------------

interface MatchParams {
  listingId: string;
}

type SingleListingPageProps = RouteComponentProps<MatchParams>;

//------------------------------------------------------------------------------------------

const SingleListingPage = ({
  match,
}: SingleListingPageProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to hooks
  //------------------------------------------------------------------------------------------
  // const breakPoint = useBreakpoint();

  // const activeBreakPoints = Object.entries(breakPoint)
  //   .filter((screen) => screen[1])
  //   .map((screen) => screen[0]);

  useTitle(`${match.params.listingId}`);

  const userContext = useContext(UserContext);
  if (userContext === undefined) {
    throw new ContextError(ContextError.CONTEXT_UNDEFINED);
  }

  const history = useHistory();

  const { loading: listingLoading, data: listingData } =
    useQuery<ListingResponse>(
      gql`
        query GetListingBase($listingId: Float!) {
          listing(id: $listingId) {
            id
          }
        }
      `,
      {
        variables: { listingId: parseFloat(match.params.listingId) },
      }
    );

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return listingLoading ? (
    <Spin />
  ) : listingData === undefined ? (
    <Result
      status="404"
      title="Hmmm.."
      subTitle="Sorry, this listing does not exist."
      extra={(
        <Button
          onClick={() => history.push(YivadoRoute.BROWSE_LISTINGS)}
          type="primary"
        >
          Back to Listings
        </Button>
      )}
    />
  ) : (
    <SingleListingPageContent
      listingId={match.params.listingId}
      currentUser={userContext.user}
    />
  );
};

export default SingleListingPage;
