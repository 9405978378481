//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { DownOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Col,
  Dropdown,
  Layout,
  List,
  Menu,
  Popconfirm,
  Row,
} from "antd";
import { Mutations } from "api/mutations";
import { Queries } from "api/queries";
import { BidsForListing, VoidedBidsForListing } from "api/types/ResponseTypes";
import { Bid } from "model/Bid";
import { VoidedBid } from "model/VoidedBid";
import React, { useEffect, useState } from "react";
import Logger from "utils/logging/Logger";
import { toTitleCase } from "utils/utils";

//------------------------------------------------------------------------------------------
// Constants
//------------------------------------------------------------------------------------------

//------------------------------------------------------------------------------------------
// Interfaces and props
//------------------------------------------------------------------------------------------

interface OffersProps {
  listingId: string;
}

//------------------------------------------------------------------------------------------

const Offers = ({ listingId }: OffersProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to hooks
  //------------------------------------------------------------------------------------------

  const [allOffers, setAllOffers] = useState<{
    active: Bid[];
    voided: VoidedBid[];
  }>({ active: [], voided: [] });

  const [voidOfferLoading, setVoidOfferLoading] = useState(false);

  const [voidOfferMutation] = useMutation(Mutations.VOID_OFFER);

  const {
    loading: nonVoidedBidsLoading,
    data: nonVoidedBids,
    error: queryError,
  } = useQuery<BidsForListing>(Queries.GET_OFFERS_FOR_LISTING, {
    variables: { listingId: parseFloat(listingId) },
    pollInterval: 500,
  });

  const { data: voidedBids } = useQuery<VoidedBidsForListing>(
    Queries.GET_VOIDED_OFFERS_FOR_LISTING,
    {
      variables: { listingId: parseFloat(listingId) },
      pollInterval: 500,
    }
  );

  useEffect(() => {
    let sortedVoidedBids: VoidedBid[] = [];
    let sortedActiveBids: Bid[] = [];

    if (nonVoidedBids !== undefined) {
      sortedActiveBids = [...nonVoidedBids.bids].sort(
        (a, b) => b.amountBid - a.amountBid
      );
    }

    if (voidedBids !== undefined) {
      sortedVoidedBids = [...voidedBids.voidedBids].sort(
        (a, b) => b.amountBid - a.amountBid
      );
    }

    setAllOffers({ active: sortedActiveBids, voided: sortedVoidedBids });
  }, [nonVoidedBids, voidedBids]);

  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  if (queryError) {
    Logger.error(JSON.stringify(queryError, null, 2));
    return <div>Error</div>;
  }

  const voidOffer = (bidId: string): void => {
    setVoidOfferLoading(true);
    voidOfferMutation({ variables: { bidId: parseFloat(bidId) } })
      .then(() => {
        setVoidOfferLoading(false);
      })
      .catch((err) => {
        Logger.error(err);
        setVoidOfferLoading(false);
      });
  };

  //------------------------------------------------------------------------------------------
  // All of the columns
  //------------------------------------------------------------------------------------------

  const contactDropDown = (
    buyerEmail: string,
    agentEmail: string
  ): React.ReactElement => (
    <Dropdown
      key="contact"
      overlay={(
        <Menu>
          <Menu.Item key="BUYER">
            <a href={`mailto:${buyerEmail}`}>Buyer</a>
          </Menu.Item>
          <Menu.Item key="AGENT">
            <a href={`mailto:${agentEmail}`}>Agent</a>
          </Menu.Item>
        </Menu>
      )}
    >
      <Button type="primary">
        Contact <DownOutlined />
      </Button>
    </Dropdown>
  );

  const voidOfferButton = (bid: Bid): React.ReactElement | null => (
    <Popconfirm
      title="Are you sure you want to void this offer?"
      onConfirm={() => voidOffer(bid.id)}
      okText="Yes"
      cancelText="No"
    >
      <Button danger key={`delete-${bid.id}`} loading={voidOfferLoading}>
        {voidOfferLoading ? "Voiding" : "Void"} Offer
      </Button>
    </Popconfirm>
  );

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <Layout>
      <Row justify="center" style={{ height: "60vh", overflowY: "scroll" }}>
        <Col xs={24} lg={16}>
          {nonVoidedBidsLoading || nonVoidedBids !== undefined ? (
            <List
              itemLayout="horizontal"
              dataSource={[...allOffers.active, ...allOffers.voided]}
              style={{
                height: "1 1 auto",
              }}
              renderItem={(item, index) => {
                const isVoided = index > allOffers.active.length - 1;
                const endDate = new Date(item.auction.endDate);
                const now = new Date();
                const isActive = endDate > now;

                return (
                  <List.Item
                    style={{
                      background: isVoided
                        ? "#FFCCCB"
                        : index % 2 === 0
                        ? ""
                        : "#eee",
                      borderRadius: 10,
                    }}
                    actions={
                      index === 0 && !isVoided && isActive
                        ? [
                            contactDropDown(
                              item.buyerUser.contactEmail,
                              item.buyerAgent.user!.contactEmail
                            ),
                            voidOfferButton(item),
                          ]
                        : [
                            contactDropDown(
                              item.buyerUser.contactEmail,
                              item.buyerAgent.user!.contactEmail
                            ),
                          ]
                    }
                  >
                    <List.Item.Meta
                      style={{ padding: 10 }}
                      title={<b>${item.amountBid.toLocaleString()}</b>}
                      description={(
                        <b>
                          {item.buyerUser.firstName} {item.buyerUser.lastName} -{" "}
                          {isVoided
                            ? "Offer Voided"
                            : `Submitted by ${toTitleCase(
                                item.bidSubmittedBy
                              )}`}
                        </b>
                      )}
                    />
                  </List.Item>
                );
              }}
            />
          ) : (
            <div>Error</div>
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default Offers;
