import { YivadoRoute } from "utils/constants/YivadoRoute";

interface DashboardConfig {
  cardUrl: YivadoRoute;
  cardCover: {
    alt: string;
    src: string;
  };
  cardMeta: {
    title: string;
    description: string;
  };
}

export const CERTIFIED_DASHBOARD_CONFIGS: Array<DashboardConfig> = [
  {
    cardUrl: YivadoRoute.MY_LISTINGS,
    cardCover: {
      alt: "my-listings",
      src: "https://static-assets-platform.s3.amazonaws.com/DashboardPage/listings.svg",
    },
    cardMeta: {
      title: "My Listings",
      description: "Create and View Listings",
    },
  },
  {
    cardUrl: YivadoRoute.MY_BUYERS,
    cardCover: {
      alt: "my-buyers",
      src: "https://static-assets-platform.s3.amazonaws.com/DashboardPage/family.svg",
    },
    cardMeta: {
      title: "My Buyers",
      description: "Your Linked Buyers",
    },
  },
  {
    cardUrl: YivadoRoute.AGENT_RESOURCES,
    cardCover: {
      alt: "agent-resources",
      src: "https://static-assets-platform.s3.amazonaws.com/DashboardPage/resources.svg",
    },
    cardMeta: {
      title: "Tools",
      description: "Helpful Agent Resources",
    },
  },
  {
    cardUrl: YivadoRoute.TRAINING,
    cardCover: {
      alt: "trainings",
      src: "https://static-assets-platform.s3.amazonaws.com/DashboardPage/training.svg",
    },
    cardMeta: {
      title: "Training",
      description: "Learn and Train",
    },
  },
];

export const UNCERTIFIED_DASHBOARD_CONFIGS: Array<DashboardConfig> = [
  {
    cardUrl: YivadoRoute.HOW_TO_VIDEOS,
    cardCover: {
      alt: "how-to-videos",
      src: "https://static-assets-platform.s3.amazonaws.com/DashboardPage/video-marketing.svg",
    },
    cardMeta: {
      title: "How To Videos",
      description: "Learn How Yivado Works",
    },
  },
  {
    cardUrl: YivadoRoute.TERMS_AND_CONDITIONS,
    cardCover: {
      alt: "terms-and-cond",
      src: "https://static-assets-platform.s3.amazonaws.com/DashboardPage/terms.svg",
    },
    cardMeta: {
      title: "Terms and Cond.",
      description: "Yivado Terms & Conditions",
    },
  },
  {
    cardUrl: YivadoRoute.MY_BUYERS,
    cardCover: {
      alt: "my-buyerss",
      src: "https://static-assets-platform.s3.amazonaws.com/DashboardPage/family.svg",
    },
    cardMeta: {
      title: "My Buyers",
      description: "Your Linked Buyers",
    },
  },
  {
    cardUrl: YivadoRoute.TRAINING,
    cardCover: {
      alt: "trainings",
      src: "https://static-assets-platform.s3.amazonaws.com/DashboardPage/training.svg",
    },
    cardMeta: {
      title: "Get Certified",
      description: "Learn and Become Certified",
    },
  },
];
