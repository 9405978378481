//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import { useMutation, useQuery } from "@apollo/client";
import { Col, Divider, Layout, Modal, Row, Skeleton, Space } from "antd";
import { Mutations } from "api/mutations";
import { Queries } from "api/queries";
import { ListingResponse } from "api/types/ResponseTypes";
import { Approved, ApprovedStatus } from "model/Approved";
import { User } from "model/User";
import React, { useEffect, useState } from "react";
import { OfferForUser } from "types";
import Logger from "utils/logging/Logger";
import { determineMaxOffer } from "utils/utils";
import AddressUrl from "../AddressUrl";
import MakeOffer from "../MakeOffer";
import UpdateMaxBid from "../UpdateMaxBid";
import MakeOfferButton from "./MakeOfferButton";
import { HeaderQuery } from "./query";

//------------------------------------------------------------------------------------------
/// Component Wide Constants
//------------------------------------------------------------------------------------------

// const { Countdown } = Statistic;

//------------------------------------------------------------------------------------------
/// Props Definitions
//------------------------------------------------------------------------------------------

interface SingleListingHeaderProps {
  listingId: string;
  currentUser?: User;
}

//------------------------------------------------------------------------------------------

const SingleListingHeader = ({
  listingId,
  currentUser,
}: SingleListingHeaderProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to hooks
  //------------------------------------------------------------------------------------------

  const [offerOpen, switchOfferOpen] = useState(false);
  const [offerMakingApproval, setOfferMakingApproval] = useState<
    ApprovedStatus | "none" | "canNoLongerRegister"
  >("none");
  const [updateMaxBidOpen, setUpdateMaxBidOpen] = useState(false);

  const [createApproval, { loading }] = useMutation(
    Mutations.CREATE_NEW_APPROVAL
  );

  const { data: listingResponse } = useQuery<ListingResponse>(HeaderQuery, {
    variables: { listingId: parseFloat(listingId) },
    pollInterval: 1000,
  });

  const { loading: approvalsLoading, data } = useQuery(
    Queries.GET_APPROVALS_FOR_USER,
    {
      skip: currentUser === undefined,
      variables: {
        userId: currentUser !== undefined ? parseFloat(currentUser.id) : -1,
      },
      pollInterval: 1000,
    }
  );

  const listingData = listingResponse?.listing;

  useEffect(() => {
    if (data === undefined || listingData === undefined) return;

    const approvalRecordForListing: Approved = data.approvalsForUser.find(
      (record: Approved) => record.listing.id === listingData.id
    );

    if (approvalRecordForListing === undefined) {
      const now = new Date();
      const lastRegistration = new Date(listingData.lastRegistrationDay);
      if (now > lastRegistration) setOfferMakingApproval("canNoLongerRegister");
      return;
    }

    setOfferMakingApproval(approvalRecordForListing.approvalStatus);
  }, [data, listingData]);

  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const toggleOffer = (): void => {
    switchOfferOpen(!offerOpen);
  };

  const toggleUpdateMaxBid = (): void => {
    setUpdateMaxBidOpen(!updateMaxBidOpen);
  };

  const currentUserMadeOffer = (): void => {
    Modal.info({
      title: <b>You currently have the highest offer.</b>,
      content:
        "Since you currently have the highest offer, we cannot let you make anymore at this time.",
    });
  };

  const createNewApprovalRecord = (): void => {
    createApproval({
      variables: {
        approvalRecordData: {
          userId: parseFloat(currentUser!.id),
          listingId: parseFloat(listingData!.id),
        },
      },
    })
      .then(() => {
        Modal.success({
          title: "Your registration for making offers is now pending",
        });
      })
      .catch((err) => Logger.error(JSON.stringify(err, null, 2)));
  };

  //------------------------------------------------------------------------------------------
  // Constants
  //------------------------------------------------------------------------------------------

  if (listingData === undefined) return <Skeleton />;

  const now = new Date();
  const offerWindowOpen =
    listingData !== undefined &&
    now.toISOString() > listingData.auction.startDate &&
    now.toISOString() < listingData.auction.endDate;
  // const isFuture = now.toISOString() < listingData.auction.startDate;

  const currentMaxOffer =
    listingData !== undefined
      ? determineMaxOffer(
          listingData.auction.startingPrice,
          listingData.auction.bids
        )
      : ({} as OfferForUser);

  const canMakeOffers =
    offerWindowOpen && offerMakingApproval === ApprovedStatus.APPROVED;

  const currentUserAutoBidRecord = listingData.auction.autoBids.find(
    (autoBid) => {
      if (autoBid.autoBidAmount <= currentMaxOffer.offer) return false;
      return autoBid.buyerUser.id === currentUser?.id;
    }
  );

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <Layout>
      <Row
        justify="center"
        style={{
          marginTop: "15px solid white",
          paddingTop: "15px solid white",
          marginBottom: "10px",
          background: "#FFFFFF",
        }}
      >
        <Col xs={24} sm={24} md={8}
          lg={8} xl={8} xxl={6}>
          <AddressUrl listingId={listingData.id} />
        </Col>

        <Col flex="auto" />
        <Space align="end">
          <Col
            style={{
              padding: "10px",
              position: "relative",
            }}
            xs={12}
            sm={12}
            md={8}
            lg={10}
            xl={6}
            xxl={6}
          >
            <MakeOfferButton
              approvalsLoading={approvalsLoading}
              canMakeOffers={canMakeOffers}
              currentUser={currentUser}
              currentUserMadeOffer={currentUserMadeOffer}
              toggleOffer={toggleOffer}
              offerMakingApproval={offerMakingApproval}
              createNewApprovalRecord={createNewApprovalRecord}
              newApprovalLoading={loading}
              currentMaxOffer={currentMaxOffer}
              listingAgentId={listingData.listingAgent.id}
              listingId={listingData.id}
              currentUserAutoBidRecord={currentUserAutoBidRecord}
              toggleUpdateMaxBid={toggleUpdateMaxBid}
            />
          </Col>{" "}
        </Space>
        <MakeOffer
          isOpen={offerOpen}
          onCancel={toggleOffer}
          offerDetails={{
            currentMaxPrice: currentMaxOffer.offer,
            currentAuctionId: listingData.auction.id,
            numberOfBids: listingData.auction.bids.length,
            currentMaxBidUserId: currentMaxOffer.userId,
          }}
          buyersPremium={listingData.buyersPremium}
        />
        <UpdateMaxBid
          isOpen={updateMaxBidOpen}
          onCancel={toggleUpdateMaxBid}
          currentUserAutoBidRecord={currentUserAutoBidRecord}
          currentMaxOffer={currentMaxOffer.offer}
          buyersPremium={listingData.buyersPremium}
        />
      </Row>
      <Row justify="center">
        <Col style={{ marginTop: "-20px" }} span={24}>
          <Row justify="start">
            <Divider />
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default SingleListingHeader;

// Small change
