import { ShareAltOutlined, UserOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import {
  Avatar,
  Button,
  Col,
  Row,
  Skeleton,
  Statistic,
  Tooltip,
  Typography,
} from "antd";
import { Queries } from "api/queries";
import { ListingResponse } from "api/types/ResponseTypes";
import moment from "moment";
import React, { useState } from "react";
import { useProfilePicture } from "utils/hooks";
import ContactAgent from "./ContactAgent";

//------------------------------------------------------------------------------------------
/// Component Wide Constants
//------------------------------------------------------------------------------------------

const { Countdown } = Statistic;

interface ListingInformationProps {
  listingId: string;
}

const { Text } = Typography;

const ListingInfo = ({
  listingId,
}: ListingInformationProps): React.ReactElement => {
  const { data } = useQuery<ListingResponse>(Queries.GET_LISTING_INFO_SECTION, {
    variables: { listingId: parseFloat(listingId) },
  });

  const [contactAgentOpen, setContactAgentOpen] = useState(false);
  const agentPicture = useProfilePicture(
    data?.listing.listingAgent.user?.storageBucketId
  );

  // 11/19/2021 10:00:10&11/19/2021 11:00:00,12/21/2021 09:00:37&12/21/2021 11:30:04
  const openHouseDates = data?.listing.openHouseDates ? (
    data?.listing.openHouseDates.split(",").map((item) => {
      const singleDays = item.split("&");
      const start = moment(singleDays[0]);
      const end = moment(singleDays[1]);
      return (
        <React.Fragment key={item}>
          {start.format("dddd, MMMM Do YYYY, ha")}-{end.format("ha")}
          <br />
        </React.Fragment>
      );
    })
  ) : (
    <React.Fragment>No open house dates</React.Fragment>
  );

  const now = new Date();
  const isFuture =
    data !== undefined && now.toISOString() < data.listing.auction.startDate;

  const copyUrlToClip = (): void => {
    const dummy = document.createElement("input"),
      text = window.location.href;

    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  const toggleContactAgent = (): void => {
    setContactAgentOpen(!contactAgentOpen);
  };

  return data === undefined ? (
    <Skeleton />
  ) : (
    <>
      <Row
        justify="center"
        style={{
          paddingTop: "15px",
          paddingBottom: "10px",
          backgroundColor: "#F0F0F0",
          marginBottom: "5px",
        }}
      >
        <Col>
          <Countdown
            className="listing-countdown"
            style={{
              background: "#F0F0F0",
              textAlign: "center",
              maxWidth: "300px",
            }}
            value={
              isFuture
                ? data.listing.auction.startDate
                : data.listing.auction.endDate
            }
            title={isFuture ? "Starts In:" : "Offer Period Ends:"}
            format="DD [Days], HH [H], mm [m], ss [s]"
            valueStyle={{
              fontSize: "large",
              fontWeight: "bold",
            }}
          />
        </Col>
      </Row>
      <Row
        justify="start"
        style={{
          paddingTop: "15px",
          paddingBottom: "10px",
          paddingLeft: "15px",
          backgroundColor: "#F0F0F0",
        }}
      >
        <Text
          style={{
            color: "rgba(50, 50, 50, .6)",
            fontSize: "15px",
          }}
          strong
        >
          Offers Accepted Until:
        </Text>
      </Row>
      <Row
        justify="start"
        style={{
          marginBottom: "5px",
          backgroundColor: "#F0F0F0",
          paddingBottom: "15px",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        {" "}
        <Text
          style={{
            color: "rgba(25, 25, 25, .8)",
            fontSize: "15px",
          }}
          strong
        >
          {moment(data?.listing.auction.endDate).format(
            "dddd, MMMM Do YYYY, h:mm a"
          )}
        </Text>
      </Row>
      <Row
        justify="start"
        style={{
          paddingTop: "15px",
          paddingBottom: "10px",
          paddingLeft: "15px",
          backgroundColor: "#F0F0F0",
        }}
      >
        <Text
          style={{
            color: "rgba(50, 50, 50, .6)",
            fontSize: "15px",
          }}
          strong
        >
          Registration Ends:
        </Text>
      </Row>
      <Row
        justify="start"
        style={{
          marginBottom: "5px",
          backgroundColor: "#F0F0F0",
          paddingBottom: "15px",
          paddingLeft: "15px",
        }}
      >
        {" "}
        <Text
          style={{
            color: "rgba(25, 25, 25, .8)",
            fontSize: "15px",
          }}
          strong
        >
          {moment(data?.listing.lastRegistrationDay).format(
            "dddd, MMMM Do YYYY, h:mm a"
          )}
        </Text>
      </Row>
      <Row
        justify="start"
        style={{
          paddingTop: "15px",
          paddingBottom: "10px",
          paddingLeft: "15px",
          backgroundColor: "#F0F0F0",
        }}
      >
        <Text
          style={{
            color: "rgba(50, 50, 50, .6)",
            fontSize: "15px",
          }}
          strong
        >
          Open House(s):
        </Text>
      </Row>

      <Row
        justify="start"
        style={{
          marginBottom: "5px",
          backgroundColor: "#F0F0F0",
          paddingBottom: "15px",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <Text
          style={{
            color: "rgba(25, 25, 25, .8)",
            fontSize: "15px",
          }}
          strong
        >
          {openHouseDates}
        </Text>
      </Row>
      <Row
        justify="center"
        style={{
          marginBottom: "5px",
          backgroundColor: "#F0F0F0",
          padding: "15px",
        }}
      >
        <Col>
          <Tooltip
            placement="bottom"
            title="Copied to clipboard"
            trigger="click"
          >
            <Button
              style={{ width: "200px" }}
              type="primary"
              onClick={copyUrlToClip}
              icon={<ShareAltOutlined />}
            >
              Share Listing
            </Button>
          </Tooltip>
        </Col>
      </Row>
      <Row
        justify="start"
        style={{
          paddingTop: "15px",
          paddingBottom: "10px",
          paddingLeft: "15px",
          backgroundColor: "#F0F0F0",
        }}
      >
        <Text
          style={{
            color: "rgba(50, 50, 50, .6)",
            fontSize: "15px",
          }}
          strong
        >
          Listed By:
        </Text>
      </Row>
      <Row
        justify="center"
        style={{
          marginBottom: "5px",
          backgroundColor: "#F0F0F0",
          paddingBottom: "15px",
        }}
      >
        <Col
          xs={{ span: 7 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 7 }}
          style={{
            marginLeft: "15px",
            textAlign: "center",
          }}
        >
          <Avatar src={agentPicture} size={80} icon={<UserOutlined />} />
        </Col>
        <Col
          xs={{ span: 13 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 13 }}
          style={{
            backgroundColor: "#F0F0F0",
            textAlign: "center",
          }}
        >
          {" "}
          <Text
            style={{
              color: "rgba(25, 25, 25, .8)",
              fontSize: "15px",
            }}
            strong
          >
            {data?.listing.listingAgent.user?.firstName}{" "}
            {data?.listing.listingAgent.user?.lastName}
            <br />
            {data?.listing.listingAgent.broker} <br />
            {data?.listing.listingAgent.user?.phoneNumber}
            <br />
            {/* MLS #: {data?.listing.listingAgent.licenseNumber} */}
          </Text>
        </Col>
        <Col style={{ marginTop: "15px" }}>
          <Button
            style={{ width: "200px" }}
            type="primary"
            onClick={toggleContactAgent}
          >
            Contact Agent
          </Button>
        </Col>
      </Row>
      <ContactAgent
        isOpen={contactAgentOpen}
        close={toggleContactAgent}
        contactInformation={{
          name: `${data.listing.listingAgent.user?.firstName} ${data.listing.listingAgent.user?.lastName}`,
          email: data.listing.listingAgent.user!.email,
          phoneNumber: data.listing.listingAgent.user!.phoneNumber,
          listingAddress: data.listing.address,
        }}
      />
    </>
  );
};

export default ListingInfo;
