import { Col, Form, Input, Row, Select, Typography } from "antd";
import React from "react";

const { Title } = Typography;
const { Option } = Select;
const { Item: FormItem } = Form;

interface AgentInformationProps {
  isAgent: string;
  setIsAgent: (value: string) => void;
}

const AgentInformation = ({
  isAgent,
  setIsAgent,
}: AgentInformationProps): React.ReactElement => {
  return (
    <>
      <Row justify="center">
        <Col
          xs={16}
          lg={14}
          xl={10}
          xxl={6}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
            className="required-label"
          >
            Are You a Real Estate Agent?
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={16} lg={14} xl={10}
          xxl={6}>
          <FormItem
            name="isAgent"
            rules={[
              {
                required: true,
                message: "You must specify whether or not you are an agent",
              },
              () => ({
                validator(_, value) {
                  setIsAgent(value);
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select style={{ width: "100%" }}>
              <Option value="No">No</Option>
              <Option value="Yes">Yes</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      {isAgent === "Yes" && (
        <>
          <Row justify="center">
            <Col
              xs={16}
              lg={14}
              xl={10}
              xxl={6}
              style={{
                textAlign: "left",
              }}
            >
              <Title
                level={4}
                style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
                className="required-label"
              >
                Broker
              </Title>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              xs={16}
              lg={14}
              xl={10}
              xxl={6}
              style={{
                textAlign: "left",
              }}
            >
              <FormItem
                name="agentBroker"
                rules={[
                  {
                    required: true,
                    message: "You must specify your broker",
                  },
                ]}
              >
                <Input />
              </FormItem>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              xs={16}
              lg={14}
              xl={10}
              xxl={6}
              style={{
                textAlign: "left",
              }}
            >
              <Title
                level={4}
                style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
                className="required-label"
              >
                MLS ID #
              </Title>
            </Col>
          </Row>
          <Row justify="center">
            <Col
              xs={16}
              lg={14}
              xl={10}
              xxl={6}
              style={{
                textAlign: "left",
              }}
            >
              <FormItem
                name="agentLicenseNumber"
                rules={[
                  {
                    required: true,
                    message: "You must specify your broker",
                  },
                ]}
              >
                <Input />
              </FormItem>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default AgentInformation;
