import { Layout } from "antd";
import ApplicationFooter from "components/ApplicationFooter";
import React from "react";
import HowItWorksInformation from "./HowItWorksInformation";

const HowItWorksPage = (): React.ReactElement => {
  return (
    <Layout style={{ overflowX: "hidden" }}>
      <HowItWorksInformation />
      <ApplicationFooter />
    </Layout>
  );
};

export default HowItWorksPage;
