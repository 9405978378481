import { Col, Image, Layout, Row, Typography } from "antd";
import React, { CSSProperties } from "react";

const { Title, Text } = Typography;

const TitleH1Styles: CSSProperties = {
  //fontSize: "52px",
  color: "black",
  fontWeight: "bold",
  textAlign: "center",
};

const TitleH3Styles: CSSProperties = {
  // fontSize: "24px",
  color: "black",
  fontWeight: "bolder",
  marginTop: "15px",
};

const ColStyles: CSSProperties = {
  textAlign: "center",
  width: "16rem",
  margin: "30px",
  marginTop: "2rem",
};

const ContentInformation = (): React.ReactElement => {
  return (
    <Layout>
      <Row
        justify="center"
        style={{
          marginTop: "120px",
          marginBottom: "120px",
        }}
      >
        <Col style={{ width: "1600px" }}>
          <Row justify="start">
            <Col
              xs={24}
              md={24}
              lg={24}
              xl={8}
              xxl={6}
              style={{ textAlign: "center" }}
            >
              <Image
                style={{
                  border: "1px",
                  borderColor: "#000000",
                  borderStyle: "solid",
                }}
                preview={false}
                width={300}
                src="https://static-assets-platform.s3.amazonaws.com/FrontPage/confidence-frontpage.png"
              />
            </Col>
            <Col
              xs={20}
              md={20}
              lg={20}
              xl={14}
              xxl={14}
              style={{ margin: "30px", paddingLeft: "50px" }}
            >
              <Title
                level={1}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "start",
                }}
              >
                Submit your Offer with Confidence. <br />
                Get the Home You Want.
              </Title>

              <Text
                style={{
                  textAlign: "center",
                  fontWeight: "lighter",
                  fontSize: "20px",
                }}
              >
                <br />
                Have you ever felt concerned about being left out of the loop
                after you make an offer on a home? With real estate taking off
                like crazy all over the country, it’s getting harder to get the
                home you really want. In this market, you can easily miss out on
                a property without knowing why your offer didn’t make the cut.
                <br />
                <br />
                With <b>Yivado</b>, you get full transparency throughout the
                home buying process. You’ll never be left wondering what you
                could’ve done differently. Yivado keeps you up-to-date in
                real-time and puts the power in your hands so you can get the
                home you want.
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        justify="center"
        style={{
          backgroundColor: "#1DA57A",
          paddingTop: "120px",
          paddingBottom: "120px",
        }}
      >
        <Col
          style={{
            backgroundColor: "#FFFFFF",
            padding: "30px",
            width: "1400px",
            borderRadius: "50px",
          }}
        >
          <Row justify="center">
            <Col
              xs={20}
              md={20}
              lg={20}
              xl={12}
              xxl={12}
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              <Title level={1} style={{ ...TitleH1Styles, fontSize: "44px" }}>
                4 Easy Steps
              </Title>
            </Col>
          </Row>

          <Row justify="center">
            <Col
              xs={20}
              md={20}
              lg={20}
              xl={14}
              xxl={24}
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "40px",
              }}
            >
              <Row
                gutter={36}
                justify="center"
                style={{
                  marginTop: "20px",
                }}
              >
                <Col style={ColStyles}>
                  <img
                    src="/assets/registration-icon.png"
                    alt="Create Your Profile"
                    width="100px"
                    height="100px"
                  />
                  <Title
                    level={3}
                    style={{ ...TitleH3Styles, fontSize: "28px" }}
                  >
                    1. Get Registered
                  </Title>
                  <Text style={{ fontSize: "18px" }}>
                    <a href="/authentication">Create your account</a> and get
                    registed on the property listing that you want.
                  </Text>
                </Col>
                <Col style={ColStyles}>
                  <img
                    src="/assets/offers-icon.png"
                    alt="Submit Your OFfer"
                    width="100px"
                    height="100px"
                  />
                  <Title
                    level={3}
                    style={{ ...TitleH3Styles, fontSize: "28px" }}
                  >
                    2. Submit Your Offer
                  </Title>
                  <Text style={{ fontSize: "18px" }}>
                    You already know which house you want - now go get it!
                  </Text>
                </Col>
                <Col style={ColStyles}>
                  <Image
                    preview={false}
                    alt="You Win the Home"
                    width={100}
                    src="https://static-assets-platform.s3.amazonaws.com/FrontPage/winning-offer.png"
                  />
                  <Title
                    level={3}
                    style={{ ...TitleH3Styles, fontSize: "28px" }}
                  >
                    3. You Won!
                  </Title>
                  <Text style={{ fontSize: "18px" }}>
                    Mutually accepted contract proceeds through the normal
                    escrow process.
                  </Text>
                </Col>
                <Col style={ColStyles}>
                  <img
                    src="/assets/contract-icon.png"
                    alt="Make It Yours"
                    width="100px"
                    height="100px"
                  />
                  <Title
                    level={3}
                    style={{ ...TitleH3Styles, fontSize: "28px" }}
                  >
                    4. Get Your Keys!
                  </Title>
                  <Text style={{ fontSize: "18px" }}>
                    Welcome to your new home!
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default ContentInformation;
