import { Button, Col, Layout, Row, Typography } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { YivadoRoute } from "utils/constants/YivadoRoute";
import { BannerStyles, TitleH1Styles, TitleH2Styles } from "./styles";

const { Title } = Typography;

const BannerInformation = (): React.ReactElement => {
  const history = useHistory();

  return (
    <Layout id="banner">
      <Row justify="center" style={BannerStyles}>
        <Col
          style={{
            marginTop: "26%",
          }}
        >
          <Row justify="center">
            <Col>
              <Title level={1} style={TitleH1Styles}>
                See Where You Stand From The Start
              </Title>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <Title level={2} style={TitleH2Styles}>
                Yivado Brings Transparency To Real Estate
              </Title>
              <Row justify="center">
                <Col style={{ margin: "10px" }}>
                  <Button
                    type="primary"
                    shape="round"
                    style={{ fontSize: "22px", width: "200px", height: "50px" }}
                    onClick={() => history.push("/authentication?register=Y")}
                    size="large"
                  >
                    Register Now!
                  </Button>
                </Col>
                <Col style={{ margin: "10px" }}>
                  <Button
                    type="primary"
                    shape="round"
                    style={{ fontSize: "22px", width: "220px", height: "50px" }}
                    onClick={() => history.push(YivadoRoute.BUY)}
                    size="large"
                  >
                    What Is Yivado?
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default BannerInformation;
