import { BackTop, Col, Layout, Row, Spin, Tooltip } from "antd";
import { ApolloQueryClient } from "api/ApolloQueryClient";
import { Queries } from "api/queries";
import { GraphQlResponse, UserResponse } from "api/types/ResponseTypes";
import ErrorBoundary from "components/ErrorBoundary";
import NavigationBar from "components/NavigationBar/NavigationBar";
import Routing from "components/Routing";
import { UserContext, UserContextType } from "context/UserContext/UserContext";
import AESEncryption from "crypto-js/aes";
import CryptoUtf8 from "crypto-js/enc-utf8";
import jwtDecode from "jwt-decode";
import { User } from "model/User";
import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useHistory, useLocation } from "react-router-dom";
import { DecodedAuthToken } from "types";
import AuthUtils from "utils/auth/AuthUtils";
import Color from "utils/constants/Color";
import { YivadoRoute } from "utils/constants/YivadoRoute";
import Logger from "utils/logging/Logger";
// import S3Access from "utils/storage/S3Access";
import S3Access from "utils/storage";
import { cacheUserData } from "utils/utils";

const { Content } = Layout;
const s3 = new S3Access();

function App(): React.ReactElement {
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  const [userImage, setUserImage] = useState("");
  const value: UserContextType = {
    user: currentUser,
    setUser: setCurrentUser,
    userImage: userImage,
    setUserImage: setUserImage,
  };
  const [checkingForUser, setCheckingForUser] = useState(true);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const storedUser = localStorage.getItem("user");

    if (authToken && storedUser) {
      const bytes = AESEncryption.decrypt(
        storedUser,
        process.env.REACT_APP_ENCRYPTION_KEY!
      );
      const decryptedUser: User = JSON.parse(bytes.toString(CryptoUtf8));
      setCurrentUser(decryptedUser);
      setCheckingForUser(false);
      // TODO: FIGURE OUT AUTOMATIC ROUTING

      // If the user is an agent and they are on the home page, we want to reroute them
      if (decryptedUser.isAgent && location.pathname === YivadoRoute.FRONT_PAGE)
        history.push(YivadoRoute.DASHBOARD);
      else if (location.pathname === YivadoRoute.FRONT_PAGE)
        history.push(YivadoRoute.BROWSE_LISTINGS);
    }

    const updateUser = () => {
      setCheckingForUser(true);
      try {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) {
          setCheckingForUser(false);
          return;
        }

        const storedUser = localStorage.getItem("user");
        if (storedUser) {
          setCheckingForUser(false);
        }

        const decoded: DecodedAuthToken = jwtDecode(authToken);

        const queryClient = new ApolloQueryClient();
        queryClient
          .callApolloQuery(Queries.GET_USER_QUERY, {
            email: decoded.email,
          })
          .then((response: GraphQlResponse<UserResponse>) => {
            setCurrentUser(response.data.user);

            // Encrypt the users data and store in local storage
            cacheUserData(response.data.user);
            setCheckingForUser(false);

            if (
              response.data.user.isAgent &&
              location.pathname === YivadoRoute.FRONT_PAGE
            )
              history.push(YivadoRoute.DASHBOARD);
            else if (location.pathname === YivadoRoute.FRONT_PAGE)
              history.push(YivadoRoute.BROWSE_LISTINGS);

            s3.getSingleUrlFromS3(
              `users/${response.data.user.storageBucketId}/profilePicture.png`
            ).then((value) => {
              setUserImage(value);
            });
          });
      } catch (error) {
        Logger.error(error);
        setCheckingForUser(false);
      }
    };

    updateUser();
  }, [history, location]);

  const signUserOut = (): void => {
    const authUtils = new AuthUtils();
    authUtils.signOut();
    history.push(YivadoRoute.FRONT_PAGE);
    setCurrentUser(undefined);
    setUserImage("");
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <UserContext.Provider value={value}>
        <Layout>
          <NavigationBar signUserOut={signUserOut} />
          <ErrorBoundary key={location.pathname}>
            <Layout
              style={{
                background: Color.WHITE,
              }}
            >
              <Content style={{ marginTop: "64px" }}>
                {checkingForUser ? (
                  <Layout style={{ paddingTop: "40px" }}>
                    <Row justify="center">
                      <Col>
                        <Spin
                          tip="We are getting everything set up for you..."
                          size="large"
                        />
                      </Col>
                    </Row>
                  </Layout>
                ) : (
                  <>
                    <Tooltip title="Back to top">
                      <BackTop />
                    </Tooltip>
                    <Routing />
                  </>
                )}
              </Content>
            </Layout>
          </ErrorBoundary>
        </Layout>
      </UserContext.Provider>
    </DndProvider>
  );
}

export default App;
