import { Col, Form, Input, Row, Typography } from "antd";
import MaskedInput from "antd-mask-input";
import React from "react";

const { Title } = Typography;
const { Item: FormItem } = Form;

const ContactInformation = (): React.ReactElement => {
  return (
    <>
      <Row justify="center">
        <Col
          xs={16}
          lg={14}
          xl={10}
          xxl={6}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
            className="required-label"
          >
            Contact Email Address
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={16} lg={14} xl={10}
          xxl={6}>
          <FormItem
            name="contactEmail"
            rules={[{ required: true, message: "Contact Email is required" }]}
          >
            <Input
              style={{
                textAlign: "left",
                width: "100%",
                marginTop: "0.25rem",
              }}
            />
          </FormItem>
        </Col>
      </Row>
      <Row justify="center">
        <Col
          xs={16}
          lg={14}
          xl={10}
          xxl={6}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
            className="required-label"
          >
            Registration Email Address
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={16} lg={14} xl={10}
          xxl={6}>
          <FormItem name="email">
            <Input
              disabled
              style={{
                textAlign: "left",
                width: "100%",
                marginTop: "0.25rem",
              }}
            />
          </FormItem>
        </Col>
      </Row>
      <Row justify="center">
        <Col
          xs={16}
          lg={14}
          xl={10}
          xxl={6}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            Mobile Phone Number
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={16} lg={14} xl={10}
          xxl={6}>
          <FormItem name="phoneNumber">
            <MaskedInput
              style={{
                textAlign: "left",
                width: "100%",
                marginTop: "0.25rem",
              }}
              mask="(111) 111-1111"
              name="card"
            />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default ContactInformation;
