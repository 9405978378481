import { Layout } from "antd";
import ApplicationFooter from "components/ApplicationFooter";
import React from "react";
import VendorInformation from "./VendorsInformation";

const VendorPage = (): React.ReactElement => {
  return (
    <Layout>
      <VendorInformation />
      <ApplicationFooter />
    </Layout>
  );
};

export default VendorPage;
