import { DownOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Button, Col, Dropdown, Menu, Row, Skeleton } from "antd";
import { Queries } from "api/queries";
import { ListingResponse } from "api/types/ResponseTypes";
import React, { CSSProperties } from "react";
import { UploadDocType } from "utils/constants/UploadDocType";
import S3Listing from "utils/storage/S3Listing";
import { downloadBlob } from "utils/utils";

const listingS3 = new S3Listing();

interface ActionProps {
  listingId: string;
}

const Actions = ({ listingId }: ActionProps): React.ReactElement => {
  const { data } = useQuery<ListingResponse>(Queries.GET_ACTIONS_INFO, {
    variables: { listingId: parseFloat(listingId) },
  });

  const buttonStyles: CSSProperties = { width: "200px" };

  return data === undefined ? (
    <Skeleton />
  ) : (
    <Row
      justify="center"
      style={{
        backgroundColor: "#F0F0F0",
        padding: "15px",
        marginTop: "5px",
      }}
    >
      <Col>
        <Row
          justify="center"
          style={{
            paddingBottom: "15px",
            paddingTop: "15px",
          }}
        >
          <Dropdown
            overlay={(
              <DropdownMenu
                storageBucketId={data.listing.listingPicturesBucketId}
              />
            )}
          >
            <Button style={buttonStyles} type="primary">
              Download Docs <DownOutlined />
            </Button>
          </Dropdown>
        </Row>
      </Col>
    </Row>
  );
};

export default Actions;

interface DropdownMenuProps {
  storageBucketId: string;
}
const DropdownMenu = ({
  storageBucketId,
}: DropdownMenuProps): React.ReactElement => {
  const downloadDoc = async (docType: UploadDocType) => {
    const doc = await listingS3.getDocument(storageBucketId, docType);
    if (doc) {
      downloadBlob(doc, `${docType}.pdf`);
    } else {
      S3Listing.noListingDoc(docType);
    }
  };

  return (
    <Menu>
      <Menu.Item onClick={() => downloadDoc(UploadDocType.INSPECTION)}>
        Inspection
      </Menu.Item>
      <Menu.Item onClick={() => downloadDoc(UploadDocType.DISCLOSURE)}>
        Disclosure
      </Menu.Item>
      <Menu.Item onClick={() => downloadDoc(UploadDocType.MISC)}>
        Misc
      </Menu.Item>
    </Menu>
  );
};
