//------------------------------------------------------------------------------------------
// Sliders for number selections
//------------------------------------------------------------------------------------------

interface SliderConfig {
  name: string;
  numMarks: number;
  labelText: string;
  requiredMessage: string;
  step: number;
}

export const sliders: SliderConfig[] = [
  {
    name: "numberOfBedrooms",
    numMarks: 10,
    labelText: "Bedrooms",
    requiredMessage: "Please choose number of bedrooms",
    step: 1,
  },
  {
    name: "numberOfBathrooms",
    numMarks: 10,
    labelText: "Bathrooms",
    requiredMessage: "Please choose number of bathrooms",
    step: 0.25,
  },
  {
    name: "numberOfFloors",
    numMarks: 5,
    labelText: "Floors",
    requiredMessage: "Please choose number of floors",
    step: 1,
  },
  {
    name: "garageSpaces",
    numMarks: 6,
    labelText: "Garage Spaces",
    requiredMessage: "Please choose number of spaces",
    step: 1,
  },
];

//------------------------------------------------------------------------------------------
// Checkboxes for appliances and misc items
//------------------------------------------------------------------------------------------

interface ApplianceCheckboxConfig {
  name: string;
  text: string;
}

export const homeFeatures: ApplianceCheckboxConfig[] = [
  {
    name: "hasOfficeOrDen",
    text: "Office or Den",
  },
  {
    name: "hasLoft",
    text: "Loft",
  },
  {
    name: "hasBasement",
    text: "Basement",
  },
  {
    name: "hasSolar",
    text: "Solar",
  },
  {
    name: "hasPrivatePool",
    text: "Private Pool",
  },
  {
    name: "hasCommunityPool",
    text: "Community Pool",
  },
  {
    name: "hasSpaOrJacuzzi",
    text: "Spa or Jacuzzi",
  },
  {
    name: "hasFireplace",
    text: "Fireplace",
  },
  {
    name: "hasGolfCourseLot",
    text: "Golf Course Lot",
  },
  {
    name: "hasGolfCourseCommunity",
    text: "Golf Course Community",
  },
  {
    name: "hasLakeCommunity",
    text: "Lake Community",
  },
  {
    name: "hasWaterfrontLot",
    text: "Waterfront Lot",
  },
  {
    name: "hasTennisCourts",
    text: "Tennis Courts",
  },
  {
    name: "hasGatedCommunity",
    text: "Gated Community",
  },
  {
    name: "hasMountainViews",
    text: "Mountain Views",
  },
  {
    name: "hasCommunityClubHouse",
    text: "Community Club House",
  },
  {
    name: "includesOtherHomeFeatures",
    text: "Other",
  },
];

export const personalProperty: ApplianceCheckboxConfig[] = [
  {
    name: "hasWasherAndDryer",
    text: "Washer and Dryer",
  },
  {
    name: "hasFridge",
    text: "Refrigerator",
  },
  {
    name: "hasAboveGroundSpa",
    text: "Above Ground Spa",
  },
  {
    name: "includesOtherProperty",
    text: "Other",
  },
];
