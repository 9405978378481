import { StringMap } from "types";

export enum Timezone {
  PACIFIC = "pacific",
  MOUNTAIN = "mountain",
  CENTRAL = "central",
  EASTERN = "eastern",
}

interface TZoneConfig {
  label: string;
  value: string;
  utcOffsetStandard: number;
  utcOffsetDaylight: number;
}

export const TimezoneMap: StringMap<TZoneConfig> = {
  [Timezone.PACIFIC]: {
    value: "America/Los_Angeles",
    label: "Pacific",
    utcOffsetStandard: -8,
    utcOffsetDaylight: -7,
  },
  [Timezone.MOUNTAIN]: {
    value: "America/Denver",
    label: "Mountain",
    utcOffsetStandard: -7,
    utcOffsetDaylight: -6,
  },
  [Timezone.CENTRAL]: {
    value: "America/Chicago",
    label: "Central",
    utcOffsetStandard: -6,
    utcOffsetDaylight: -5,
  },
  [Timezone.EASTERN]: {
    value: "America/New_York",
    label: "Eastern",
    utcOffsetStandard: -5,
    utcOffsetDaylight: -4,
  },
};
