import { Layout } from "antd";
import ApplicationFooter from "components/ApplicationFooter";
import React from "react";
import PrivacyInformation from "./PrivacyInformation";

const PrivacyPage = (): React.ReactElement => {
  return (
    <Layout>
      <PrivacyInformation />
      <ApplicationFooter />
    </Layout>
  );
};

export default PrivacyPage;
