class ContextError extends Error {
  static readonly CONTEXT_UNDEFINED =
    "The context was undefined. Something must've went wrong";

  constructor(public message: string) {
    super(message);
    this.name = "ContextError";
    this.stack = (<any>new Error()).stack;
  }
}

export default ContextError;
