/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import jwtDecode from "jwt-decode";
import { DecodedAuthToken } from "types";
import {
  ChangePasswordResponse,
  ConfirmRepsonse,
  RequestPasswordResetResponse,
  ResetPasswordResponse,
  SignInResponse,
  SignUpResponse,
} from "utils/auth/authTypes";
import Logger from "utils/logging/Logger";

enum AuthRoute {
  LOGIN = "/login",
  REGISTER = "/register",
  CONFIRM = "/confirm",
  REQUEST_RESET = "/forgotPassword",
  RESET_PASSWORD = "/resetPassword",
  CHANGE_PASSWORD = "/changePassword",
}

export default class AuthUtils {
  baseUrl: string;

  constructor() {
    this.baseUrl = `${process.env.REACT_APP_AUTHENTICATION_SERVICE}/auth`;
  }

  //-----------------------------------------------------------------------------------------

  /**
   * Signing a user in with the amplify sdk
   *
   * @param userName the users email
   * @param password the password
   * @returns signin response for error handling
   */
  async signIn(email: string, password: string): Promise<SignInResponse> {
    const signInResponse = await this.makeAuthCall<SignInResponse>(
      AuthRoute.LOGIN,
      { email, password }
    );

    return signInResponse;
  }

  //-----------------------------------------------------------------------------------------

  /**
   * Signing User out with Amplify
   */
  async signOut(): Promise<void> {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
  }

  //-----------------------------------------------------------------------------------------

  /**
   * Updating the current users password
   *
   * @param oldPassword the users old password
   * @param newPassword the users new password
   */
  async updatePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<ChangePasswordResponse> {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) throw new Error("There is no user logged in.");

      const decoded: DecodedAuthToken = jwtDecode(authToken);

      return await this.makeAuthCall<ChangePasswordResponse>(
        AuthRoute.CHANGE_PASSWORD,
        {
          email: decoded.email,
          oldPassword,
          newPassword,
        }
      );
    } catch (err: any) {
      Logger.error(err);
      return {
        success: false,
        code: err.code,
        message: err.message,
      };
    }
  }

  //-----------------------------------------------------------------------------------------

  async signUp(fieldValues: any): Promise<SignUpResponse> {
    return await this.makeAuthCall<SignUpResponse>(
      AuthRoute.REGISTER,
      fieldValues
    );
  }

  //-----------------------------------------------------------------------------------------

  async confirmSignUp(
    email: string,
    confirmationCode: string
  ): Promise<ConfirmRepsonse> {
    try {
      return await this.makeAuthCall<ConfirmRepsonse>(AuthRoute.CONFIRM, {
        email,
        confirmationCode,
      });
    } catch (error: any) {
      return {
        confirmationSuccessful: false,
        code: error.code,
        message: error.message,
      };
    }
  }

  //-----------------------------------------------------------------------------------------

  async userForgotPassword(email: string): Promise<any> {
    try {
      return await this.makeAuthCall<RequestPasswordResetResponse>(
        AuthRoute.REQUEST_RESET,
        { email }
      );
    } catch (err) {
      Logger.error(JSON.stringify(err, null, 2));
      return null;
    }
  }

  //-----------------------------------------------------------------------------------------

  async submitPasswordReset(
    email: string,
    code: string,
    newPassword: string
  ): Promise<ResetPasswordResponse> {
    try {
      return await this.makeAuthCall<ResetPasswordResponse>(
        AuthRoute.RESET_PASSWORD,
        {
          email,
          forgotPasswordCode: code,
          newPassword,
        }
      );
    } catch (err) {
      Logger.error(err);
      return {
        code: "UnexpectedError",
        message: "Something unexpected happened",
        resetSuccessful: false,
      };
    }
  }

  //-----------------------------------------------------------------------------------------

  private async makeAuthCall<T>(route: AuthRoute, bodyValues: any): Promise<T> {
    const url = `${this.baseUrl}${route}`;
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyValues),
    });

    const responseBody: T = await response.json();

    return responseBody;
  }
}
