import { Col, Layout, Row, Typography } from "antd";
import ApplicationFooter from "components/ApplicationFooter";
import React from "react";
import { Link } from "react-router-dom";
import { YivadoRoute } from "utils/constants/YivadoRoute";

const { Title, Paragraph } = Typography;

const HowToVideos = (): React.ReactElement => {
  return (
    <Layout
      id="help-info"
      style={{
        backgroundColor: "#1DA57A",
        overflowX: "hidden",
      }}
    >
      <Row
        justify="center"
        style={{
          backgroundColor: "#1DA57A",
          paddingTop: "120px",
          paddingBottom: "120px",
          marginBottom: "240px",
        }}
      >
        <Col
          style={{
            backgroundColor: "#FFFFFF",
            padding: "30px",
            width: "1200px",
            borderRadius: "50px",
          }}
        >
          <Row justify="center">
            <Col
              span={22}
              style={{ textAlign: "center", marginBottom: "30px" }}
            >
              <Title
                level={1}
                style={{
                  color: "black",
                  fontSize: "32px",
                  fontWeight: "bold",
                  marginTop: "60px",
                }}
              >
                How To Videos
              </Title>
            </Col>
          </Row>
          <Row justify="center" style={{ marginBottom: "240px" }}>
            <Col style={{ width: "800px" }}>
              <Paragraph style={{ fontSize: "18px" }}>
                Here at Yivado we have developed informational content for
                Buyers, Sellers and Agents. <br />
                <br />
                If you have any direct questions or comments, please reach out
                to us using our{" "}
                <Link to={YivadoRoute.CONTACT}>contact page</Link>.
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
      <ApplicationFooter />
    </Layout>
  );
};

export default HowToVideos;
