import { Button, Col, Image, Row, Typography } from "antd";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext/UserContext";

const { Title, Text } = Typography;

const RealtorInformation = (): React.ReactElement => {
  const history = useHistory();

  const userContext = useContext(UserContext);

  return (
    <>
      <Row
        justify="center"
        style={{
          backgroundColor: "#1DA57A",
          paddingTop: "120px",
        }}
      >
        <Col
          xs={20}
          md={20}
          lg={14}
          xl={12}
          xxl={12}
          style={{
            backgroundColor: "#FFFFFF",
            textAlign: "center",
            paddingBottom: "40px",
            borderRadius: "50px",
          }}
        >
          <Row justify="center">
            <Col span={18}>
              <Title
                level={1}
                style={{
                  backgroundColor: "#FFFFFF",
                  marginTop: "50px",
                  fontWeight: "bolder",
                  fontSize: "50px",
                }}
              >
                Yivado Agents
              </Title>
            </Col>
          </Row>

          <Row justify="center">
            <Col span={20} style={{ textAlign: "left", marginTop: "20px" }}>
              <b style={{ fontSize: "20px" }}>Yivado </b>
              <span
                style={{
                  backgroundColor: "#FFFFFF",
                  marginTop: "20px",
                  fontWeight: "lighter",
                  fontSize: "20px",
                }}
              >
                simplifies the home offer process and gives you a much better
                chance of getting the property you want. Unlike the traditional
                home purchasing process, Yivado allows the buyers and sellers to
                see all of the offers on a particular property. With this
                visibility, you can truly bring your highest and best.
              </span>
            </Col>
          </Row>

          <Title
            level={2}
            style={{
              backgroundColor: "#FFFFFF",
              marginTop: "60px",
              fontWeight: "bolder",
              fontSize: "40px",
              textAlign: "center",
            }}
          >
            Why Yivado?
          </Title>

          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
                marginTop: "20px",
                marginBottom: "40px",
              }}
            >
              <Text
                style={{
                  backgroundColor: "#FFFFFF",
                  marginTop: "20px",
                  fontWeight: "lighter",
                  fontSize: "20px",
                }}
              >
                Sellers can ask for exactly what they want from the get-go and
                buyers can match that because they can see what the seller wants
                for their property. This much more transparent process saves
                everyone time and stress, including buyers, sellers, and agents.
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        justify="center"
        style={{
          backgroundColor: "#1DA57A",
          paddingTop: "120px",
          paddingBottom: "120px",
        }}
      >
        <Col
          style={{
            backgroundColor: "#FFFFFF",
            padding: "30px",
            width: "1200px",
            borderRadius: "50px",
          }}
        >
          <Row justify="space-around">
            <Col
              xs={{ span: 20, order: 2 }}
              md={{ span: 20, order: 2 }}
              lg={{ span: 20, order: 2 }}
              xl={{ span: 12, order: 1 }}
              xxl={{ span: 12, order: 1 }}
            >
              <Title
                level={2}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginTop: "60px",
                }}
              >
                Informed Purchase
              </Title>
              <Row
                justify="start"
                style={{
                  marginTop: "20px",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontWeight: "lighter",
                    fontSize: "20px",
                  }}
                >
                  Instead of getting just some of the information you need to
                  make an informed offer, get all the information you need to
                  make this your new home.
                </Text>
              </Row>
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              md={{ span: 24, order: 1 }}
              lg={{ span: 24, order: 1 }}
              xl={{ span: 6, order: 2 }}
              xxl={{ span: 6, order: 2 }}
            >
              <Row justify="center">
                <Image
                  preview={false}
                  width={320}
                  src="https://static-assets-platform.s3.amazonaws.com/FrontPage/informed-purchase.png"
                />
              </Row>
            </Col>
          </Row>
          <Row
            justify="space-around"
            style={{
              marginTop: "60px",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Col
              xs={{ span: 24, order: 1 }}
              md={{ span: 24, order: 1 }}
              lg={{ span: 24, order: 1 }}
              xl={{ span: 6, order: 2 }}
              xxl={{ span: 6, order: 2 }}
            >
              <Row justify="center">
                <Image
                  preview={false}
                  width={320}
                  src="https://static-assets-platform.s3.amazonaws.com/FrontPage/real-time-offers.png"
                />
              </Row>
            </Col>
            <Col
              xs={{ span: 20, order: 2 }}
              md={{ span: 20, order: 2 }}
              lg={{ span: 20, order: 2 }}
              xl={{ span: 12, order: 2 }}
              xxl={{ span: 12, order: 2 }}
            >
              <Title
                level={2}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginTop: "60px",
                }}
              >
                Offer in Real-Time
              </Title>

              <Row
                justify="start"
                style={{
                  marginTop: "20px",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontWeight: "lighter",
                    fontSize: "20px",
                  }}
                >
                  The top offer is updated the moment it is entered. Put your
                  best foot forward (and get it in the door) with access to all
                  current offers.
                </Text>
              </Row>
            </Col>
          </Row>
          <Row justify="space-around">
            <Col
              xs={{ span: 20, order: 2 }}
              md={{ span: 20, order: 2 }}
              lg={{ span: 20, order: 2 }}
              xl={{ span: 12, order: 1 }}
              xxl={{ span: 12, order: 1 }}
            >
              <Title
                level={2}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginTop: "60px",
                }}
              >
                Get Your Keys Faster
              </Title>
              <Row
                justify="start"
                style={{
                  marginTop: "20px",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontWeight: "lighter",
                    fontSize: "20px",
                  }}
                >
                  We speed up the closing process by giving buyers access to
                  review disclosures, inspections, and tour homes before making
                  any offers. This avoids any confusion or delays at closing
                  time.
                </Text>
              </Row>
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              md={{ span: 24, order: 1 }}
              lg={{ span: 24, order: 1 }}
              xl={{ span: 6, order: 2 }}
              xxl={{ span: 6, order: 2 }}
            >
              <Row justify="center">
                <Image
                  preview={false}
                  width={320}
                  src="https://static-assets-platform.s3.amazonaws.com/FrontPage/faster-keys.png"
                />
              </Row>
            </Col>
          </Row>
          <Row
            justify="center"
            style={{ textAlign: "center", marginTop: "60px" }}
          >
            <Col span={12}>
              <Text
                style={{
                  fontSize: "24px",
                  fontWeight: "bolder",
                }}
              >
                {" "}
                Finding your perfect home shouldn&apos;t be a hassle.
                <br />
                Get clarity and peace of mind with Yivado.
              </Text>
            </Col>
          </Row>
          {(userContext === undefined || userContext.user === undefined) && (
            <Row justify="center" style={{ margin: "60px" }}>
              <Button
                type="primary"
                shape="round"
                size="large"
                style={{ fontSize: "22px", width: "400px", height: "50px" }}
                onClick={() => history.push("/authentication?register=Y")}
              >
                Get Registered!
              </Button>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default RealtorInformation;
