import AdminPage from "pages/AdminPage";
import AgentDashboard from "pages/AgentPages/Dashboard";
import MyBuyersPage from "pages/AgentPages/MyBuyers";
import MyListingsPage from "pages/AgentPages/MyListings";
import AgentResourcesPage from "pages/AgentPages/Resources";
import Authentication from "pages/Authentication";
import Confirmation from "pages/Authentication/Confirmation";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import BuyerDashboardPage from "pages/BuyerDashboardPage";
import BuyerApprovedPage from "pages/BuyerRegPage";
import ContactPage from "pages/ContactPage";
import EditListingPage from "pages/EditListingPage";
import FrontPage from "pages/FrontPage";
import GetCertificationPage from "pages/GetCertificationPage";
import BrowseListings from "pages/ListingPages/BrowseListings";
import ListingCreationPage from "pages/ListingPages/ListingCreationPage";
import ListingManagement from "pages/ListingPages/ListingManagement";
import MyOffersPage from "pages/MyOffersPage";
import ProfilePage from "pages/ProfilePage";
import RequestRealtorPage from "pages/RequestRealtorPage";
import SingleListingPage from "pages/SingleListingPage";
import AboutPage from "pages/StaticContentPages/AboutPage";
import BuyingFAQPage from "pages/StaticContentPages/FAQPages/BuyingFAQPage";
import FAQPage from "pages/StaticContentPages/FAQPages/FAQPage";
import RealtorFAQPage from "pages/StaticContentPages/FAQPages/RealtorFAQPage";
import SellingFAQPage from "pages/StaticContentPages/FAQPages/SellingFAQPage";
import HowItWorksPage from "pages/StaticContentPages/HowPages/HowItWorksPage";
import HowToVideos from "pages/StaticContentPages/HowPages/HowToVideos";
import PrivacyPage from "pages/StaticContentPages/PrivacyPage";
import TermsPage from "pages/StaticContentPages/TermsPage";
import TrainingPage from "pages/TrainingPage";
import VendorsPage from "pages/VendorsPage";
import { ReactElement } from "react";
import { YivadoRoute } from "utils/constants/YivadoRoute";

interface RouteItem {
  path: YivadoRoute;
  component: (props: any) => ReactElement;
  isPrivate: boolean;
}

const RoutingConfig: Array<RouteItem> = [
  {
    path: YivadoRoute.FRONT_PAGE,
    component: FrontPage,
    isPrivate: false,
  },
  {
    path: YivadoRoute.BROWSE_LISTINGS,
    component: BrowseListings,
    isPrivate: false,
  },
  {
    path: YivadoRoute.LISTING_CREATION,
    component: ListingCreationPage,
    isPrivate: true,
  },
  {
    path: YivadoRoute.SINGLE_LISTING,
    component: SingleListingPage,
    isPrivate: false,
  },
  {
    path: YivadoRoute.EDIT_LISTING,
    component: EditListingPage,
    isPrivate: true,
  },
  {
    path: YivadoRoute.MANAGE_LISTING,
    component: ListingManagement,
    isPrivate: true,
  },
  {
    path: YivadoRoute.PROFILE,
    component: ProfilePage,
    isPrivate: true,
  },
  {
    path: YivadoRoute.HOW_IT_WORKS,
    component: HowItWorksPage,
    isPrivate: false,
  },
  {
    path: YivadoRoute.REQUEST_REALTOR,
    component: RequestRealtorPage,
    isPrivate: true,
  },
  {
    path: YivadoRoute.GET_CERTIFICATION,
    component: GetCertificationPage,
    isPrivate: false,
  },
  {
    path: YivadoRoute.ABOUT,
    component: AboutPage,
    isPrivate: false,
  },
  {
    path: YivadoRoute.ADMIN,
    component: AdminPage,
    isPrivate: true,
  },
  {
    path: YivadoRoute.MY_LISTINGS,
    component: MyListingsPage,
    isPrivate: true,
  },
  {
    path: YivadoRoute.DASHBOARD,
    component: AgentDashboard,
    isPrivate: true,
  },
  {
    path: YivadoRoute.BUYER_DASHBOARD,
    component: BuyerDashboardPage,
    isPrivate: true,
  },
  {
    path: YivadoRoute.APPROVED_LISTINGS,
    component: BuyerApprovedPage,
    isPrivate: true,
  },
  {
    path: YivadoRoute.MY_BUYERS,
    component: MyBuyersPage,
    isPrivate: true,
  },
  {
    path: YivadoRoute.MY_OFFERS,
    component: MyOffersPage,
    isPrivate: true,
  },
  {
    path: YivadoRoute.AGENT_RESOURCES,
    component: AgentResourcesPage,
    isPrivate: true,
  },
  {
    path: YivadoRoute.CONTACT,
    component: ContactPage,
    isPrivate: false,
  },
  {
    path: YivadoRoute.FAQ,
    component: FAQPage,
    isPrivate: false,
  },
  {
    path: YivadoRoute.HOW_TO_VIDEOS,
    component: HowToVideos,
    isPrivate: false,
  },
  {
    path: YivadoRoute.BUY,
    component: BuyingFAQPage,
    isPrivate: false,
  },
  {
    path: YivadoRoute.SELL,
    component: SellingFAQPage,
    isPrivate: false,
  },
  {
    path: YivadoRoute.REALTORS,
    component: RealtorFAQPage,
    isPrivate: false,
  },
  {
    path: YivadoRoute.VENDORS,
    component: VendorsPage,
    isPrivate: false,
  },
  {
    path: YivadoRoute.PRIVACY,
    component: PrivacyPage,
    isPrivate: false,
  },
  {
    path: YivadoRoute.TERMS_AND_CONDITIONS,
    component: TermsPage,
    isPrivate: false,
  },
  {
    path: YivadoRoute.AUTHENTICATION,
    component: Authentication,
    isPrivate: false,
  },
  {
    path: YivadoRoute.TRAINING,
    component: TrainingPage,
    isPrivate: true,
  },
  {
    path: YivadoRoute.RECOVER,
    component: ForgotPassword,
    isPrivate: false,
  },
  {
    path: YivadoRoute.CONFIRM,
    component: Confirmation,
    isPrivate: false,
  },
];

export default RoutingConfig;
