import { Col, Layout, Row, Typography } from "antd";
import React from "react";

const { Title } = Typography;

const GetCertificationInformation = (): React.ReactElement => {
  return (
    <Layout>
      <Row
        justify="center"
        style={{
          backgroundColor: "#1DA57A",
          paddingTop: "120px",
          paddingBottom: "2000px",
        }}
      >
        <Col
          xs={20}
          md={20}
          lg={14}
          xl={12}
          xxl={12}
          style={{
            backgroundColor: "#FFFFFF",
            textAlign: "center",
            paddingBottom: "40px",
          }}
        >
          <Title level={1} style={{ marginTop: "2rem", fontWeight: "bolder" }}>
            Get Yivado Certified
          </Title>

          <Title level={2} style={{ marginTop: "2rem", fontWeight: "bolder" }}>
            Watch Our Training Videos
          </Title>

          <Title level={2} style={{ marginTop: "2rem", fontWeight: "bolder" }}>
            Ask Us Any Questions
          </Title>

          <Title level={2} style={{ marginTop: "2rem", fontWeight: "bolder" }}>
            Take and Pass Certification Exam
          </Title>
        </Col>
      </Row>
    </Layout>
  );
};

export default GetCertificationInformation;
