//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { Card, Col, Row, Tag } from "antd";
import { Approved, ApprovedStatus } from "model/Approved";
import React from "react";
import { useHistory } from "react-router-dom";
import { determineMaxOffer } from "utils/utils";

//------------------------------------------------------------------------------------------
// Interfaces/Props
//------------------------------------------------------------------------------------------

interface BuyerRegCardProps {
  approval: Approved;
}

//------------------------------------------------------------------------------------------
// Component Definition
//------------------------------------------------------------------------------------------

export const BuyerRegCard = ({
  approval,
}: BuyerRegCardProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to hooks
  //------------------------------------------------------------------------------------------

  const history = useHistory();

  //------------------------------------------------------------------------------------------
  /// Helpers/Handlers
  //------------------------------------------------------------------------------------------

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------
  const { approvalStatus } = approval;

  const tagColor =
    approvalStatus === ApprovedStatus.PENDING
      ? "geekblue"
      : approvalStatus === ApprovedStatus.APPROVED
      ? "green"
      : "red";

  return (
    <Card
      title={(
        <>
          <Row style={{ justifyContent: "space-between", fontSize: "24px" }}>
            <Col>
              <b
                className="my-listings-card-title"
                onClick={() => history.push(`/listing/${approval.listing.id}`)}
              >
                {approval.listing.address}
              </b>
            </Col>
            <Col>
              <Tag style={{ fontSize: "14px" }} color={tagColor}>
                {approvalStatus}
              </Tag>
            </Col>
          </Row>
        </>
      )}
    >
      <Row
        key="high-offer"
        justify="space-between"
        style={{ marginBottom: "10px" }}
      >
        <Col>
          <b>Highest Offer:</b>
        </Col>
        <Col>
          $
          {determineMaxOffer(
            approval.listing.auction.startingPrice,
            approval.listing.auction.bids
          ).offer.toLocaleString()}
        </Col>
      </Row>
      <Row
        key="total-offers"
        justify="space-between"
        style={{ marginBottom: "10px" }}
      >
        <Col>
          <b>Total Offers:</b>
        </Col>
        <Col>{approval.listing.auction.bids.length}</Col>
      </Row>
    </Card>
  );
};

export default BuyerRegCard;
