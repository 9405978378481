import { Layout, Row } from "antd";
import NotFound from "components/Routing/NotFound";
import { UserContext } from "context/UserContext/UserContext";
import React, { useContext } from "react";
import ContextError from "utils/errors/ContextError";

const AdminPage = (): React.ReactElement => {
  const userContext = useContext(UserContext);
  if (userContext === undefined || userContext.user === undefined) {
    throw new ContextError(ContextError.CONTEXT_UNDEFINED);
  }

  const { user } = userContext;
  if (!user.isAdmin) {
    return <NotFound />;
  }

  return (
    <Layout style={{ paddingTop: "40px" }}>
      <Row justify="center">Here is the admin page</Row>
    </Layout>
  );
};

export default AdminPage;
