import { CameraOutlined, UploadOutlined } from "@ant-design/icons";
import { Avatar, Badge, Col, Row, Tooltip } from "antd";
import React, { useState } from "react";
import UploadProfilePicture from "./UploadProfilePicture";

interface ProfilePageHeaderProps {
  userImage: string;
}

const ProfilePageHeader = ({
  userImage,
}: ProfilePageHeaderProps): React.ReactElement => {
  const [uploadOpen, setUploadOpen] = useState(false);

  const toggleUpload = () => {
    setUploadOpen(!uploadOpen);
  };

  return (
    <>
      <Row justify="center" style={{ marginTop: "2rem" }}>
        <Col>
          <Tooltip
            title={
              userImage === ""
                ? "Upload a Profile Picture"
                : "Update Profile Picture"
            }
          >
            <Badge count={<CameraOutlined />}>
              <Avatar
                shape="circle"
                size={200}
                style={{ backgroundColor: "#1DA57A", cursor: "pointer" }}
                icon={<UploadOutlined />}
                src={userImage}
                onClick={toggleUpload}
              />
            </Badge>
          </Tooltip>
        </Col>
      </Row>
      <UploadProfilePicture isOpen={uploadOpen} close={toggleUpload} />
    </>
  );
};

export default ProfilePageHeader;
