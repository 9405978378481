import { Col, Layout, Row, Typography } from "antd";
import ApplicationFooter from "components/ApplicationFooter";
import React from "react";
import Color from "utils/constants/Color";

const { Title, Text } = Typography;

const AboutPage = (): React.ReactElement => {
  return (
    <Layout style={{ overflowX: "hidden" }}>
      <Layout
        style={{
          background: Color.WHITE,
        }}
      >
        <Row
          justify="center"
          style={{
            backgroundColor: "#1DA57A",
            paddingTop: "120px",
            paddingBottom: "140px",
          }}
        >
          <Col
            xs={20}
            md={20}
            lg={14}
            xl={12}
            xxl={12}
            style={{
              backgroundColor: "#FFFFFF",
              textAlign: "center",
              paddingBottom: "40px",
              borderRadius: "50px",
            }}
          >
            <Row justify="center" style={{ marginTop: "60px" }}>
              <Title level={1} style={{ fontWeight: "bolder" }}>
                Who We Are
              </Title>
            </Row>
            <Row justify="center">
              <Col span={18}>
                <Row justify="space-around">
                  <Col
                    span={6}
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    <Row justify="center">
                      <img
                        src="https://static-assets-platform.s3.amazonaws.com/Jesse_headshot.JPG"
                        alt="Jesse Dau Headshot"
                        width="200px"
                        height="200px"
                      />
                    </Row>
                    <Row justify="center">
                      <Text style={{ width: "200px" }}>Jesse Dau</Text>
                    </Row>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    <Row justify="center">
                      <img
                        src="https://static-assets-platform.s3.amazonaws.com/Jackon_headshot.JPG"
                        alt="Jackson Wilkey Headshot"
                        width="200px"
                        height="200px"
                      />
                    </Row>
                    <Row justify="center">
                      <Text style={{ width: "200px" }}>Jackson Wilkey</Text>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              justify="center"
              style={{ marginTop: "40px", marginBottom: "100px" }}
            >
              <Col span={20}>
                <Text
                  style={{
                    fontSize: "18px",
                  }}
                >
                  Jackson Wilkey and Jesse Dau, a.k.a. The YouTube Agents, are
                  the duo that rocked the real estate world. These two took a
                  look at dusty real estate practices, and thought it was about
                  time to shake things up a little. They thought outside the
                  lines and created a new path for other agents as well as
                  better experiences for clients. From countless hours of
                  research and years of experience, they found ways to majorly
                  improve the real estate process.
                  <br />
                  <br />
                  These two have tried just about everything you can try when it
                  comes to the home buying and home selling processes. At first,
                  this trial and error led to spending way too much time and
                  money all while garnering no leads. Changing course, they
                  discovered that search engine optimization (SEO) was the key
                  to bringing in potential clients based on the content they
                  were creating and helping those clients learn about the city
                  they were thinking about moving to. They really wanted to
                  emphasize efficiency by having their content do way more than
                  any traditional techniques could. From the days with no leads
                  to changing the real estate landscape forever and now Yivado,
                  Jackson, and Jesse are breaking through any barriers that
                  traditional real estate could ever throw in front of them.
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
      <ApplicationFooter />
    </Layout>
  );
};

export default AboutPage;
