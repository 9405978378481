//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import { useMutation } from "@apollo/client";
import { Button, Col, Form, Layout, Modal, Row } from "antd";
import { Mutations } from "api/mutations";
import { UserContext } from "context/UserContext/UserContext";
import React, { useContext, useState } from "react";
import Color from "utils/constants/Color";
import ContextError from "utils/errors/ContextError";
import Logger from "utils/logging/Logger";
import S3Buyer from "utils/storage/S3Buyer";
import { cacheUserData, formatPhoneNumber } from "utils/utils";
import MyAgent from "./MyAgent";
import AgentInformation from "./ProfileInformation/AgentInformation";
import ContactInformation from "./ProfileInformation/ContactInformation";
import NameInformation from "./ProfileInformation/NameInformation";
import NotificationInformation from "./ProfileInformation/NotificationInformation";
import PasswordInformation from "./ProfileInformation/PasswordInformation";
import PreApprovalDocs from "./ProfileInformation/PreApprovalDocs";
import ProfilePageHeader from "./ProfilePageHeader";

//------------------------------------------------------------------------------------------
/// Component Wide Constants
//------------------------------------------------------------------------------------------

const buyerS3 = new S3Buyer();

//------------------------------------------------------------------------------------------

const ProfilePage = (): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  /// Calls to hooks
  //------------------------------------------------------------------------------------------

  const userContext = useContext(UserContext);
  if (userContext === undefined) {
    throw new ContextError(ContextError.CONTEXT_UNDEFINED);
  }

  if (userContext.user === undefined) {
    throw new ContextError("The user is undefined");
  }

  const [form] = Form.useForm();
  const [formChanged, setFormChanged] = useState(false);
  const [formIsSaving, setFormSaving] = useState(false);
  const [updateUser] = useMutation(Mutations.UPDATE_USER_INFORMATION);
  const { user, userImage } = userContext;

  const [isAgent, setIsAgent] = useState(user.isAgent ? "Yes" : "No");

  //------------------------------------------------------------------------------------------
  /// Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const uploadUserPreApproval = (): void => {
    buyerS3.uploadBuyerFiles(
      form.getFieldValue("userDocs").fileList,
      user.storageBucketId
    );
  };

  const initialFormValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    contactEmail: user.contactEmail,
    email: user.email,
    phoneNumber: formatPhoneNumber(user.phoneNumber),
    wantsOfferEmails: user.wantsOfferEmails,
    wantsOfferTexts: user.wantsOfferTexts,
    wantsOtherEmails: user.wantsOtherEmails,
    wantsOtherTexts: user.wantsOtherTexts,
    isAgent: user.isAgent ? "Yes" : "No",
    referralCode: user.referralCode,
    agentBroker: user.isAgent ? user.agent.broker : "",
    agentLicenseNumber: user.isAgent ? user.agent.licenseNumber : "",
  };

  /**
   * Saving the changes the user made to their profile
   */
  const saveAccountChanges = async () => {
    setFormSaving(true);
    uploadUserPreApproval();
    const values = form.getFieldsValue();
    updateUser({
      variables: {
        userId: parseFloat(user.id),
        updatedUserInfo: {
          contactEmail: values.contactEmail,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          wantsOfferEmails: values.wantsOfferEmails,
          wantsOfferTexts: values.wantsOfferTexts,
          wantsOtherEmail: values.wantsOtherEmail,
          wantsOtherTexts: values.wantsOtherTexts,
          isAgent: form.getFieldValue("isAgent") === "Yes",
        },
      },
    })
      .then((res) => {
        Modal.success({
          title: "Changes made successfully",
        });
        userContext.setUser(res.data.updateUser);
        cacheUserData(res.data.updateUser);
        setFormChanged(false);
      })
      .catch((err) => {
        Modal.error({
          title: "Something went wrong. Try Again.",
        });
        Logger.error(JSON.stringify(err, null, 2));
      })
      .finally(() => {
        setFormSaving(false);
      });
  };

  //------------------------------------------------------------------------------------------
  /// Rendering
  //------------------------------------------------------------------------------------------

  return (
    <Layout
      style={{
        background: Color.WHITE,
        overflowX: "hidden",
        paddingTop: "40px",
      }}
    >
      <ProfilePageHeader userImage={userImage} />
      <Form
        form={form}
        initialValues={initialFormValues}
        onValuesChange={() => setFormChanged(true)}
        onFinish={saveAccountChanges}
      >
        <NameInformation />
        <ContactInformation />
        <AgentInformation isAgent={isAgent} setIsAgent={setIsAgent} />
        {isAgent !== "Yes" && (
          <PreApprovalDocs userBucket={user.storageBucketId} />
        )}
        <NotificationInformation />
        <Row justify="center">
          <Col xs={14} lg={12} xl={8}
            xxl={4}>
            <Button
              disabled={!formChanged}
              type="primary"
              htmlType="submit"
              shape="round"
              size="large"
              loading={formIsSaving}
              style={{
                marginTop: "1.5rem",
                width: "100%",
              }}
            >
              Save Profile Changes
            </Button>
          </Col>
        </Row>
      </Form>
      {isAgent !== "Yes" && (
        <MyAgent myAgent={user.partneredAgent} userId={user.id} />
      )}
      {/* <MyAgent myAgent={null} /> */}
      <PasswordInformation />
    </Layout>
  );
};

export default ProfilePage;
