import { useQuery } from "@apollo/client";
import { Layout, Row, Spin } from "antd";
import { Queries } from "api/queries";
import { ActiveListingsResponse } from "api/types/ResponseTypes";
import { ListingCardInfo } from "pages/ListingPages/BrowseListings/ListingsPageTypes";
import React, { useEffect, useState } from "react";
import Color from "utils/constants/Color";
import { generateCardInfo } from "../listingUtils";
import ListingTabs from "./ListingTabs/ListingTabs";

const { Content } = Layout;

const ListingsContent = (): React.ReactElement => {
  const [cardInfo, setCardInfo] = useState<Array<ListingCardInfo>>([]);

  const { loading: listingsLoading, data: listingData } =
    useQuery<ActiveListingsResponse>(Queries.GET_LISTING_IDS, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-first",
      pollInterval: 1000,
    });

  useEffect(() => {
    if (!listingsLoading && listingData) {
      const cardInfo: Array<ListingCardInfo> = generateCardInfo(listingData);
      setCardInfo(cardInfo);
    }
  }, [listingsLoading, listingData]);

  const ListingsContentStyles: React.CSSProperties = {
    background: Color.WHITE,
    padding: "15px",
  };

  return (
    <Content style={ListingsContentStyles}>
      {/* <ListingsContentHeader /> */}
      {/* <Divider style={{ marginTop: 10 }} /> */}
      {listingsLoading && !listingData ? (
        <Row justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <ListingTabs cardInfo={cardInfo} />
      )}
    </Content>
  );
};

export default ListingsContent;
