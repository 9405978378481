import { useQuery } from "@apollo/client";
import { Col, Row, Skeleton, Typography } from "antd";
import { Queries } from "api/queries";
import { ListingResponse } from "api/types/ResponseTypes";
import React from "react";

interface SellerTermsProps {
  listingId: string;
}

const { Text } = Typography;

const SellerTerms = ({ listingId }: SellerTermsProps): React.ReactElement => {
  const { data } = useQuery<ListingResponse>(Queries.GET_SELLER_TERMS_INFO, {
    variables: {
      listingId: parseFloat(listingId),
    },
  });

  return data === undefined ? (
    <Skeleton />
  ) : (
    <>
      <Row
        justify="center"
        style={{
          marginTop: "5px",
          paddingTop: "15px",
          backgroundColor: "#F0F0F0",
        }}
      >
        <Col style={{ textAlign: "center" }}>
          <Text
            style={{
              color: "rgba(0, 0, 0, .95)",
              fontSize: "22px",
              marginBottom: "20px",
            }}
            strong
          >
            Seller Terms
          </Text>
        </Col>
      </Row>
      <Col
        style={{
          backgroundColor: "#F0F0F0",
          paddingBottom: "15px",
          marginBottom: "0px",
        }}
      >
        <HomeDetailsSection
          title="Earnest Deposit:"
          text={`$${data.listing.earnestDeposit.toLocaleString()}`}
        />
        <HomeDetailsSection
          title="Close of Escrow:"
          text={`Between ${data.listing.startEscrowRange} and ${data.listing.endEscrowRange} days`}
        />
        <HomeDetailsSection
          title="Inspection Days:"
          text={data.listing.inspectionDays + " days"}
        />
        <HomeDetailsSection
          title="Buyer's Premium:"
          text={data.listing.buyersPremium + "%"}
        />
        <HomeDetailsSection
          title="Home Warranty:"
          text={`${data.listing.homeWarranty ? "Yes" : "No"}`}
        />
      </Col>
    </>
  );
};

export default SellerTerms;

interface HomeDetailsSectionProps {
  title: string;
  text: number | string | boolean | JSX.Element[];
}

const HomeDetailsSection = ({
  title,
  text,
}: HomeDetailsSectionProps): React.ReactElement => (
  <Row justify="center">
    <Col
      span={11}
      style={{
        paddingTop: "15px",
        backgroundColor: "#F0F0F0",
      }}
    >
      <Text
        style={{
          color: "rgba(50, 50, 50, .6)",
          fontSize: "15px",
        }}
        strong
      >
        {title}
      </Text>
    </Col>
    <Col
      span={10}
      style={{
        paddingTop: "15px",
        paddingLeft: "10px",
        backgroundColor: "#F0F0F0",
      }}
    >
      <Text
        style={{
          color: "rgba(25, 25, 25, .8)",
          fontSize: "15px",
        }}
        strong
      >
        {text}
      </Text>
    </Col>
  </Row>
);
