//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import {
  CheckCircleOutlined,
  DownOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  Button,
  Card,
  Col,
  Collapse,
  Dropdown,
  Input,
  Menu,
  notification,
  Row,
  Spin,
  Tag,
} from "antd";
import { Mutations } from "api/mutations";
import UploadBuyerDocs from "components/UploadBuyerDocs";
import { Approved, ApprovedStatus } from "model/Approved";
import React, { useState } from "react";
import { useBuyerDocs } from "utils/hooks";
import ApproveDenyModal from "./ApproveDenyModal";

//------------------------------------------------------------------------------------------
// Component Wide Constants
//------------------------------------------------------------------------------------------

const { TextArea } = Input;

//------------------------------------------------------------------------------------------
// Interfaces/Props
//------------------------------------------------------------------------------------------

interface BuyerCardProps {
  approvalRecord: Approved;
}

//------------------------------------------------------------------------------------------
// Component Definition
//------------------------------------------------------------------------------------------

const BuyerCard = ({ approvalRecord }: BuyerCardProps): React.ReactElement => {
  const { approvalStatus, agentNotes, listing } = approvalRecord;
  const {
    firstName: buyerFirstName,
    lastName: buyerLastName,
    contactEmail: buyerContactEmail,
    phoneNumber: buyerPhoneNumber,
    isCashPurchase,
    isPreQualified,
    storageBucketId,
    id: buyerUserId,
  } = approvalRecord.approvedUser;

  const {
    firstName: agentFirstName,
    lastName: agentLastName,
    email: agentEmail,
    phoneNumber: agentPhoneNumber,
  } = approvalRecord.approvedUser.partneredAgent!.user!;

  //------------------------------------------------------------------------------------------
  // Calls to hooks
  //------------------------------------------------------------------------------------------

  const [approveDenyOpen, setApproveDenyOpen] = useState(false);
  const [uploadingOpen, setUploadingOpen] = useState(false);
  const [savingNotes, setSavingNotes] = useState(false);
  const [saveNotes] = useMutation(Mutations.SAVE_NOTES_FOR_APPROVAL);
  const { buyerDocs } = useBuyerDocs(storageBucketId);

  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const toggleModal = (): void => setApproveDenyOpen(!approveDenyOpen);
  const toggleUploadModal = (): void => setUploadingOpen(!uploadingOpen);

  const updateNotes = (
    userId: string,
    notes: string,
    listingId: string
  ): void => {
    setSavingNotes(true);
    saveNotes({
      variables: {
        notesData: {
          listingId: parseFloat(listingId),
          userId: parseFloat(userId),
          agentNotes: notes,
        },
      },
    }).then(() => {
      notification.success({
        message: "Notes Updates",
      });
      setSavingNotes(false);
    });
  };

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  const tagColor =
    approvalStatus === ApprovedStatus.PENDING
      ? "geekblue"
      : approvalStatus === ApprovedStatus.APPROVED
      ? "green"
      : "red";

  const viewDocsMenu = (
    <Menu>
      <Menu.ItemGroup title="Docs">
        {buyerDocs.map((doc) => (
          <Menu.Item key={doc.fileName}>
            <a
              href={URL.createObjectURL(doc.file)}
              target="_blank"
              key="view-doc"
              rel="noopener noreferrer"
            >
              {doc.fileName}
            </a>
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
      <Menu.ItemGroup title="Manage">
        <Menu.Item onClick={toggleUploadModal}>
          <SettingOutlined /> Manage Docs
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <>
      <Card
        title={(
          <>
            <Row justify="space-between">
              <Col style={{ fontSize: "18px" }}>
                <b>
                  {buyerFirstName} {buyerLastName}
                </b>
              </Col>
              <Col>
                <Tag style={{ fontSize: "14px" }} color={tagColor}>
                  {approvalStatus}
                </Tag>
              </Col>
            </Row>
          </>
        )}
        actions={[
          <Button
            style={{ width: 140 }}
            type="primary"
            key="approve-deny"
            onClick={toggleModal}
          >
            Approve/Deny
          </Button>,
          <Dropdown overlay={viewDocsMenu} key="view-docs">
            <Button type="primary">
              View Docs <DownOutlined />
            </Button>
          </Dropdown>,
        ]}
        style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)" }}
      >
        <div id="buyer-cards">
          <a href={"mailto: " + buyerContactEmail}>
            <Card.Meta
              description={buyerContactEmail}
              style={{ marginBottom: "2px", fontSize: "18px" }}
            />
          </a>
          <a href={"tel:+1" + buyerPhoneNumber}>
            <Card.Meta
              description={buyerPhoneNumber}
              style={{ marginBottom: "2px", fontSize: "18px" }}
            />
          </a>
          <Card.Meta
            description={`Purchasing Cash: ${isCashPurchase ? "Yes" : "No"}`}
            style={{ marginBottom: "2px", fontSize: "18px" }}
          />
          <Card.Meta
            description={`Pre-qualified: ${isPreQualified ? "Yes" : "No"}`}
            style={{ marginBottom: "2px", fontSize: "18px" }}
          />
        </div>
        <Collapse ghost>
          <Collapse.Panel
            header={<u>Agent Info</u>}
            className="customPanel"
            key="agentInfo"
            style={{ fontWeight: "bold", fontSize: "18px" }}
          >
            <Row style={{ fontSize: "18px" }}>
              {agentFirstName} {agentLastName}
            </Row>
            <Row style={{ fontSize: "18px" }}>
              <a href={"mailto: " + agentEmail}>{agentEmail}</a>
            </Row>
            <Row style={{ fontSize: "18px" }}>
              {" "}
              <a href={"tel:+1" + agentPhoneNumber}>{agentPhoneNumber}</a>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel
            header={<u>Notes</u>}
            className="customPanel"
            key="notes"
            style={{ fontWeight: "bold", fontSize: "18px" }}
          >
            <TextArea
              defaultValue={agentNotes ? agentNotes : ""}
              onBlur={(event) => {
                // We only want to run this if the notes actually change
                if (event.target.value !== agentNotes)
                  updateNotes(buyerUserId, event.target.value, listing.id);
              }}
            />
            <Row justify="end" style={{ fontSize: "small" }}>
              <Col>
                {savingNotes ? (
                  <>
                    <Spin size="small" style={{ right: 0 }} />
                    Autosaving
                  </>
                ) : (
                  <>
                    <CheckCircleOutlined style={{ color: "green" }} /> Notes
                    Saved
                  </>
                )}
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      </Card>
      <ApproveDenyModal
        isOpen={approveDenyOpen}
        close={toggleModal}
        approvedRecord={approvalRecord}
      />
      <UploadBuyerDocs
        isOpen={uploadingOpen}
        close={toggleUploadModal}
        currentBuyerInfo={{
          buyerName: `${buyerFirstName} ${buyerLastName}`,
          storageBucket: storageBucketId,
        }}
      />
    </>
  );
};

export default BuyerCard;
