//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import { PlusOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { Mutations } from "api/mutations";
import { UserContext } from "context/UserContext/UserContext";
import { User } from "model/User";
import React, { useContext, useState } from "react";
import { VoidFunction } from "types";
import ContextError from "utils/errors/ContextError";
import Logger from "utils/logging/Logger";
import { cacheUserData } from "utils/utils";

//------------------------------------------------------------------------------------------
// Interface/Props Definitions
//------------------------------------------------------------------------------------------

interface NoAgentProps {
  buyerId: string;
}

//------------------------------------------------------------------------------------------

const NoAgent = ({ buyerId }: NoAgentProps): React.ReactElement => {
  const [linkAgentOpen, setLinkAgentOpen] = useState(false);

  const toggleLinkAgent = (): void => {
    setLinkAgentOpen(!linkAgentOpen);
  };

  return (
    <>
      <Row justify="center">
        <Col>
          <Button type="primary" onClick={toggleLinkAgent}>
            <PlusOutlined />
            You have no agent. Click here to link one
          </Button>
        </Col>
      </Row>
      <LinkAgent
        buyerId={buyerId}
        isOpen={linkAgentOpen}
        close={toggleLinkAgent}
      />
    </>
  );
};

export default NoAgent;

//------------------------------------------------------------------------------------------

interface LinkAgentProps {
  isOpen: boolean;
  close: VoidFunction;
  buyerId: string;
}

const LinkAgent = ({
  isOpen,
  close,
  buyerId,
}: LinkAgentProps): React.ReactElement => {
  const [form] = Form.useForm();
  const [linkInProgress, setLinkInProgress] = useState(false);
  const [linkAgent] = useMutation(Mutations.BUYER_LINKS_AGENT);

  const userContext = useContext(UserContext);
  if (userContext === undefined)
    throw new ContextError(ContextError.CONTEXT_UNDEFINED);

  const { user, setUser } = userContext;

  const linkNewAgent = (): void => {
    setLinkInProgress(true);
    const formValues = form.getFieldsValue();

    linkAgent({
      variables: {
        agentEmail: formValues.agentEmail,
        buyerId: parseFloat(buyerId),
      },
    })
      .then((res) => {
        setLinkInProgress(false);
        form.resetFields();
        close();
        Modal.success({
          title: "Request Submitted Successfully",
          content: (
            <p>
              Your agent will show up on your profile page if they are
              registered with an account.
            </p>
          ),
        });

        const updatedUser: User = {
          ...user,
          partneredAgent: res.data.buyerLinksAgent,
        } as User;

        setUser(updatedUser);
        cacheUserData(updatedUser);
      })
      .catch((err) => {
        Modal.error({
          title:
            "Sorry the email you provided does not exist. Please try a different email or contact your agent for their correct information",
        });
        setLinkInProgress(false);
        Logger.error(JSON.stringify(err, null, 2));
      });
  };

  return (
    <Modal
      title="Add Agent"
      visible={isOpen}
      onCancel={() => {
        form.resetFields();
        close();
      }}
      onOk={() => form.validateFields().then(() => linkNewAgent())}
      okText="Link Agent"
      getContainer={false}
      confirmLoading={linkInProgress}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          validateTrigger="onBlur"
          name="agentEmail"
          label="Agent Email"
          rules={[
            {
              required: true,
              message: "Please provide your agent's email",
            },
            {
              type: "email",
              message: "Please provide a valid email",
            },
          ]}
        >
          <Input placeholder="Provide your agent's email" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
