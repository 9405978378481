//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import { PlusOutlined, SmileOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import {
  Button,
  Col,
  ConfigProvider,
  Layout,
  List,
  Row,
  Spin,
  Tabs,
  Typography,
} from "antd";
import { Queries } from "api/queries";
import { AgentListingsResponse } from "api/types/ResponseTypes";
import { UserContext } from "context/UserContext/UserContext";
import { Listing } from "model/Listing";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { YivadoRoute } from "utils/constants/YivadoRoute";
import ContextError from "utils/errors/ContextError";
import Logger from "utils/logging/Logger";
import {
  useActiveListings,
  useClosedListings,
  useUpcomingListings,
} from "./agentHooks";
import MyListingCard from "./MyListingCard";
import { MyListingsTab } from "./MyListingsTab";

const { Title } = Typography;
const { TabPane } = Tabs;

const MyListingsPage = (): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  /// Calls to Hooks
  //------------------------------------------------------------------------------------------

  const userContext = useContext(UserContext);

  if (userContext === undefined || userContext.user === undefined) {
    throw new ContextError(ContextError.CONTEXT_UNDEFINED);
  }

  const {
    loading: listingsLoading,
    data: listingsResponse,
    refetch: refetchListings,
    error,
  } = useQuery<AgentListingsResponse>(Queries.GET_LISTINGS_FOR_AGENT, {
    variables: { agentId: parseFloat(userContext.user.agent.id) },
    fetchPolicy: "cache-and-network",
  });

  const history = useHistory();

  const upcomingListings = useUpcomingListings(listingsResponse);
  const activeListings = useActiveListings(listingsResponse);
  const closedListings = useClosedListings(listingsResponse);

  if (error) {
    Logger.error(error);
    return <div>Error</div>;
  }

  //------------------------------------------------------------------------------------------
  /// Rendering
  //------------------------------------------------------------------------------------------

  const renderNoData = (tab: MyListingsTab) => {
    return (
      <div style={{ textAlign: "center" }}>
        <SmileOutlined style={{ fontSize: 20 }} />
        <p>You have no {tab} listings</p>
      </div>
    );
  };

  interface ListParentProps {
    listings: Listing[];
    tab: MyListingsTab;
  }

  const ListingsGrid: React.FC<ListParentProps> = (props) => (
    <ConfigProvider renderEmpty={() => renderNoData(props.tab)}>
      <List
        grid={{
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 4,
        }}
        dataSource={props.listings}
        renderItem={(listing) => (
          <List.Item style={{ paddingLeft: "8px", paddingRight: "8px" }}>
            <MyListingCard
              listing={listing}
              tab={props.tab}
              refetchListings={refetchListings}
            />
          </List.Item>
        )}
      />
    </ConfigProvider>
  );

  return (
    <Layout id="agent-info" style={{ paddingTop: "40px" }}>
      {listingsLoading && listingsResponse === undefined ? (
        <Spin />
      ) : (
        <Layout>
          <Row justify="center">
            <Col>
              <Title
                level={1}
                style={{
                  textAlign: "center",
                  marginTop: "2rem",
                }}
              >
                My Listings
              </Title>
            </Col>
          </Row>
          <Row justify="center" style={{ marginBottom: "2rem" }}>
            <Col>
              <Button
                type="primary"
                onClick={() => history.push(YivadoRoute.LISTING_CREATION)}
                icon={<PlusOutlined />}
              >
                Create a new listing now
              </Button>
            </Col>
          </Row>
          <Tabs type="card" centered>
            <TabPane tab={MyListingsTab.ACTIVE} key={MyListingsTab.ACTIVE}>
              <ListingsGrid
                listings={activeListings}
                tab={MyListingsTab.ACTIVE}
              />
            </TabPane>
            <TabPane tab={MyListingsTab.UPCOMING} key={MyListingsTab.UPCOMING}>
              <ListingsGrid
                listings={upcomingListings}
                tab={MyListingsTab.UPCOMING}
              />
            </TabPane>
            <TabPane tab={MyListingsTab.CLOSED} key={MyListingsTab.CLOSED}>
              <ListingsGrid
                listings={closedListings}
                tab={MyListingsTab.CLOSED}
              />
            </TabPane>
          </Tabs>
        </Layout>
      )}
    </Layout>
  );
};

export default MyListingsPage;
