//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import { SmileOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Col, ConfigProvider, Layout, List, Row, Spin, Typography } from "antd";
import { Queries } from "api/queries";
import { UserContext } from "context/UserContext/UserContext";
import { Approved } from "model/Approved";
import React, { useContext } from "react";
import ContextError from "utils/errors/ContextError";
import BuyerRegCard from "./BuyerRegCard";

const { Title, Text } = Typography;

const BuyerRegPage = (): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  /// Calls to Hooks
  //------------------------------------------------------------------------------------------

  const userContext = useContext(UserContext);

  if (userContext === undefined || userContext.user === undefined) {
    throw new ContextError(ContextError.CONTEXT_UNDEFINED);
  }

  const { loading: approvalsLoading, data: buyerData } = useQuery(
    Queries.GET_APPROVALS_FOR_BUYER,
    {
      variables: {
        userId: parseFloat(userContext.user.id),
      },
      pollInterval: 1000,
    }
  );

  //------------------------------------------------------------------------------------------
  /// Rendering
  //------------------------------------------------------------------------------------------

  const renderNoData = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <SmileOutlined style={{ fontSize: 20 }} />
        <p>You have no listing registration requests.</p>
      </div>
    );
  };

  interface RegistrationsParentProps {
    approvals: Approved[];
  }

  const RegistrationsGrid: React.FC<RegistrationsParentProps> = (props) => (
    <ConfigProvider renderEmpty={() => renderNoData()}>
      <List
        grid={{
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 4,
        }}
        dataSource={props.approvals}
        renderItem={(approval) => (
          <List.Item style={{ paddingLeft: "8px", paddingRight: "8px" }}>
            <BuyerRegCard approval={approval} />
          </List.Item>
        )}
      />
    </ConfigProvider>
  );

  return approvalsLoading ? (
    <Spin />
  ) : (
    <Layout id="buyer-reg-info" style={{ paddingTop: "40px" }}>
      <Row justify="center">
        <Col>
          <Title
            level={1}
            style={{
              textAlign: "center",
              marginTop: "2rem",
            }}
          >
            My Offers
          </Title>
        </Col>
      </Row>
      <Row justify="center" style={{ padding: "5px" }}>
        <Text>
          <i>
            Shown below are your Listing Registrations that are available for
            Offers.
          </i>
        </Text>
      </Row>
      <RegistrationsGrid approvals={buyerData.approvalsForUser} />
    </Layout>
  );
};

export default BuyerRegPage;
