import { Listing } from "model/Listing";
import { User } from "model/User";

export interface Approved {
  id: string;
  listing: Listing;
  approvedUser: User;
  approvalStatus: ApprovedStatus;
  agentNotes: string;
}

export enum ApprovedStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
}
