//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { UploadOutlined } from "@ant-design/icons";
import { Button, Modal, Upload, UploadProps } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import React from "react";

//------------------------------------------------------------------------------------------
// Interfaces/Props
//------------------------------------------------------------------------------------------

interface DocUploaderProps {
  name: string;
  onChange: (info: UploadChangeParam<UploadFile<any>>) => void;
  uploadedFiles?: any;
}

//------------------------------------------------------------------------------------------

const DocUploader = ({
  name,
  onChange,
  uploadedFiles,
}: DocUploaderProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const props: UploadProps = {
    beforeUpload: (file) => {
      if (file.type !== "application/pdf") {
        Modal.error({ title: "Please upload a PDF document only." });
        return Upload.LIST_IGNORE;
      }

      return false;
    },
  };

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <Upload
      {...props}
      onChange={onChange}
      name={name}
      defaultFileList={uploadedFiles}
      multiple
    >
      <Button icon={<UploadOutlined />}>Upload Document (PDF Only)</Button>
    </Upload>
  );
};

export default DocUploader;
