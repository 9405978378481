import { ApolloQueryResult, DocumentNode, FetchResult } from "@apollo/client";
import { initializeApollo } from "api/configuration";

// We want to allow the any type here
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export class ApolloQueryClient {
  callApolloQuery = async (
    query: DocumentNode,
    variables?: any
  ): Promise<ApolloQueryResult<any>> => {
    const client = initializeApollo();
    return client.query({
      query,
      variables,
    });
  };

  callApolloMutation = async (
    mutation: DocumentNode,
    variables: any
  ): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> => {
    const client = initializeApollo();
    return client.mutate({
      mutation,
      variables,
    });
  };
}
