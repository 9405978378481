import { Col, Layout, Row, Typography } from "antd";
import ApplicationFooter from "components/ApplicationFooter";
import React from "react";
import Color from "utils/constants/Color";

const { Title, Paragraph } = Typography;

const TermsPage = (): React.ReactElement => {
  return (
    <Layout id="terms" style={{ overflowX: "hidden", background: Color.WHITE }}>
      <Row
        justify="center"
        style={{
          backgroundColor: "#1DA57A",
          paddingTop: "120px",
          paddingBottom: "120px",
        }}
      >
        <Col
          style={{
            backgroundColor: "#FFFFFF",
            padding: "30px",
            width: "1200px",
            borderRadius: "50px",
          }}
        >
          <Row justify="center">
            <Col span={22} style={{ textAlign: "center" }}>
              <Title
                level={1}
                style={{
                  color: "black",
                  fontSize: "32px",
                  fontWeight: "bold",
                  marginTop: "60px",
                }}
              >
                Terms &#38; Conditions
              </Title>
              <Row
                justify="start"
                style={{ paddingLeft: "40px", paddingRight: "40px" }}
              >
                <Paragraph>
                  Your use of the services and tools located on or provided
                  through Yivado.com, the Yivado applications for smart phones
                  (the “Apps”), or through any employees or representatives of
                  the Companies (collectively, the “Services”) is subject to
                  these terms and conditions (these “Terms and Conditions” or
                  this “Agreement”). By accessing the Websites, using the Apps
                  or any of the Services, You, the end user (hereinafter “You”
                  or “User”) hereby agree to the following Terms and Conditions
                  contained in this Agreement. This is a legal agreement between
                  You, Yivado, Inc., and all of Yivado Technology’s affiliated
                  Real Estate Brokerages (“Yivado”) (all listed companies
                  collectively, the “Companies”). You also agree to the{" "}
                  <a href="/privacy">Yivado Privacy Policy</a>, which is
                  available on the Website. If You do not agree to these Terms
                  and Conditions, You may not use, access, or submit information
                  to the Services.
                </Paragraph>
                <Paragraph>
                  Yivado, Inc. may update these Terms and Conditions at any time
                  and without notice. The latest version of the Terms and
                  Conditions is available on the Website and Your use of the
                  Website or Services is conditioned upon Your acceptance of the
                  latest version of this Agreement accessible on the Website at
                  the time of Your use.
                </Paragraph>
                <Paragraph>
                  PLEASE NOTE: WHERE PERMITTED BY APPLICABLE LAW, THIS AGREEMENT
                  CONTAINS A BINDING AND FINAL ARBITRATION PROVISION (SECTION
                  10). PLEASE READ THESE TERMS CAREFULLY. BY ENTERING INTO THIS
                  AGREEMENT, YOU ACKNOWLEDGE THAT YOU UNDERSTAND THIS AGREEMENT
                  (INCLUDING THE DISPUTE RESOLUTION AND ARBITRATION PROVISIONS
                  IN SECTION 10 AND AGREE TO ALL OF ITS TERMS. IF YOU DO NOT
                  AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS
                  AGREEMENT, YOU MAY NOT USE OR ACCESS THE SERVICES.{" "}
                </Paragraph>
              </Row>
            </Col>
          </Row>

          <Row justify="start" style={{ padding: "40px" }}>
            <Col span={22}>
              <Title level={2}>General Provisions:</Title>
              <ol>
                <li>
                  <b>PERMISSIBLE USE OF THE SERVICES</b>
                  <ol>
                    <br />
                    <li>
                      PERMISSIBLE USERS. In order to use the Services of the
                      Websites, You acknowledge that You are: 1) at least the
                      age of majority in the state, province, or territory where
                      you live to use the Services, and 2) a resident of the
                      United States or Canada. If you are under 13 years old, do
                      not use the Services.
                    </li>
                    <br />
                    <li>
                      NO RIGHT TO REPRODUCE. Except as expressly stated herein,
                      this Agreement does not provide You a license to use,
                      reproduce, distribute, display or provide access to any
                      portion of the Services on third-party Web sites or
                      otherwise.
                    </li>
                    <br />
                    <li>
                      NO COPYING, DISTRIBUTING OR CREATING DERIVATIVE WORKS. You
                      agree not to copy, distribute, display or create
                      derivative works from, decompile, disassemble or reverse
                      engineer any portion of the Services. You also agree not
                      to remove or modify any copyright or other intellectual
                      property notices that appear in the Services.
                    </li>
                    <br />
                    <li>
                      NO OWNERSHIP CLAIMS. You will not assert ownership rights
                      of any kind in any data that you view or obtain through
                      the Services.
                    </li>
                    <br />
                  </ol>
                </li>

                <li>
                  <b>SUBMISSION AND ADMINISTRATION OF SERVICES</b>

                  <ol>
                    <br />
                    <li>
                      WEBSITE DATA. You understand and agree that all
                      information provided on the Website are estimations, and
                      only to be used as such. This Website Data is based on
                      limited information, and You agree to only use such data
                      as an estimation for all purposes. Individual financial
                      and personal circumstances vary, and as such, by access
                      the Website and the Services, You acknowledge that all
                      products and services, including fees, regulations, and
                      rates may be different from those listed on the Website.
                    </li>
                    <br />
                    <li>
                      CONTENT. You may submit personal information, documents,
                      photographs, images, videos, graphics, virtual tours or
                      other supported media or copy and other information to the
                      Companies (collectively, the “Content”) for each
                      transaction You create using the Services. The Companies
                      may also hire third party vendors to produce Content for
                      Your Services.
                    </li>
                    <br />
                    <li>
                      PROFESSIONAL ADVICE. You understand and acknowledge that
                      the Companies are not providing professional advice
                      regarding the physical or legal condition of any property.
                      The Companies are also not trained or licensed in tax
                      matters pertaining to the sale or purchase of any
                      property. The Companies advise You to not rely on any
                      agents of the company for a determination regarding the
                      physical or legal condition of the property. The Companies
                      strongly recommend that in connection with any of the
                      Services provided by the Companies, You retain the
                      appropriate professional services for certain advice. The
                      Companies provide You with advice solely pertaining to the
                      Services.
                    </li>
                    <br />
                    <li>
                      OWNERSHIP. You retain any applicable ownership rights that
                      You may have with respect to the Content You Submit,
                      provided, however that You grant the Companies, and any of
                      their affiliates and their licensees a royalty-free,
                      worldwide, perpetual, irrevocable, non-exclusive and fully
                      sub-licensable right and license (through multiple tiers)
                      to use, reproduce, adapt, perform, display, publish,
                      translate, prepare derivative works from, modify,
                      distribute, sell, and take any other action with respect
                      to all such Content (in whole or part), whether submitted
                      in the past or in the future, and to incorporate such
                      Content into other works in any form, media, or technology
                      now known or later developed. Any and all Content created
                      by the Companies or their affiliates, will be owned by the
                      Companies, provided the exceptions in the following
                      service specific sections of these Terms and Conditions.
                    </li>
                    <br />
                    <li>
                      USE AND OWNERSHIP OF TRANSACTION DATA. You agree that any
                      data submitted or processed through the Services will be
                      captured, stored and will become the sole property of the
                      Companies. You hereby grant the Companies an irrevocable
                      license and right to use, reproduce, resell, and otherwise
                      profit from, in any manner now known or later developed,
                      such transaction data captured through the Services. The
                      foregoing notwithstanding, the Companies will not publish,
                      use or resell any personally identifying information.
                    </li>
                    <br />
                    <li>
                      UPDATED INFORMATION AND CONTACT DETAILS. You agree to
                      maintain accurate contact information (specifically, but
                      not limited to, a valid phone number and email address) in
                      order to submit and maintain active Services through the
                      Website. You may not use robot, spider or other automated
                      services to submit Content to the Website.
                    </li>
                    <br />
                    <li>
                      USER REPRESENTATIONS. You (a) represent and warrant that
                      all information You provide, including submitted Content,
                      will be accurate; and (b) agree to maintain the accuracy
                      of all required information and Content at all times. You
                      agree to indemnify and defend the Companies for any
                      liability arising out of incorrect or falsely advertised
                      content. All Content is the sole responsibility of the
                      User providing the same, and all Users agree that the
                      Companies bear no responsibility for the accuracy,
                      legality or completeness thereof.
                    </li>
                    <br />
                    <li>
                      PROTECTION OF CONTENT AND INFORMATION. The Companies will
                      make reasonable efforts to backup data and maintain
                      Content submitted or displayed on the Website, but You
                      will be solely responsible for retaining back-up copies of
                      all information, photographs and other materials submitted
                      to the Companies. The Companies may add digital watermarks
                      to certain Content that You submit; the Companies adds
                      these digital watermarks to protect against the copying or
                      further distribution of Your Content without Your
                      permission.
                    </li>
                  </ol>
                </li>

                <br />
                <li>
                  <b>USER REGISTRATION AND COMMUNICATION</b>
                  <ol>
                    <br />
                    <li>
                      REGISTRATION AND LOGIN. The Companies require that each
                      registered user to maintain a valid email address and a
                      password, which shall be utilized for access to the
                      Services. You may not share Your individual login
                      information with others. In order to use the Services, You
                      agree that any other individual, group or entity required
                      to sign documents with you (“Co-signer”) will also
                      maintain a different valid email address and a password.
                      You agree to provide a valid email address for a Co-signer
                      if asked by the Companies, that is different from the
                      email provided by You at registration. The Companies have
                      the right to refuse service to any individual, group or
                      entity that refuses to abide by these Terms and
                      Conditions.
                    </li>
                    <br />
                    <li>
                      EMAIL AND TEXT PERMISSIONS.
                      <ol>
                        <li>
                          Yivado Technology, Inc., a Delaware corporation,
                          including its wholly owned subsidiary companies
                          (collectively, “Yivado”) uses email and text as its
                          primary communication channel with you, its registered
                          user (“You”). As a registered user of the Yivado
                          platform, You hereby acknowledge and grant Yivado
                          permission to communicate with You via email for any
                          purposes Yivado determines to be relevant including,
                          but not limited to, your transaction(s) with Yivado,
                          system messages, product updates, service
                          announcements and other marketing messages. You also
                          hereby acknowledge and grant Yivado permission to
                          communicate with You via text messaging (“SMS”) for
                          any purposes Yivado determines to be relevant
                          including, but not limited to, your transaction(s)
                          with Yivado, system messages, product updates, service
                          announcements and other marketing messages. You
                          represent that You are the account holder of the
                          mobile telephone line associated with your
                          registration, or that You have the account holder’s
                          permission to receive communications from Yivado. You
                          consent to receive text messages from Yivado.
                        </li>
                        <br />
                        <li>
                          You can cancel the SMS service at any time. Just reply
                          `&quot;STOP`&quot; to stop receiving SMS service from
                          Yivado. After You send the SMS message
                          `&quot;STOP`&quot;, Yivado will send You an SMS
                          message to confirm that You have been unsubscribed.
                          After this, You will no longer receive SMS messages
                          from Yivado. If You want to join again, just sign up
                          as You did the first time and Yivado will start
                          sending SMS messages to You again.
                        </li>
                        <br />
                        <li>
                          If You are experiencing issues with the messaging
                          program You can reply with the keyword HELP for more
                          assistance, or You can get help directly at{" "}
                          <a href="mailto:contact@yivado.com">
                            contact@yivado.com
                          </a>
                          .
                        </li>
                        <br />
                        <li>
                          Carriers are not liable for delayed or undelivered
                          messages.
                        </li>
                        <li>
                          As always, message and data rates may apply for any
                          messages sent to You from Yivado and to Yivado from
                          You. The frequency of text messages will vary
                          depending on your transactions with Yivado. If You
                          have any questions about your text plan or data plan,
                          it is best to contact your wireless provider as Yivado
                          has no liability for any charges related to your text
                          and/or data plans.
                        </li>
                        <li>
                          If you have any questions regarding privacy, please
                          read our privacy policy:
                          <a href="/privacy">https://www.Yivado.com/privacy</a>.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      SHARING OF COMMUNICATION INFORMATION. The Companies will
                      not sell your email address, phone number or other contact
                      information to third parties. However, the Companies may
                      transfer such information to third parties when you
                      request information or services from such third parties
                      through the Website or the Services. You expressly give
                      the Companies permission to provide its third-party
                      service provider partners all contact and Property
                      information you provide the Companies following your
                      positive indication of interest in information, goods or
                      services provided by such third party. You give your
                      expressed consent, by using the Services, that the
                      Companies may share your email address, phone number or
                      other contact information between the Companies.
                    </li>
                    <br />
                    <li>
                      COMMUNICATIONS DISCLAIMERS. The Companies will have no
                      liability for sending any email or text message to its
                      registered users, regardless of content. You acknowledge
                      and agree that the Companies may record telephone and
                      other electronic communications it has with You for
                      internal business purposes, including but not limited to
                      training and quality assurance purposes.
                    </li>
                    <br />
                    <li>
                      PASSWORDS. You are required to maintain the
                      confidentiality of all logins and passwords. You are
                      responsible for the activities that occur under Your
                      account, login or password. The Companies are not
                      responsible for any loss or damage arising from Your
                      failure to maintain the confidentiality of Your account,
                      login information or password.
                    </li>
                    <br />
                    <li>
                      BONA FIDE USE AND PENALTIES. You may only use the Website,
                      Apps and Services for bona fide purposes. You may not
                      submit false information, fake or inauthentic tour
                      requests, offers or inquiries, or use the Website to
                      disparage the Companies, or, in connection with producing
                      inauthentic reviews, posts or other negative and
                      public-facing content, regardless of where posted. You may
                      not impersonate other people or use fake, newly created or
                      duplicate (Facebook or Gmail accounts) or other
                      inauthentic accounts or credentials to access the Website,
                      Apps or Services. You may not create more than one account
                      with the Companies and Your account may only reflect Your
                      accurate and authentic identity information. You may not
                      advertise Properties that You do not own or manage, or
                      Properties that do not exist. You may not use the Apps,
                      Website or Services to test or investigate the Companies,
                      the Website or the Services. You agree that any use of the
                      Website, Apps or Services in a way that is prohibited by
                      this Agreement would result in real damages to the
                      Companies, the monetary value of which would be difficult
                      to calculate. Consequently, You agree that each instance
                      of non-bona fide use, or use prohibited by this Agreement
                      will result in liquidated damages of $1,000 to the
                      Companies. You agree to immediately pay such liquidated
                      damages to the Companies upon demand and cooperate with
                      the Companies to determine the identity of the User and
                      nature of such non-bona fide use. You further agree to pay
                      any legal or other fees or costs of litigation and
                      collection for any liquidated damages You incur. Any other
                      provisions of these Terms &amp; Conditions
                      notwithstanding, the Companies are not required to
                      arbitrate any claims seeking liquidated damages for
                      violations of the requirements set forth in this Section
                      3(f), and You may not require or seek arbitration relating
                      to such claims. In connection with liquidated damages
                      demands and claims for violations of this Section 3(f),
                      the Companies may seek payment and redress in any
                      appropriate venue including all appropriate courts and
                      administrative bodies. If You fail to pay liquidated
                      damages to the Companies upon demand, You agree that the
                      Companies can, and You will hold the Companies harmless
                      for, reporting the non-payment of liquidated damages to
                      appropriate bodies such as employers, banks, credit
                      reporting bodies, licensing organizations, etc. Solely
                      with respect to the enforcement of the rights and
                      penalties located in this Section 3(f), You authorize,
                      constitute and appoint Yivado, Inc.’s Chief Legal Officer
                      or any attorney designated by Yivado, Inc., as Your true
                      and lawful attorney-in-fact to appear for You and to
                      confess judgment against You in favor of the Companies for
                      the full amount of the liquidated damages, plus court
                      costs and attorneys’ fees equal to 25% of the amount due,
                      all without providing You prior notice or opportunity for
                      prior hearing, without stay of execution or right of
                      appeal, and expressly waiving the benefit of all exemption
                      laws, appeals, stay of execution or supplementary
                      proceedings, or other relief from the enforcement or
                      immediate enforcement of a judgment or related proceedings
                      on a judgment, and any irregularity or error in entering
                      any such judgment. No single exercise of the power to
                      confess judgment granted in this Section shall exhaust the
                      power, regardless of whether such exercise is ruled
                      invalid, void, or voidable by any court. The power to
                      confess judgment granted in this Section may be exercised
                      from time to time and as often as the Companies may elect.
                    </li>
                    <br />
                    <li>
                      GOOD FAITH. Yivado introduces programs and promotions for
                      the Services in good faith and expects the same good faith
                      in return. You agree and acknowledge that Yivado may, in
                      its sole discretion, withhold awards, promotions, prizes,
                      or anything of monetary value related to such programs and
                      promotions where Yivado believes customers are acting in
                      bad faith or otherwise acting contrary to the intent of
                      this program. Yivado cannot cover every nefarious
                      scenario, nor does Yivado attempt to cover all scenarios,
                      but Yivado promises to be fair and reasonable.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <b>USE OF INFORMATION</b>
                  <ol>
                    <br />
                    <li>
                      DISCLAIMERS. You agree to treat all information obtained
                      from the Services, including, but not limited to,
                      historical transaction information, analytical market
                      data, quoted rates, loan information, title reports
                      (collectively, the “Reports”), and any information
                      otherwise made available through the Services as the
                      Companies’ sole property, or the sole property of third
                      party providers, as applicable, and You may not resell or
                      otherwise use such Reports other than pursuant to the
                      terms of this Agreement. You agree to maintain as
                      confidential and a trade secret of the Companies all
                      information provided to You through the Services. The
                      Companies do not guarantee the accuracy of, endorse or
                      recommend any information, including the Reports, provided
                      through the Services and You agree to use such information
                      at Your own risk. The Reports and other similar
                      information, tutorials, and advice provided through the
                      Services ARE PROVIDED SOLELY FOR GENERAL INFORMATION, AND
                      DO NOT CONSTITUTE REAL ESTATE, LEGAL, TAX, ACCOUNTING, OR
                      OTHER PROFESSIONAL ADVICE. BEFORE ACTING ON ANY
                      INFORMATION PROVIDED BY OR THROUGH THE COMPANIES, YOU
                      SHOULD CONSULT WITH AN APPROPRIATE PROFESSIONAL.
                    </li>
                    <br />
                    <li>
                      LIMITATIONS. You may only use the Reports and other
                      information provided by the Companies for personal and
                      internal use, and may not use any such information or
                      Reports for further distribution, publication, public
                      display, or preparation of derivative works, or to
                      facilitate any of these activities in any way. You may not
                      use the Services to compete with the Companies in any way.
                      You may not copy the Companies’ products, services or
                      information; decompile, decode or reverse engineer the
                      Companies’ software; or use the Companies’ products or
                      services in an unlawful manner, such as for offensive,
                      abusive, tortious, libelous, defamatory fraudulent or
                      other illegal purposes, and You agree to indemnify and
                      defend the Companies if you use or attempt to use the
                      Companies’ products, services or the Website in any such
                      manner.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <b>UNSOLICITED COMMERCIAL EMAIL (SPAM).</b>The Services may
                  not be used to generate or send unsolicited commercial email
                  (spam) or unsolicited calls or texts. You agree to send email,
                  call and text only to those who have given You consent, or
                  with whom You have an established business relationship. The
                  Companies may revoke Your ability to send email through the
                  Services if You violate these terms.
                </li>
                <br />
                <li>
                  <b>OWNERSHIP AND LICENSE GRANT</b>
                  <ol>
                    <br />
                    <li>
                      OWNERSHIP. The Companies retain all rights including
                      intellectual property rights, title and interest in the
                      Website, Apps, other technology of the Companies, email
                      databases and all underlying technology and data,
                      including any enhancements and improvements thereto as a
                      result of providing the Services hereunder. You may not,
                      and will not allow others to: reverse engineer, decompile,
                      disassemble, merge, copy, use, disclose, sell or transfer
                      the underlying source code or structure or sequence of the
                      Companies’ technology or delete or alter author attributes
                      or copyright notices. You are permitted to use the
                      Services solely for Your own use and may not allow others
                      to use the Services through Your login ID/email and
                      password. You may not use the Website or Services on
                      behalf of any other person or in a service bureau
                      capacity.
                    </li>
                    <br />
                    <li>
                      LICENSE TO USE CONTENT. For all Content You post or
                      otherwise provide to the Companies in connection with the
                      Services, You: i) grant the Companies a perpetual,
                      irrevocable, unlimited, non-exclusive, royalty-free,
                      worldwide license to (a) use, copy, distribute, display,
                      transmit, publicly display, publicly perform, reproduce,
                      edit, modify, prepare derivative works of or incorporate
                      into other works, and translate your Content, in
                      connection with the Services or in any other media, and
                      (b) sublicense these rights, and ii) waive all moral
                      rights in and to all Content You post or otherwise provide
                      to the Companies, all to the maximum extent permitted by
                      applicable law. The Companies will not pay You for Your
                      Content or to exercise any rights related to Your Content
                      set forth in the preceding sentence. For all Content, You
                      agree to provide accurate and complete information and
                      represent that You have all rights necessary to grant the
                      Companies the rights in this paragraph, that the Companies
                      use of the Content will not infringe any third-party
                      rights and that the Content complies with these Terms and
                      Conditions. Without limiting the foregoing, You agree that
                      the Companies may promote and market Your Content. You are
                      solely responsible for all Content made through Your
                      account on the Services or that You otherwise make
                      available through the Services.
                    </li>
                    <br />
                  </ol>
                </li>
                <li>
                  <b>LIMITATION OF LIABILITY AND INDEMNIFICATION</b>
                  <ol>
                    <br />
                    <li>
                      LIMITATION OF LIABILITY. THE COMPANIES ARE NOT LIABLE FOR
                      ANY INDIRECT, EXEMPLARY, PUNITIVE, SPECIAL, INCIDENTAL OR
                      CONSEQUENTIAL DAMAGES (INCLUDING WITHOUT LIMITATION,
                      DAMAGES FOR LOSS OF BUSINESS PROFITS, LOSS OF BUSINESS,
                      INTERRUPTION OF BUSINESS, LOSS OF GOODWILL OR BUSINESS
                      REPUTATION, OTHER INTANGIBLE LOSS, INACCURACIES, ERRORS OR
                      OMISSIONS IN THE INFORMATION, PRODUCTS OR SERVICES
                      PROVIDED, RELIANCE BY ANY USER ON THE COMPLETENESS OR
                      ACCURACY OF INFORMATION, PRODUCTS OR SERVICES, LOSS OF USE
                      OF DATA, LOSS OF DATA, COMPUTER CORRUPTION, DELETION OR
                      CORRUPTION OF CONTENT OR DATA MAINTAINED OR TRANSMITTED
                      THROUGH THE USE OF THE SERVICES. Your exclusive remedy,
                      and the Companies’ entire liability under this Agreement
                      shall be a refund of fees paid to the Companies hereunder.
                      In no event will the Companies’ liability for any reason
                      exceed the amount of fees You have actually paid to the
                      Companies. If you have not paid the Companies any fees,
                      the Companies’ maximum liability for any loss or liability
                      of any kind or nature, regardless of the theory of
                      liability, will not exceed the total of the fees to be
                      collected by the Companies at the end of the Services. The
                      Companies (and their officers, directors, employees and
                      agents) shall not be liable for any damages whatsoever
                      arising from Your use of the Apps, Website or Services.
                    </li>
                    <br />
                    <li>
                      INDEMNIFICATION. You agree to indemnify the Companies (and
                      their officers, directors, employees and agents), and hold
                      each of them harmless from and against any and all costs,
                      damages or losses by any of them (including, without
                      limitation, reasonable attorneys’ fees) as a result of a
                      claim by any person other than You arising from Your use
                      or application of the Website, App or Services. Further
                      You agree not to bring suit, file a complaint or cooperate
                      in any action claiming the Companies acted on Your behalf,
                      represented You in connection with any activity related to
                      real estate, mortgage services, settlement services or
                      closing services, or conducted any other activity that
                      would be in violation of applicable real estate laws or
                      regulations.
                    </li>
                    <br />
                  </ol>
                </li>
                <li>
                  <b>WARRANTY DISCLAIMERS</b>. THE SERVICES ARE PROVIDED “AS IS”
                  WITHOUT WARRANTY OF ANY KIND. THE COMPANIES DO NOT REPRESENT
                  OR WARRANT THE ACCURACY, OPERATION, EFFECTIVENESS, CURRENCY,
                  OR VIABILITY OF THE SERVICES OR ANY INFORMATION PROVIDED
                  THROUGH THE SERVICES. WITH RESPECT TO CONTENT, THE COMPANIES
                  DO NOT VERIFY, AND DISCLAIM ALL WARRANTIES AS TO ACCURACY,
                  OPERATION, CONFORMITY TO ANY REPRESENTATION OR DESCRIPTION, OR
                  THE EXISTENCE OF ANY LATENT OR PATENT DEFECTS. THE COMPANIES
                  SPECIFICALLY DISCLAIM ALL IMPLIED WARRANTIES OF
                  MERCHANTABILITY, TITLE, QUIET ENJOYMENT, NONINFRINGEMENT AND
                  FITNESS FOR A PARTICULAR PURPOSE, WARRANTIES RELATING TO
                  ENCUMBERANCES OR LIENS. THE COMPANIES DO NOT WARRANT THE
                  SERVICES OR GUARANTEE ANY RESULTS. THE WEBSITES AND SERVICES
                  ARE ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WAIVE
                  ALL CLAIMS AND CAUSES OF ACTION RELATING TO ANY DAMAGE OR LOSS
                  CAUSED BY THE SAME. NO ADVICE OR INFORMATION, WHETHER ORAL OR
                  WRITTEN, OBTAINED FROM THE COMPANIES, THEIR EMPLOYEES OR THEIR
                  SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE
                  TERMS AND CONDITIONS.
                </li>
                <br />
                <li>
                  <b>LINKS TO THIRD PARTY SITES.</b>
                  The Websites and Apps may contain hyperlinks to other websites
                  operated by parties other than the Companies. Such sites are
                  beyond the Companies’ control. Third parties may provide
                  services or sell product lines on the Websites or through the
                  Apps that take You outside of the Services. The Companies do
                  not control, are not responsible for examining or evaluating,
                  and do not warrant the offerings of any third party. The
                  Companies do not assume any liability for the actions,
                  products, and content of these and any other third parties.
                  The Companies make no representations and are not responsible
                  for the accuracy, relevancy, copyright compliance, legality,
                  or decency of material on such third-party websites.
                </li>
                <br />
                <li>
                  <b>GOVERNING LAW; ARBITRATION</b>

                  <Paragraph>
                    <br />
                    <b>
                      THIS SECTION 10 APPLIES TO THE FULLEST EXTENT PERMITTED BY
                      APPLICABLE LAW, AND IS VOID WHERE PROHIBITED BY LAW.
                    </b>
                  </Paragraph>
                  <ol>
                    <li>
                      INFORMAL NEGOTIATIONS. To expedite resolution and reduce
                      the cost of any dispute, controversy or claim arising
                      between You and the Companies (each a
                      `&quot;Claim`&quot;and collectively `&quot;Claims`&quot;),
                      You and the Companies agree to first attempt to negotiate
                      any Claim (except those Claims expressly excluded below)
                      informally for at least thirty (30) days before initiating
                      any arbitration or court proceeding. Such informal
                      negotiations will commence upon written notice. Your
                      address for such notices is your personal home address,
                      with an email copy to the email address You have provided
                      to the Companies. Notice of the Claim must follow terms of
                      Section 13 below. If necessary to preserve a Claim under
                      any applicable statute of limitations, You or the
                      Companies may initiate arbitration while engaging in the
                      informal negotiations.
                    </li>
                    <br />
                    <li>
                      ARBITRATION. This Agreement—except as provided in Section
                      3(f)—and the Services provided by the Companies, shall be
                      governed by the laws of the State of Utah, without
                      reference to conflict of laws principles. Any dispute
                      related to this Agreement shall be submitted to binding
                      arbitration in Salt Lake City, Utah. Notice of any claim
                      requiring arbitration shall be submitted to the American
                      Arbitration Association and one arbitrator shall be chosen
                      in accordance with AAA’s standard procedures. The parties
                      agree that for any matter requiring judicial intervention
                      jurisdiction and venue will be the State and Federal
                      courts of Salt Lake County, Utah. All claims or causes of
                      action related to this Agreement or the Services must be
                      brought within one year of such the initial event that
                      gave rise to such claim or cause of action, or the same
                      will be barred, cannot be brought or enforced against the
                      Companies and of no further force and effect.
                    </li>
                    <br />
                  </ol>
                </li>
                <li>
                  <b>ASSIGNMENT.</b> This Agreement shall be binding upon and
                  shall inure to the benefit of the parties and their respective
                  successors and permitted assigns. You may not assign,
                  sublicense or otherwise transfer this Agreement or the rights
                  hereunder without the prior written consent from Yivado, Inc.
                </li>
                <br />
                <li>
                  <b>WAIVER, SEVERABILITY AND SURVIVAL.</b> The failure of the
                  Companies to exercise or enforce a legal right or remedy
                  contained in this Agreement does not constitute a waiver of
                  any such right or remedy. No waiver of any right, term or
                  provision of this Agreement is deemed a waiver of any other
                  right, term or provision. If a court of competent jurisdiction
                  finds any provision of the Terms and Conditions to be invalid,
                  User agrees to continue to abide by such provision and that
                  the remaining terms and provisions will remain in full force
                  and effect. The following sections of this Agreement will
                  survive the Termination of the Agreement and Your
                  discontinuance of use of the Websites and Services: 1, 2(d),
                  3(b), 3(e),4, 7, 8, 10, 12, 17, 25, 27, 32, 33, 37.
                </li>
                <br />
                <li>
                  <b>NOTICES.</b> All notices to Yivado must be in writing and
                  must be sent via registered mail, certified mail, or overnight
                  mail with a return receipt requested to Chief Legal Officer at
                  Yivado, Inc.
                </li>
                <br />
                <li>
                  <b>AFFILIATED BUSINESSES.</b> You acknowledge and understand
                  the Companies have affiliated businesses that may be part of
                  Your transaction, either that You choose to use or the other
                  party in Your transaction chooses to use. You further
                  acknowledge that You are not obligated to use any of the
                  Companies’ affiliated businesses for any part of Your
                  transaction and You understand that you may use any
                  unaffiliated business. You agree that you will hold the
                  Companies, and any affiliated business, harmless for all
                  reasons, as agreed to above in Section 7 and 8.
                </li>
                <br />
                <li>
                  <b>COUNTERPARTS AND FACSIMILES.</b> You agree that any
                  electronic transmission of a signed copy of transaction data
                  shall be the same as delivery of an original. You further
                  agree that any transaction may be in counterparts, each of
                  which is deemed an original, but all of which together
                  constitute one and the same agreement. These documents may be
                  delivered by facsimile transmission, and facsimile copies of
                  executed signature pages shall be binding as originals.
                </li>
                <br />
                <li>
                  <b>ENTIRE AGREEMENT.</b> This Agreement, with the relevant
                  specific provisions for Services used, constitutes the entire
                  agreement between the parties and supersedes all previous
                  agreements and understandings, whether oral or written,
                  between the parties hereto with respect to the subject matter
                  of this Agreement. No representative of the Companies may make
                  oral modifications or amendments to this Agreement. Only
                  written modifications signed by an executive of Yivado, Inc.
                  will be valid.
                </li>
                <br />
              </ol>
              <Title level={2}>Real Estate Provisions</Title>
              <Paragraph>
                Notwithstanding the aforementioned Terms and Conditions, the
                following sections—section 17 to section 26—apply in addition to
                the specific Services provided by Yivado:
              </Paragraph>
              <ol>
                <li>
                  <b>LIMITED AGENCY OR REPRESENTATION.</b> By using the Services
                  on the Website You are entering into a buyer/seller broker
                  agreement with Yivado. You will be required to sign either a
                  Listing Agreement and Agency Disclosure (the “Listing
                  Agreement) or a Buyer Broker Agreement (the “Buyer
                  Agreement”). You further agree that, if both You and the other
                  party involved in the same real estate transaction are Yivado
                  customers, Yivado may provide services to both parties
                  involved in the transaction.{" "}
                  <b>
                    A limited agent must remain neutral in the representation of
                    a seller and buyer, and may not disclose to either party
                    information likely to weaken the bargaining position of the
                    other. Material facts concerning a defect in the property or
                    a client’s ability to perform on the contract do not fall
                    within the scope of such confidentiality. You understand, by
                    using the Website and the Services, that you are using
                    Yivado, but that you may terminate this relationship upon
                    written notice to Yivado.
                  </b>{" "}
                  You agree that Yivado is not liable for unknown material facts
                  about the property and that unknown material facts cannot,
                  under any circumstances, be disclosed by Yivado. You further
                  agree that You waive the right to, in the event that both
                  buyer and seller are Yivado customers, undivided loyalty,
                  absolute confidentiality, and full disclosure from Yivado or
                  the Services provided by Yivado. You further agree that Yivado
                  has no liability to You or any third party in connection with
                  the purchase or sale of Your parcel of residential real estate
                  (Your “Property”). Yivado provides software tools for home
                  buyers and sellers that desire to buy or sell residential real
                  estate. You understand and agree that all Services are
                  intended to simplify the home buying or selling process, but
                  that the Services do not and cannot offer advice, direction,
                  or definitive answers to questions that You may encounter
                  during the home buying or home selling process. All
                  information, data, statistics, comparable sales, home value
                  reports, tips, tricks and responses to frequently asked
                  questions are provided for information purposes only and do
                  not constitute advice or direction for Your transaction. Only
                  You can decide, and You are fully responsible for, how such
                  materials and information are used.&nbsp; Yivado employs
                  licensed real estate agents that may assist in real estate
                  transactions. Such agents may also provide drafting
                  suggestions, explanations of typical outcomes, suggested
                  process actions and digital signature capabilities in
                  connection with your use of the Services. Nevertheless, You
                  alone are responsible for all actions taken and all contracts
                  transmitted in connection with the purchase or sale of Your
                  home. You alone are responsible for reading and understanding
                  your Purchase Contract and the transaction and process
                  outlined therein. If You do not understand any portion of the
                  contract, the process or transaction, You must reach out to
                  Yivado for clarification. You understand and agree that Yivado
                  is not negotiating, generating, drafting or completing any
                  contracts or transactions on Your behalf. Yivado cannot and
                  does not make any decisions for You and it is Your
                  responsibility to verify all contract drafts and actions that
                  You take are accurate and in Your best interest before signing
                  any contracts or completing any transactions. You agree that
                  all forms, purchase agreements, disclosure statements, and
                  other documents transmitted by or through the Services, even
                  if prepared using any of the Services, are drafted by You, and
                  by signing any such documents, You expressly acknowledge and
                  agree that You alone are responsible for the content thereof.
                  You are solely responsible for complying with the terms and
                  conditions contained in any such contract, including meeting
                  any deadlines, and You alone are responsible for the
                  consequences of any breach or nonperformance under any such
                  contracts. By physically or electronically signing any such
                  documents produced or transmitted through the Services, You
                  expressly represent and warrant that You have read and that
                  You understood all provisions contained therein. Further, by
                  signing any documents produced or transmitted by or through
                  the Services, You expressly release Yivado from any liability
                  (i) in connection with the content, legality and
                  enforceability of such documents, (ii) related to the subject
                  matter thereof, or, (iii) arising out of the transactions
                  contemplated therein. Before executing any document by or
                  through the Services, Yivado recommends that You contact an
                  independent real estate attorney to answer any legal or
                  contract related questions. Yivado expressly disclaims any
                  liability for, and You further agree to indemnify Yivado in
                  connection with, any dispute arising out of or related to Your
                  use of the Services and the content of, and Your compliance or
                  non-compliance with, any contract produced or transmitted by
                  or through the Services. In order to use any buyer services
                  provided by Yivado, You agree and understand that You must
                  sign a buyer-broker agreement. Without signing buyer-broker
                  agreement, Yivado cannot assist You with purchasing a
                  property. In order to use any seller services provided by
                  Yivado, You agree and understand that You must sign a listing
                  agreement. Without signing a listing agreement, Yivado cannot
                  assist You with the sale of Your property. All data, including
                  all measurements and calculations of area, is obtained from
                  various sources and has not been, and will not be, verified by
                  Yivado or the MLS. All information should be independently
                  reviewed and verified for accuracy. Properties may or may not
                  be listed by the office/agent presenting the information.
                </li>
                <br />
                <li>
                  <b>PROFESSIONAL ADVICE.</b> User understands and acknowledges
                  that Yivado is not trained or licensed to provide professional
                  advice regarding the physical or legal condition of any
                  property. Yivado is also not trained or licensed in tax
                  matters pertaining with the sale or purchase of any property.
                  Yivado advises buyers and sellers to not rely on any agents of
                  the company for a determination regarding the physical or
                  legal condition of the property. Yivado strongly recommends
                  that in connection with any offer to acquire any property or
                  contract to sell any property, User retain the appropriate
                  professional services for certain advice.
                </li>
                <br />
                <li>
                  <b>CONTENT.</b> In addition to the aforementioned provisions,
                  You represent and warrant that (i) You own or have the legal
                  power to advertise the subject Property for sale, (ii) You own
                  or have the full right, power and authority to grant Yivado
                  the right to use and display all such Content (iii) Your
                  license of such Content to Yivado hereunder does not, and the
                  use or license of such Content by Yivado to third parties will
                  not, infringe any right or interest owned or possessed by any
                  third party; and (iv) You are not subject to any claims,
                  settlements, judgments, or pending claims or litigation,
                  relating to such Content.
                </li>
                <br />
                <li>
                  <b>USE OF CONTENT.</b> Yivado may preserve, use and disclose
                  any submitted Content in its sole discretion (including
                  without limitation within other products offered by Yivado and
                  its affiliates). You may not submit any Content unless You
                  have all necessary rights and authorizations, including from
                  the photographer or videographer and/or copyright owner to
                  publish such Content on the Website. Yivado, in its sole
                  discretion, may terminate the accounts of, and refuse service
                  to, any User that violates this Agreement. Additionally, You
                  agree to allow submitted property advertisements and Content,
                  or any part therein, to be searched, displayed, accessed,
                  downloaded, copied, and otherwise referred to by users of the
                  Services and other Yivado partners or affiliate websites.
                  Yivado has the sole authority to choose the manner in which
                  any property advertisement will be searched, displayed,
                  accessed, downloaded, copied, and otherwise used on the
                  Website or in other advertisements. Yivado has the right to
                  modify Your property advertisement without notice in the
                  exercise of its rights under this Agreement. You agree and
                  authorize Yivado to make any changes to such Content for any
                  Fair Housing Act violations. The Fair Housing Act provides
                  that it is illegal to advertise or make any statement that
                  indicates a limitation or preference based on race, color,
                  national origin, religion, sex, familial status, or handicap.
                </li>
                <br />
                <li>
                  <b> OWNERSHIP.</b> Notwithstanding Section 2(d) of these Terms
                  and Conditions, Yivado grants You a one-year license to use
                  any photographs, floor plans, virtual tours, or other
                  Yivado-created Content for any Property You list.
                </li>
                <br />
                <li>
                  <b>USE AND OWENRSHIP OF TRANSACTION DATA.</b> The foregoing
                  notwithstanding, Yivado specifically reserves the right to
                  retain, publish, use and sell all property details (such as
                  square footage, number of bedrooms, including address,
                  together with listing and sale prices) associated with Your
                  Property.
                </li>
                <br />
                <li>
                  <b>Yivado BUYER REFUND.</b> Estimated Yivado Buyer Refund
                  amount not guaranteed. Yivado Buyer Refund amount may vary
                  according to purchase price, prevailing market conditions, and
                  other factors. Lender approval is required for commission
                  refunds. In some circumstances buyer’s lender may not allow
                  buyer to receive a refund and Yivado cannot guarantee that all
                  lenders will allow 100% distribution of the Yivado Buyer
                  Refund—as a result, Yivado strongly recommends that buyers
                  discuss with their lenders, in advance, the anticipated
                  receipt of the Yivado Buyer Refund.
                </li>
                <br />
                <li>
                  <b>RESPONSIBILITY FOR PROTECTION OF PROPERTY AND SELF.</b>{" "}
                  Yivado provides communication tools that allow Users to
                  interact with each other and schedule in-person tours of
                  Properties advertised on Yivado. Yivado also provides real
                  estate agents (“Tour Agents”) to assist with the viewing of
                  non-Yivado listed properties. Yivado does not background check
                  or verify the authenticity of its Users, its Tours Agents, or
                  any their accounts. You alone are responsible for Your safety
                  and the protection of Your Property when interacting with
                  other Users and the Tour Agents. You agree to hold Yivado
                  harmless (i) from any damage or injury to life or property
                  occurring in connection with Your use of the Services as they
                  relate to the scheduling and conducting of property tours (ii)
                  in connection with Yivado’s assistance in providing access to,
                  and information about, Your home and (iii) from any liability
                  resulting from the actions of Users or third parties resulting
                  from or related to Yivado’s communication, home tour and home
                  access tools and Services.
                </li>
                <br />
                <li>
                  <b>PAYMENT TERMS</b>
                  <ol>
                    <br />
                    <li>
                      PAYMENT TERMS. You agree to pay for all Services ordered
                      through the Website, Apps or third-party websites
                      (partners, advertisers, etc.) using the payment method
                      indicated, and provide Yivado express authorization to
                      charge said fees to You through the payment provider
                      connected to such payment method at the time of purchase.
                      You agree to pay any attorney fees, court costs, or other
                      costs incurred in collection of delinquent amounts. If
                      payment is not current, Yivado may immediately cease to
                      provide any and all paid Services and remove any and all
                      advertisements You have posted. The fees charged do not
                      include sales, use, excise or any other taxes or fees now
                      or hereafter imposed by any governmental authority with
                      respect to the Services. At Yivado’s option, User is
                      required to pay such taxes or fees directly or pay to
                      Yivado any such taxes or fees immediately upon receiving
                      an invoice from Yivado. Yivado is required to collect
                      sales tax on purchases in select states. All setup fees
                      are due and payable in advance of posting Your Property
                      advertisement. The settlement fee, as defined in the
                      buyer-broker agreement or listing agreement, is due and
                      payable upon closing. If such contract falls through, the
                      settlement fee shall not be charged.
                    </li>
                    <br />
                    <li>
                      CANCELLATION. You may cancel Your advertisement in
                      accordance with Your signed listing agreement. If there
                      has been no activity or response from You regarding an
                      advertised Property for a period of more than Six months,
                      Yivado reserves the right to remove such Property
                      advertisement in its sole discretion. It is Your sole
                      responsibility to cancel Your advertisement when your
                      transaction is complete, and You agree to alert Yivado
                      after your transaction is complete. Yivado will remove any
                      Property advertisements for accounts where timely payment
                      has not been made or has been revoked. Yivado may also
                      cancel its Services with you in its sole discretion,
                      though may provide a refund if you do not agree to the
                      cancellation of the Services. If Yivado cancels its
                      Services with you, Yivado will automatically remove all
                      advertisements for the Property.
                    </li>
                    <br />
                    <li>
                      BILLING ISSUES. You must notify Yivado about any billing
                      problems or discrepancies within 90 days after charges
                      first appear on Your account statement. You agree to waive
                      Your right to dispute any problems or charges if not
                      brought to Yivado’s attention within 90 days of the
                      occurrence of such problems or charges.
                    </li>
                  </ol>
                </li>
              </ol>
            </Col>
          </Row>
        </Col>
      </Row>
      <ApplicationFooter />
    </Layout>
  );
};

export default TermsPage;
