import { gql, useQuery } from "@apollo/client";
import { Col, Row, Spin, Tooltip, Typography } from "antd";
import React from "react";
import { ListingResponse } from "../../../../api/types/ResponseTypes";

const { Text } = Typography;

interface AddressUrlProps {
  listingId: string;
}

const AddressUrl = ({ listingId }: AddressUrlProps): React.ReactElement => {
  const { data } = useQuery<ListingResponse>(
    gql`
      query GetAddressData($listingId: Float!) {
        listing(id: $listingId) {
          id
          address
          city
          state
          zipcode
        }
      }
    `,
    { variables: { listingId: parseFloat(listingId) } }
  );

  const mapUrl =
    data !== undefined
      ? `https://maps.google.com/?q=${data.listing.address}, ${data.listing.city}, ${data.listing.state} ${data.listing.zipcode}`
      : "";

  return (
    <>
      {data === undefined ? (
        <Spin />
      ) : (
        <Tooltip
          style={{ background: "#FFFFFF" }}
          title="View on Map"
          placement="right"
        >
          <Text
            style={{
              background: "#FFFFFF",
              color: "#1DA57A",
              fontSize: "20px",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => window.open(mapUrl)}
          >
            <Row justify="center">
              <Col md={{ span: 24 }}>
                <u>{data.listing.address}</u>&nbsp;
              </Col>
              <Col md={{ span: 24 }}>
                <u>
                  {data.listing.city}, {data.listing.state}{" "}
                  {data.listing.zipcode}
                </u>
              </Col>
            </Row>
          </Text>
        </Tooltip>
      )}
    </>
  );
};

export default AddressUrl;
