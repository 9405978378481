import { Col, Divider, Row, Typography } from "antd";
import React from "react";
import { calculateBuyersPremium } from "utils/utils";
const { Text } = Typography;

interface BuyersPremiumMessageProps {
  offerValue: number;
  buyersPremium: number;
}

const BuyersPremiumMessage = ({
  offerValue,
  buyersPremium,
}: BuyersPremiumMessageProps): React.ReactElement => {
  const buyersPremiumAmount = calculateBuyersPremium(
    offerValue,
    buyersPremium / 100
  );

  const ValueCol: React.FC = (props) => (
    <Col style={{ fontSize: "20px", textAlign: "right" }} span={8} {...props}>
      {props.children}
    </Col>
  );

  const TitleCol: React.FC = (props) => (
    <Col style={{ fontSize: "20px", textAlign: "left" }} span={12}>
      {props.children}
    </Col>
  );

  return (
    <>
      <Row justify="center">
        <Col>
          <h3>
            <b>
              <u>Buyer&apos;s Premium</u>
            </b>
          </h3>
        </Col>
      </Row>

      <Row>
        <Col style={{ marginBottom: "30px" }}>
          Your offer will also include a buyer&apos;s premium, which is{" "}
          {buyersPremium}% of the total offer price. After including the
          buyer&apos;s premium, your total purchase price will be as follows:
        </Col>
      </Row>
      <Row justify="center" gutter={16}>
        <TitleCol>
          <b>Current Offer:</b>
        </TitleCol>
        <ValueCol>${offerValue.toLocaleString()}</ValueCol>
      </Row>
      <Row justify="center">
        <TitleCol>
          <b>Buyer&apos;s Premium:</b>
        </TitleCol>
        <ValueCol>${buyersPremiumAmount.toLocaleString()}</ValueCol>
      </Row>
      <Row justify="center">
        <Col span={20}>
          <Divider />
        </Col>
      </Row>
      <Row justify="center" gutter={16}>
        <TitleCol>
          <b>Total Purchase Price:</b>
        </TitleCol>
        <ValueCol>
          <Text style={{ color: "#1DA57A" }}>
            ${(buyersPremiumAmount + offerValue).toLocaleString()}
          </Text>
        </ValueCol>
      </Row>
    </>
  );
};

export default BuyersPremiumMessage;
