import AWS from "aws-sdk";
import Logger from "utils/logging/Logger";

AWS.config.update({
  credentials: new AWS.Credentials({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY!,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,
  }),
});

export default class S3Access {
  bucket: string;
  s3: AWS.S3;

  //-----------------------------------------------------------------------------------------

  constructor() {
    this.bucket = process.env.REACT_APP_S3_STORAGE_BUCKET!;
    this.s3 = new AWS.S3({
      params: { Bucket: this.bucket },
      region: process.env.REACT_APP_S3_REGION!,
    });
  }

  //-----------------------------------------------------------------------------------------

  async getSingleUrlFromS3(key: string): Promise<string> {
    const params = {
      Bucket: this.bucket,
      Key: key,
      Expires: 60 * 60 * 5,
    };

    const url: string = await new Promise((resolve, reject) => {
      this.s3.getSignedUrl("getObject", params, (err, url) => {
        err ? reject(err) : resolve(url);
      });
    });

    return url;
  }

  //-----------------------------------------------------------------------------------------

  async getObjectAsBlob(key: string): Promise<Blob | null> {
    try {
      const document: Blob = await new Promise((resolve, reject) => {
        this.s3.getObject({ Bucket: this.bucket, Key: key }, (error, data) => {
          error
            ? reject(error)
            : resolve(
                new Blob([data.Body as Blob], { type: "application/pdf" })
              );
        });
      });
      return document;
    } catch (err) {
      Logger.error(JSON.stringify(err, null, 2));
      return null;
    }
  }
}
