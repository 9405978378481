/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloQueryClient } from "api/ApolloQueryClient";
import { Mutations } from "api/mutations";

enum LogLevel {
  DEBUG = "DEBUG",
  INFO = "INFO",
  WARN = "WARN",
  ERROR = "ERROR",
  FATAL = "FATAL",
}

/**
 * Class with static members that is used to log to the server.
 * This way we can easily go back to errors to find out what happened
 * on our client.
 */
export default class Logger {
  private static queryClient: ApolloQueryClient = new ApolloQueryClient();

  private static logToServer(
    logLevel: LogLevel,
    message: string | unknown
  ): void {
    Logger.queryClient
      .callApolloMutation(Mutations.LOG_TO_SERVER, {
        data: {
          logLevel: logLevel,
          message: message,
        },
      })
      .catch(() => {});
  }

  static debug(message: string | unknown): void {
    Logger.logToServer(LogLevel.DEBUG, message);
  }

  static info(message: string | unknown): void {
    Logger.logToServer(LogLevel.INFO, message);
  }

  static warn(message: string | unknown): void {
    Logger.logToServer(LogLevel.WARN, message);
  }

  static error(message: string | unknown): void {
    Logger.logToServer(LogLevel.ERROR, message);
  }

  static fatal(message: string | unknown): void {
    Logger.logToServer(LogLevel.FATAL, message);
  }
}
