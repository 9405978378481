//------------------------------------------------------------------------------------------------------------

import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * Getting the url query parameters from a route in an object
 *
 * @returns query parameters
 */
export function useQueryParams(): any {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
