import { gql } from "@apollo/client";

export class Queries {
  static readonly GET_USER_QUERY = gql`
    query GetUser($email: String!) {
      user(email: $email) {
        id
        firstName
        lastName
        email
        contactEmail
        phoneNumber
        wantsOfferEmails
        wantsOfferTexts
        wantsOtherEmails
        wantsOtherTexts
        isAgent
        referralCode
        storageBucketId
        isAdmin
        agent {
          id
          isCertified
          broker
          licenseNumber
        }
        partneredAgent {
          id
          broker
          licenseNumber
          user {
            id
            firstName
            lastName
            contactEmail
            phoneNumber
          }
        }
      }
    }
  `;

  static readonly GET_BUYERS_FOR_AGENT = gql`
    query GetBuyersForAgent($agentId: Float!) {
      buyersForAgent(agentId: $agentId) {
        id
        email
        contactEmail
        firstName
        lastName
        phoneNumber
        storageBucketId
        partneredAgentNotes
        approvals {
          approvalStatus
          id
          listing {
            id
            city
            state
            zipcode
            address
            auction {
              id
              bids {
                id
                amountBid
                offerVoided
                buyerUser {
                  id
                }
              }
            }
          }
        }
      }
    }
  `;

  static readonly GET_USER_BY_EMAIL = gql`
    query GetUser($email: String!) {
      user(email: $email) {
        id
        firstName
      }
    }
  `;

  static readonly GET_LISTING_IDS = gql`
    query {
      activeListings {
        id
        featureImageUrl
        auction {
          id
          startDate
          endDate
        }
      }
    }
  `;

  static readonly GET_LISTING = gql`
    query GetListing($listingId: Float!) {
      listing(id: $listingId) {
        id
        address
        city
        state
        zipcode
        numberOfBedrooms
        numberOfBathrooms
        houseSizeInSquareFeet
        listingPicturesBucketId
        featureImageUrl
        auction {
          id
          startingPrice
          startDate
          endDate
          bids {
            amountBid
            dateBid
            offerVoided
            buyerUser {
              id
              email
            }
          }
        }
        listingAgent {
          id
          broker
        }
      }
    }
  `;

  static readonly GET_OFFER_HEADER_INFO = gql`
    query GetOfferHeaderInfo($listingId: Float!) {
      listing(id: $listingId) {
        id
        buyersPremium
        auction {
          id
          startingPrice
          bids {
            id
            amountBid
            offerVoided
            buyerUser {
              id
              email
            }
          }
        }
      }
    }
  `;

  static readonly GET_HOME_DETAILS = gql`
    query GetHomeDetails($listingId: Float!) {
      listing(id: $listingId) {
        id
        numberOfBedrooms
        numberOfBathrooms
        houseSizeInSquareFeet
        homeType
        yearBuilt
        lotSizeInSquareFeet
        numberofFloors
        garageSpaces
        hasHoa
        hoaMonthlyCost
        homeFeatures
        personalProperty
        otherPersonalProperty
        otherHomeFeatures
      }
    }
  `;

  static readonly GET_OVERVIEW_INFO = gql`
    query GetOverviewInfo($listingId: Float!) {
      listing(id: $listingId) {
        id
        auction {
          id
          startingPrice
        }
        buyersPremium
        propertyDescription
      }
    }
  `;

  static readonly GET_LISTING_INFO_SECTION = gql`
    query GetListingInfoSection($listingId: Float!) {
      listing(id: $listingId) {
        id
        address
        listingAgent {
          id
          user {
            id
            storageBucketId
            firstName
            lastName
            phoneNumber
            email
          }
          broker
          licenseNumber
        }
        auction {
          id
          startDate
          endDate
        }
        openHouseDates
      }
    }
  `;

  static readonly GET_ACTIONS_INFO = gql`
    query GetActionsInfo($listingId: Float!) {
      listing(id: $listingId) {
        id
        listingPicturesBucketId
        listingAgent {
          id
          user {
            id
            email
          }
        }
      }
    }
  `;

  static readonly GET_SELLER_TERMS_INFO = gql`
    query GeSellerTermsInfo($listingId: Float!) {
      listing(id: $listingId) {
        id
        earnestDeposit
        closeOfEscrowDays
        startEscrowRange
        endEscrowRange
        inspectionDays
        buyersPremium
        homeWarranty
      }
    }
  `;

  static readonly GET_ALL_LISTING_INFO = gql`
    query GetAllListingInfo($listingId: Float!) {
      listing(id: $listingId) {
        id
        address
        addressOne
        addressTwo
        city
        state
        zipcode
        homeType
        yearBuilt
        houseSizeInSquareFeet
        lotSizeInSquareFeet
        numberOfBathrooms
        numberOfBedrooms
        numberofFloors
        hasHoa
        hoaMonthlyCost
        buyersPremium
        garageSpaces
        propertyTaxes
        propertyDescription
        earnestDeposit
        closeOfEscrowDays
        startEscrowRange
        endEscrowRange
        inspectionDays
        homeWarranty
        listingPicturesBucketId
        featureImageUrl
        openHouseDates
        otherPersonalProperty
        otherHomeFeatures
        timezone
        lastRegistrationDay
        homeFeatures
        personalProperty
        auction {
          id
          startDate
          endDate
          startingPrice
          bids {
            id
            amountBid
            offerVoided
            buyerUser {
              email
              id
            }
          }
        }
        listingAgent {
          id
          broker
          licenseNumber
          user {
            id
            firstName
            lastName
            email
            phoneNumber
            storageBucketId
          }
        }
      }
    }
  `;

  static readonly GET_LISTING_ADDRESS = gql`
    query GetListingAddress($listingId: Float!) {
      listing(id: $listingId) {
        id
        address
        city
        state
      }
    }
  `;

  static readonly GET_LISTINGS_FOR_AGENT = gql`
    query GetListingsForAgent($agentId: Float!) {
      agentListings(agentId: $agentId) {
        id
        address
        city
        state
        zipcode
        listingPicturesBucketId
        auction {
          id
          startDate
          startingPrice
          endDate
          bids {
            id
            amountBid
            dateBid
            offerVoided
            buyerUser {
              id
              email
            }
          }
        }
      }
    }
  `;

  static readonly GET_BUYERS_FOR_LISTING = gql`
    query GetBuyersForListing($listingId: Float!) {
      approvalsForListing(listingId: $listingId) {
        id
        listing {
          id
          address
        }
        approvedUser {
          id
          firstName
          lastName
          isCashPurchase
          isPreQualified
          phoneNumber
          contactEmail
          storageBucketId
          partneredAgent {
            id
            user {
              id
              email
              firstName
              lastName
              phoneNumber
            }
          }
        }
        approvalStatus
        agentNotes
      }
    }
  `;

  static readonly GET_OFFERS_FOR_LISTING = gql`
    query GetBidsForListing($listingId: Float!) {
      bids(auctionId: $listingId) {
        id
        amountBid
        dateBid
        bidSubmittedBy
        buyerUser {
          id
          firstName
          lastName
          contactEmail
        }
        buyerAgent {
          id
          user {
            email
            contactEmail
          }
        }
        auction {
          id
          endDate
        }
      }
    }
  `;

  static readonly GET_VOIDED_OFFERS_FOR_LISTING = gql`
    query GetVoidedBidsForListing($listingId: Float!) {
      voidedBids(auctionId: $listingId) {
        id
        amountBid
        dateBid
        bidSubmittedBy
        buyerUser {
          id
          firstName
          lastName
          contactEmail
        }
        buyerAgent {
          id
          user {
            email
            contactEmail
          }
        }
        auction {
          id
          endDate
        }
      }
    }
  `;

  static readonly GET_APPROVALS_FOR_USER = gql`
    query GetApprovalsForUser($userId: Float!) {
      approvalsForUser(userId: $userId) {
        id
        approvalStatus
        listing {
          id
        }
      }
    }
  `;

  static readonly GET_APPROVALS_FOR_BUYER = gql`
    query GetApprovalsForBuyer($userId: Float!) {
      approvalsForUser(userId: $userId) {
        id
        approvalStatus
        listing {
          id
          address
          auction {
            id
            startingPrice
            bids {
              id
              amountBid
              offerVoided
              buyerUser {
                id
                email
              }
            }
          }
        }
      }
    }
  `;

  static readonly CHECK_IF_USER_EXISTS = gql`
    query CheckIfUserExists($userEmail: String!) {
      userExists(userEmail: $userEmail)
    }
  `;

  static readonly GET_TOP_LISTINGS = gql`
    query {
      topListings {
        id
        address
        city
        state
      }
    }
  `;

  static readonly GET_TRAINING_INFO = gql`
    query {
      trainingModules {
        id
        moduleName
        moduleOrder
        moduleType
        subModules {
          id
          videoUrl
          subModuleName
          order
        }
      }
    }
  `;
}
