import { Col, Form, Input, Row, Typography } from "antd";
import { MaskedInput } from "antd-mask-input";
import React, { CSSProperties } from "react";
import { FormSection } from "../FormSection";

const { Item: FormItem } = Form;
const { Title } = Typography;

const WorkingWithRealtor = ({
  fieldsRequired,
  display,
}: FormSection): React.ReactElement => {
  const style: CSSProperties = { display: display ? "" : "none" };

  return (
    <div style={style} className="working-with-realtor">
      <Row justify="center" gutter={16}>
        <Col
          xs={11}
          lg={8}
          xl={5}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            Realtor&apos;s First Name
          </Title>
        </Col>
        <Col
          xs={11}
          lg={8}
          xl={5}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            Realtor&apos;s Last Name
          </Title>
        </Col>
      </Row>
      <Row justify="center" gutter={16}>
        <Col xs={11} lg={8} xl={5}>
          <FormItem
            name="agentFirstName"
            rules={[
              {
                required: fieldsRequired,
                message: "Please provide your realtor's first name",
              },
            ]}
          >
            <Input size="large" />
          </FormItem>
        </Col>
        <Col xs={11} lg={8} xl={5}>
          <FormItem
            name="agentLastName"
            rules={[
              {
                required: fieldsRequired,
                message: "Please provide your realtor's last name",
              },
            ]}
          >
            <Input size="large" />
          </FormItem>
        </Col>
      </Row>

      <Row justify="center" gutter={16}>
        <Col
          xs={11}
          lg={8}
          xl={5}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            Realtor&apos;s Email
          </Title>
        </Col>
        <Col
          xs={11}
          lg={8}
          xl={5}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            Realtor&apos;s Phone Number
          </Title>
        </Col>
      </Row>
      <Row justify="center" gutter={16}>
        <Col xs={11} lg={8} xl={5}>
          <FormItem
            name="agentEmail"
            rules={[
              {
                required: fieldsRequired,
                message: "Please provide your realtor's email",
              },
              {
                type: "email",
                message: "Please provide a valid email",
              },
            ]}
          >
            <Input type="email" size="large" />
          </FormItem>
        </Col>
        <Col xs={11} lg={8} xl={5}>
          <FormItem
            name="agentPhoneNumber"
            rules={[
              {
                required: fieldsRequired,
                message: "Please provide your realtor's phone number",
              },
            ]}
          >
            <MaskedInput mask="(111) 111-1111" />
          </FormItem>
        </Col>
      </Row>
    </div>
  );
};

export default WorkingWithRealtor;
