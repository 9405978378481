//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { Button, Col, Row } from "antd";
import React from "react";
import { VoidFunction } from "types";

//------------------------------------------------------------------------------------------
// Props Definition
//------------------------------------------------------------------------------------------

interface SubmitAndResetProps {
  formChanged: boolean;
  formIsSaving: boolean;
  resetForm: VoidFunction;
}

//------------------------------------------------------------------------------------------

const SubmitAndReset = ({
  formChanged,
  formIsSaving,
  resetForm,
}: SubmitAndResetProps): React.ReactElement => {
  return (
    <Row justify="center" gutter={16}>
      <Col xs={8} lg={7} xl={5}
        xxl={3}>
        <Button
          disabled={!formChanged}
          type="primary"
          htmlType="submit"
          shape="round"
          size="large"
          loading={formIsSaving}
          style={{
            display: "center",
            width: "100%",
            marginTop: "1.5rem",
          }}
        >
          Submit
        </Button>
      </Col>
      <Col xs={8} lg={7} xl={5}
        xxl={3}>
        <Button
          disabled={!formChanged}
          danger
          shape="round"
          size="large"
          onClick={resetForm}
          style={{
            display: "center",
            width: "100%",
            marginTop: "1.5rem",
          }}
        >
          Reset
        </Button>
      </Col>
    </Row>
  );
};

export default SubmitAndReset;
