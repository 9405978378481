//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import { QuestionCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Col, Form, InputNumber, Modal, Row } from "antd";
import { Mutations } from "api/mutations";
import { AutoBid } from "model/AutoBid";
import React, { useState } from "react";
import { VoidFunction } from "types";
import { calculateBuyersPremium } from "utils/utils";

//------------------------------------------------------------------------------------------
/// Props
//------------------------------------------------------------------------------------------

interface UpdateMaxBidProps {
  isOpen: boolean;
  onCancel: VoidFunction;
  currentUserAutoBidRecord?: AutoBid;
  currentMaxOffer: number;
  buyersPremium: number;
}

//------------------------------------------------------------------------------------------

const UpdateMaxBid = ({
  isOpen,
  onCancel,
  currentUserAutoBidRecord,
  currentMaxOffer,
  buyersPremium,
}: UpdateMaxBidProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to hooks
  //------------------------------------------------------------------------------------------

  const [form] = Form.useForm();
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [maxBidUpdating, setMaxBidUpdating] = useState(false);
  const [newMax, setNewMax] = useState<number | undefined>(undefined);
  const [updateMaxBid] = useMutation(Mutations.UPDATE_MAX_OFFER);

  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const submitNewMaxBid = () => {
    setMaxBidUpdating(true);

    updateMaxBid({
      variables: {
        newMaxBid: form.getFieldValue("newMaxBid"),
        autoBidId: parseFloat(currentUserAutoBidRecord!.id),
      },
    })
      .then(() => {
        Modal.success({
          title: "Max offer updated sucessfully.",
        });
        setMaxBidUpdating(false);
        close();
      })
      .catch(() => {
        Modal.error({ title: "Something went wrong, please try again" });
        setMaxBidUpdating(false);
      });
  };

  const confirmSubmit = () => {
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure you want to update your max offer?",
      onOk: submitNewMaxBid,
      icon: <QuestionCircleOutlined />,
      okText: "Update",
    });
  };

  const handleFormValueChanges = (changedValues: any) => {
    if (changedValues.newMaxBid === currentUserAutoBidRecord?.autoBidAmount) {
      setUpdateDisabled(true);
    } else {
      setUpdateDisabled(false);
    }
  };

  const close = () => {
    form.resetFields();
    setUpdateDisabled(true);
    setNewMax(undefined);
    onCancel();
  };

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  const buyersPremiumAmount = newMax
    ? calculateBuyersPremium(newMax, buyersPremium / 100)
    : 0;

  return (
    <Modal
      title="Update Max Offer"
      visible={isOpen}
      onCancel={close}
      getContainer={false}
      okText={maxBidUpdating ? "Updating..." : "Update Max Offer"}
      okButtonProps={{ disabled: updateDisabled, loading: maxBidUpdating }}
      onOk={() => {
        form.validateFields().then(() => confirmSubmit());
      }}
    >
      <Form
        id="update-max-bid-form"
        form={form}
        initialValues={{ newMaxBid: currentUserAutoBidRecord?.autoBidAmount }}
        onValuesChange={handleFormValueChanges}
      >
        <Row
          justify="center"
          style={{ marginBottom: "20px", textAlign: "center" }}
        >
          <Col span={24} style={{ marginBottom: "10px" }}>
            <b>YOU ARE CURRENTLY THE TOP OFFER.</b>
          </Col>
          <Col span={24} style={{ marginBottom: "10px" }}>
            You have a max offer set for{" "}
            <b>${currentUserAutoBidRecord?.autoBidAmount.toLocaleString()}</b>.
          </Col>
          <Col span={24}>
            Your new maximum offer must be greater than the current offer of{" "}
            <b>${currentMaxOffer.toLocaleString()} </b> <br /> <br />{" "}
            <b>Would you like to change it?</b>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={<b>New Max Offer</b>}
              name="newMaxBid"
              rules={[
                {
                  required: true,
                  message: "Please submit a new maximum offer.",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                step={1000}
                min={currentMaxOffer + 1000}
                onChange={(val) => setNewMax(val)}
              />
            </Form.Item>
          </Col>
        </Row>
        {newMax && (
          <Row justify="start">
            <Col>
              <b>With Buyer&apos;s Premium:</b>
            </Col>
            <Col offset={1} style={{ color: "#1DA57A" }}>
              ${(buyersPremiumAmount + newMax).toLocaleString()}
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default UpdateMaxBid;
