//------------------------------------------------------------------------------------------------------------

import { useEffect, useState } from "react";
import Logger from "utils/logging/Logger";
import S3Buyer, { BuyerDocsResponse } from "utils/storage/S3Buyer";

//------------------------------------------------------------------------------------------------------------

interface UserBuyerDocsReturn {
  buyerDocs: BuyerDocsResponse[];
  refetchDocs: VoidFunction;
  deletingDoc: boolean;
  deleteDoc: (fileName: string) => Promise<void>;
}

export function useBuyerDocs(storageBucketId: string): UserBuyerDocsReturn {
  const [buyerDocs, setBuyerDocs] = useState<BuyerDocsResponse[]>([]);
  const [fetched, setFetched] = useState(true);
  const [deletingDoc, setDeletingDoc] = useState(false);

  const refetchDocs = () => {
    console.log("refetching");
    setFetched(false);
  };

  const deleteDoc = async (fileName: string): Promise<void> => {
    const buyerS3 = new S3Buyer();
    setDeletingDoc(true);
    buyerS3
      .deleteUserDoc(storageBucketId, fileName)
      .catch((err) => {
        Logger.error(
          `Error deleting document. FileName: [${fileName}]. StorageBucketId: [${storageBucketId}]. Error: [${err}]`
        );
      })
      .finally(() => {
        setDeletingDoc(false);
      });
  };

  useEffect(() => {
    const buyerS3 = new S3Buyer();
    buyerS3.getBuyerDocuments(storageBucketId).then((val) => {
      if (val) {
        setBuyerDocs(val);
        setFetched(true);
      }
    });
  }, [storageBucketId, fetched]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchDocs();
    }, 5000);

    return () => clearInterval(interval);
  });

  return { buyerDocs, refetchDocs, deletingDoc, deleteDoc };
}
