import { Col, Row } from "antd";
import { Listing } from "model/Listing";
import React from "react";
import { determineMaxOffer } from "utils/utils";

const StartingPrice = (listing: Listing): React.ReactElement => (
  <Row
    key="starting-price"
    justify="space-between"
    style={{ marginBottom: "10px" }}
  >
    <Col>
      <b>Starting Price:</b>
    </Col>
    <Col>${listing.auction.startingPrice.toLocaleString()}</Col>
  </Row>
);

const CurrentHighOffer = (listing: Listing): React.ReactElement => (
  <Row
    key="high-offer"
    justify="space-between"
    style={{ marginBottom: "10px" }}
  >
    <Col>
      <b>Highest Offer:</b>
    </Col>
    <Col>
      $
      {determineMaxOffer(
        listing.auction.startingPrice,
        listing.auction.bids
      ).offer.toLocaleString()}
    </Col>
  </Row>
);

const TotalOffers = (listing: Listing): React.ReactElement => (
  <Row
    key="total-offers"
    justify="space-between"
    style={{ marginBottom: "10px" }}
  >
    <Col>
      <b>Total Offers:</b>
    </Col>
    <Col>{listing.auction.bids.length}</Col>
  </Row>
);

export const ActiveColumns: Array<(listing: Listing) => React.ReactElement> = [
  StartingPrice,
  CurrentHighOffer,
  TotalOffers,
];

export const UpcomingColumns: Array<(listing: Listing) => React.ReactElement> =
  [StartingPrice];

export const ClosedColumns: Array<(listing: Listing) => React.ReactElement> = [
  StartingPrice,
  CurrentHighOffer,
  TotalOffers,
];
