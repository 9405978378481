import { Checkbox, Col, Form, Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";

const { Title } = Typography;
const { Item: FormItem } = Form;

interface TableDataType {
  key: string;
  name: string;
}

const NotificationInformation = (): React.ReactElement => {
  const columns: ColumnsType<TableDataType> = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      align: "left",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      // eslint-disable-next-line react/display-name
      render: (text, record) => {
        const notificationType = record.name.split(" ")[0];
        return (
          <FormItem
            name={
              notificationType === "Offer"
                ? "wantsOfferEmails"
                : "wantsOtherEmails"
            }
            valuePropName="checked"
          >
            <Checkbox />
          </FormItem>
        );
      },
    },
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
      align: "center",
      // eslint-disable-next-line react/display-name
      render: (text, record) => {
        const notificationType = record.name.split(" ")[0];
        return (
          <FormItem
            name={
              notificationType === "Offer"
                ? "wantsOfferTexts"
                : "wantsOtherTexts"
            }
            valuePropName="checked"
          >
            <Checkbox />
          </FormItem>
        );
      },
    },
  ];

  const data: Array<TableDataType> = [
    {
      key: "1",
      name: "Offer Notifications",
    },
    {
      key: "2",
      name: "Other Notifications",
    },
  ];

  return (
    <>
      <Row justify="center">
        <Col
          xs={16}
          lg={14}
          xl={10}
          xxl={6}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            Notification Settings
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={16} lg={14} xl={10}
          xxl={6}>
          <Table columns={columns} dataSource={data} pagination={false} />
        </Col>
      </Row>
    </>
  );
};

export default NotificationInformation;
