interface ConfirmationResultInterface {
  status: "error" | "success";
  title: string;
  buttonText: string;
  buttonRoute: "/" | "/authentication";
  subTitle: string | null;
}

interface ConfirmationStatusesConfig {
  success: ConfirmationResultInterface;
  error: ConfirmationResultInterface;
}

export const statusConfig: ConfirmationStatusesConfig = {
  success: {
    status: "success",
    title: "Successfully Confirmed Account",
    buttonText: "Sign in Now",
    buttonRoute: "/authentication",
    subTitle: null,
  },
  error: {
    status: "error",
    title: "Something went wrong with your confirmation",
    buttonText: "Go Home",
    buttonRoute: "/",
    subTitle: "Please contact us for support if you feel this was a mistake",
  },
};
