//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import {
  CheckCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  Button,
  Card,
  Col,
  Collapse,
  Dropdown,
  Input,
  Menu,
  Modal,
  notification,
  Row,
  Spin,
  Tag,
} from "antd";
import { Mutations } from "api/mutations";
import UploadBuyerDocs from "components/UploadBuyerDocs";
import { ApprovedStatus } from "model/Approved";
import { User } from "model/User";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useBuyerDocs } from "utils/hooks";

//------------------------------------------------------------------------------------------
/// Component Wide Constants
//------------------------------------------------------------------------------------------

const { Meta } = Card;
const { TextArea } = Input;

//------------------------------------------------------------------------------------------
/// Interfaces/Props
//------------------------------------------------------------------------------------------

interface BuyerCardProps {
  buyer: User;
}

//------------------------------------------------------------------------------------------
/// Rendering
//------------------------------------------------------------------------------------------

const BuyerCard = ({ buyer }: BuyerCardProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to Hooks
  //------------------------------------------------------------------------------------------

  const [unlinkBuyer] = useMutation(Mutations.UNLINK_BUYER);
  const [buyerDocsOpen, setBuyerDocsOpen] = useState(false);
  const [savingNotes, setSavingNotes] = useState(false);
  const [saveNotes] = useMutation(Mutations.UPDATE_PARTNERED_AGENT_NOTES);
  const history = useHistory();
  const { buyerDocs } = useBuyerDocs(buyer.storageBucketId);

  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const toggleBuyerDocs = () => setBuyerDocsOpen(!buyerDocsOpen);

  //------------------------------------------------------------------------------------------

  const runUnlinkBuyer = (buyer: User): void => {
    Modal.confirm({
      title: `Are you sure you want to unlink ${buyer.firstName} ${buyer.lastName}?`,
      content:
        "Unlinking this buyer means you will no longer be representing them. If you do this accidentally, just relink them on your My Buyers page.",
      icon: <ExclamationCircleOutlined />,
      okType: "danger",
      okText: "Unlink",
      onOk() {
        unlinkBuyer({ variables: { buyerId: parseFloat(buyer.id) } }).then(
          () => {
            Modal.success({ title: "Successfully unlinked buyer." });
          }
        );
      },
    });
  };

  //------------------------------------------------------------------------------------------

  const updateNotes = (userId: string, notes: string): void => {
    setSavingNotes(true);
    saveNotes({
      variables: { buyerId: parseFloat(userId), notes: notes },
    }).then(() => {
      notification.success({
        message: "Notes Updates",
      });
      setSavingNotes(false);
    });
  };

  //------------------------------------------------------------------------------------------

  const cardMenu = (
    <Menu>
      <Menu.Item danger onClick={() => runUnlinkBuyer(buyer)}>
        Unlink Buyer
      </Menu.Item>
    </Menu>
  );

  //------------------------------------------------------------------------------------------

  const panels =
    buyer.approvals.length !== 0
      ? buyer.approvals.map((approval) => {
          const { approvalStatus } = approval;

          const tagColor =
            approvalStatus === ApprovedStatus.PENDING
              ? "geekblue"
              : approvalStatus === ApprovedStatus.APPROVED
              ? "green"
              : "red";

          return (
            <Collapse.Panel
              className="customPanel"
              header={approval.listing.address}
              key={approval.listing.id}
            >
              <Row
                style={{
                  fontSize: "small",
                  background: "#E7E7E7",
                  padding: 5,
                }}
                justify="space-between"
              >
                <Col>Approval Status:</Col>
                <Col>
                  <Tag color={tagColor} style={{ marginRight: 0 }}>
                    {approvalStatus}
                  </Tag>
                </Col>
              </Row>
              <Row
                style={{ fontSize: "small", padding: 5 }}
                justify="space-between"
              >
                <Col>Offers Submitted:</Col>
                <Col>
                  {
                    approval.listing.auction.bids.filter(
                      (bid) => bid.buyerUser.id === buyer.id
                    ).length
                  }
                </Col>
              </Row>
              <Row
                style={{
                  fontSize: "small",
                  background: "#E7E7E7",
                  padding: 5,
                }}
                justify="space-between"
              >
                <Col>Is Highest Offer:</Col>
                <Col>
                  {approval.listing.auction.bids.length > 0
                    ? approval.listing.auction.bids.reduce((prev, current) => {
                        return prev.amountBid > current.amountBid
                          ? prev
                          : current;
                      }).buyerUser.id === buyer.id
                      ? "Yes"
                      : "No"
                    : "No offers on listing"}
                </Col>
              </Row>
              <Row justify="start">
                <Col>
                  <Button
                    type="link"
                    style={{ textAlign: "left", padding: 0 }}
                    onClick={() =>
                      history.push(`/listing/${approval.listing.id}`)
                    }
                  >
                    <u>Go to listing</u>
                  </Button>
                </Col>
              </Row>
            </Collapse.Panel>
          );
        })
      : null;

  const viewDocsMenu = (
    <Menu>
      {buyerDocs.map((doc) => (
        <Menu.Item key={doc.fileName}>
          <a
            href={URL.createObjectURL(doc.file)}
            target="_blank"
            key="view-doc"
            rel="noopener noreferrer"
          >
            {doc.fileName}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );

  //------------------------------------------------------------------------------------------

  return (
    <>
      <Card
        title={(
          <div style={{ fontSize: "18px", fontWeight: "bold" }}>
            {buyer.firstName} {buyer.lastName}
          </div>
        )}
        extra={(
          <Dropdown
            overlay={cardMenu}
            trigger={["click"]}
            placement="bottomLeft"
          >
            <Button
              style={{ color: "red" }}
              icon={<DeleteOutlined />}
              shape="round"
            />
          </Dropdown>
        )}
        actions={[
          <Dropdown
            overlay={viewDocsMenu}
            disabled={buyerDocs.length === 0}
            key="view-docs"
          >
            <Button type="primary">
              {buyerDocs.length === 0 ? (
                <>No Docs</>
              ) : (
                <>
                  View Docs <DownOutlined />
                </>
              )}
            </Button>
          </Dropdown>,
          <Button
            style={{ width: 125 }}
            type="primary"
            key="upload"
            onClick={toggleBuyerDocs}
          >
            Manage Docs
          </Button>,
        ]}
        style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)" }}
      >
        <div id="buyer-cards">
          <a href={"mailto: " + buyer.contactEmail}>
            <Meta
              style={{ fontSize: "18px" }}
              description={<p>{buyer.contactEmail}</p>}
            />
          </a>
          <a href={"tel:+1" + buyer.phoneNumber}>
            <Meta
              style={{ fontSize: "18px" }}
              description={buyer.phoneNumber}
            />
          </a>
        </div>

        <Collapse ghost style={{ marginTop: "20px" }}>
          <Collapse.Panel
            className="customPanel"
            header={<u>View Info</u>}
            key="view"
            style={{ fontWeight: "bold", fontSize: "18px" }}
          >
            {panels !== null ? (
              <Collapse ghost accordion>
                {panels}
              </Collapse>
            ) : (
              <p style={{ fontSize: "small" }}>
                This buyer is not registered to make offers on any listings.
              </p>
            )}
          </Collapse.Panel>
          <Collapse.Panel
            className="customPanel"
            header={<u>Notes</u>}
            key="notes"
            style={{ fontWeight: "bold", fontSize: "18px" }}
          >
            <TextArea
              defaultValue={
                buyer.partneredAgentNotes ? buyer.partneredAgentNotes : ""
              }
              onBlur={(event) => {
                // We only want to run this if the notes actually change
                if (event.target.value !== buyer.partneredAgentNotes)
                  updateNotes(buyer.id, event.target.value);
              }}
            />
            <Row justify="end" style={{ fontSize: "small" }}>
              <Col>
                {savingNotes ? (
                  <>
                    <Spin size="small" style={{ right: 0 }} />
                    Autosaving
                  </>
                ) : (
                  <>
                    <CheckCircleOutlined style={{ color: "green" }} /> Notes
                    Saved
                  </>
                )}
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      </Card>
      <UploadBuyerDocs
        isOpen={buyerDocsOpen}
        close={toggleBuyerDocs}
        currentBuyerInfo={{
          storageBucket: buyer.storageBucketId,
          buyerName: `${buyer.firstName} ${buyer.lastName}`,
        }}
      />
    </>
  );
};

export default BuyerCard;
