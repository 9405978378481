//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Button, Col, Layout, Menu, Row, Typography } from "antd";
import { Queries } from "api/queries";
import { TrainingDataResponse } from "api/types/ResponseTypes";
import React, { useState } from "react";

//------------------------------------------------------------------------------------------
// Component Wide Constants
//------------------------------------------------------------------------------------------

const { Item: MenuItem, SubMenu } = Menu;
const { Title } = Typography;

//------------------------------------------------------------------------------------------
// Components/Props
//------------------------------------------------------------------------------------------

interface SectionIndexes {
  currentMainIndex: number;
  currentSubSectionIndex: number;
}

//------------------------------------------------------------------------------------------

const TrainingPage = (): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // calls to Hooks
  //------------------------------------------------------------------------------------------

  const { loading, data: trainingData } = useQuery<TrainingDataResponse>(
    Queries.GET_TRAINING_INFO
  );

  const [currentIndexes, setCurrentIndexes] = useState<SectionIndexes>({
    currentMainIndex: 0,
    currentSubSectionIndex: 0,
  });

  //------------------------------------------------------------------------------------------
  // Validation
  //------------------------------------------------------------------------------------------

  if (trainingData === undefined && loading) return <div>Loading...</div>;
  if (trainingData === undefined) return <div>Error</div>;

  const { trainingModules } = trainingData;

  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const nextSection = () => {
    setCurrentIndexes((currIndexes) => {
      const { currentSubSectionIndex, currentMainIndex } = currIndexes;
      if (
        currentSubSectionIndex ===
        trainingModules[currentMainIndex].subModules.length - 1
      ) {
        return {
          currentMainIndex: currentMainIndex + 1,
          currentSubSectionIndex: 0,
        };
      } else {
        return {
          currentMainIndex,
          currentSubSectionIndex: currentSubSectionIndex + 1,
        };
      }
    });
  };

  const prevSection = () => {
    setCurrentIndexes((currIndexes) => {
      const { currentSubSectionIndex, currentMainIndex } = currIndexes;

      if (currentSubSectionIndex === 0) {
        return {
          currentMainIndex: currentMainIndex - 1,
          currentSubSectionIndex:
            trainingModules[currentMainIndex - 1].subModules.length - 1,
        };
      } else {
        return {
          currentMainIndex,
          currentSubSectionIndex: currentSubSectionIndex - 1,
        };
      }
    });
  };

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <Layout id="training">
      <Row justify="center">
        <Col>
          <Title
            level={1}
            style={{
              textAlign: "center",
              marginTop: "2rem",
            }}
          >
            Training Center
          </Title>
        </Col>
      </Row>
      <Row style={{ marginTop: "3rem" }} justify="start">
        <Col>
          <Menu
            selectedKeys={[
              trainingModules[currentIndexes.currentMainIndex].subModules[
                currentIndexes.currentSubSectionIndex
              ].subModuleName,
            ]}
            openKeys={[
              trainingModules[currentIndexes.currentMainIndex].moduleName,
            ]}
            mode="inline"
          >
            {trainingModules.map((mainSection, mainSectionIndex) => (
              <SubMenu
                key={mainSection.moduleName}
                title={mainSection.moduleName}
                onTitleClick={() => {
                  setCurrentIndexes({
                    currentMainIndex: mainSectionIndex,
                    currentSubSectionIndex: 0,
                  });
                }}
              >
                {[...mainSection.subModules]
                  .sort((a, b) => a.order - b.order)
                  .map((subModule, subModuleIndex) => (
                    <MenuItem
                      key={subModule.subModuleName}
                      onClick={() =>
                        setCurrentIndexes({
                          ...currentIndexes,
                          currentSubSectionIndex: subModuleIndex,
                        })
                      }
                    >
                      {subModule.subModuleName}
                    </MenuItem>
                  ))}
              </SubMenu>
            ))}
            <MenuItem>Final Remarks</MenuItem>
          </Menu>
        </Col>
        <Col offset={2}>
          <Row>
            <iframe
              width="700"
              height="500"
              src={
                trainingModules[currentIndexes.currentMainIndex].subModules[
                  currentIndexes.currentSubSectionIndex
                ].videoUrl
              }
            />
          </Row>
          <Row justify="space-around" style={{ marginTop: "10px" }}>
            <Button
              type="primary"
              disabled={
                currentIndexes.currentMainIndex === 0 &&
                currentIndexes.currentSubSectionIndex === 0
              }
              onClick={prevSection}
            >
              <LeftOutlined />
              Prev
            </Button>
            <Button
              type="primary"
              disabled={
                currentIndexes.currentMainIndex ===
                  trainingModules.length - 1 &&
                currentIndexes.currentSubSectionIndex ===
                  trainingModules[trainingModules.length - 1].subModules
                    .length -
                    1
              }
              onClick={nextSection}
            >
              Next <RightOutlined />
            </Button>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default TrainingPage;
