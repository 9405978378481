import { User } from "model/User";
import React from "react";

// TODO: Clear context on sign out
export interface UserContextType {
  user: User | undefined;
  setUser: (user: User) => void;
  userImage: string;
  setUserImage: (imageUrl: string) => void;
}

const UserContext = React.createContext<UserContextType | undefined>(undefined);

export { UserContext };
