import { Card, Col, Layout, Row, Typography } from "antd";
import ApplicationFooter from "components/ApplicationFooter";
import { UserContext } from "context/UserContext/UserContext";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { YivadoRoute } from "utils/constants/YivadoRoute";
import ContextError from "utils/errors/ContextError";

const { Title, Text } = Typography;

const { Meta } = Card;

const BuyerDashboardPage = (): React.ReactElement => {
  const userContext = useContext(UserContext);
  //const [show, setShow] = useState(true);

  if (userContext === undefined || userContext.user === undefined) {
    throw new ContextError(ContextError.CONTEXT_UNDEFINED);
  }

  return (
    <Layout
      id="dashboard"
      style={{ backgroundColor: "#1da57a", overflowX: "hidden" }}
    >
      <Row
        justify="center"
        style={{
          paddingTop: "60px",
          paddingBottom: "120px",
          backgroundColor: "#1da57a",
        }}
      >
        <Col
          style={{
            border: "1px solid #888888",
            boxShadow: "1px 1px #888888",
            borderRadius: "5px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Row justify="center">
            <Col>
              <Title
                level={1}
                style={{
                  textAlign: "center",
                  marginTop: "2rem",
                  marginBottom: "1rem",
                }}
              >
                Dashboard
              </Title>
              <Row justify="center" style={{ padding: "25px" }}>
                <Text>
                  <i>
                    Click a button below to navigate to different site locations
                  </i>
                </Text>
              </Row>
            </Col>
          </Row>
          <div id="buyer-dashboard-cards">
            <Row justify="center">
              <Link to={YivadoRoute.APPROVED_LISTINGS}>
                <Card
                  hoverable
                  cover={(
                    <img
                      alt="listing-regs"
                      src="https://static-assets-platform.s3.amazonaws.com/DashboardPage/listings.svg"
                      //src="https://static-assets-platform.s3.amazonaws.com/DashboardPage/resources.svg"
                    />
                  )}
                  style={{
                    marginTop: "30px",
                    marginLeft: "80px",
                    marginRight: "80px",
                  }}
                >
                  <Meta
                    title="My Offers"
                    description="Listing Offers & Registrations"
                    style={{
                      backgroundColor: "#1da57a",
                    }}
                  />
                </Card>
              </Link>
            </Row>
            <Row justify="center" style={{ marginBottom: "100px" }}>
              <Link to={YivadoRoute.HOW_TO_VIDEOS}>
                <Card
                  hoverable
                  cover={(
                    <img
                      alt="how-to-videos"
                      src="https://static-assets-platform.s3.amazonaws.com/DashboardPage/video-marketing.svg"
                    />
                  )}
                  style={{
                    marginLeft: "80px",
                    marginRight: "80px",
                    marginTop: "30px",
                  }}
                >
                  <Meta
                    title="How To Videos"
                    description="Learn How Yivado Works"
                    style={{
                      backgroundColor: "#1da57a",
                    }}
                  />
                </Card>
              </Link>
              <Link to={YivadoRoute.TERMS_AND_CONDITIONS}>
                <Card
                  hoverable
                  cover={(
                    <img
                      alt="terms-and-cond"
                      src="https://static-assets-platform.s3.amazonaws.com/DashboardPage/terms.svg"
                    />
                  )}
                  style={{
                    marginLeft: "80px",
                    marginRight: "80px",
                    marginTop: "30px",
                  }}
                >
                  <Meta
                    title="Terms and Cond."
                    description="Yivado Terms & Conditions"
                    style={{
                      backgroundColor: "#1da57a",
                    }}
                  />
                </Card>
              </Link>
            </Row>
          </div>
        </Col>
      </Row>
      <ApplicationFooter />
    </Layout>
  );
};

export default BuyerDashboardPage;
