//-----------------------------------------------------------------------------------------
// Imports
//-----------------------------------------------------------------------------------------

import { Modal } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import Regex from "utils/constants/Regex";
import { UploadDocType } from "utils/constants/UploadDocType";
import Logger from "utils/logging/Logger";
import S3Access from ".";
import { DownloadedImages } from "./types";

//-----------------------------------------------------------------------------------------
// Types
//-----------------------------------------------------------------------------------------

interface ImageUrlsResponse {
  url: string;
  key: string;
}

export type DocumentUrlMap = { [key in UploadDocType]?: string | null };

//-----------------------------------------------------------------------------------------

export default class S3Listing extends S3Access {
  //-----------------------------------------------------------------------------------------

  /**
   * Uploading documents for a listing
   *
   * @param file file for a listing
   * @param listingFolderId the listing specifc s3 object id
   * @param fileType the type of file
   */

  async documentUpload(
    file: UploadFile,
    listingFolderId: string,
    fileType: UploadDocType
  ): Promise<void> {
    const fileExtension = Regex.FILE_EXTENSION.exec(file.name);
    if (fileExtension === null) return;
    const key = `listings/${listingFolderId}/docs/${fileType}/${fileType}.${fileExtension[1]}`;

    const params = {
      Body: file.originFileObj,
      Bucket: this.bucket,
      Key: key,
    };

    this.s3
      .putObject(params)
      .promise()
      .catch((err) => Logger.error("Error uploading file: " + err));
  }

  //-----------------------------------------------------------------------------------------

  /**
   * Uploading an image for a listing
   *
   * @param file one listing image
   * @param listingFolderId the listing specific s3 object id
   */

  async imagesUpload(
    file: UploadFile<any>,
    listingFolderId: string,
    index: number
  ): Promise<string> {
    const imageId = `image_${index}`;

    // We need the file extension
    const fileExtension = Regex.FILE_EXTENSION.exec(file.name);
    if (fileExtension === null) return "";

    const key = `listings/${listingFolderId}/images/${imageId}.${fileExtension[1]}`;

    const params = {
      Body: file.originFileObj,
      Bucket: this.bucket,
      Key: key,
    };

    this.s3
      .putObject(params)
      .promise()
      .catch((err) => Logger.error("Error uploading file: " + err));

    return key;
  }

  //-----------------------------------------------------------------------------------------

  /**
   * Return a list of signed image urls for a listing. These urls can be used as the src
   * prop on an image component
   *
   * @param listingFolderId the listing specifc s3 object id
   * @returns All the signed image urls for a listing
   */

  async getImageUrlsForListing(
    listingFolderId: string
  ): Promise<Array<ImageUrlsResponse>> {
    const params = {
      Bucket: this.bucket,
      Prefix: `listings/${listingFolderId}/images`,
    };

    const response = await this.s3.listObjects(params).promise();

    const urls: Array<ImageUrlsResponse> =
      response.Contents !== undefined
        ? await Promise.all(
            response.Contents.map(async (obj) => {
              const url = await this.getSingleUrlFromS3(obj.Key!);
              return {
                url: url,
                key: obj.Key!.split("/")[3].split(".")[0],
              };
            })
          )
        : [];

    return urls;
  }

  //-----------------------------------------------------------------------------------------

  async getDocument(
    listingFolderId: string,
    docType: UploadDocType
  ): Promise<Blob | null> {
    const downloadParams: AWS.S3.GetObjectRequest = {
      Bucket: this.bucket,
      Key: `listings/${listingFolderId}/docs/${docType}/${docType}.pdf`,
    };

    try {
      const doc: Blob = await new Promise((resolve, reject) => {
        this.s3.getObject(downloadParams, (error, data) => {
          error
            ? reject(error)
            : resolve(
                new Blob([data.Body as Blob], { type: data.ContentType })
              );
        });
      });
      return doc;
    } catch (err) {
      Logger.error(JSON.stringify(err, null, 2));
      return null;
    }
  }

  //-----------------------------------------------------------------------------------------

  async getUrlOfDocuments(
    listingFolderId: string,
    docTypes: UploadDocType[]
  ): Promise<DocumentUrlMap> {
    const urlMap: DocumentUrlMap = {};

    for (const docType of docTypes) {
      const downloadParams = {
        Bucket: this.bucket,
        Key: `listings/${listingFolderId}/docs/${docType}/${docType}.pdf`,
        Expires: 60 * 60 * 24,
      };

      const url: string = await new Promise((resolve, reject) => {
        this.s3.getSignedUrl("getObject", downloadParams, (err, url) => {
          err ? reject(err) : resolve(url);
        });
      });

      urlMap[docType] = url;
    }

    return urlMap;
  }

  //-----------------------------------------------------------------------------------------

  async deleteListingDocument(
    listingFolderId: string,
    docType: UploadDocType
  ): Promise<void> {
    const params = {
      Bucket: this.bucket,
      Key: `listings/${listingFolderId}/docs/${docType}/${docType}.pdf`,
    };

    await this.s3.deleteObject(params).promise();
  }

  //-----------------------------------------------------------------------------------------

  /**
   * Deleting the folder that corresponds to a listing
   *
   * @param listingFolderId The s3 object id for a specific listing
   */

  async deleteListingFolder(listingFolderId: string): Promise<void> {
    const baseListingObject = `listings/${listingFolderId}`;

    // We must delete all subobjects within the s3 folder before deleting the listing specifc
    // folder. S3 SDK cannot delete a non-empty object

    // Delete Images
    await this.emptySpecificFolder(baseListingObject, "images");

    // Delete Docs
    await this.emptySpecificFolder(baseListingObject, "docs/disclosure");
    await this.emptySpecificFolder(baseListingObject, "docs/inspection");
    await this.emptySpecificFolder(baseListingObject, "docs/misc");
    await this.emptySpecificFolder(baseListingObject, "docs");

    // Delete images and docs folders
    await this.emptySpecificFolder(baseListingObject, "");

    // Delete Entire folder
    const params = {
      Bucket: this.bucket,
      Key: `listings/${listingFolderId}`,
    };

    await this.s3.deleteObject(params);
  }

  //-----------------------------------------------------------------------------------------

  /**
   * Emptying an S3 object to prep for deletion
   *
   * @param objectId The object we want to empty
   * @param subObjectId If we are emptying a subfolder, then empty that instead
   */

  async emptySpecificFolder(
    objectId: string,
    subObjectId: string
  ): Promise<void> {
    const params = {
      Bucket: this.bucket,
      Prefix: subObjectId === "" ? objectId : `${objectId}/${subObjectId}`,
    };

    const listedObjects = await this.s3.listObjectsV2(params).promise();

    // If there are no contents then we can just return here
    if (
      listedObjects.Contents === undefined ||
      listedObjects.Contents.length === 0
    )
      return;

    const deleteParams: AWS.S3.DeleteObjectsRequest = {
      Bucket: this.bucket,
      Delete: { Objects: [] },
    };

    listedObjects.Contents.forEach(({ Key }) => {
      if (Key !== undefined) deleteParams.Delete.Objects.push({ Key });
    });

    await this.s3.deleteObjects(deleteParams).promise();
  }

  //-----------------------------------------------------------------------------------------

  /**
   * Downloading all of the images for a listing
   *
   * @param listingFolderId The s3 object id for a specific listing
   */

  async downloadImages(
    listingFolderId: string
  ): Promise<Array<DownloadedImages>> {
    const params = {
      Bucket: this.bucket,
      Prefix: `listings/${listingFolderId}/images`,
    };

    const response = await this.s3.listObjects(params).promise();

    const downloadedImages: Array<DownloadedImages> =
      response.Contents !== undefined
        ? await Promise.all(
            response.Contents.map(async (obj) => {
              const url = await this.getSingleUrlFromS3(obj.Key!);
              return {
                imageUrl: url,
                imageKey: obj.Key!,
              };
            })
          )
        : [];

    return downloadedImages;
  }

  //-----------------------------------------------------------------------------------------

  static noListingDoc(docType: UploadDocType): void {
    Modal.error({
      title: `There are no ${docType} documents for this listing`,
    });
  }
}
