//-----------------------------------------------------------------------------------------

import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Row } from "antd";
import { ListingResponse } from "api/types/ResponseTypes";
import {
  ButtonBack,
  ButtonFirst,
  ButtonLast,
  ButtonNext,
  CarouselProvider,
  Dot,
  DotGroup,
  Image,
  Slide,
  Slider,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import React, { useEffect, useState } from "react";
import S3Listing from "utils/storage/S3Listing";

//-----------------------------------------------------------------------------------------
// Component Wide Constants
//-----------------------------------------------------------------------------------------
const listingS3 = new S3Listing();

//-----------------------------------------------------------------------------------------
// Props/Interfaces
//-----------------------------------------------------------------------------------------

interface CarouselImage {
  image: string;
  fileName: string;
}

interface ListingImagesProps {
  listingId: string;
}

//-----------------------------------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ListingImages = ({ listingId }: ListingImagesProps) => {
  const [imageUrls, setImageUrls] = useState<Array<CarouselImage>>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { data: imageData } = useQuery<ListingResponse>(
    gql`
      query GetImageInfo($listingId: Float!) {
        listing(id: $listingId) {
          id
          listingPicturesBucketId
          featureImageUrl
        }
      }
    `,
    { variables: { listingId: parseFloat(listingId) } }
  );

  useEffect(() => {
    if (imageData !== undefined) {
      listingS3
        .getImageUrlsForListing(imageData?.listing.listingPicturesBucketId)
        .then((response) => {
          const images: Array<CarouselImage> = response.map((url) => {
            return {
              image: url.url,
              fileName: url.key,
            };
          });

          images.sort((a, b) => {
            return a.fileName.localeCompare(b.fileName, undefined, {
              numeric: true,
              sensitivity: "base",
            });
          });

          setImageUrls(images);
        });
    }
  }, [imageData]);

  return (
    <Row style={{ marginTop: "20px" }}>
      <div style={{ width: "100%" }}>
        <CarouselProvider
          totalSlides={imageUrls.length}
          naturalSlideHeight={1}
          naturalSlideWidth={1.78}
          currentSlide={currentIndex}
        >
          <div style={{ position: "relative", margin: "auto" }}>
            <Slider>
              {imageUrls.map((image, index) => (
                <Slide index={index} key={image.image}>
                  <Image hasMasterSpinner={false} src={image.image} />
                </Slide>
              ))}
            </Slider>
            {currentIndex === 0 ? (
              <ButtonLast
                className="ant-btn ant-btn-primary"
                onClick={() => setCurrentIndex(imageUrls.length - 1)}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "0",
                  transform: "translateY(-50%)",
                }}
              >
                <ArrowLeftOutlined />
              </ButtonLast>
            ) : (
              <ButtonBack
                onClick={() => setCurrentIndex(currentIndex - 1)}
                className="ant-btn ant-btn-primary"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "0",
                  transform: "translateY(-50%)",
                }}
              >
                <ArrowLeftOutlined />
              </ButtonBack>
            )}
            {currentIndex === imageUrls.length - 1 ? (
              <ButtonFirst
                onClick={() => setCurrentIndex(0)}
                className="ant-btn ant-btn-primary"
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "0",
                  transform: "translateY(-50%)",
                }}
              >
                <ArrowRightOutlined />
              </ButtonFirst>
            ) : (
              <ButtonNext
                onClick={() => setCurrentIndex(currentIndex + 1)}
                className="ant-btn ant-btn-primary"
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "0",
                  transform: "translateY(-50%)",
                }}
              >
                <ArrowRightOutlined />
              </ButtonNext>
            )}
            <DotGroup
              style={{ textAlign: "center", marginTop: "5px" }}
              renderDots={() => {
                const dots = [];
                for (let i = 0; i < imageUrls.length; i++) {
                  dots.push(
                    <Dot
                      slide={i}
                      onClick={() => setCurrentIndex(i)}
                      style={{ margin: "2px", padding: 0 }}
                      key={imageUrls[i].image}
                    >
                      <img
                        src={imageUrls[i].image}
                        style={{ width: "75px", height: "60px" }}
                      />
                    </Dot>
                  );
                }
                return dots;
              }}
            />
          </div>
        </CarouselProvider>
      </div>
    </Row>
  );
};

export default ListingImages;
