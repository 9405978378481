import { Col, Form, Row, Typography } from "antd";
import BuyerDocsList from "components/BuyerDocsList";
import DocUploader from "components/DocUploader/DocUploader";
import React from "react";
import { no_op } from "utils/utils";

const { Title } = Typography;
const { Item: FormItem } = Form;

interface PreApprovalDocsProps {
  userBucket: string;
}
const PreApprovalDocs = ({
  userBucket,
}: PreApprovalDocsProps): React.ReactElement => {
  return (
    <>
      <Row justify="center">
        <Col
          xs={16}
          lg={14}
          xl={10}
          xxl={6}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            My Documents
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col
          xs={16}
          lg={14}
          xl={10}
          xxl={6}
          style={{
            textAlign: "left",
          }}
        >
          <BuyerDocsList userBucket={userBucket} />
        </Col>
      </Row>
      <Row justify="center">
        <Col
          xs={16}
          lg={14}
          xl={10}
          xxl={6}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            New Document
          </Title>
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ span: 16, offset: 4 }}
          lg={{ span: 14, offset: 5 }}
          xl={{ span: 10, offset: 7 }}
          xxl={{ span: 6, offset: 9 }}
          style={{
            textAlign: "left",
          }}
        >
          <FormItem name="userDocs">
            <DocUploader name="userDocuments" onChange={no_op} />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default PreApprovalDocs;
