import { Card, Col, Layout, Row, Typography } from "antd";
import ApplicationFooter from "components/ApplicationFooter";
import React, { CSSProperties } from "react";

const { Link, Title, Text } = Typography;

const headStyles: CSSProperties = {
  minHeight: "48px",
  padding: "0 24px",
  color: "#FFFFFF",
  fontSize: "30px",
  background: "transparent",
  border: "1px solid black",
  borderRadius: "2px 2px 0 0",
  borderColor: "black",
  textAlign: "center",
  backgroundColor: "#1da57a",
};

const contentStyles: CSSProperties = {
  minHeight: "48px",
  color: "rgba(0, 0, 0, 0.85)",
  fontWeight: "bolder",
  fontSize: "20px",
  textAlign: "center",
  background: "transparent",
  border: "1px solid black",
  borderRadius: "2px 2px 0 0",
  borderColor: "black",
  padding: "20px",
};

const cardStyles: CSSProperties = {
  width: "350px",
  marginLeft: "60px",
  marginRight: "60px",
  marginTop: "60px",
};

const AgentResourcesPage = (): React.ReactElement => {
  return (
    <Layout
      id="resources-cards"
      style={{
        backgroundColor: "#1da57a",
        overflowX: "hidden",
      }}
    >
      <Row
        justify="center"
        style={{
          paddingTop: "60px",
          paddingBottom: "120px",
          backgroundColor: "#1da57a",
        }}
      >
        <Col
          style={{
            border: "1px solid #888888",
            boxShadow: "1px 1px #888888",
            borderRadius: "5px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Row justify="center">
            <Col>
              <Title
                level={1}
                style={{
                  textAlign: "center",
                  marginTop: "2rem",
                  marginBottom: "1rem",
                }}
              >
                Resources
              </Title>
              <Row justify="center" style={{ padding: "25px" }}>
                <Text>
                  <i>
                    Find information below for various helpful resources for
                    Yivado Agents.
                  </i>
                </Text>
              </Row>
            </Col>
          </Row>

          <Row justify="center">
            <Col>
              <Card
                headStyle={headStyles}
                bodyStyle={contentStyles}
                title="Forms"
                style={cardStyles}
              >
                <p>
                  <Link>Technology Agreement Fee</Link>
                </p>
                <p>
                  <Link>Escrow Instructions</Link>
                </p>
              </Card>
            </Col>
            <Col>
              <Card
                headStyle={headStyles}
                bodyStyle={contentStyles}
                title="Marketing"
                style={cardStyles}
              >
                <p>
                  <Link>Templates</Link>
                </p>
                <p>
                  <Link>Social Media Guides</Link>
                </p>
              </Card>
            </Col>
          </Row>
          <Row justify="center" style={{ marginBottom: "120px" }}>
            <Col>
              <Card
                headStyle={headStyles}
                bodyStyle={contentStyles}
                title="Tools"
                style={cardStyles}
              >
                <p>
                  <Link>Planning</Link>
                </p>
                <p>
                  <Link>Zapier Guide</Link>
                </p>
              </Card>
            </Col>
            <Col>
              <Card
                headStyle={headStyles}
                bodyStyle={contentStyles}
                title="Partners"
                style={cardStyles}
              >
                <p>
                  <Link>Vendors</Link>
                </p>
                <p>
                  <Link>eXp Realty</Link>
                </p>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <ApplicationFooter />
    </Layout>
  );
};

export default AgentResourcesPage;
