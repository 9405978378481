import { Layout } from "antd";
import ApplicationFooter from "components/ApplicationFooter";
import React from "react";
import GetCertificationInformation from "./GetCertificationInformation";

const GetCertificationPage = (): React.ReactElement => {
  return (
    <Layout style={{ overflowX: "hidden" }}>
      <GetCertificationInformation />
      <ApplicationFooter />
    </Layout>
  );
};

export default GetCertificationPage;
