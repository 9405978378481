//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import { useQuery } from "@apollo/client";
import { Col, Divider, Layout, Row, Spin, Typography } from "antd";
import { Queries } from "api/queries";
import { AgentListingsResponse } from "api/types/ResponseTypes";
import { UserContext } from "context/UserContext/UserContext";
import React, { useContext } from "react";
import ContextError from "utils/errors/ContextError";

const { Title, Text } = Typography;

const MyOffersInformation = (): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  /// Calls to Hooks
  //------------------------------------------------------------------------------------------

  const userContext = useContext(UserContext);

  if (userContext === undefined || userContext.user === undefined) {
    throw new ContextError(ContextError.CONTEXT_UNDEFINED);
  }

  const {
    loading: listingsLoading,
    data: listingsResponse,
    error,
  } = useQuery<AgentListingsResponse>(Queries.GET_LISTINGS_FOR_AGENT, {
    variables: { agentId: parseFloat(userContext.user.agent.id) },
    fetchPolicy: "cache-and-network",
  });

  // const upcomingListings = useUpcomingListings(listingsResponse);
  // const activeListings = useActiveListings(listingsResponse);

  if (error) {
    return <div>Error</div>;
  }

  //------------------------------------------------------------------------------------------
  /// Rendering
  //------------------------------------------------------------------------------------------

  return listingsLoading && listingsResponse === undefined ? (
    <Spin />
  ) : (
    <Layout>
      <Row justify="start">
        <Col>
          <Title
            style={{
              padding: "20px",
              fontWeight: "bolder",
            }}
          >
            My Buyers
          </Title>
        </Col>
      </Row>
      <Row style={{ marginTop: "3rem" }} justify="center">
        <Col span={18}>
          <Text
            type="secondary"
            style={{
              fontSize: "28px",
            }}
          >
            Offers
          </Text>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={18}>
          <Divider
            style={{
              marginTop: 0,
              color: "black",
            }}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col span={18}>
          {/* <MyOffersTable
            listingData={activeListings}
            tableStatus="active"
            refetchListings={refetchListings}
          /> */}
        </Col>
      </Row>
    </Layout>
  );
};

export default MyOffersInformation;
