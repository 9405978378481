import { Layout } from "antd";
import ApplicationFooter from "../../../../components/ApplicationFooter";
import React from "react";
import BuyingFAQInformation from "./BuyingFAQInformation";

const BuyingFAQPage = (): React.ReactElement => {
  return (
    <Layout style={{ overflowX: "hidden" }}>
      <BuyingFAQInformation />
      <ApplicationFooter />
    </Layout>
  );
};

export default BuyingFAQPage;
