//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import { Col, Row, Typography } from "antd";
import { Agent } from "model/Agent";
import React from "react";
import HasAgent from "./HasAgent";
import NoAgent from "./NoAgent";

//------------------------------------------------------------------------------------------
/// Component wide constants
//------------------------------------------------------------------------------------------

const { Title } = Typography;

//------------------------------------------------------------------------------------------
/// Interfaces/Props definitions
//------------------------------------------------------------------------------------------

interface MyAgentProps {
  myAgent: Agent | null;
  userId: string;
}

//------------------------------------------------------------------------------------------

const MyAgent = ({ myAgent, userId }: MyAgentProps): React.ReactElement => {
  return (
    <>
      <Row justify="center">
        <Col
          xs={16}
          lg={14}
          xl={10}
          xxl={6}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            My Agent
          </Title>
        </Col>
      </Row>
      {myAgent === null ? (
        <NoAgent buyerId={userId} />
      ) : (
        <HasAgent myAgent={myAgent} userId={userId} />
      )}
    </>
  );
};

export default MyAgent;
