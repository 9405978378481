import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

export function useAnalytics(): any {
  // Verify Google Anatlytics was initialized
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    ReactGA.initialize([{ trackingId: process.env.REACT_APP_GA_ID! }]);
    // Update state to true. Should happen once
    setInitialized(true);
  }, []);

  return {
    initialized,
  };
}
