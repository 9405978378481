//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { useMutation } from "@apollo/client";
import { Form, Input, Modal } from "antd";
import { Mutations } from "api/mutations";
import React, { useState } from "react";
import { VoidFunction } from "types";

//------------------------------------------------------------------------------------------
// Interfaces and Props
//------------------------------------------------------------------------------------------

interface AddBuyerProps {
  isOpen: boolean;
  close: VoidFunction;
  agentId: string;
}

//------------------------------------------------------------------------------------------
// Component Definition
//------------------------------------------------------------------------------------------

const AddBuyer = ({
  isOpen,
  close,
  agentId,
}: AddBuyerProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to Hooks
  //------------------------------------------------------------------------------------------

  const [form] = Form.useForm();
  const [linkInProgress, setLinkInProgress] = useState(false);
  const [linkBuyer] = useMutation(Mutations.AGENT_LINKS_BUYER);

  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const linkNewBuyer = (): void => {
    setLinkInProgress(true);
    const formValues = form.getFieldsValue();

    linkBuyer({
      variables: {
        buyerEmail: formValues.buyerEmail,
        agentId: parseFloat(agentId),
      },
    }).then((res) => {
      setLinkInProgress(false);
      form.resetFields();
      close();

      if (!res.data.agentLinksBuyer.linked) {
        Modal.error({
          title: "Link Unsuccessful",
          content: <p>{res.data.agentLinksBuyer.message}</p>,
        });
      } else {
        Modal.success({
          title: "Request Submitted Successfully",
          content: (
            <p>
              Your newly linked buyer will show up on your my buyers page if
              they have an account and are not already linked with an agent.
            </p>
          ),
        });
      }
    });
  };

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <Modal
      title="Add Buyer"
      visible={isOpen}
      onCancel={() => {
        form.resetFields();
        close();
      }}
      onOk={() => form.validateFields().then(() => linkNewBuyer())}
      okText="Link Buyer"
      getContainer={false}
      confirmLoading={linkInProgress}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          validateTrigger="onBlur"
          name="buyerEmail"
          label="Buyer Email"
          rules={[
            {
              required: true,
              message: "Please provide your buyer's email",
            },
            {
              type: "email",
              message: "Please provide a valid email",
            },
          ]}
        >
          <Input placeholder="Provide your buyer's email" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddBuyer;
