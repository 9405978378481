import { Col, Form, Input, Row, Typography } from "antd";
import React from "react";

const { Title } = Typography;
const { Item: FormItem } = Form;

// interface NameInformationProps {
//   onFieldChange: (
//     event: React.FormEvent<HTMLInputElement>,
//     fieldName: string
//   ) => void;
// }

const NameInformation = (): React.ReactElement => {
  return (
    <>
      <Row justify="center" gutter={16}>
        <Col xs={8} lg={7} xl={5}
          xxl={3}>
          {" "}
          <Title
            level={4}
            style={{
              textAlign: "left",
              marginTop: "1.5rem",
              fontWeight: "bolder",
            }}
            className="required-label"
          >
            First Name
          </Title>
        </Col>
        <Col xs={8} lg={7} xl={5}
          xxl={3}>
          {" "}
          <Title
            level={4}
            style={{
              textAlign: "left",
              marginTop: "1.5rem",
              fontWeight: "bolder",
            }}
            className="required-label"
          >
            Last Name
          </Title>
        </Col>
      </Row>
      <Row justify="center" gutter={16}>
        <Col xs={8} lg={7} xl={5}
          xxl={3}>
          <FormItem
            name="firstName"
            rules={[{ required: true, message: "First Name is required" }]}
          >
            <Input
              style={{
                textAlign: "left",
              }}
            />
          </FormItem>
        </Col>
        <Col xs={8} lg={7} xl={5}
          xxl={3}>
          <FormItem
            name="lastName"
            rules={[{ required: true, message: "Last Name is required" }]}
          >
            <Input
              style={{
                float: "right",
              }}
            />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default NameInformation;
