//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { Col, Form, Modal, Row, Typography } from "antd";
import BuyerDocsList from "components/BuyerDocsList";
import DocUploader from "components/DocUploader/DocUploader";
import React, { useState } from "react";
import { VoidFunction } from "types";
import Logger from "utils/logging/Logger";
import S3Buyer from "utils/storage/S3Buyer";
import { no_op } from "utils/utils";

const buyerS3 = new S3Buyer();
const { Title } = Typography;

//------------------------------------------------------------------------------------------
// Interfaces/Props
//------------------------------------------------------------------------------------------

interface UploadBuyerDocsProps {
  isOpen: boolean;
  close: VoidFunction;
  currentBuyerInfo: BuyerInformation;
}

interface BuyerInformation {
  storageBucket: string;
  buyerName: string;
}

//------------------------------------------------------------------------------------------
// Component Definition
//------------------------------------------------------------------------------------------

const UploadBuyerDocs = ({
  isOpen,
  close,
  currentBuyerInfo,
}: UploadBuyerDocsProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to hooks
  //------------------------------------------------------------------------------------------

  const [formsUploading, setFormsUploading] = useState(false);
  const [form] = Form.useForm();

  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const uploadBuyerDocs = (): void => {
    setFormsUploading(true);

    buyerS3
      .uploadBuyerFiles(
        form.getFieldValue("buyerDocs")?.fileList,
        currentBuyerInfo.storageBucket
      )
      .then(() => {
        Modal.success({
          title: "Documents uploaded successfully!",
        });
      })
      .catch((err) => {
        Logger.error(err);
      })
      .finally(() => {
        console.log("ARE WE HERE!!!!!");
        setFormsUploading(false);
        form.setFieldsValue({ buyerDocs: [] });
      });
  };

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <Modal
      title={`Manage Documents for ${currentBuyerInfo.buyerName}`}
      visible={isOpen}
      okText="Upload Docs"
      getContainer={false}
      onCancel={() => {
        form.resetFields();
        close();
      }}
      confirmLoading={formsUploading}
      onOk={() => form.validateFields().then(() => uploadBuyerDocs())}
    >
      <Row>
        <Col>
          <Title level={4} style={{ fontWeight: "bolder" }}>
            Current Documents
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <BuyerDocsList userBucket={currentBuyerInfo.storageBucket} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            New Documents
          </Title>
        </Col>
      </Row>
      <Row>
        <Form layout="vertical" form={form}>
          <Form.Item
            name="buyerDocs"
            rules={[{ required: true, message: "Please provide documents" }]}
          >
            <DocUploader name="buyersDocs" onChange={no_op} />
          </Form.Item>
        </Form>
      </Row>
    </Modal>
  );
};

export default UploadBuyerDocs;
