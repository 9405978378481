import { Col, Row, Typography } from "antd";
import React from "react";

const { Title, Text } = Typography;

const BuyingFAQPageInformation = (): React.ReactElement => {
  return (
    <>
      <Row
        justify="center"
        style={{
          backgroundColor: "#1DA57A",
          paddingTop: "120px",
          paddingBottom: "120px",
        }}
      >
        <Col
          xs={20}
          md={20}
          lg={14}
          xl={12}
          xxl={12}
          style={{
            backgroundColor: "#FFFFFF",
            textAlign: "center",
            paddingBottom: "40px",
            borderRadius: "50px",
          }}
        >
          <Row justify="center">
            <Col span={18}>
              <Title
                level={1}
                style={{
                  backgroundColor: "#FFFFFF",
                  marginTop: "50px",
                  fontWeight: "bolder",
                  fontSize: "50px",
                }}
              >
                What are the Buying Benefits?
              </Title>
            </Col>
          </Row>
          <Row justify="center" style={{ paddingBottom: "120px" }}>
            <Col span={20} style={{ textAlign: "left", marginTop: "20px" }}>
              <Text
                style={{
                  textAlign: "left",
                  fontWeight: "lighter",
                  fontSize: "26px",
                }}
              >
                All types of Buyers get a Fair Chance.
              </Text>
              <br />
              <br />
              <Text
                style={{
                  textAlign: "left",
                  fontWeight: "lighter",
                  fontSize: "26px",
                }}
              >
                Ability to view the property many times.{" "}
              </Text>
              <br />
              <br />
              <Text
                style={{
                  textAlign: "left",
                  fontWeight: "lighter",
                  fontSize: "26px",
                }}
              >
                Time to research the neighborhood and area before making an
                offer.
              </Text>
              <br />
              <br />
              <Text
                style={{
                  textAlign: "left",
                  fontWeight: "lighter",
                  fontSize: "26px",
                }}
              >
                Receive disclosures before submitting an offer.
              </Text>
              <br />
              <br />
              <Text
                style={{
                  textAlign: "left",
                  fontWeight: "lighter",
                  fontSize: "26px",
                }}
              >
                Time to get finances in order.
              </Text>
              <br />
              <br />
              <Text
                style={{
                  textAlign: "left",
                  fontWeight: "lighter",
                  fontSize: "26px",
                }}
              >
                Terms and conditions established up-front.
              </Text>
              <br />
              <br />
              <Text
                style={{
                  textAlign: "left",
                  fontWeight: "lighter",
                  fontSize: "26px",
                }}
              >
                Know the top price to beat.
              </Text>
              <br />
              <br />
            </Col>
          </Row>

          {/* <Row justify="center">
            <Col span={20} style={{ textAlign: "left", marginTop: "20px" }}>
              <b style={{ fontSize: "20px" }}>Yivado </b>
              <span
                style={{
                  backgroundColor: "#FFFFFF",
                  marginTop: "20px",
                  fontWeight: "lighter",
                  fontSize: "20px",
                }}
              >
                simplifies the home buying process and gives you a much better
                chance of getting the property you want. Unlike the traditional
                home purchasing process, Yivado allows the buyers and sellers to
                see all of the offers on a particular property. With this
                visibility, you can truly bring your highest and best.
              </span>
            </Col>
          </Row>

          <Title
            level={2}
            style={{
              backgroundColor: "#FFFFFF",
              marginTop: "60px",
              fontWeight: "bolder",
              fontSize: "40px",
              textAlign: "center",
            }}
          >
            Why Yivado?
          </Title>

          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
                marginTop: "20px",
                marginBottom: "40px",
              }}
            >
              <Text
                style={{
                  backgroundColor: "#FFFFFF",
                  marginTop: "20px",
                  fontWeight: "lighter",
                  fontSize: "20px",
                }}
              >
                Sellers can ask for exactly what they want from the get-go and
                buyers can match that because they can see what the seller wants
                for their property. This much more transparent process saves
                everyone time and stress, including buyers, sellers, and agents.
              </Text>
            </Col>
          </Row> */}
        </Col>
      </Row>
      {/* <Row
        justify="center"
        style={{
          backgroundColor: "#1DA57A",
          paddingTop: "120px",
          paddingBottom: "120px",
        }}
      >
        <Col
          style={{
            backgroundColor: "#FFFFFF",
            padding: "30px",
            width: "1200px",
            borderRadius: "50px",
          }}
        >
          <Row justify="space-around">
            <Col
              xs={{ span: 20, order: 2 }}
              md={{ span: 20, order: 2 }}
              lg={{ span: 20, order: 2 }}
              xl={{ span: 12, order: 1 }}
              xxl={{ span: 12, order: 1 }}
            >
              <Title
                level={2}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginTop: "60px",
                }}
              >
                Make Educated Offers
              </Title>
              <Row
                justify="start"
                style={{
                  marginTop: "20px",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontWeight: "lighter",
                    fontSize: "20px",
                  }}
                >
                  Yivado gives you access to all the information you can’t
                  usually see. When you can see exactly what the seller is
                  looking for and what other buyers are offering, you can feel
                  confident when making your offer.
                </Text>
              </Row>
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              md={{ span: 24, order: 1 }}
              lg={{ span: 24, order: 1 }}
              xl={{ span: 6, order: 2 }}
              xxl={{ span: 6, order: 2 }}
            >
              <Row justify="center">
                <Image
                  preview={false}
                  width={320}
                  src="https://static-assets-platform.s3.amazonaws.com/FrontPage/educated-offers.png"
                />
              </Row>
            </Col>
          </Row>
          <Row
            justify="space-around"
            style={{
              marginTop: "60px",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Col
              xs={{ span: 24, order: 1 }}
              md={{ span: 24, order: 1 }}
              lg={{ span: 24, order: 1 }}
              xl={{ span: 6, order: 2 }}
              xxl={{ span: 6, order: 2 }}
            >
              <Row justify="center">
                <Image
                  preview={false}
                  width={320}
                  src="https://static-assets-platform.s3.amazonaws.com/FrontPage/own-realtor.png"
                />
              </Row>
            </Col>
            <Col
              xs={{ span: 20, order: 2 }}
              md={{ span: 20, order: 2 }}
              lg={{ span: 20, order: 2 }}
              xl={{ span: 12, order: 2 }}
              xxl={{ span: 12, order: 2 }}
            >
              <Title
                level={2}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginTop: "60px",
                }}
              >
                Use Your Own Realtor
              </Title>

              <Row
                justify="start"
                style={{
                  marginTop: "20px",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontWeight: "lighter",
                    fontSize: "20px",
                  }}
                >
                  Our platform allows you to use your own realtor. If you
                  haven’t found one yet, we have a huge network of real estate
                  professionals for you to choose from.
                </Text>
              </Row>
            </Col>
          </Row>
          <Row
            justify="space-around"
            style={{
              marginTop: "60px",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Col
              xs={{ span: 20, order: 2 }}
              md={{ span: 20, order: 2 }}
              lg={{ span: 20, order: 2 }}
              xl={{ span: 12, order: 1 }}
              xxl={{ span: 12, order: 1 }}
            >
              <Title
                level={2}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginTop: "60px",
                }}
              >
                Dial Down the Stress
              </Title>
              <Row
                justify="start"
                style={{
                  marginTop: "20px",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontWeight: "lighter",
                    fontSize: "20px",
                  }}
                >
                  Real estate is one of the biggest purchases you can make. For
                  many, it can also be one of the most stressful. Yivado reduces
                  both time and paperwork for an all-around better experience.
                </Text>
              </Row>
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              md={{ span: 24, order: 1 }}
              lg={{ span: 24, order: 1 }}
              xl={{ span: 6, order: 2 }}
              xxl={{ span: 6, order: 2 }}
            >
              <Row justify="center">
                <Image
                  preview={false}
                  width={320}
                  src="https://static-assets-platform.s3.amazonaws.com/FrontPage/dial-down-stress.png"
                />
              </Row>
            </Col>
          </Row>

          <Row
            justify="space-around"
            style={{
              marginTop: "60px",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Col
              xs={{ span: 24, order: 1 }}
              md={{ span: 24, order: 1 }}
              lg={{ span: 24, order: 1 }}
              xl={{ span: 6, order: 2 }}
              xxl={{ span: 6, order: 2 }}
            >
              <Row justify="center">
                <Image
                  preview={false}
                  width={320}
                  src="https://static-assets-platform.s3.amazonaws.com/FrontPage/know-terms.png"
                />
              </Row>
            </Col>
            <Col
              xs={{ span: 20, order: 2 }}
              md={{ span: 20, order: 2 }}
              lg={{ span: 20, order: 2 }}
              xl={{ span: 12, order: 2 }}
              xxl={{ span: 12, order: 2 }}
            >
              <Title
                level={2}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginTop: "60px",
                }}
              >
                Know the Terms Upfront
              </Title>

              <Row
                justify="start"
                style={{
                  marginTop: "20px",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontWeight: "lighter",
                    fontSize: "20px",
                  }}
                >
                  Take the guesswork out of the seller’s terms. With Yivado,
                  they set out their preferred terms and make them visible for
                  all to see.
                </Text>
              </Row>
            </Col>
          </Row>
          <Row
            justify="space-around"
            style={{
              marginTop: "60px",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Col
              xs={{ span: 20, order: 2 }}
              md={{ span: 20, order: 2 }}
              lg={{ span: 20, order: 2 }}
              xl={{ span: 12, order: 1 }}
              xxl={{ span: 12, order: 1 }}
            >
              <Title
                level={2}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  marginTop: "60px",
                }}
              >
                Automate Your Offers
              </Title>
              <Row
                justify="start"
                style={{
                  marginTop: "20px",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              >
                <Text
                  style={{
                    textAlign: "left",
                    fontWeight: "lighter",
                    fontSize: "20px",
                  }}
                >
                  Set up automatic offers and set a limit so that you’ll never
                  go over budget. If a higher offer comes in, you’ll be notified
                  right away.
                </Text>
              </Row>
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              md={{ span: 24, order: 1 }}
              lg={{ span: 24, order: 1 }}
              xl={{ span: 6, order: 2 }}
              xxl={{ span: 6, order: 2 }}
            >
              <Row justify="center">
                <Image
                  preview={false}
                  width={320}
                  src="https://static-assets-platform.s3.amazonaws.com/FrontPage/automate-offers.png"
                />
              </Row>
            </Col>
          </Row>
          {userContext === undefined ||
            (userContext.user === undefined && (
              <Row
                justify="center"
                style={{
                  marginTop: "120px",
                  marginBottom: "60px",
                }}
              >
                <Button
                  type="primary"
                  shape="round"
                  style={{ fontSize: "22px", width: "400px", height: "50px" }}
                  onClick={() => history.push(YivadoRoute.AUTHENTICATION)}
                  size="large"
                >
                  Register Now!
                </Button>
              </Row>
            ))}
        </Col>
      </Row> */}
    </>
  );
};

export default BuyingFAQPageInformation;
