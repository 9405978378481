import { Col, Divider, Layout, Row, Typography } from "antd";
import React from "react";

const { Title } = Typography;

const ListingPageHeader = (): React.ReactElement => (
  <Layout>
    <Row justify="center">
      <Col>
        <Title
          level={1}
          style={{
            textAlign: "center",
            marginTop: "2rem",
            fontWeight: "bolder",
          }}
        >
          Create Your Listing
        </Title>
      </Col>
    </Row>
    <Row style={{ marginTop: "3rem" }} justify="center">
      <Col style={{ fontSize: "18px" }}>
        <span style={{ color: "red" }}>*</span> indicates a required field
      </Col>
    </Row>
    <Row justify="center">
      <Col xs={20} md={20} lg={16}
        xl={12} xxl={12}>
        <Divider
          style={{
            marginTop: 0,
            color: "red",
          }}
        />
      </Col>
    </Row>
  </Layout>
);

export default ListingPageHeader;
