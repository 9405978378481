//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { useMutation } from "@apollo/client";
import { Form, Input, Modal } from "antd";
import { Mutations } from "api/mutations";
import React, { useState } from "react";
import { VoidFunction } from "types";
import Logger from "utils/logging/Logger";
import { BlastType } from "./BlastType";

//------------------------------------------------------------------------------------------
// Component Constants
//------------------------------------------------------------------------------------------

const { TextArea } = Input;

//------------------------------------------------------------------------------------------
// Interfaces and Props
//------------------------------------------------------------------------------------------

interface SendBlastProps {
  isOpen: boolean;
  close: VoidFunction;
  blastType: BlastType;
  listingId: string;
}

//------------------------------------------------------------------------------------------

const SendBlast = ({
  isOpen,
  close,
  blastType,
  listingId,
}: SendBlastProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to Hooks
  //------------------------------------------------------------------------------------------

  const [emailMutation] = useMutation(Mutations.SEND_EMAIL_BLAST);
  const [messageSending, setMessageSending] = useState(false);
  const [form] = Form.useForm();

  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const sendMessage = (): void => {
    if (blastType === BlastType.EMAIL) sendEmail();
    if (blastType === BlastType.SMS) console.log("Sending SMS");
  };

  const sendEmail = (): void => {
    setMessageSending(true);
    emailMutation({
      variables: {
        listingId: parseFloat(listingId),
        message: form.getFieldValue("message"),
      },
    })
      .then(() => {
        Modal.success({ title: "Your message was sent successfully" });
        form.resetFields();
        setMessageSending(false);
        close();
      })
      .catch((err) => {
        setMessageSending(false);
        Modal.error({ title: "Something went wrong sending your message" });
        Logger.error(JSON.stringify(err, null, 2));
      });
  };

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <Modal
      visible={isOpen}
      onCancel={() => {
        form.resetFields();
        close();
      }}
      onOk={() => {
        form.validateFields().then(() => {
          sendMessage();
        });
      }}
      title={`Send ${blastType}`}
      okText={`Send ${blastType}`}
      confirmLoading={messageSending}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={`${blastType} Message`}
          rules={[
            { required: true, message: "Please provide a message to send" },
          ]}
          name="message"
        >
          <TextArea placeholder="Message for buyers" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SendBlast;
