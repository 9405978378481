import { Layout } from "antd";
import React from "react";
import ApplicationFooter from "../../../../components/ApplicationFooter";
import RealtorInformation from "./RealtorInformation";

const RealtorFAQPage = (): React.ReactElement => {
  return (
    <Layout style={{ overflowX: "hidden" }}>
      <RealtorInformation />
      <ApplicationFooter />
    </Layout>
  );
};

export default RealtorFAQPage;
