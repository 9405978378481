import React from "react";

interface ListingFormLabelProps {
  text: string;
  required?: boolean;
}

const ListingFormLabel = ({
  text,
  required,
}: ListingFormLabelProps): React.ReactElement => (
  <h3
    className={required ? "required-label" : ""}
    style={{ fontWeight: "bolder", fontSize: "24px" }}
  >
    {text}
  </h3>
);

export default ListingFormLabel;
