import { ActiveListingsResponse } from "api/types/ResponseTypes";
import { ListingCardInfo } from "pages/ListingPages/BrowseListings/ListingsPageTypes";

//------------------------------------------------------------------------------------------------------------

export const generateCardInfo = (
  listingData: ActiveListingsResponse
): Array<ListingCardInfo> => {
  return listingData.activeListings.map((listing) => {
    const cardInfo: ListingCardInfo = {
      id: listing.id,
      endDate: listing.auction?.endDate,
      imageUrl: listing.featureImageUrl,
    };

    return cardInfo;
  });
};

//------------------------------------------------------------------------------------------------------------

export const getStatusForListing = (
  startDate: string | undefined,
  endDate: string | undefined
): string => {
  if (startDate === undefined || endDate === undefined) return "invalid";

  const now = new Date().toISOString();

  if (now >= startDate && now <= endDate) {
    return "active";
  } else if (now > endDate) {
    return "past";
  } else {
    return "future";
  }
};
