export enum YivadoRoute {
  FRONT_PAGE = "/",
  BROWSE_LISTINGS = "/listings",
  LISTING_CREATION = "/listing-create",
  SINGLE_LISTING = "/listing/:listingId",
  EDIT_LISTING = "/listing/edit/:listingId",
  MANAGE_LISTING = "/listing/manage/:listingId",
  PROFILE = "/profile",
  HOW_IT_WORKS = "/how-it-works",
  REQUEST_REALTOR = "/request-realtor",
  GET_CERTIFICATION = "/get-certification",
  ABOUT = "/about",
  MY_LISTINGS = "/my-listings",
  APPROVED_LISTINGS = "/approved-listings",
  DASHBOARD = "/dashboard",
  BUYER_DASHBOARD = "/buyer-dashboard",
  ADMIN = "/admin",
  MY_BUYERS = "/my-buyers",
  MY_OFFERS = "/my-offers",
  AGENT_RESOURCES = "/agent-resources",
  CONTACT = "/contact",
  FAQ = "/faq",
  HOW_TO_VIDEOS = "/how-to-videos",
  BUY = "/buy",
  SELL = "/sell",
  REALTORS = "/realtors",
  VENDORS = "/vendors",
  PRIVACY = "/privacy",
  TERMS_AND_CONDITIONS = "/terms-conditions",
  AUTHENTICATION = "/authentication",
  TRAINING = "/training",
  RECOVER = "/recover",
  CONFIRM = "/confirm",
}
