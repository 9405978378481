//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { useQuery } from "@apollo/client";
import { Col, Layout, Row, Spin, Tabs, Typography } from "antd";
import { Queries } from "api/queries";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Buyers from "./Buyers";
import { ManagementTab } from "./ManagementTabConfig";
import Offers from "./Offers";

//------------------------------------------------------------------------------------------
// Component Wide Constants
//------------------------------------------------------------------------------------------

const { TabPane } = Tabs;
const { Text } = Typography;

//------------------------------------------------------------------------------------------
// Interfaces and Props
//------------------------------------------------------------------------------------------

interface MatchParams {
  listingId: string;
}

type ListingManagementProps = RouteComponentProps<MatchParams>;

//------------------------------------------------------------------------------------------

const ListingManagement = ({
  match,
}: ListingManagementProps): React.ReactElement => {
  const { data } = useQuery(Queries.GET_LISTING_ADDRESS, {
    variables: { listingId: parseFloat(match.params.listingId) },
  });

  const address =
    data === undefined ? (
      <Spin />
    ) : (
      <b>
        {data.listing.address}, {data.listing.city}, {data.listing.state}
      </b>
    );

  return (
    <Layout style={{ paddingTop: "40px" }}>
      <Row justify="center">
        <Col
          style={{
            fontSize: "40px",
            textAlign: "center",
            alignItems: "center",
          }}
          span={12}
        >
          <Text
            style={{
              fontSize: "40px",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            {address}
          </Text>
        </Col>
      </Row>
      <Tabs
        defaultActiveKey={ManagementTab.OFFERS}
        style={{
          marginTop: "10px",
          marginLeft: "30px",
          marginRight: "30px",
        }}
        // tabBarExtraContent={{
        //   right: messageBuyersDropDown,
        // }}
        centered
        type="card"
      >
        <TabPane
          tab={ManagementTab.OFFERS}
          key={ManagementTab.OFFERS}
          style={{ padding: "5px" }}
        >
          <Offers listingId={match.params.listingId} />
        </TabPane>
        <TabPane
          tab={ManagementTab.BUYERS}
          key={ManagementTab.BUYERS}
          style={{ padding: "5px" }}
        >
          <Buyers listingId={match.params.listingId} />
        </TabPane>
      </Tabs>
    </Layout>
  );
};

export default ListingManagement;
