type FeatureConfig = {
  displayString: string;
};

export const HomeFeaturesConfig: Map<string, FeatureConfig> = new Map<
  string,
  FeatureConfig
>([
  ["officeOrDen", { displayString: "Office or Den" }],
  ["loft", { displayString: "Loft" }],
  ["basement", { displayString: "Basement" }],
  ["solar", { displayString: "Solar" }],
  ["privatePool", { displayString: "Private Pool" }],
  ["communityPool", { displayString: "Community Pool" }],
  ["spaOrJacuzzi", { displayString: "Spa or Jacuzzi" }],
  ["fireplace", { displayString: "Fireplace" }],
  ["golfCourseLot", { displayString: "Golf Course Lot" }],
  ["golfCourseCommunity", { displayString: "Golf Course Community" }],
  ["lakeCommunity", { displayString: "Lake Community" }],
  ["waterfrontLot", { displayString: "Waterfront Lot" }],
  ["tennisCourts", { displayString: "Tennis Courts" }],
  ["gatedCommunity", { displayString: "Gated Community" }],
  ["mountainViews", { displayString: "Mountain Views" }],
  ["communityClubHouse", { displayString: "Community Clubhouse" }],
]);
