import { CSSProperties } from "react";
import Background from "../../../static/banner-background-new-comp.jpg";

export const ButtonStylesNonHover: CSSProperties = {
  border: "1px solid black",
  color: "black",
  boxShadow: "0 0 0 transparent",
  fontSize: "16px",
  height: "40px",
  transition: "background .45s @ease-out, box-shadow 0.45s @ease-out",
};

export const ButtonStylesHover: CSSProperties = {
  borderColor: "black",
  color: "black",
  boxShadow: "0 0 10px white",
};

export const BannerStyles: CSSProperties = {
  width: "100%",
  height: "100vh",
  textAlign: "center",
  backgroundImage: `url(${Background})`,
  backgroundSize: "cover",
  backgroundPosition: "center top",
  minHeight: "40rem",
};

export const TitleH1Styles: CSSProperties = {
  // fontSize: "40px",
  color: "white",
  fontWeight: "bolder",
  justifyContent: "center",
  textShadow: "2px 2px black",
  marginBottom: "2rem",
};

export const TitleH2Styles: CSSProperties = {
  // fontSize: "60px",
  color: "white",
  fontWeight: "bold",
  justifyContent: "center",
  textShadow: "2px 2px black",
  marginBottom: "2rem",
};
