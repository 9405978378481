//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { SmileOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, List, Popconfirm } from "antd";
import React from "react";
import { useBuyerDocs } from "utils/hooks";

//------------------------------------------------------------------------------------------
// Interfaces/Props
//------------------------------------------------------------------------------------------

interface BuyerDocsListProps {
  userBucket: string;
}

//------------------------------------------------------------------------------------------
// Component Definition
//------------------------------------------------------------------------------------------

export const BuyerDocsList = ({
  userBucket,
}: BuyerDocsListProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to hooks
  //------------------------------------------------------------------------------------------

  const { buyerDocs, refetchDocs, deleteDoc, deletingDoc } =
    useBuyerDocs(userBucket);

  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const renderNoDocs = () => (
    <div style={{ textAlign: "center" }}>
      <SmileOutlined style={{ fontSize: 20 }} />
      <p>There are no documents.</p>
    </div>
  );

  const runDelete = (fileName: string) => {
    deleteDoc(fileName).finally(() => {
      refetchDocs();
    });
  };

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <ConfigProvider renderEmpty={renderNoDocs}>
      <List
        itemLayout="horizontal"
        dataSource={buyerDocs}
        loading={deletingDoc}
        renderItem={(doc) => (
          <List.Item
            actions={[
              <Popconfirm
                key="delete"
                title="Are you sure you want to delete this document?"
                onConfirm={() => runDelete(doc.fileName)}
              >
                <Button type="link" danger>
                  Delete
                </Button>
              </Popconfirm>,
              <a
                href={URL.createObjectURL(doc.file)}
                target="_blank"
                key="view-doc"
                rel="noopener noreferrer"
              >
                View
              </a>,
            ]}
          >
            {doc.fileName}
          </List.Item>
        )}
      />
    </ConfigProvider>
  );
};

export default BuyerDocsList;
