import { CheckOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, message, Modal } from "antd";
import { Mutations } from "api/mutations";
import { Approved, ApprovedStatus } from "model/Approved";
import React, { useState } from "react";
import { VoidFunction } from "types";
import Logger from "utils/logging/Logger";
import DenyReason from "./DenyReason";

interface ApproveDenyModalProps {
  isOpen: boolean;
  close: VoidFunction;
  approvedRecord?: Approved;
}

const ApproveDenyModal = ({
  isOpen,
  close,
  approvedRecord,
}: ApproveDenyModalProps): React.ReactElement => {
  const [attemptedApprovalStatus, setAttemptedApprovalStatus] =
    useState<ApprovedStatus>();

  const [denyReasonOpen, setDenyReasonOpen] = useState(false);
  const [updateApprovalStatus, { loading }] = useMutation(
    Mutations.UPDATE_APPROVAL_STATUS
  );

  const gatherDenyReason = (): void => {
    setDenyReasonOpen(true);
  };

  const setBuyerApprovalStatus = (
    newApprovalStatus: ApprovedStatus,
    denyReason?: string
  ): void => {
    setAttemptedApprovalStatus(newApprovalStatus);

    updateApprovalStatus({
      variables: {
        approvalStatusData: {
          listingId: parseFloat(approvedRecord!.listing.id),
          userId: parseFloat(approvedRecord!.approvedUser.id),
          approvalStatus: newApprovalStatus,
          denyReason: [null, undefined, ""].includes(denyReason)
            ? null
            : denyReason,
        },
      },
    })
      .then(() => {
        message.success("Status updated successfully");
        close();
      })
      .catch((err) => {
        Logger.error(err);
      });
  };

  return (
    <>
      <Modal
        title="Approve or Deny"
        onCancel={close}
        visible={isOpen}
        footer={[
          <Button
            key="approve"
            type="primary"
            icon={<CheckOutlined />}
            loading={
              loading && attemptedApprovalStatus === ApprovedStatus.APPROVED
            }
            disabled={
              loading && attemptedApprovalStatus === ApprovedStatus.DENIED
            }
            onClick={() => setBuyerApprovalStatus(ApprovedStatus.APPROVED)}
          >
            Approve
          </Button>,
          <Button
            key="deny"
            danger
            loading={
              loading && attemptedApprovalStatus === ApprovedStatus.DENIED
            }
            disabled={
              loading && attemptedApprovalStatus === ApprovedStatus.APPROVED
            }
            icon={<CloseCircleOutlined />}
            onClick={gatherDenyReason}
          >
            Deny
          </Button>,
        ]}
      >
        <p>
          Please select whether you would like to approve or deny this buyer.
        </p>
      </Modal>
      <DenyReason
        isOpen={denyReasonOpen}
        close={() => setDenyReasonOpen(!denyReasonOpen)}
        setApprovalStatus={setBuyerApprovalStatus}
      />
    </>
  );
};

export default ApproveDenyModal;
