export interface MenuItem {
  to: string;
  text: string;
  key: string;
}

export const NavigationBarItems: Array<MenuItem> = [
  {
    to: "/buy",
    text: "Buy",
    key: "Buy",
  },
  {
    to: "/sell",
    text: "Sell",
    key: "Sell",
  },
  {
    to: "/realtors",
    text: "Realtors",
    key: "Realtors",
  },
];

export const SubMenuItems: Array<MenuItem> = [
  {
    to: "/about",
    text: "About",
    key: "About",
  },
  {
    to: "/how-to-videos",
    text: "How To",
    key: "How To Videos",
  },
  {
    to: "/terms-conditions",
    text: "Terms",
    key: "Terms",
  },
  {
    to: "/contact",
    text: "Contact Us",
    key: "contact",
  },
  {
    to: "/faq",
    text: "FAQ",
    key: "faq",
  },
];
