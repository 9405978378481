import { useMutation } from "@apollo/client";
import { Button, Col, Form, Input, Layout, Modal, Row, Typography } from "antd";
import MaskedInput from "antd-mask-input";
import { Mutations } from "api/mutations";
import ApplicationFooter from "components/ApplicationFooter";
import React, { useState } from "react";
import ListingFormLabel from "../../components/ListingFormItems/ListingFormLabel";

const { Title } = Typography;
const { TextArea } = Input;
const { Item: FormItem } = Form;

const ContactPage = (): React.ReactElement => {
  const [form] = Form.useForm();
  const [contactYivado] = useMutation(Mutations.CONTACT_YIVADO);
  const [contactingYivado, setContactingYivado] = useState(false);

  const handleSubmit = () => {
    const formValues = form.getFieldsValue();

    setContactingYivado(true);

    contactYivado({
      variables: {
        contactData: {
          userEmail: formValues.email,
          userPhoneNumber: formValues.phoneNumber,
          userName: formValues.name,
          userMessage: formValues.message,
        },
      },
    }).then(() => {
      form.resetFields();
      setContactingYivado(false);
      Modal.success({
        title:
          "Your message was sent successfully. Someone will be reaching out to you soon.",
      });
    });
  };

  return (
    <Layout id="contact-us" style={{ overflowX: "hidden" }}>
      <Row
        justify="center"
        style={{
          backgroundColor: "#1DA57A",
          paddingTop: "80px",
          paddingBottom: "400px",
        }}
      >
        <Col
          xs={24}
          sm={20}
          md={14}
          lg={12}
          xl={10}
          style={{
            textAlign: "center",
            backgroundColor: "#FFFFFF",
            padding: "30px",
            borderRadius: "50px",
          }}
        >
          <Title
            level={1}
            style={{
              color: "black",
              fontWeight: "bold",
              marginTop: "20px",
            }}
          >
            Contact Us
          </Title>

          <Form layout="vertical" form={form} onSubmitCapture={handleSubmit}>
            <Row justify="center">
              <Col xs={22} md={20}>
                <FormItem
                  rules={[
                    { required: true, message: "Please input your name" },
                  ]}
                  required={false} // For some reason we need this to get rid of the leading asterisk
                  label={<ListingFormLabel required text="Name" />}
                  name="name"
                >
                  <Input
                    title="Name"
                    placeholder="Name"
                    disabled={contactingYivado}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row justify="center">
              <Col xs={22} md={20}>
                <FormItem
                  rules={[
                    { message: "Please input your email", required: true },
                  ]}
                  required={false} // For some reason we need this to get rid of the leading asterisk
                  label={<ListingFormLabel required text="Email" />}
                  name="email"
                  validateTrigger="onBlur"
                >
                  <Input
                    type="email"
                    placeholder="Email"
                    disabled={contactingYivado}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row justify="center">
              <Col xs={22} md={20}>
                <FormItem
                  rules={[
                    {
                      message: "Please input your phone number",
                      required: true,
                    },
                  ]}
                  required={false} // For some reason we need this to get rid of the leading asterisk
                  label={<ListingFormLabel required text="Phone Number" />}
                  name="phoneNumber"
                  validateTrigger="onBlur"
                >
                  <MaskedInput
                    style={{
                      textAlign: "left",
                      width: "100%",
                      marginTop: "0.25rem",
                    }}
                    mask="(111) 111-1111"
                    name="card"
                    autoComplete="new-password"
                    disabled={contactingYivado}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row justify="center" style={{ marginBottom: "40px" }}>
              <Col xs={22} md={20}>
                <FormItem
                  rules={[
                    {
                      message: "Please provide a message to us!",
                      required: true,
                    },
                  ]}
                  required={false} // For some reason we need this to get rid of the leading asterisk
                  label={<ListingFormLabel required text="Message" />}
                  name="message"
                >
                  <TextArea
                    style={{ minHeight: "200px" }}
                    placeholder="Your message here"
                    disabled={contactingYivado}
                  />
                </FormItem>
              </Col>
            </Row>

            <Row justify="center">
              <Col xs={22} md={18}>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  loading={contactingYivado}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <ApplicationFooter />
    </Layout>
  );
};

export default ContactPage;
