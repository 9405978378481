//------------------------------------------------------------------------------------------------------------

import { useEffect } from "react";

//------------------------------------------------------------------------------------------------------------

// Change titles on pages
export function useTitle(title: string): any {
  useEffect(() => {
    document.title = title;
  }, [title]);
}
