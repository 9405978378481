import { AgentListingsResponse } from "api/types/ResponseTypes";
import { Listing } from "model/Listing";
import { useMemo } from "react";

export function useUpcomingListings(
  listingResponse: AgentListingsResponse | undefined
): Listing[] {
  return useMemo(() => {
    // When there is no data yet we can just return an empty array
    if (listingResponse === undefined) return [];
    const now = new Date().toISOString().replace("Z", "+00");
    return listingResponse.agentListings.filter(
      (listing) =>
        listing.auction.startDate > now && listing.auction.endDate > now
    );
  }, [listingResponse]);
}

export function useActiveListings(
  listingResponse: AgentListingsResponse | undefined
): Listing[] {
  return useMemo(() => {
    // When there is no data yet we can just return an empty array
    if (listingResponse === undefined) return [];
    const now = new Date().toISOString().replace("Z", "+00");
    return listingResponse.agentListings.filter(
      (listing) =>
        listing.auction.startDate < now && listing.auction.endDate > now
    );
  }, [listingResponse]);
}

export function useClosedListings(
  listingsResponse: AgentListingsResponse | undefined
): Listing[] {
  return useMemo(() => {
    // When there is no data yet we can just return an empty array
    if (listingsResponse === undefined) return [];

    const now = new Date().toISOString().replace("Z", "+00");

    return listingsResponse.agentListings.filter(
      (listing) => listing.auction.endDate < now
    );
  }, [listingsResponse]);
}
