//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { Button, Checkbox, Col, Form, Modal, Row, Typography } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { VoidFunction } from "types";
import AuthUtils from "utils/auth/AuthUtils";
import { YivadoRoute } from "utils/constants/YivadoRoute";
import { useForceUpdate } from "utils/hooks";
import Logger from "utils/logging/Logger";
import S3Buyer from "utils/storage/S3Buyer";
import AccountAndPersonal from "./RegistrationSteps/AccountAndPersonal";
import IsARealtor from "./RegistrationSteps/IsARealtor";
import NotWorkingWithRealtor from "./RegistrationSteps/NotWorkingWithRealtor";
import WorkingWithRealtor from "./RegistrationSteps/WorkingWithRealtor";
import { buildCreateUserInput } from "./utils";

const buyerS3 = new S3Buyer();

//------------------------------------------------------------------------------------------
// Global Constants
//------------------------------------------------------------------------------------------

const { Title } = Typography;
const authUtils = new AuthUtils();

//------------------------------------------------------------------------------------------
// Interfaces and Props
//------------------------------------------------------------------------------------------

interface RegisterProps {
  backToSignIn: VoidFunction;
}

//------------------------------------------------------------------------------------------

const Register = ({ backToSignIn }: RegisterProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to Hooks
  //------------------------------------------------------------------------------------------

  const [form] = Form.useForm();
  const forceUpdate = useForceUpdate();
  const [registrationInProgress, setRegistrationInProgress] = useState(false);

  const history = useHistory();

  //------------------------------------------------------------------------------------------
  // Helpers and Handlers
  //------------------------------------------------------------------------------------------

  const returnToSignIn = () => {
    form.resetFields();
    backToSignIn();
  };

  const uploadDocs = async (bucketId: string) => {
    if (form.getFieldValue("userDocs") === undefined) return;

    await buyerS3.uploadBuyerFiles(
      form.getFieldValue("userDocs")?.fileList,
      bucketId
    );
  };

  const submitRegistrationForm = async (): Promise<void> => {
    const fieldValues = form.getFieldsValue();

    setRegistrationInProgress(true);

    const input = buildCreateUserInput(fieldValues);

    authUtils
      .signUp(input)
      .then((response) => {
        uploadDocs(input.storageBucketId).then(() => {
          setRegistrationInProgress(false);
          console.log("are we here???");
          if (!response.success) {
            Modal.error({ title: response.message });
            return;
          }

          Modal.success({
            title: "Your account has been successfully created.",
            content: "Please check your email to verify your account.",
          });
          history.push(YivadoRoute.FRONT_PAGE);
        });
      })
      .catch((err) => {
        Logger.error(JSON.stringify(err, null, 2));
        setRegistrationInProgress(false);
      });
  };

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  const workingWithRealtor = form.getFieldValue("workingWithRealtor");

  return (
    <>
      <Row justify="center">
        <Col>
          <Title level={1} style={{ marginTop: "2rem", fontWeight: "bolder" }}>
            Create Your Account
          </Title>
        </Col>
      </Row>
      <Form
        form={form}
        onSubmitCapture={submitRegistrationForm}
        initialValues={{ wantsNotifications: true }}
        id="registration-form"
        layout="vertical"
      >
        <AccountAndPersonal onSelectChange={forceUpdate} />
        <WorkingWithRealtor
          fieldsRequired={workingWithRealtor === "Y"}
          display={workingWithRealtor === "Y"}
        />
        <NotWorkingWithRealtor
          fieldsRequired={workingWithRealtor === "N"}
          display={workingWithRealtor === "N"}
        />
        <IsARealtor
          fieldsRequired={workingWithRealtor === "realtor"}
          display={workingWithRealtor === "realtor"}
        />
        <Row
          style={{
            marginTop: "10px",
            display: workingWithRealtor ? "" : "none",
          }}
        >
          <Col xs={{ offset: 1 }} lg={{ offset: 4 }} xl={{ offset: 7 }}>
            <Form.Item valuePropName="checked" name="wantsNotifications">
              <Checkbox>
                <b>Opt in for Email and SMS Notifications</b>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row
          justify="center"
          style={{
            marginTop: "10px",
            display: workingWithRealtor ? "" : "none",
          }}
        >
          <Col xs={8} lg={7} xl={5}
            xxl={3}>
            <Button
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={registrationInProgress}
            >
              Register
            </Button>
          </Col>
        </Row>
      </Form>
      <Row
        justify="center"
        style={{ marginTop: workingWithRealtor ? "" : "10px" }}
      >
        <Col>
          <Button type="link" size="large" onClick={returnToSignIn}>
            Already have an account? Sign in now.
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Register;
