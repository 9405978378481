//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { DownOutlined, SearchOutlined, SmileOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import {
  Button,
  Col,
  ConfigProvider,
  Dropdown,
  Input,
  Layout,
  List,
  Menu,
  Row,
  Select,
  Spin,
} from "antd";
import { Queries } from "api/queries";
import { ApprovalForListingResponse } from "api/types/ResponseTypes";
import { ApprovedStatus } from "model/Approved";
import React, { useState } from "react";
import { useBreakpoint } from "utils/hooks";
import Logger from "utils/logging/Logger";
import SendBlast from "../SendBlast";
import { BlastType } from "../SendBlast/BlastType";
import BuyerCard from "./BuyerCard";
import { applyFilters, BuyerFilters } from "./utils";

//------------------------------------------------------------------------------------------
// Component Wide Constants
//------------------------------------------------------------------------------------------

const STATUS_PRIORITIES: Map<ApprovedStatus, number> = new Map<
  ApprovedStatus,
  number
>([
  [ApprovedStatus.PENDING, 0],
  [ApprovedStatus.APPROVED, 1],
  [ApprovedStatus.DENIED, 2],
]);

//------------------------------------------------------------------------------------------
// Interfaces and Props
//------------------------------------------------------------------------------------------

interface BlastStatus {
  open: boolean;
  blastType: BlastType;
}

interface BuyersProps {
  listingId: string;
}

//------------------------------------------------------------------------------------------

const Buyers = ({ listingId }: BuyersProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to hooks
  //------------------------------------------------------------------------------------------

  const {
    loading: buyersLoading,
    data: buyersData,
    error: queryError,
  } = useQuery<ApprovalForListingResponse>(Queries.GET_BUYERS_FOR_LISTING, {
    variables: { listingId: parseFloat(listingId) },
    pollInterval: 1000,
  });

  const breakPoint = useBreakpoint();

  const [blastStatus, setBlastStatus] = useState<BlastStatus>({
    open: false,
    blastType: BlastType.EMAIL,
  });

  const [filters, setFilters] = useState<BuyerFilters>({
    search: "",
    approvalStatus: new Set<string>(),
  });

  if (queryError) {
    Logger.error(JSON.stringify(queryError, null, 2));
    return <div>Error</div>;
  }

  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const updateSearchFilter = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFilters({
      ...filters,
      search: event.target.value,
    });
  };

  const updateApprovalFilter = (selectedValues: Array<string>): void => {
    setFilters({
      ...filters,
      approvalStatus: new Set(selectedValues),
    });
  };

  const closeBlast = (): void => {
    setBlastStatus({
      open: false,
      blastType: BlastType.EMAIL,
    });
  };

  const openBlast = (blastType: BlastType): void => {
    setBlastStatus({
      open: true,
      blastType,
    });
  };

  //------------------------------------------------------------------------------------------
  // Applying all filters
  //------------------------------------------------------------------------------------------

  // APPLYING THE SEARCH FIELD CRITERIA

  const filteredData =
    buyersData !== undefined
      ? buyersData.approvalsForListing.filter((approval) => {
          return applyFilters(approval, filters);
        })
      : [];

  // We want to sort the filtered data by approval status
  // PENDING --> APPROVED --> DENIED
  filteredData.sort((a, b) => {
    return (
      STATUS_PRIORITIES.get(a.approvalStatus)! -
      STATUS_PRIORITIES.get(b.approvalStatus)!
    );
  });

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  const renderNoData = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <SmileOutlined style={{ fontSize: 20 }} />
        {buyersData !== undefined &&
        buyersData.approvalsForListing.length > 0 ? (
          <p>We couldn&apos;t find anyone with that search criteria</p>
        ) : (
          <p>No one has registered to make offers on this listing</p>
        )}
      </div>
    );
  };

  const messageBuyersMenu = (
    <Menu>
      <Menu.Item key="EMAIL" onClick={() => openBlast(BlastType.EMAIL)}>
        Email
      </Menu.Item>
      <Menu.Item key="SMS" onClick={() => openBlast(BlastType.SMS)}>
        SMS
      </Menu.Item>
    </Menu>
  );

  const messageBuyersDropDown = (
    <Dropdown overlay={messageBuyersMenu}>
      <Button type="primary">
        Message Buyers <DownOutlined />
      </Button>
    </Dropdown>
  );

  const renderData = buyersLoading ? (
    <Row justify="center">
      <Spin />
    </Row>
  ) : buyersData === undefined ? (
    <div>Error</div>
  ) : (
    <>
      <Row
        style={{
          marginBottom: "20px",
        }}
        justify="space-around"
        gutter={[{ xs: 0, sm: 8 }, 16]}
      >
        <Col xs={24} sm={12} md={8}>
          <Input
            placeholder="Search for buyer..."
            prefix={<SearchOutlined />}
            onChange={updateSearchFilter}
            allowClear
            style={{ width: "100%" }}
            name="search"
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Select
            style={{ width: "100%" }}
            allowClear
            mode="multiple"
            placeholder="Filter by approval status"
            onChange={updateApprovalFilter}
          >
            <Select.Option key="pending" value="PENDING">
              Pending
            </Select.Option>
            <Select.Option key="approved" value="APPROVED">
              Approved
            </Select.Option>
            <Select.Option key="denied" value="DENIED">
              Denied
            </Select.Option>
          </Select>
        </Col>
        <Col
          xs={24}
          sm={12}
          md={8}
          style={{ textAlign: breakPoint === "xs" ? "center" : "right" }}
        >
          {messageBuyersDropDown}
        </Col>
      </Row>
      <ConfigProvider renderEmpty={renderNoData}>
        <List
          grid={{
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 4,
          }}
          dataSource={filteredData}
          renderItem={(approval) => (
            <List.Item style={{ paddingLeft: "8px", paddingRight: "8px" }}>
              <BuyerCard approvalRecord={approval} />
            </List.Item>
          )}
        />
      </ConfigProvider>
      <SendBlast
        isOpen={blastStatus.open}
        close={closeBlast}
        blastType={blastStatus.blastType}
        listingId={listingId}
      />
    </>
  );

  return <Layout>{renderData}</Layout>;
};

export default Buyers;
