//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { Form, Input, Modal } from "antd";
import { ApprovedStatus } from "model/Approved";
import React from "react";
import { VoidFunction } from "types";

const { TextArea } = Input;

//------------------------------------------------------------------------------------------
// Interfaces/Props
//------------------------------------------------------------------------------------------

interface DenyReasonProps {
  isOpen: boolean;
  close: VoidFunction;
  setApprovalStatus: (
    approvalStatus: ApprovedStatus,
    denyReason?: string
  ) => void;
}

//------------------------------------------------------------------------------------------

const DenyReason = ({
  isOpen,
  close,
  setApprovalStatus,
}: DenyReasonProps): React.ReactElement => {
  const [form] = Form.useForm();

  const submitDenyReason = (): void => {
    setApprovalStatus(ApprovedStatus.DENIED, form.getFieldValue("denyReason"));
    form.resetFields();
    close();
  };

  return (
    <Modal
      title="Provide a deny reason (Optional)"
      visible={isOpen}
      onCancel={close}
      onOk={submitDenyReason}
    >
      <Form layout="vertical" form={form}>
        <Form.Item name="denyReason" label="Deny Reason">
          <TextArea placeholder="Provide a deny reason if you would like." />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DenyReason;
