//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import { PlusOutlined, SmileOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import {
  Button,
  Col,
  ConfigProvider,
  Layout,
  List,
  Row,
  Spin,
  Typography,
} from "antd";
import { Queries } from "api/queries";
import { BuyersForAgent } from "api/types/ResponseTypes";
import { UserContext } from "context/UserContext/UserContext";
import React, { useContext, useState } from "react";
import ContextError from "utils/errors/ContextError";
import BuyerCard from "./BuyerCard";
import AddBuyer from "./BuyerCard/AddBuyer";

const { Title } = Typography;

const MyBuyersPage = (): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  /// Calls to Hooks
  //------------------------------------------------------------------------------------------

  const userContext = useContext(UserContext);

  if (userContext === undefined || userContext.user === undefined) {
    throw new ContextError(ContextError.CONTEXT_UNDEFINED);
  }

  const { loading: buyersLoading, data: buyers } = useQuery<BuyersForAgent>(
    Queries.GET_BUYERS_FOR_AGENT,
    {
      variables: { agentId: parseFloat(userContext.user.agent.id) },
      pollInterval: 1000,
    }
  );

  const [addBuyerOpen, setAddBuyerOpen] = useState(false);

  //------------------------------------------------------------------------------------------
  /// Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const toggleAddBuyer = () => setAddBuyerOpen(!addBuyerOpen);

  //------------------------------------------------------------------------------------------
  /// Rendering
  //------------------------------------------------------------------------------------------
  const renderNoData = () => (
    <div style={{ textAlign: "center" }}>
      <SmileOutlined style={{ fontSize: 20 }} />
      <p>You have no buyers associated with you right now.</p>
      <Button type="primary" icon={<PlusOutlined />} onClick={toggleAddBuyer}>
        Add a Buyer Now
      </Button>
    </div>
  );

  return (
    <Layout id="my-buyers">
      <Row justify="center">
        <Col>
          <Title
            level={1}
            style={{
              textAlign: "center",
              marginTop: "2rem",
            }}
          >
            My Buyers
          </Title>
        </Col>
      </Row>
      <Row justify="center" style={{ marginBottom: "2rem" }}>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={toggleAddBuyer}
          >
            Add a buyer now
          </Button>
        </Col>
      </Row>
      {buyersLoading || buyers === undefined ? (
        <Row justify="center">
          <Spin />
        </Row>
      ) : (
        <>
          <ConfigProvider renderEmpty={renderNoData}>
            <List
              grid={{
                xs: 1,
                sm: 1,
                md: 2,
                lg: 3,
                xl: 3,
                xxl: 4,
              }}
              dataSource={buyers.buyersForAgent}
              renderItem={(buyer) => (
                <List.Item
                  style={{
                    paddingRight: "8px",
                    paddingLeft: "8px",
                  }}
                >
                  <BuyerCard buyer={buyer} />
                </List.Item>
              )}
            />
          </ConfigProvider>
          <AddBuyer
            isOpen={addBuyerOpen}
            close={toggleAddBuyer}
            agentId={userContext.user.agent.id}
          />
        </>
      )}
    </Layout>
  );
};

export default MyBuyersPage;
