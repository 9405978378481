//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import { SmileOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import { Mutations } from "api/mutations";
import { Queries } from "api/queries";
import { GraphQLErrorResponse } from "api/types/GraphQLErrorResponse";
import { BuyersForAgent } from "api/types/ResponseTypes";
import { Logger } from "aws-amplify";
import { UserContext } from "context/UserContext/UserContext";
import { ApprovedStatus } from "model/Approved";
import { User } from "model/User";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { VoidFunction } from "types";
import ErrorMessage from "utils/constants/ErrorMessage";
import ContextError from "utils/errors/ContextError";
import { useForceUpdate } from "utils/hooks";
import { calculateBuyersPremium } from "utils/utils";
import BuyersPremiumMessage from "./BuyersPremiumMessage";

//------------------------------------------------------------------------------------------
// Class constants that we only need to declare once
//------------------------------------------------------------------------------------------

const logger = new Logger("MakeOffer");

//------------------------------------------------------------------------------------------
// Props definitions
//------------------------------------------------------------------------------------------

interface OfferDetails {
  currentMaxPrice: number;
  currentAuctionId: string;
  numberOfBids: number;
  currentMaxBidUserId: string;
}

interface MakeOfferProps {
  isOpen: boolean;
  onCancel: VoidFunction;
  offerDetails: OfferDetails;
  buyersPremium: number;
}

//------------------------------------------------------------------------------------------

const MakeOffer = ({
  isOpen,
  onCancel,
  offerDetails,
  buyersPremium,
}: MakeOfferProps): React.ReactElement => {
  // This is our min offer value
  const offerValue =
    offerDetails.numberOfBids === 0
      ? offerDetails.currentMaxPrice
      : offerDetails.currentMaxPrice + 1000;

  //------------------------------------------------------------------------------------------
  // Calls to hooks
  //------------------------------------------------------------------------------------------
  const userContext = useContext(UserContext);
  if (userContext === undefined) {
    throw new ContextError(ContextError.CONTEXT_UNDEFINED);
  }
  const forceUpdate = useForceUpdate();
  const [offerBeingSubmitted, changeOfferBeingSubmitted] = useState(false);
  const [makeOffer] = useMutation(Mutations.MAKE_OFFER);
  const [maxBidSetup, setMaxBidSetup] = useState(false);
  const [maxOfferPurchasePrice, setMaxOfferPurchasePrice] = useState(0);
  const [registerBuyerVisible, setRegisterBuyerVisible] = useState(false);
  const [createApproval, { loading: approvalCreationLoading }] = useMutation(
    Mutations.CREATE_NEW_APPROVAL
  );

  const [form] = Form.useForm();

  const [registerBuyerForm] = Form.useForm();

  const setFormValues = useCallback(
    (offerValue: number) => {
      form.setFieldsValue({ newOfferValue: offerValue, maxBidSetup: false });
    },
    [form]
  );

  // We need to ensure the newOfferValue is set right when the modal opens
  useEffect(() => {
    setFormValues(offerValue);
  }, [offerValue, setFormValues]);

  //------------------------------------------------------------------------------------------
  // Base destructuring
  //------------------------------------------------------------------------------------------

  const { user } = userContext;

  const { data: agentBuyersData } = useQuery<BuyersForAgent>(
    Queries.GET_BUYERS_FOR_AGENT,
    {
      skip: user === undefined || !user.isAgent,
      variables: {
        agentId:
          user !== undefined && user.agent !== null
            ? parseFloat(user.agent.id)
            : -1,
      },
      pollInterval: 1000,
    }
  );

  //------------------------------------------------------------------------------------------
  // Handlers
  //------------------------------------------------------------------------------------------

  const registerBuyer = (): void => {
    registerBuyerForm.validateFields().then(() => {
      const values = registerBuyerForm.getFieldsValue();

      createApproval({
        variables: {
          approvalRecordData: {
            userId: parseFloat(values.buyerToRegister),
            listingId: parseFloat(offerDetails.currentAuctionId),
          },
        },
      }).then(() => {
        setRegisterBuyerVisible(false);
        registerBuyerForm.resetFields();
        Modal.success({
          title: "Your buyer's registration is now pending on this listing",
        });
      });
    });
  };

  const closeOfferForm = () => {
    setMaxBidSetup(false);
    setMaxOfferPurchasePrice(0);
    onCancel();
    form.resetFields();
    forceUpdate();
  };

  const submitOffer = () => {
    changeOfferBeingSubmitted(true);
    const buyerFromForm = form.getFieldValue("buyer");

    if (user!.partneredAgent === null && !user?.isAgent) {
      Modal.error({
        title:
          "Sorry, you need to be working with an agent to make an offer. Connect an Agent in your Profile settings.",
      });
      changeOfferBeingSubmitted(false);
    } else if (
      buyerFromForm !== undefined &&
      buyerFromForm === offerDetails.currentMaxBidUserId
    ) {
      Modal.error({
        title: "Sorry, this buyer already has the max offer.",
      });
      changeOfferBeingSubmitted(false);
      form.resetFields();
      onCancel();
    } else {
      const offerData = {
        amountBid: form.getFieldValue("newOfferValue"),
        buyerUserId:
          buyerFromForm !== undefined
            ? parseFloat(buyerFromForm)
            : parseFloat(user!.id),
        buyerAgentId:
          buyerFromForm !== undefined
            ? parseFloat(user!.agent.id)
            : parseFloat(user!.partneredAgent!.id),
        auctionId: parseFloat(offerDetails.currentAuctionId),
        bidSubmittedBy: buyerFromForm !== undefined ? "agent" : "buyer",
        maximumBidAmount: form.getFieldValue("maximumBid"),
      };

      makeOffer({ variables: { offerData } })
        .then((res) => {
          changeOfferBeingSubmitted(false);
          onCancel();

          if (res.data.makeBid.outBidByAuto) {
            Modal.error({
              title: "Higher Offer",
              content: <p>{res.data.makeBid.message}</p>,
            });
          } else {
            Modal.success({
              title: "Successful",
              content: res.data.makeBid.message,
            });
          }

          form.resetFields();
        })
        .catch((error: GraphQLErrorResponse) => {
          logger.error(JSON.stringify(error, null, 2));
          changeOfferBeingSubmitted(false);

          if (error.message === ErrorMessage.DUPLICATE_OFFER) {
            Modal.error({
              title:
                "Sorry someone has already placed an offer of that amount.",
            });
          } else if (error.message === ErrorMessage.AUCTION_OVER) {
            Modal.error({
              title: "Sorry the offer period for this listing is now over.",
            });
          } else {
            Modal.error({
              title:
                "Something went wrong submitting your offer. Please try again",
            });
          }
        });
    }
  };

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  const maxPriceString = `$${offerDetails.currentMaxPrice.toLocaleString()}`;

  const offerInfoString =
    offerDetails.numberOfBids === 0
      ? `There are no offers currently. You must offer the initial listing price of ${maxPriceString}`
      : `The current high offer is ${maxPriceString}`;

  let approvedBuyersOptions: JSX.Element[] = [];
  if (agentBuyersData !== undefined) {
    approvedBuyersOptions = agentBuyersData.buyersForAgent
      .filter((buyer) => {
        return buyer.approvals.some(
          (item) =>
            item.listing.auction.id === offerDetails.currentAuctionId &&
            (item.approvalStatus === ApprovedStatus.APPROVED ||
              item.approvalStatus === ApprovedStatus.PENDING)
        );
      })
      .map((buyer) => {
        const approvalForBuyer = buyer.approvals.find(
          (item) => item.listing.auction.id === offerDetails.currentAuctionId
        )!;

        const buyerIsPending =
          approvalForBuyer.approvalStatus === ApprovedStatus.PENDING;

        return (
          <Select.Option
            key={buyer.id}
            value={buyer.id}
            disabled={buyerIsPending}
          >
            {buyer.firstName} {buyer.lastName}
            {buyerIsPending ? " - Pending" : ""}
          </Select.Option>
        );
      });
  }

  let unregisteredBuyers: User[] = [];
  if (agentBuyersData !== undefined) {
    unregisteredBuyers = agentBuyersData.buyersForAgent.filter((buyer) => {
      return buyer.approvals.every(
        (item) =>
          item.listing.auction.id !== offerDetails.currentAuctionId ||
          (item.listing.auction.id === offerDetails.currentAuctionId &&
            item.approvalStatus === ApprovedStatus.PENDING)
      );
    });
  }

  const handleFormValueChanges = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];

    if (fieldName === "maxBidSetup") {
      const value = changedValues[fieldName];
      setMaxBidSetup(value);
    }

    if (fieldName === "maximumBid") {
      const purchasePrice =
        calculateBuyersPremium(changedValues[fieldName], buyersPremium / 100) +
        changedValues[fieldName];

      setMaxOfferPurchasePrice(purchasePrice);
    }
  };

  return (
    <Modal
      className="make-offer-modal"
      title={(
        <div style={{ fontSize: "24px", textAlign: "center" }}>
          <b>Make An Offer</b>
        </div>
      )}
      visible={isOpen}
      onCancel={closeOfferForm}
      getContainer={false}
      footer={(
        <OfferFooter
          offerBeingSubmitted={offerBeingSubmitted}
          submitOffer={() => {
            form.validateFields().then(() => submitOffer());
          }}
          maxBidSetup={maxBidSetup}
          regularOfferValue={form.getFieldValue("newOfferValue")}
          maxOfferPurchasePrice={maxOfferPurchasePrice}
          buyersPremium={buyersPremium}
        />
      )}
    >
      <Form
        form={form}
        id="offer-form"
        onValuesChange={handleFormValueChanges}
        initialValues={{
          newOfferValue: offerValue,
          maxBidSetup: false,
        }}
      >
        <Row
          justify="center"
          style={{ marginBottom: "10px", textAlign: "center" }}
        >
          <Col>
            <h3>
              <b>{offerInfoString}</b>
            </h3>
          </Col>
        </Row>
        {user?.isAgent && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="Buyer"
                name="buyer"
                rules={[
                  {
                    required: true,
                    message:
                      "You must select a buyer in order to submit this offer.",
                  },
                ]}
              >
                <Select
                  notFoundContent={(
                    <div style={{ textAlign: "center" }}>
                      <SmileOutlined style={{ fontSize: 20 }} />
                      <p>You have no buyer&apos;s approved on this listing</p>
                    </div>
                  )}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: "8px 0",
                          backgroundColor: "transparent",
                        }}
                      />
                      <Row justify="end" style={{ padding: "0 8px 4px" }}>
                        <Button
                          type="primary"
                          onClick={() => setRegisterBuyerVisible(true)}
                        >
                          Register your buyer
                        </Button>
                      </Row>
                    </>
                  )}
                  placeholder="Select a buyer"
                >
                  {approvedBuyersOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={24}>
            <Form.Item
              label={<b>{`${user?.isAgent ? "Their" : "Your"} Next Offer`}</b>}
              name="newOfferValue"
              required
            >
              <InputNumber
                min={offerValue}
                style={{ fontWeight: "bold", width: "100%" }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                max={offerValue}
                step={1000}
                value={offerValue}
                readOnly
              />
            </Form.Item>
          </Col>
        </Row>
        <BuyersPremiumMessage
          offerValue={form.getFieldValue("newOfferValue")}
          buyersPremium={buyersPremium}
        />
        <Row justify="center" style={{ marginTop: "20px" }}>
          <Col span={20}>
            <Divider style={{ backgroundColor: "transparent" }}>
              Automatic Maximum Offer
            </Divider>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <b>
              {!maxBidSetup
                ? "Would you like to set up an automatic maximum offer?"
                : "Turn off automatic maximum offer"}
            </b>
          </Col>
        </Row>

        <Row justify="center">
          <Col>
            <Form.Item name="maxBidSetup" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        {maxBidSetup && (
          <>
            <Row style={{ marginBottom: "10px" }}>
              <Col>
                Please input the maximum amount you want to offer below. <br />
                Your offer will automatically increase in $1000 increments above
                any new offers until your automatic maximum offer price is
                reached.
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={<b>Automatic Maximum Offer</b>}
                  required
                  name="maximumBid"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please provide your automatic maximum offer amount, or turn off automatic maximum offer above.",
                    },
                  ]}
                >
                  <InputNumber
                    min={offerValue}
                    style={{ width: "100%" }}
                    formatter={(value) => {
                      return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    }}
                    step={1000}
                    placeholder="Automatic Maximum Offer"
                  />
                </Form.Item>
              </Col>
            </Row>
            {maxOfferPurchasePrice !== 0 && (
              <Row justify="start">
                <Col>
                  <b>Purchase Price With Buyer&apos;s Premium:</b>
                </Col>
                <Col offset={1} style={{ color: "#1DA57A" }}>
                  ${maxOfferPurchasePrice.toLocaleString()}
                </Col>
              </Row>
            )}
          </>
        )}
      </Form>
      <Modal
        title={(
          <div style={{ fontSize: "24px", textAlign: "center" }}>
            <b>Register Buyer</b>
          </div>
        )}
        visible={registerBuyerVisible}
        onCancel={() => {
          registerBuyerForm.resetFields();
          setRegisterBuyerVisible(false);
        }}
        okText="Register"
        cancelButtonProps={{ danger: true }}
        okButtonProps={{ loading: approvalCreationLoading }}
        onOk={registerBuyer}
      >
        <Form layout="vertical" form={registerBuyerForm}>
          <Form.Item
            label={(
              <div style={{ fontSize: "18px" }}>
                <b>Select the buyer you want to register</b>
              </div>
            )}
            rules={[
              {
                required: true,
                message: "Please select the buyer you want to register",
              },
            ]}
            required
            name="buyerToRegister"
          >
            <Select
              placeholder="Select Buyer"
              disabled={approvalCreationLoading}
            >
              {unregisteredBuyers.map((buyer) => {
                const approvalForBuyer = buyer.approvals.find(
                  (item) =>
                    item.listing.auction.id === offerDetails.currentAuctionId
                );
                return (
                  <Select.Option
                    key={buyer.id}
                    value={buyer.id}
                    disabled={approvalForBuyer !== undefined}
                  >
                    {buyer.firstName} {buyer.lastName}
                    {approvalForBuyer !== undefined ? " - Pending" : ""}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Modal>
  );
};

interface OfferFooterProps {
  offerBeingSubmitted: boolean;
  submitOffer: VoidFunction;
  maxBidSetup: boolean;
  regularOfferValue: number;
  maxOfferPurchasePrice: number;
  buyersPremium: number;
}

const OfferFooter = ({
  offerBeingSubmitted,
  submitOffer,
  maxBidSetup,
  regularOfferValue,
  maxOfferPurchasePrice,
  buyersPremium,
}: OfferFooterProps) => {
  const confirmOffer = () => {
    const regularOfferBuyersPremium = calculateBuyersPremium(
      regularOfferValue,
      buyersPremium / 100
    );

    Modal.confirm({
      title: "Are you sure?",
      content: (
        <>
          <p>
            Are you sure you want to submit this offer for a total purchase
            price of{" "}
            <b>
              $
              {(regularOfferValue + regularOfferBuyersPremium).toLocaleString()}
            </b>
            ?
          </p>
          {maxBidSetup && (
            <p>
              And are you sure you want to submit your automatic maximum offer
              with a total purchase price of{" "}
              <b>${maxOfferPurchasePrice.toLocaleString()}</b>?
            </p>
          )}
        </>
      ),
      okText: "Yes!",
      cancelText: "No",
      onOk() {
        submitOffer();
      },
    });
  };

  return (
    <React.Fragment>
      <Row justify="end">
        <Col span={14}>
          <Button
            key="submit"
            type="primary"
            form="offer-form"
            loading={offerBeingSubmitted}
            onClick={confirmOffer}
          >
            {offerBeingSubmitted
              ? "Submitting..."
              : maxBidSetup
              ? "Submit Offer and Auto Max Offer"
              : "Submit Offer"}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MakeOffer;
