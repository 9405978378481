import { gql } from "@apollo/client";

export const HeaderQuery = gql`
  query GetHeaderData($listingId: Float!) {
    listing(id: $listingId) {
      id
      lastRegistrationDay
      buyersPremium
      auction {
        id
        startDate
        endDate
        startingPrice
        bids {
          id
          amountBid
          buyerUser {
            email
            id
          }
          offerVoided
        }
        autoBids {
          id
          datePlaced
          autoBidAmount
          buyerUser {
            id
            email
          }
        }
      }
      listingAgent {
        id
      }
    }
  }
`;
