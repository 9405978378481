//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { Col, Divider, Layout, Row } from "antd";
import ImageUploader from "components/ImageUploader/ImageUploader";
import React from "react";

//------------------------------------------------------------------------------------------
// Props Definitions
//------------------------------------------------------------------------------------------

interface UploadImageInformationProps {
  fileList: any[];
  setFileList: (images: any[]) => void;
}

//------------------------------------------------------------------------------------------

const UploadImageInformation = ({
  fileList,
  setFileList,
}: UploadImageInformationProps): React.ReactElement => {
  return (
    <Layout>
      <Row justify="center">
        <Col
          xs={20}
          md={20}
          lg={16}
          xl={12}
          xxl={12}
          style={{
            marginTop: "3rem",
          }}
        >
          <h2
            style={{
              fontWeight: "bolder",
            }}
            id="photo-upload"
          >
            Photos
          </h2>
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={20} md={20} lg={16}
          xl={12} xxl={12}>
          <Divider
            style={{
              marginTop: 0,
            }}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col
          xs={20}
          md={20}
          lg={16}
          xl={12}
          xxl={12}
          style={{
            marginTop: "0.5rem",
          }}
        >
          <p style={{ fontSize: "16px" }}>
            <i>
              Please upload your images below. Each image will be displayed in
              the order that they are uploaded. <br /> <br />
              Drag and drop the images to change the order in which they will be
              displayed. <br /> <br /> The image in the first position will be
              your <b>Featured Image</b>.
            </i>
          </p>
        </Col>
      </Row>
      <Row justify="center">
        <Col
          xs={20}
          md={20}
          lg={16}
          xl={12}
          xxl={12}
          style={{
            marginTop: "0.5rem",
          }}
        >
          <ImageUploader
            name="homeListingImages"
            fileList={fileList}
            setFileList={setFileList}
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default UploadImageInformation;
