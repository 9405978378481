import { Col, Divider, Layout, Row, Typography } from "antd";
import React, { CSSProperties } from "react";

const { Text, Link } = Typography;

const TextStyles: CSSProperties = {
  fontSize: "14px",
  fontWeight: "bolder",
  backgroundColor: "#FFFFFF",
  textAlign: "center",
  color: "#1DA57A",
};

const ApplicationFooter = (): React.ReactElement => {
  return (
    <Layout
      style={{ position: "sticky", bottom: "0px", right: "0px", left: "0px" }}
    >
      <Row
        style={{
          backgroundColor: "#FFFFFF",
        }}
        justify="center"
      >
        <Col span={24}>
          <Divider style={{ margin: "0px", height: "1.25px" }} />
        </Col>
      </Row>
      <Row
        justify="center"
        style={{
          backgroundColor: "#FFFFFF",
          marginTop: "30px",
        }}
      >
        <Col xs={20} md={20} lg={20}
          xl={14} xxl={12}>
          <Row
            gutter={32}
            justify="center"
            style={{ height: "10rem", marginBottom: "30px" }}
          >
            <Col style={{ marginTop: "10px" }}>
              <Row justify="center" style={{ marginTop: "5px" }}>
                <Text style={TextStyles}>
                  All Rights Reserved, Copyright © 2021 Yivado
                </Text>
              </Row>
              <Row justify="center" style={{ marginTop: "5px" }}>
                <Link
                  href="https://portal.hud.gov/hudportal/HUD?src=/program_offices/fair_housing_equal_opp"
                  target="_blank"
                  style={TextStyles}
                >
                  Equal Housing Opportunity
                </Link>
              </Row>
              <Row justify="center" style={{ marginTop: "5px" }}>
                <Link
                  href="https://storyset.com/people"
                  target="_blank"
                  style={TextStyles}
                >
                  People illustrations by Storyset
                </Link>
              </Row>
            </Col>
            <Col style={{ marginTop: "10px" }}>
              <Row justify="center" style={{ marginTop: "5px" }}>
                <Link href="/privacy" target="_blank" style={TextStyles}>
                  Privacy Policy
                </Link>
              </Row>
              <Row justify="center" style={{ marginTop: "5px" }}>
                <Link
                  href="/terms-conditions"
                  target="_blank"
                  style={TextStyles}
                >
                  Terms and Conditions
                </Link>
              </Row>
              <Row justify="center" style={{ marginTop: "5px" }}>
                <Link href="/contact" target="_blank" style={TextStyles}>
                  Contact
                </Link>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default ApplicationFooter;
