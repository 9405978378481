import { valueOrDefault } from "utils/utils";
import { v4 as uuidv4 } from "uuid";

export const buildCreateUserInput = (fieldValues: FieldValues): any => {
  return {
    firstName: fieldValues.firstName,
    lastName: fieldValues.lastName,
    isAgent: fieldValues.workingWithRealtor === "realtor",
    email: fieldValues.email,
    password: fieldValues.password,
    agentBroker: valueOrDefault(fieldValues.agentBroker, null),
    agentEmail: valueOrDefault(fieldValues.agentEmail, null),
    agentFirstName: valueOrDefault(fieldValues.agentFirstName, null),
    agentLastName: valueOrDefault(fieldValues.agentLastName, null),
    agentLicenseNumber: valueOrDefault(fieldValues.agentLicenseNumber, null),
    agentPhoneNumber: valueOrDefault(fieldValues.agentPhoneNumber, null),
    isCashPurchase: fieldValues.isCashPurchase === "Y",
    isPreQualified: fieldValues.isPreQualified === "Y",
    storageBucketId: uuidv4(),
    phoneNumber: fieldValues.phoneNumber,
    wantsNotifications: fieldValues.wantsNotifications,
  };
};

interface FieldValues {
  firstName: string;
  lastName: string;
  workingWithRealtor: string;
  email: string;
  password: string;
  agentBroker: string;
  agentEmail: string;
  agentFirstName: string;
  agentLastName: string;
  agentLicenseNumber: string;
  agentPhoneNumber: string;
  isCashPurchase: "Y" | "N";
  isPreQualified: "Y" | "N";
  phoneNumber: string;
  wantsNotifications: boolean;
}
