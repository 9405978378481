//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { Tooltip } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import React from "react";
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from "react-dnd";

//------------------------------------------------------------------------------------------
// Component Wide Constants
//------------------------------------------------------------------------------------------

const type = "DragableUploadList";

//------------------------------------------------------------------------------------------
// Interfaces/Props
//------------------------------------------------------------------------------------------

interface UploadListItemProps {
  originNode: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  file: UploadFile<any>;
  fileList: UploadFile<any>[];
}

//------------------------------------------------------------------------------------------

const UploadListItem = ({
  originNode,
  moveRow,
  file,
  fileList,
}: UploadListItemProps): React.ReactElement => {
  const index = fileList.indexOf(file);

  //------------------------------------------------------------------------------------------
  // Calls to Hooks
  //------------------------------------------------------------------------------------------

  const ref = React.useRef<HTMLDivElement>(null);

  //------------------------------------------------------------------------------------------

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor: DropTargetMonitor) => {
      const { index: dragIndex } = monitor.getItem<{ index: number }>() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? "drop-over-downward" : "drop-over-upward",
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    },
  });

  //------------------------------------------------------------------------------------------

  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  //------------------------------------------------------------------------------------------

  // We need to wrap the ref in both drag and drop
  drop(drag(ref));

  //------------------------------------------------------------------------------------------

  const errorNode = (
    <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
  );

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${
        isOver ? dropClassName : ""
      } ${index === 0 ? "feature-image" : ""}`}
      style={{ cursor: "move" }}
    >
      {file.status === "error" ? errorNode : originNode}
    </div>
  );
};

export default UploadListItem;
