import { Layout } from "antd";
import React from "react";
import MyOffersInformation from "./MyOffersInformation";

const MyOffersPage = (): React.ReactElement => {
  return (
    <Layout style={{ paddingTop: "40px" }}>
      <MyOffersInformation />
    </Layout>
  );
};

export default MyOffersPage;
