import { Layout } from "antd";
import React from "react";
import ApplicationFooter from "../../components/ApplicationFooter";
import BannerInformation from "./BannerInformation";
import ContentInformation from "./ContentInformation";

const FrontPage = (): React.ReactElement => {
  return (
    <Layout style={{ overflowX: "hidden" }}>
      <BannerInformation />
      <ContentInformation />
      <ApplicationFooter />
    </Layout>
  );
};

export default FrontPage;
