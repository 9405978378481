import { Col, Form, Input, Row, Typography } from "antd";
import React from "react";

const { Title } = Typography;

const PersonalNames = (): React.ReactElement => (
  <>
    <Row justify="center" gutter={16}>
      <Col
        xs={11}
        lg={8}
        xl={5}
        style={{
          textAlign: "left",
        }}
      >
        <Title level={4} style={{ marginTop: "1.5rem", fontWeight: "bolder" }}>
          First Name
        </Title>
      </Col>
      <Col
        xs={11}
        lg={8}
        xl={5}
        style={{
          textAlign: "left",
        }}
      >
        <Title level={4} style={{ marginTop: "1.5rem", fontWeight: "bolder" }}>
          Last Name
        </Title>
      </Col>
    </Row>

    <Row justify="center" gutter={16}>
      <Col xs={11} lg={8} xl={5}>
        <Form.Item
          name="firstName"
          rules={[{ required: true, message: "Please enter your first name" }]}
        >
          <Input size="large" type="text" />
        </Form.Item>
      </Col>
      <Col xs={11} lg={8} xl={5}>
        <Form.Item
          name="lastName"
          rules={[{ required: true, message: "Please enter your last name" }]}
        >
          <Input size="large" type="text" />
        </Form.Item>
      </Col>
    </Row>
  </>
);
export default PersonalNames;
