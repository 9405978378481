import { Approved } from "model/Approved";

export type BuyerFilters = {
  search: string;
  approvalStatus: Set<string>;
};

export const applyFilters = (
  approval: Approved,
  filters: BuyerFilters
): boolean => {
  const { search, approvalStatus } = filters;

  // Search Filter
  const buyerName =
    `${approval.approvedUser.firstName} ${approval.approvedUser.lastName}`.toLowerCase();

  const passesSearchFilter =
    search === "" || buyerName.indexOf(search.toLowerCase()) > -1;

  // Approval Status Filter
  const passesApprovalStatusFilter =
    approvalStatus.size === 0 || approvalStatus.has(approval.approvalStatus);

  return passesSearchFilter && passesApprovalStatusFilter;
};
