//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { Col, Divider, Form, Input, InputNumber, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { HomeWarrantyStatus } from "utils/constants/HomeWarrantyStatus";
import ListingFormLabel from "../ListingFormLabel";

//------------------------------------------------------------------------------------------
// Component Wide Constants
//------------------------------------------------------------------------------------------

const { Item: FormItem } = Form;

type SellerTermsInformationProps = {
  defaultStartEscrowRange?: number;
};

//------------------------------------------------------------------------------------------

const SellerTermsInformation = ({
  defaultStartEscrowRange = 0,
}: SellerTermsInformationProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to hooks
  //------------------------------------------------------------------------------------------

  const [startEscrowRange, setStartEscrowRange] = useState(
    defaultStartEscrowRange
  );

  useEffect(() => {
    setStartEscrowRange(defaultStartEscrowRange);
  }, [defaultStartEscrowRange]);

  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const homeWarrantyStatusOptions = HomeWarrantyStatus.map(
    (homeWarrantyStatus) => (
      <Select.Option key={homeWarrantyStatus} value={homeWarrantyStatus}>
        {homeWarrantyStatus}
      </Select.Option>
    )
  );

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <>
      <Row justify="center">
        <Col
          xs={20}
          md={20}
          lg={16}
          xl={12}
          xxl={12}
          style={{
            marginTop: "2rem",
          }}
        >
          <h2
            style={{
              fontWeight: "bolder",
            }}
          >
            {"Seller's Preffered Terms"}
          </h2>
        </Col>
      </Row>
      <div id="seller-terms">
        <Row justify="center">
          <Col xs={20} md={20} lg={16}
            xl={12} xxl={12}>
            <Divider
              style={{
                marginTop: 0,
              }}
            />
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={20} md={20} lg={16}
            xl={12} xxl={12}>
            <Row justify="start">
              <Col style={{ marginRight: "36px" }}>
                <FormItem
                  rules={[
                    {
                      required: true,
                      message: "Please provide the earnest deposit",
                    },
                  ]}
                  label={<ListingFormLabel required text="Earnest Deposit" />}
                  name="earnestDeposit"
                >
                  <InputNumber
                    style={{ width: "200px" }}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                    // disabled={auctionStarted}
                  />
                  {/* <Input
                    style={{ width: "100px" }}
                    type="number"
                    step={0.25}
                    suffix="%"
                    min={0}
                    max={100}
                  /> */}
                </FormItem>
              </Col>
              <Col>
                <Form.Item
                  label={(
                    <ListingFormLabel
                      required
                      text="Preferred Close of Escrow"
                    />
                  )}
                >
                  <Input.Group compact style={{ width: "400px" }}>
                    <Form.Item
                      name={["closeOfEscrow", "startRange"]}
                      noStyle
                      rules={[{ required: true, message: "Start is required" }]}
                    >
                      <Input
                        type="number"
                        prefix="Between"
                        placeholder="From"
                        suffix="and"
                        style={{ width: "50%", textAlign: "right" }}
                        onChange={(e) =>
                          setStartEscrowRange(Number(e.target.value))
                        }
                        min={0}
                      />
                    </Form.Item>
                    <Form.Item
                      name={["closeOfEscrow", "endRange"]}
                      noStyle
                      rules={[{ required: true, message: "End is required" }]}
                    >
                      <Input
                        type="number"
                        style={{ width: "50%" }}
                        placeholder="To"
                        suffix="days"
                        min={startEscrowRange + 1}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="start">
              <Col style={{ marginRight: "36px" }}>
                <FormItem
                  rules={[
                    {
                      required: true,
                      message: "Please input amount of days for inspection.",
                    },
                  ]}
                  label={(
                    <ListingFormLabel
                      required
                      text="Preferred Inspection Period"
                    />
                  )}
                  name="inspectionDays"
                >
                  <Input
                    type="number"
                    suffix="Days"
                    prefix="Up to"
                    min={0}
                    style={{ width: "200px" }}
                    placeholder="Days"
                    autoComplete="new-password"
                  />
                </FormItem>
              </Col>
              <Col>
                <FormItem
                  style={{ width: "250px" }}
                  rules={[
                    {
                      required: true,
                      message: "Please choose home warranty status",
                    },
                  ]}
                  label={<ListingFormLabel required text="Home Warranty" />}
                  name="homeWarranty"
                >
                  <Select placeholder="Home Warranty">
                    {homeWarrantyStatusOptions}
                  </Select>
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SellerTermsInformation;
