//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import {
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  TimePicker,
  Typography,
} from "antd";
import moment from "moment";
import React from "react";
import ListingFormLabel from "../ListingFormLabel";
import OpenHouseDates from "./OpenHouseDates";

//------------------------------------------------------------------------------------------
// Component Wide Constants
//------------------------------------------------------------------------------------------

const { Item: FormItem } = Form;
const { Text } = Typography;

//------------------------------------------------------------------------------------------
// Props Definitions
//------------------------------------------------------------------------------------------

interface OfferInformationProps {
  form: FormInstance<any>;
}

//------------------------------------------------------------------------------------------

const OfferInformation = ({
  form,
}: OfferInformationProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const lastRegistrationDateDisabled = (current: moment.Moment) => {
    return (
      current &&
      (current < form.getFieldValue("auctionStartDate") ||
        current > moment(form.getFieldValue("auctionEndDate")).add(1, "days"))
    );
  };

  function getDisabledHours() {
    const hours: Array<number> = [];
    if (
      moment(form.getFieldValue("auctionEndDate")).format("YYYY-MM-DD") !==
      moment(form.getFieldValue("lastRegistrationDate")).format("YYYY-MM-DD")
    ) {
      return hours;
    }
    for (
      let i = moment(form.getFieldValue("auctionEndTime")).hour() + 1;
      i < 24;
      i++
    ) {
      hours.push(i);
    }
    return hours;
  }

  function getDisabledMinutes(selectedHour: number) {
    const minutes: Array<number> = [];
    if (
      moment(form.getFieldValue("auctionEndDate")).format("YYYY-MM-DD") ===
        moment(form.getFieldValue("lastRegistrationDate")).format(
          "YYYY-MM-DD"
        ) &&
      moment(form.getFieldValue("auctionEndTime")).hour() === selectedHour
    ) {
      for (
        let i = moment(form.getFieldValue("auctionEndTime")).minute() + 1;
        i < 60;
        i++
      ) {
        minutes.push(i);
      }
    }

    return minutes;
  }

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <>
      <Row justify="center">
        <Col
          xs={20}
          md={20}
          lg={16}
          xl={12}
          xxl={12}
          style={{
            marginTop: "2rem",
          }}
        >
          <h2
            style={{
              fontWeight: "bolder",
            }}
          >
            Listing Information
          </h2>
        </Col>
      </Row>
      <div id="offer-information">
        <Row justify="center">
          <Col xs={20} md={20} lg={16}
            xl={12} xxl={12}>
            <Divider
              style={{
                marginTop: 0,
              }}
            />
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={20} md={20} lg={16}
            xl={12} xxl={12}>
            <Row justify="start">
              <Col style={{ marginRight: "36px" }}>
                <FormItem
                  rules={[
                    {
                      required: true,
                      message: "Please provide the starting price",
                    },
                  ]}
                  label={<ListingFormLabel required text="Starting Price" />}
                  name="auctionStartingPrice"
                >
                  <InputNumber
                    style={{ width: "180px" }}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                    // disabled={auctionStarted}
                  />
                </FormItem>
              </Col>
              <Col>
                <FormItem
                  rules={[
                    {
                      required: true,
                      message: "Please provide the buyer's premium",
                    },
                  ]}
                  label={<ListingFormLabel required text="Buyer's Premium" />}
                  name="buyersPremium"
                >
                  <Input
                    style={{ width: "100px" }}
                    type="number"
                    suffix="%"
                    step={0.1}
                    min={0}
                    max={100}
                    autoComplete="new-password"
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row id="last-registration-day" justify="center">
          <Col
            xs={20}
            md={20}
            lg={16}
            xl={12}
            xxl={12}
            style={{
              marginTop: "2rem",
              width: "100%",
            }}
          >
            <Row justify="start">
              <Col
                xs={24}
                md={24}
                lg={24}
                xl={24}
                xxl={14}
                style={{ marginBottom: "10px" }}
              >
                <ListingFormLabel required text="Offer Period" />
              </Col>
            </Row>
            <Row justify="start">
              <Col
                xs={24}
                md={24}
                lg={24}
                xl={24}
                xxl={14}
                style={{ minWidth: "650px", marginRight: "36px" }}
              >
                <Row>
                  <Col xs={10} md={10} lg={10}
                    xl={10} xxl={10}>
                    <Row justify="end" style={{ marginBottom: "20px" }}>
                      {" "}
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          marginRight: "10px",
                        }}
                      >
                        Start Date:
                      </Text>{" "}
                      <FormItem
                        rules={[
                          {
                            required: true,
                            message: "Provide the start date",
                          },
                        ]}
                        name="auctionStartDate"
                      >
                        <DatePicker
                          use12Hours
                          disabledDate={(current) => {
                            const customDate = moment().format("YYYY-MM-DD");
                            return (
                              current &&
                              current < moment(customDate, "YYYY-MM-DD")
                            );
                          }}
                          format="MM/DD/YYYY"
                          minuteStep={30}
                          // disabled={auctionStarted}
                        />
                      </FormItem>
                    </Row>
                    <Row justify="end">
                      {" "}
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          marginRight: "10px",
                        }}
                      >
                        End Date:
                      </Text>{" "}
                      <FormItem
                        rules={[
                          { required: true, message: "Provide the end date" },
                        ]}
                        name="auctionEndDate"
                      >
                        <DatePicker
                          use12Hours
                          disabledDate={(current) => {
                            const customDate = moment().format("YYYY-MM-DD");
                            return (
                              current &&
                              current < moment(customDate, "YYYY-MM-DD")
                            );
                          }}
                          format="MM/DD/YYYY"
                          minuteStep={30}
                          // disabled={auctionStarted}
                        />
                      </FormItem>
                    </Row>
                  </Col>
                  <Col xs={10} md={10} lg={10}
                    xl={10} xxl={10}>
                    <Row justify="end" style={{ marginBottom: "20px" }}>
                      {" "}
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          marginRight: "10px",
                        }}
                      >
                        Start Time:
                      </Text>{" "}
                      <FormItem
                        rules={[
                          { required: true, message: "Provide the start time" },
                        ]}
                        name="auctionStartTime"
                      >
                        <TimePicker
                          use12Hours
                          format="h:mm A"
                          // disabled={auctionStarted}
                        />
                      </FormItem>
                    </Row>
                    <Row justify="end">
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          marginRight: "10px",
                        }}
                      >
                        End Time:
                      </Text>
                      <FormItem
                        rules={[
                          { required: true, message: "Provide the end time" },
                        ]}
                        name="auctionEndTime"
                      >
                        <TimePicker
                          use12Hours
                          format="h:mm A"
                          // disabled={auctionStarted}
                        />
                      </FormItem>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xs={20}
            md={20}
            lg={16}
            xl={12}
            xxl={12}
            style={{
              marginTop: ".5rem",
              width: "100%",
            }}
          >
            <Row justify="start">
              <Col
                xs={24}
                md={24}
                lg={24}
                xl={24}
                xxl={14}
                style={{ marginBottom: "10px" }}
              >
                <ListingFormLabel required text="Last Registration Day" />
              </Col>
            </Row>
            <Row justify="start">
              <Col
                xs={24}
                md={24}
                lg={24}
                xl={24}
                xxl={14}
                style={{ minWidth: "650px", marginRight: "36px" }}
              >
                <Row>
                  <Col xs={10} md={10} lg={10}
                    xl={10} xxl={10}>
                    <Row justify="end" style={{ marginBottom: "20px" }}>
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          marginRight: "10px",
                        }}
                      >
                        End Date:
                      </Text>
                      <FormItem
                        rules={[
                          {
                            required: true,
                            message: "Provide the last day to register",
                          },
                        ]}
                        name="lastRegistrationDate"
                      >
                        <DatePicker
                          use12Hours
                          // disabledDate={(current) => {
                          //   const customDate = moment().format("YYYY-MM-DD");
                          //   return (
                          //     current &&
                          //     current < moment(customDate, "YYYY-MM-DD")
                          //   );
                          // }}
                          disabledDate={lastRegistrationDateDisabled}
                          format="MM/DD/YYYY"
                          minuteStep={30}
                        />
                      </FormItem>
                    </Row>
                  </Col>
                  <Col xs={10} md={10} lg={10}
                    xl={10} xxl={10}>
                    <Row justify="end" style={{ marginBottom: "20px" }}>
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          marginRight: "10px",
                        }}
                      >
                        End Time:
                      </Text>
                      <FormItem
                        rules={[
                          { required: true, message: "Provide the time" },
                        ]}
                        name="lastRegistrationTime"
                      >
                        <TimePicker
                          use12Hours
                          disabledHours={getDisabledHours}
                          disabledMinutes={getDisabledMinutes}
                          format="h:mm A"
                        />
                      </FormItem>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: "20px" }}>
          <Col xs={20} md={20} lg={16}
            xl={12} xxl={12}>
            <OpenHouseDates />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OfferInformation;
