import { useQuery } from "@apollo/client";
import { Row, Skeleton, Typography } from "antd";
import { Queries } from "api/queries";
import { ListingResponse } from "api/types/ResponseTypes";
import React from "react";
import { calculateBuyersPremium } from "utils/utils";

const { Text } = Typography;

interface OverviewInformationProps {
  listingId: string;
}

const OverviewInformation = ({
  listingId,
}: OverviewInformationProps): React.ReactElement => {
  const { data } = useQuery<ListingResponse>(Queries.GET_OVERVIEW_INFO, {
    variables: { listingId: parseFloat(listingId) },
  });

  //------------------------------------------------------------------------------------------
  // Private Components
  //------------------------------------------------------------------------------------------

  const startingPrice = data?.listing.auction.startingPrice;
  const buyersPremium = data?.listing.buyersPremium;

  return data === undefined ? (
    <Skeleton />
  ) : (
    <>
      <Row
        style={{
          marginTop: "40px",
        }}
      >
        <Text
          style={{
            color: "rgba(0, 0, 0, .95)",
            fontSize: "24px",
            marginBottom: "15px",
          }}
          strong
        >
          Overview
        </Text>
      </Row>
      <Row>
        <Text style={{ fontSize: "16px" }}>
          {data?.listing.propertyDescription}
        </Text>
      </Row>
      <Row>
        <Text style={{ fontSize: "16px" }}>
          <br />
          Starting Bid:{"  "}
          <b>${startingPrice!.toLocaleString()}</b> +{" "}
          <b>
            {" "}
            $
            {calculateBuyersPremium(
              startingPrice!,
              buyersPremium! / 100
            ).toLocaleString()}
            ({data.listing.buyersPremium}%) = $
            {(
              startingPrice! +
              calculateBuyersPremium(startingPrice!, buyersPremium! / 100)
            ).toLocaleString()}
          </b>
          <br />
          <br />
          Buyers and their Agents can offer online anytime from the beginning to
          the end of Yivado! Once the seller will accept the winning offer
          everything follows just like in any regular sale, the buyer will go in
          escrow with all buyer’s contingencies and inspections time frame. For
          more information contact Listing Agent!
          <br />
          <br />
          <b>
            SELLER RESERVES THE RIGHT TO ACCEPT, COUNTER OR REJECT ANY Offer!
          </b>
          <br />
          <br />
          <b>Buyer’s Premium {data.listing.buyersPremium}% </b>
          (built in the sales price). Buyer’s premium will be added on top of
          the winning offer which shall establish the final sales price. Highest
          Offer + Buyer’s Premium = Final Sales Price! Notice to Buyers If you
          are one of the buyers selected by the seller, you have 24 hours to
          submit a purchase contract from the end of the offer process. If you
          back out without submitting a purchase contract, you may be liable for
          a $500 withdrawal penalty fee.
          <br />
          <br />
          <b>Auto Offer Option</b> – You may offer the maximum you are willing
          to offer for this property. The system is designed to raise your offer
          in $1000 increments only as high as necessary to offer over others and
          only up to your maximum.
          <br />
          <b>Example:</b> If current offer is $100,000 and you offer $150,000,
          you will become the highest offer, but the system will raise current
          offer only to $101,000. If someone offers $102,000, the system will
          automatically increase your offer to $103,000 and keep you the highest
          offer. If someone places a higher offer, say $120,000, the system will
          automatically increase your offer to $121,000 and so on. However, if
          someone places an offer of $151,000 or greater, they will exceed your
          maximum and become the highest offer as the system will no longer
          increase your offer. The system will notify you of all changes via
          email.
          <br />
          <br />
          <b>Auto Offer Increment</b> – For this property is $1,000
          <br />
          <br />
          <b>Offer Period Auto Extend</b> – If someone offers within 5 minutes
          till the end, the system will automatically extend the offer period by
          5 minutes in order to give other buyers a chance to respond.
          <br />
          <br />
          <b>Attention Winning Offer!</b> – If You Are The Winning Offer: Please
          have your agent submit a signed purchase agreement to the seller
          within the time specified in “sale terms and conditions” (please see
          the posting details). If you do not have an agent, please Contact Us.
          Information deemed reliable but not guaranteed and subject to change
          without notice!
        </Text>
      </Row>
    </>
  );
};

export default OverviewInformation;
