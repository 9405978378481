import { Layout } from "antd";
import React from "react";
import ListingsContent from "./ListingsContent/ListingsContent";

const BrowseListings = (): React.ReactElement => {
  return (
    <Layout style={{ paddingTop: "40px" }}>
      <ListingsContent />
    </Layout>
  );
};

export default BrowseListings;
