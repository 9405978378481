import { useQuery } from "@apollo/client";
import { Layout, Row, Spin } from "antd";
import { Queries } from "api/queries";
import { ListingResponse } from "api/types/ResponseTypes";
import { UserContext } from "context/UserContext/UserContext";
import React, { useContext } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import ContextError from "utils/errors/ContextError";
import EditListingForm from "./EditListingForm";
import ListingPageHeader from "./PageHeader";

//------------------------------------------------------------------------------------------
// Props definitions
//------------------------------------------------------------------------------------------

interface MatchParams {
  listingId: string;
}

type EditListingPageProps = RouteComponentProps<MatchParams>;

const EditListingPage = ({
  match,
}: EditListingPageProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to hooks
  //------------------------------------------------------------------------------------------
  const userContext = useContext(UserContext);
  if (userContext === undefined) {
    throw new ContextError(ContextError.CONTEXT_UNDEFINED);
  }

  const history = useHistory();

  const { loading: listingLoading, data: listingData } =
    useQuery<ListingResponse>(Queries.GET_ALL_LISTING_INFO, {
      variables: { listingId: parseFloat(match.params.listingId) },
    });

  if (listingData !== undefined) {
    if (
      userContext.user !== undefined &&
      userContext.user.agent.id !== listingData.listing.listingAgent.id
    ) {
      history.push(`/listing/${match.params.listingId}`);
    }
  }

  return listingLoading ? (
    <Row justify="center">
      <Spin />
    </Row>
  ) : (
    <Layout id="listing-create">
      {listingData !== undefined && (
        <ListingPageHeader address={listingData?.listing.address} />
      )}
      {listingData !== undefined && (
        <EditListingForm listingData={listingData?.listing} />
      )}
    </Layout>
  );
};

export default EditListingPage;
