//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Card, Col, Dropdown, Menu, Modal, Row } from "antd";
import Countdown from "antd/lib/statistic/Countdown";
import { Mutations } from "api/mutations";
import { Listing } from "model/Listing";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import S3Listing from "utils/storage/S3Listing";
import { MyListingsTab } from "../MyListingsTab";
import { ActiveColumns, ClosedColumns, UpcomingColumns } from "./cardConfig";

//------------------------------------------------------------------------------------------
// Component Wide Constants
//------------------------------------------------------------------------------------------

const listingS3 = new S3Listing();

//------------------------------------------------------------------------------------------
// Interfaces/Props
//------------------------------------------------------------------------------------------

interface MyListingCardProps {
  listing: Listing;
  tab: MyListingsTab;
  refetchListings: () => void;
}

//------------------------------------------------------------------------------------------
// Component Definition
//------------------------------------------------------------------------------------------

export const MyListingCard = ({
  listing,
  tab,
  refetchListings,
}: MyListingCardProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to hooks
  //------------------------------------------------------------------------------------------

  const history = useHistory();
  const [deleteListingMutation] = useMutation(Mutations.DELETE_LISTING);
  const [dataLoading, setDataLoading] = useState(false);

  //------------------------------------------------------------------------------------------
  /// Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const deleteListing = (listingId: string, bucketId: string) => {
    setDataLoading(true);

    deleteListingMutation({
      variables: { listingId: parseFloat(listingId) },
    })
      .then(async (res) => {
        await listingS3.deleteListingFolder(bucketId);
        setDataLoading(false);
        if (res.data.deleteListing) {
          Modal.success({
            title: "Listing deleted successfully!",
          });
          refetchListings();
        } else {
          Modal.error({
            title:
              "Something went wrong trying to delete the listing. Please try again.",
          });
        }
      })
      .catch(() => {
        setDataLoading(false);
        Modal.error({
          title: "Something unexpected happened. Please try again.",
        });
      });
  };

  const confirmDeletion = () => {
    Modal.confirm({
      title: (
        <p>
          Are you sure you want to delete the listing at{" "}
          <b>{listing.address}</b>?
        </p>
      ),
      content:
        "Deleting a listing is irreversible. Please confirm whether or not you want to do this.",
      icon: <ExclamationCircleOutlined />,
      okType: "danger",
      okText: "Delete",
      onOk() {
        deleteListing(listing.id, listing.listingPicturesBucketId);
      },
    });
  };

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  const elementsToRender =
    tab === MyListingsTab.ACTIVE
      ? ActiveColumns
      : tab === MyListingsTab.CLOSED
      ? ClosedColumns
      : UpcomingColumns;

  const cardData = elementsToRender.map((func) => func(listing));

  //------------------------------------------------------------------------------------------

  const cardMenu = (
    <Menu>
      <Menu.Item danger onClick={confirmDeletion}>
        Delete Listing
      </Menu.Item>
    </Menu>
  );

  return (
    <Card
      title={(
        <>
          <Row>
            <b
              className="my-listings-card-title"
              onClick={() => history.push(`/listing/${listing.id}`)}
            >
              {listing.address}
            </b>
          </Row>
          {tab === MyListingsTab.ACTIVE ? (
            <Row gutter={8} style={{ marginTop: "5px" }}>
              <Col style={{ fontSize: "small", fontWeight: "bold" }}>
                Ends In:{" "}
              </Col>
              <Col>
                <Countdown
                  value={listing.auction.endDate}
                  valueStyle={{
                    fontSize: "small",
                    // color: determineCountdownColor(
                    //   listing.auction.endDate,
                    //   "active"
                    // ),
                  }}
                  format="D [days] H [hours] m [min] s [sec]"
                />
              </Col>
            </Row>
          ) : tab === MyListingsTab.UPCOMING ? (
            <Row gutter={8} style={{ marginTop: "5px" }}>
              <Col style={{ fontSize: "small" }}>Starts In: </Col>
              <Col>
                <Countdown
                  value={listing.auction.startDate}
                  valueStyle={{
                    fontSize: "small",
                  }}
                  format="D [days] H [hours] m [min] s [sec]"
                />
              </Col>
            </Row>
          ) : null}
        </>
      )}
      extra={(
        <Dropdown overlay={cardMenu} trigger={["click"]} placement="bottomLeft">
          <Button
            style={{ color: "red" }}
            icon={<DeleteOutlined />}
            shape="round"
          />
        </Dropdown>
      )}
      actions={[
        <Button
          type="primary"
          key="manage-listing"
          style={{ width: 140 }}
          onClick={() => history.push(`/listing/manage/${listing.id}`)}
        >
          Manage
        </Button>,
        <Button
          type="primary"
          key="edit-listing"
          style={{ width: 140 }}
          onClick={() => history.push(`/listing/edit/${listing.id}`)}
        >
          Edit
        </Button>,
      ]}
      style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)" }}
      loading={dataLoading}
    >
      {cardData}
    </Card>
  );
};

export default MyListingCard;
