import { Layout } from "antd";
import React, { useState } from "react";
import { useQueryParams } from "utils/hooks";
import Register from "./Register";
import SignIn from "./SignIn";

enum AuthOption {
  SIGN_IN,
  REGISTER,
}

const Authentication = (): React.ReactElement => {
  const queryParams = useQueryParams();

  const [authOption, setAuthOption] = useState<AuthOption>(
    queryParams.get("register") === "Y"
      ? AuthOption.REGISTER
      : AuthOption.SIGN_IN
  );

  const signIn = (
    <SignIn switchToRegistration={() => setAuthOption(AuthOption.REGISTER)} />
  );

  const register = (
    <Register backToSignIn={() => setAuthOption(AuthOption.SIGN_IN)} />
  );

  const optionToRender = authOption === AuthOption.SIGN_IN ? signIn : register;

  return <Layout>{optionToRender}</Layout>;
};

export default Authentication;
