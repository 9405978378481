import { Button, Col, Form, Input, Modal, Row, Typography } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { VoidFunction } from "types";
import AuthUtils from "utils/auth/AuthUtils";
import { YivadoRoute } from "utils/constants/YivadoRoute";
import AskForUsername from "./AskForUsername";

const { Title } = Typography;
const authUtils = new AuthUtils();

interface SignInProps {
  switchToRegistration: VoidFunction;
}

const SignIn = ({ switchToRegistration }: SignInProps): React.ReactElement => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loginProgress, changeLoginProgress] = useState(false);
  const [askForUsernameOpen, setAskForUsernameOpen] = useState(false);

  const toggleUsernameModal = (): void =>
    setAskForUsernameOpen(!askForUsernameOpen);

  const submitSignInForm = async (
    formSubmissionEvent: React.SyntheticEvent
  ) => {
    formSubmissionEvent.preventDefault();
    await form.validateFields();
    signInUser();
  };

  const signInUser = () => {
    changeLoginProgress(true);

    authUtils
      .signIn(form.getFieldValue("email"), form.getFieldValue("password"))
      .then((response) => {
        changeLoginProgress(false);
        if (response.signInSuccessful) {
          window.localStorage.setItem("authToken", response.token!);
          window.dispatchEvent(new Event("storage"));
          form.resetFields();
          history.push(YivadoRoute.FRONT_PAGE);
        } else {
          Modal.error({
            title: response.message,
          });
        }
      });
  };

  return (
    <>
      <Row justify="center">
        <Col>
          <Title level={1} style={{ marginTop: "2rem", fontWeight: "bolder" }}>
            Sign In
          </Title>
        </Col>
      </Row>
      <Form form={form} layout="vertical" onSubmitCapture={submitSignInForm}>
        <Row justify="center">
          <Col
            xs={16}
            lg={14}
            xl={10}
            xxl={6}
            style={{
              textAlign: "left",
            }}
          >
            <Title
              level={4}
              style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
            >
              Email
            </Title>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={16} lg={14} xl={10}
            xxl={6}>
            <Form.Item
              name="email"
              id="signin-email"
              rules={[{ required: true, message: "Please enter email" }]}
              style={{ width: "100%" }}
            >
              <Input size="large" placeholder="Email" type="email" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xs={12}
            lg={12}
            xl={8}
            xxl={4}
            style={{
              textAlign: "left",
            }}
          >
            <Title
              level={4}
              style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
            >
              Password
            </Title>
          </Col>
          <Col
            xs={4}
            lg={2}
            xl={2}
            xxl={2}
            style={{
              textAlign: "right",
            }}
          >
            <Button
              type="link"
              style={{ marginTop: "1.5rem" }}
              onClick={toggleUsernameModal}
            >
              Forgot?
            </Button>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={16} lg={14} xl={10}
            xxl={6}>
            <Form.Item
              name="password"
              id="signin-password"
              rules={[
                { required: true, message: "Please enter your password" },
              ]}
              style={{ width: "100%" }}
            >
              <Input.Password size="large" placeholder="Password" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={8} lg={7} xl={5}
            xxl={3}>
            <Button
              type="primary"
              shape="round"
              htmlType="submit"
              size="large"
              loading={loginProgress}
              style={{ width: "100%" }}
            >
              Sign In
            </Button>
          </Col>
        </Row>
      </Form>
      <Row justify="center">
        <Col>
          <Button
            type="link"
            size="large"
            style={{ width: "100%" }}
            onClick={switchToRegistration}
          >
            Don&apos;t have an account? Create one now.
          </Button>
        </Col>
      </Row>
      <AskForUsername
        isOpen={askForUsernameOpen}
        toggle={toggleUsernameModal}
      />
    </>
  );
};

export default SignIn;
