//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import { useQuery } from "@apollo/client";
import { Badge, Card, Statistic, Typography } from "antd";
import { Queries } from "api/queries";
import { ListingResponse } from "api/types/ResponseTypes";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { OfferForUser } from "types";
import Color from "utils/constants/Color";
import { ListingStatus } from "utils/constants/ListingStatus";
import Logger from "utils/logging/Logger";
import S3Access from "utils/storage";
import { determineMaxOffer } from "utils/utils";

//------------------------------------------------------------------------------------------
// Component Wide Constants
//------------------------------------------------------------------------------------------

const { Ribbon } = Badge;
const { Countdown } = Statistic;
const { Text } = Typography;
const s3 = new S3Access();

const MILLIS_IN_DAYS = 24 * 60 * 60 * 1000;

//------------------------------------------------------------------------------------------
/// Props Definitions
//------------------------------------------------------------------------------------------

interface ListingCardProps {
  id: string;
  listingStatus: string;
  imageUrl: string;
}

//------------------------------------------------------------------------------------------

const ListingCard = ({
  id,
  listingStatus,
  imageUrl,
}: ListingCardProps): React.ReactElement | null => {
  //------------------------------------------------------------------------------------------
  /// calls to Hooks
  //------------------------------------------------------------------------------------------

  const history = useHistory();
  const [featureImage, setFeatureImage] = useState("");

  const {
    loading: listingLoading,
    data: listingResponse,
    error,
  } = useQuery<ListingResponse>(Queries.GET_LISTING, {
    variables: { listingId: parseFloat(id) },
    pollInterval: 1000,
  });

  useEffect(() => {
    s3.getSingleUrlFromS3(imageUrl).then((url) => {
      setFeatureImage(url);
    });
  }, [imageUrl]);

  //------------------------------------------------------------------------------------------
  /// Constants
  //------------------------------------------------------------------------------------------

  const priceToList =
    listingResponse !== undefined
      ? determineMaxOffer(
          listingResponse?.listing.auction.startingPrice,
          listingResponse?.listing.auction.bids
        )
      : ({ offer: 0, email: "", userId: "" } as OfferForUser);

  //------------------------------------------------------------------------------------------
  //  Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const viewListing = () => {
    const path = `/listing/${id}`;
    history.push(path);
  };

  const determineColorOfRibbon = (
    time: string | undefined,
    status: string
  ): string => {
    // We only care if it is an active listing
    // If not active just set it to the base green
    if (status !== ListingStatus.ACTIVE || time === undefined)
      return Color.GREEN_RIBBON;

    const date = new Date(time);

    const timeDiffInDays =
      (date.getTime() - new Date().getTime()) / MILLIS_IN_DAYS;

    if (timeDiffInDays > 5) {
      return Color.GREEN_RIBBON;
    } else if (timeDiffInDays < 2) {
      return Color.RED_RIBBON;
    } else {
      return Color.YELLOW_RIBBON;
    }
  };

  if (error) {
    Logger.error(error);
    return null;
  }

  //------------------------------------------------------------------------------------------
  /// Rendering
  //------------------------------------------------------------------------------------------

  const listingCard = (
    <Card
      hoverable
      bordered
      loading={listingLoading}
      cover={(
        <img src={featureImage} width="300" height="400"
          alt="" />
)}
      onClick={viewListing}
    >
      <p style={{ marginBottom: 0 }}>
        <Text strong style={{ fontSize: "150%" }}>
          ${priceToList?.offer.toLocaleString()}
        </Text>
      </p>
      <p style={{ marginBottom: 2 }}>
        <Text style={{ fontSize: "115%" }}>
          {listingResponse?.listing?.numberOfBedrooms} bd |{" "}
          {listingResponse?.listing?.numberOfBathrooms} ba |{" "}
          {listingResponse?.listing?.houseSizeInSquareFeet} sqft
        </Text>
      </p>
      <p style={{ marginBottom: 2 }}>
        {listingResponse?.listing?.address}, {listingResponse?.listing?.city},{" "}
        {listingResponse?.listing?.state} {listingResponse?.listing?.zipcode}
      </p>
      <p>{listingResponse?.listing?.listingAgent?.broker}</p>
    </Card>
  );

  //------------------------------------------------------------------------------------------

  return listingStatus === "past" ? (
    listingCard
  ) : (
    <Ribbon
      text={(
        <Countdown
          value={
            listingStatus === ListingStatus.ACTIVE
              ? listingResponse?.listing.auction.endDate
              : listingResponse?.listing.auction.startDate
          }
          valueStyle={{ fontSize: "small", color: Color.WHITE }}
          format={`${
            listingStatus === ListingStatus.ACTIVE ? "[Ends in]" : "[Starts in]"
          } D [days] H [hours] m [min] s [sec]`}
        />
      )}
      color={determineColorOfRibbon(
        listingResponse?.listing?.auction.endDate,
        listingStatus
      )}
    >
      {listingCard}
    </Ribbon>
  );
};

export default ListingCard;
