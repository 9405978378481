import React from "react";
import { VoidFunction } from "types";
import Passwords from "./Passwords";
import PersonalNames from "./PersonalNames";
import Realtor from "./Realtor";
import RegistrationNames from "./RegistrationNames";

interface AccountAndPersonalProps {
  onSelectChange: VoidFunction;
}

const AccountAndPersonal = ({
  onSelectChange,
}: AccountAndPersonalProps): React.ReactElement => {
  return (
    <>
      <PersonalNames />
      <RegistrationNames />
      <Passwords />
      <Realtor onSelectChange={onSelectChange} />
    </>
  );
};

export default AccountAndPersonal;
