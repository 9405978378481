import {
  Listing,
  ListingForm,
  OpenHouseDate,
  OtherFeatures,
  OtherProperty,
} from "model/Listing";
import moment from "moment";
import { HomeFeaturesConfig } from "utils/constants/HomeFeatures";
import { PersonalPropertyConfig } from "utils/constants/PersonalProperty";
import { TimezoneMap } from "utils/constants/Timezone";
import { ListingInput, UpdateListingInput } from "./inputs";

type DateValidation = "success" | "lastRegistration";

export default class ListingUtils {
  //------------------------------------------------------------------------------------------------------------
  static createDefaultFormValues(listingData: Listing): ListingForm {
    const openHouseDates: Array<OpenHouseDate> =
      listingData.openHouseDates === ""
        ? []
        : listingData.openHouseDates.split(",").map((date) => {
            const dates = date.split("&");

            return {
              date: moment(dates[0]),
              startTime: moment(dates[0]),
              endTime: moment(dates[1]),
            };
          });

    const auctionEnd = moment(listingData.auction.endDate);
    const auctionStart = moment(listingData.auction.startDate);

    const homeFeatures = new Set<string>(listingData.homeFeatures?.split(","));
    const personalProperty = new Set<string>(
      listingData.personalProperty?.split(",")
    );

    const otherPersonalProperty: Array<OtherProperty> =
      listingData.otherPersonalProperty === ""
        ? []
        : listingData.otherPersonalProperty?.split("&&").map((item) => {
            return {
              property: item,
            };
          });

    const otherHomeFeatures: Array<OtherFeatures> =
      listingData.otherHomeFeatures === ""
        ? []
        : listingData.otherHomeFeatures?.split("&&").map((item) => {
            return {
              feature: item,
            };
          });

    return {
      address: listingData.address,
      addressOne: listingData.addressOne,
      addressTwo: listingData.addressTwo,
      city: listingData.city,
      state: listingData.state,
      zipcode: listingData.zipcode,
      auctionStartingPrice: listingData.auction.startingPrice,
      buyersPremium: listingData.buyersPremium,
      auctionStartDate: auctionStart,
      auctionStartTime: auctionStart,
      auctionEndDate: auctionEnd,
      auctionEndTime: auctionEnd,
      earnestDeposit: listingData.earnestDeposit,
      closeOfEscrowDays: listingData.closeOfEscrowDays,
      closeOfEscrow: {
        startRange: listingData.startEscrowRange,
        endRange: listingData.endEscrowRange,
      },
      inspectionDays: listingData.inspectionDays,
      homeWarranty: listingData.homeWarranty ? "Yes" : "No",
      homeType: listingData.homeType,
      yearBuilt: listingData.yearBuilt,
      houseSizeInSquareFeet: listingData.houseSizeInSquareFeet,
      lotSizeInSquareFeet: listingData.lotSizeInSquareFeet,
      hasHoa: listingData.hasHoa ? "Y" : "N",
      hoaMonthlyCost: listingData.hoaMonthlyCost,
      numberOfBedrooms: listingData.numberOfBedrooms,
      numberOfBathrooms: listingData.numberOfBathrooms,
      numberOfFloors: listingData.numberofFloors,
      garageSpaces: listingData.garageSpaces,
      propertyDescription: listingData.propertyDescription,
      hasFridge: personalProperty.has("hasFridge"),
      hasWasherAndDryer: personalProperty.has("hasWasherAndDryer"),
      hasAboveGroundSpa: personalProperty.has("hasAboveGroundSpa"),
      openHouseDates: openHouseDates,
      otherPersonalProperty: otherPersonalProperty,
      otherHomeFeatures: otherHomeFeatures,
      includesOtherProperty: listingData.otherPersonalProperty !== null,
      includesOtherHomeFeatures: listingData.otherHomeFeatures !== null,
      titleCompanyUrl: listingData.titleCompanyUrl,
      timezone: listingData.timezone,
      lastRegistrationDate: moment(listingData.lastRegistrationDay),
      lastRegistrationTime: moment(listingData.lastRegistrationDay),
      officeOrDen: homeFeatures.has("officeOrDen"),
      loft: homeFeatures.has("loft"),
      basement: homeFeatures.has("basement"),
      solar: homeFeatures.has("solar"),
      privatePool: homeFeatures.has("privatePool"),
      communityPool: homeFeatures.has("communityPool"),
      spaOrJacuzzi: homeFeatures.has("spaOrJacuzzi"),
      fireplace: homeFeatures.has("fireplace"),
      golfCourseLot: homeFeatures.has("golfCourseLot"),
      golfCourseCommunity: homeFeatures.has("golfCourseCommunity"),
      lakeCommunity: homeFeatures.has("lakeCommunity"),
      waterfrontLot: homeFeatures.has("waterfrontLot"),
      tennisCourts: homeFeatures.has("tennisCourts"),
      gatedCommunity: homeFeatures.has("gatedCommunity"),
      mountainViews: homeFeatures.has("mountainViews"),
      communityClubHouse: homeFeatures.has("communityClubHouse"),
    };
  }

  //------------------------------------------------------------------------------------------------------------

  static validateDates(formValues: ListingForm): DateValidation {
    const auctionStartDate = moment(formValues.auctionStartDate);
    auctionStartDate.set({
      hour: formValues.auctionStartTime.get("hour"),
      minute: formValues.auctionStartTime.get("minute"),
      second: 0,
    });

    const auctionEndDate = moment(formValues.auctionEndDate);
    auctionEndDate.set({
      hour: formValues.auctionEndTime.get("hour"),
      minute: formValues.auctionEndTime.get("minute"),
      second: 0,
    });

    const lastRegistrationDate = moment(formValues.lastRegistrationDate);
    lastRegistrationDate.set({
      hour: formValues.lastRegistrationTime.get("hour"),
      minute: formValues.lastRegistrationTime.get("minute"),
      second: 0,
    });

    if (
      lastRegistrationDate < auctionStartDate ||
      lastRegistrationDate > auctionEndDate
    ) {
      return "lastRegistration";
    }

    return "success";
  }

  static createListingInputFromForm(
    formValues: ListingForm,
    agentId: number,
    listingImagesBucketId: string,
    featureImageUrl: string
  ): ListingInput {
    const openHouseDates = formValues.openHouseDates
      .map((openHouseObj) => {
        const timezoneInfo = TimezoneMap[formValues.timezone]!;

        const offset = openHouseObj.date.isDST()
          ? timezoneInfo.utcOffsetDaylight
          : timezoneInfo.utcOffsetStandard;

        const start = moment(openHouseObj.date);
        start.set({
          hour: openHouseObj.startTime.get("hour"),
          minute: openHouseObj.startTime.get("minute"),
          second: 0,
        });

        start.utcOffset(offset);

        const end = moment(openHouseObj.date);
        end.set({
          hour: openHouseObj.endTime.get("hour"),
          minute: openHouseObj.endTime.get("minute"),
          second: 0,
        });

        end.utcOffset(offset);

        return `${start.format("MM/DD/YYYY HH:mm:ss Z")}&${end.format(
          "MM/DD/YYYY HH:mm:ss Z"
        )}`;
      })
      .join(",");

    const auctionStartDate = formValues.auctionStartDate;
    auctionStartDate.set({
      hour: formValues.auctionStartTime.get("hour"),
      minute: formValues.auctionStartTime.get("minute"),
      second: 0,
    });

    const auctionEndDate = formValues.auctionEndDate;
    auctionEndDate.set({
      hour: formValues.auctionEndTime.get("hour"),
      minute: formValues.auctionEndTime.get("minute"),
      second: 0,
    });

    const lastRegistrationDate = formValues.lastRegistrationDate;
    lastRegistrationDate.set({
      hour: formValues.lastRegistrationTime.get("hour"),
      minute: formValues.lastRegistrationTime.get("minute"),
      second: 0,
    });

    const homeFeaturesIncluded: Array<string> = [];

    HomeFeaturesConfig.forEach((value, key) => {
      if (formValues[key]) {
        {
          homeFeaturesIncluded.push(key);
        }
      }
    });

    const personalPropertyIncluded: Array<string> = [];

    PersonalPropertyConfig.forEach((_, property) => {
      if (formValues[property]) {
        {
          personalPropertyIncluded.push(property);
        }
      }
    });

    const otherPersonalProperty = formValues.otherPersonalProperty
      .map((item) => item.property)
      .join("&&");

    const otherHomeFeatures = formValues.otherHomeFeatures
      .map((item) => item.feature)
      .join("&&");

    return {
      address: `${formValues.addressOne} ${formValues.addressTwo}`,
      addressOne: formValues.addressOne,
      addressTwo: formValues.addressTwo,
      city: formValues.city,
      state: formValues.state,
      zipcode: formValues.zipcode,
      homeType: formValues.homeType,
      yearBuilt: Number(formValues.yearBuilt),
      houseSizeInSquareFeet: Number(formValues.houseSizeInSquareFeet),
      lotSizeInSquareFeet: Number(formValues.lotSizeInSquareFeet),
      numberOfBedrooms: Number(formValues.numberOfBedrooms),
      numberOfBathrooms: Number(formValues.numberOfBathrooms),
      numberofFloors: Number(formValues.numberOfFloors),
      hasHoa: formValues.hasHoa === "Y",
      hoaMonthlyCost:
        formValues.hasHoa === "Y" ? Number(formValues.hoaMonthlyCost) : 0,
      listingAgentId: agentId,
      auctionStartDate: auctionStartDate.toDate(),
      auctionEndDate: auctionEndDate.toDate(),
      auctionStartingPrice: Number(formValues.auctionStartingPrice),
      sellerCommissionPercentage: Number(formValues.buyersPremium),
      listingPicturesBucketId: listingImagesBucketId,
      buyersPremium: Number(formValues.buyersPremium),
      earnestDeposit: Number(formValues.earnestDeposit),
      startEscrowRange: Number(formValues.closeOfEscrow!.startRange),
      endEscrowRange: Number(formValues.closeOfEscrow!.endRange),
      inspectionDays: Number(formValues.inspectionDays),
      homeWarranty: formValues.homeWarranty === "Yes",
      openHouseDates: openHouseDates,
      propertyDescription: formValues.propertyDescription,
      garageSpaces: Number(formValues.garageSpaces),
      featureImageUrl: featureImageUrl,
      otherPersonalProperty: otherPersonalProperty,
      otherHomeFeatures: otherHomeFeatures,
      titleCompanyUrl: formValues.titleCompanyUrl,
      timezone: formValues.timezone,
      lastRegistrationDay: lastRegistrationDate.toDate(),
      homeFeatures:
        homeFeaturesIncluded.length === 0
          ? null
          : homeFeaturesIncluded.join(","),
      personalProperty:
        personalPropertyIncluded.length === 0
          ? null
          : personalPropertyIncluded.join(","),
    };
  }
  //------------------------------------------------------------------------------------------------------------

  static updateListingInputFromForm(
    formValues: ListingForm,
    featureImageUrl: string
  ): UpdateListingInput {
    const openHouseDates = formValues.openHouseDates
      .map((openHouseObj) => {
        const timezoneInfo = TimezoneMap[formValues.timezone]!;

        const offset = openHouseObj.date.isDST()
          ? timezoneInfo.utcOffsetDaylight
          : timezoneInfo.utcOffsetStandard;

        const start = moment(openHouseObj.date);
        start.set({
          hour: openHouseObj.startTime.get("hour"),
          minute: openHouseObj.startTime.get("minute"),
          second: 0,
        });

        start.utcOffset(offset);

        const end = moment(openHouseObj.date);
        end.set({
          hour: openHouseObj.endTime.get("hour"),
          minute: openHouseObj.endTime.get("minute"),
          second: 0,
        });

        end.utcOffset(offset);

        return `${start.format("MM/DD/YYYY HH:mm:ss Z")}&${end.format(
          "MM/DD/YYYY HH:mm:ss Z"
        )}`;
      })
      .join(",");

    const auctionStartDate = formValues.auctionStartDate;
    auctionStartDate.set({
      hour: formValues.auctionStartTime.get("hour"),
      minute: formValues.auctionStartTime.get("minute"),
      second: 0,
    });

    const auctionEndDate = formValues.auctionEndDate;
    auctionEndDate.set({
      hour: formValues.auctionEndTime.get("hour"),
      minute: formValues.auctionEndTime.get("minute"),
      second: 0,
    });

    const lastRegistrationDate = formValues.lastRegistrationDate;
    lastRegistrationDate.set({
      hour: formValues.lastRegistrationTime.get("hour"),
      minute: formValues.lastRegistrationTime.get("minute"),
      second: 0,
    });

    const homeFeaturesIncluded: Array<string> = [];

    HomeFeaturesConfig.forEach((_, feature) => {
      if (formValues[feature]) {
        {
          homeFeaturesIncluded.push(feature);
        }
      }
    });

    const personalPropertyIncluded: Array<string> = [];

    PersonalPropertyConfig.forEach((_, property) => {
      if (formValues[property]) {
        {
          personalPropertyIncluded.push(property);
        }
      }
    });

    const otherPersonalProperty = formValues.otherPersonalProperty
      .map((item) => item.property)
      .join("&&");

    const otherHomeFeatures = formValues.otherHomeFeatures
      .map((item) => item.feature)
      .join("&&");

    return {
      address: `${formValues.addressOne} ${formValues.addressTwo}`,
      addressOne: formValues.addressOne,
      addressTwo: formValues.addressTwo,
      city: formValues.city,
      state: formValues.state,
      zipcode: formValues.zipcode,
      homeType: formValues.homeType,
      yearBuilt: Number(formValues.yearBuilt),
      houseSizeInSquareFeet: Number(formValues.houseSizeInSquareFeet),
      lotSizeInSquareFeet: Number(formValues.lotSizeInSquareFeet),
      numberOfBedrooms: Number(formValues.numberOfBedrooms),
      numberOfBathrooms: Number(formValues.numberOfBathrooms),
      numberofFloors: Number(formValues.numberOfFloors),
      hasHoa: formValues.hasHoa === "Y",
      hoaMonthlyCost:
        formValues.hasHoa === "Y" ? Number(formValues.hoaMonthlyCost) : 0,
      auctionStartDate: auctionStartDate.toDate(),
      auctionEndDate: auctionEndDate.toDate(),
      auctionStartingPrice: Number(formValues.auctionStartingPrice),
      sellerCommissionPercentage: Number(formValues.buyersPremium),
      buyersPremium: Number(formValues.buyersPremium),
      earnestDeposit: Number(formValues.earnestDeposit),
      // closeOfEscrowDays: Number(formValues.closeOfEscrowDays),
      startEscrowRange: Number(formValues.closeOfEscrow!.startRange),
      endEscrowRange: Number(formValues.closeOfEscrow!.endRange),
      inspectionDays: Number(formValues.inspectionDays),
      homeWarranty: formValues.homeWarranty === "Yes",
      openHouseDates: openHouseDates,
      propertyDescription: formValues.propertyDescription,
      garageSpaces: Number(formValues.garageSpaces),
      featureImageUrl: featureImageUrl,
      otherPersonalProperty: otherPersonalProperty,
      otherHomeFeatures: otherHomeFeatures,
      titleCompanyUrl: formValues.titleCompanyUrl,
      timezone: formValues.timezone,
      lastRegistrationDay: lastRegistrationDate.toDate(),
      homeFeatures:
        homeFeaturesIncluded.length === 0
          ? null
          : homeFeaturesIncluded.join(","),
      personalProperty:
        personalPropertyIncluded.length === 0
          ? null
          : personalPropertyIncluded.join(","),
    };
  }
}
