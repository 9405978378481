//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import {
  LogoutOutlined,
  MenuOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Drawer, Dropdown, Layout, Menu } from "antd";
import { UserContext } from "context/UserContext/UserContext";
import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { VoidFunction } from "types";
import { YivadoRoute } from "utils/constants/YivadoRoute";
import ContextError from "utils/errors/ContextError";
import { MenuItem, NavigationBarItems, SubMenuItems } from "./RouteConfig";

//------------------------------------------------------------------------------------------
// Interfaces/Props
//------------------------------------------------------------------------------------------

const { SubMenu } = Menu;

//------------------------------------------------------------------------------------------
// Interfaces/Props
//------------------------------------------------------------------------------------------

interface NavigationBarProps {
  signUserOut: VoidFunction;
}

//------------------------------------------------------------------------------------------

const NavigationBar = ({
  signUserOut,
}: NavigationBarProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to Hooks
  //------------------------------------------------------------------------------------------

  const [drawerOpen, setDrawerOpen] = useState(false);

  const history = useHistory();

  const userContext = useContext(UserContext);
  if (userContext === undefined) {
    throw new ContextError(ContextError.CONTEXT_UNDEFINED);
  }

  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const toggleDrawer = (): void => {
    setDrawerOpen(!drawerOpen);
  };

  const renderMenuItem = (menuitem: MenuItem): React.ReactElement => (
    <Menu.Item
      style={{
        marginTop: "5px",
      }}
      key={menuitem.to}
      onClick={() => {
        setDrawerOpen(false);
      }}
    >
      <Link to={menuitem.to}>{menuitem.text}</Link>
    </Menu.Item>
  );

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  const navigationMenuItems = NavigationBarItems.map(renderMenuItem);

  const subMenuNavigationItems = SubMenuItems.map(renderMenuItem);

  if (userContext.user?.isAdmin) {
    subMenuNavigationItems.push(
      <Menu.Item
        style={{ marginTop: "5px" }}
        key="admin-route"
        onClick={() => setDrawerOpen(false)}
      >
        <Link to={YivadoRoute.ADMIN}>Admin</Link>
      </Menu.Item>
    );
  }

  const dashBoardMenuItem =
    userContext.user !== undefined && userContext.user.isAgent ? (
      <Menu.Item
        style={{
          marginTop: "5px",
        }}
        key="/dashboard"
        onClick={() => {
          setDrawerOpen(false);
        }}
      >
        <Link to="/dashboard">Dashboard</Link>
      </Menu.Item>
    ) : userContext.user !== undefined ? (
      <Menu.Item
        style={{
          marginTop: "5px",
        }}
        key="/buyer-dashboard"
        onClick={() => {
          setDrawerOpen(false);
        }}
      >
        <Link to="/buyer-dashboard">Dashboard</Link>
      </Menu.Item>
    ) : null;

  const dropdownMenu = () => (
    <Menu>
      <Menu.Item
        key="profile-link"
        onClick={() => {
          history.push(YivadoRoute.PROFILE);
        }}
      >
        <Button type="link" icon={<ProfileOutlined />}>
          Profile
        </Button>
      </Menu.Item>
      <Menu.Item
        key="sign-out-link"
        onClick={() => {
          signUserOut();
        }}
      >
        <Button type="link" danger icon={<LogoutOutlined />}>
          Sign out
        </Button>
      </Menu.Item>
    </Menu>
  );

  const profileItem =
    userContext.user !== undefined ? (
      <Menu.Item key="profile">
        <Dropdown overlay={dropdownMenu}>
          <Avatar
            size="large"
            style={{ backgroundColor: "#1DA57A", cursor: "pointer" }}
            src={userContext.userImage}
          >
            {userContext.user.firstName[0].toUpperCase()}
          </Avatar>
        </Dropdown>
      </Menu.Item>
    ) : null;

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <React.Fragment>
      <Layout.Header
        className="main-header"
        style={{
          width: "100%",
          backgroundColor: "white",
          height: "70px",
          position: "fixed",
          zIndex: "2",
          // borderBottom: "1px solid #D3D3D3",
        }}
      >
        <Link className="logo" to={YivadoRoute.FRONT_PAGE} />
        <div className="menuContainer">
          <div className="mainMenu">
            <Menu mode="horizontal" disabledOverflow>
              <Menu.Item style={{ marginTop: "5px" }}>
                <Button type="primary" shape="round">
                  <Link style={{ color: "white" }} to="/listings">
                    VIEW LISTINGS
                  </Link>
                </Button>
              </Menu.Item>
              {dashBoardMenuItem}
              {navigationMenuItems}
              {userContext.user === undefined && (
                <Menu.Item key="sign-in">
                  <Link to="/authentication">Sign In</Link>
                </Menu.Item>
              )}
              <SubMenu
                key="more"
                title="More"
                style={{
                  fontSize: "large",
                  fontWeight: "bold",
                  marginTop: "5px",
                }}
              >
                {subMenuNavigationItems}
              </SubMenu>
              {profileItem}
            </Menu>
          </div>
          <Button
            className="barsMenu"
            type="link"
            icon={<MenuOutlined />}
            onClick={toggleDrawer}
            style={{ marginRight: "20px" }}
          />
          <Drawer
            title={<Link className="logo" to={YivadoRoute.FRONT_PAGE} />}
            width={"20rem"}
            placement="right"
            closable={false}
            onClose={toggleDrawer}
            visible={drawerOpen}
          >
            <Menu
              mode="inline"
              style={{ borderRight: "none", fontSize: "1rem" }}
              className="nav-drawer-menu"
            >
              <Menu.Item style={{ marginTop: "5px" }}>
                <Button
                  onClick={() => {
                    setDrawerOpen(false);
                  }}
                  type="primary"
                  shape="round"
                >
                  <Link style={{ color: "white" }} to="/listings">
                    VIEW LISTINGS
                  </Link>
                </Button>
              </Menu.Item>
              {dashBoardMenuItem}
              {navigationMenuItems}
              {subMenuNavigationItems}
              {userContext.user === undefined && (
                <Menu.Item
                  key="sign-in"
                  onClick={() => {
                    setDrawerOpen(false);
                  }}
                >
                  <Link to="/authentication">Sign In</Link>
                </Menu.Item>
              )}
              {userContext.user !== undefined && (
                <>
                  <Menu.Item
                    key="profile"
                    onClick={() => {
                      setDrawerOpen(false);
                    }}
                  >
                    <Link to="/profile">Profile</Link>
                  </Menu.Item>
                  <Menu.Item
                    key="signOut"
                    onClick={() => {
                      signUserOut();
                      setDrawerOpen(false);
                    }}
                  >
                    Sign Out
                  </Menu.Item>
                </>
              )}
            </Menu>
          </Drawer>
        </div>
      </Layout.Header>
    </React.Fragment>
  );
};

export default NavigationBar;
