import { Col, Form, Row, Select, Typography } from "antd";
import DocUploader from "components/DocUploader/DocUploader";
import React, { CSSProperties, useState } from "react";
import { no_op } from "utils/utils";
import { FormSection } from "../FormSection";

const { Option } = Select;
const { Item: FormItem } = Form;
const { Title } = Typography;

const NotWorkingWithRealtor = ({
  display,
  fieldsRequired,
}: FormSection): React.ReactElement => {
  const style: CSSProperties = {
    display: display ? "" : "none",
  };

  const [isPreQualified, setIsPreQualified] = useState("");

  return (
    <div style={style}>
      <Row justify="center">
        <Col
          xs={22}
          lg={16}
          xl={10}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            Have you been pre-qualified with a lender?
          </Title>
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ span: 14, offset: 1 }}
          lg={{ span: 12, offset: 4 }}
          xl={{ span: 4, offset: 7 }}
        >
          <FormItem
            name="isPreQualified"
            rules={[
              {
                required: fieldsRequired,
                message: "Please say whether or not you are pre-qualified",
              },
              () => ({
                validator(_, value) {
                  setIsPreQualified(value);
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Select size="large" allowClear>
              <Option value="Y">Yes</Option>
              <Option value="N">No</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      {isPreQualified === "Y" && (
        <>
          <Row justify="center">
            <Col
              xs={22}
              lg={16}
              xl={10}
              style={{
                textAlign: "left",
              }}
            >
              <Title
                level={4}
                style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
              >
                Feel free to upload any pre-approval documents you have here
              </Title>
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 14, offset: 1 }}
              lg={{ span: 12, offset: 4 }}
              xl={{ span: 4, offset: 7 }}
            >
              <FormItem name="userDocs">
                <DocUploader name="userDocuments" onChange={no_op} />
              </FormItem>
            </Col>
          </Row>
        </>
      )}
      <Row justify="center">
        <Col
          xs={22}
          lg={16}
          xl={10}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            Will this be a cash purchase?
          </Title>
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ span: 14, offset: 1 }}
          lg={{ span: 12, offset: 4 }}
          xl={{ span: 4, offset: 7 }}
        >
          <FormItem
            name="isCashPurchase"
            rules={[
              {
                required: fieldsRequired,
                message: "Please say whether or this is a cash purchase",
              },
            ]}
          >
            <Select size="large" allowClear>
              <Option value="Y">Yes</Option>
              <Option value="N">No</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
    </div>
  );
};

export default NotWorkingWithRealtor;
