//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { Col, Divider, Form, Layout, Row } from "antd";
import DocUploader from "components/DocUploader/DocUploader";
import React from "react";
import { no_op } from "utils/utils";
import ListingFormLabel from "../ListingFormLabel";

//------------------------------------------------------------------------------------------
// Constants
//------------------------------------------------------------------------------------------

const { Item: FormItem } = Form;

//------------------------------------------------------------------------------------------
// Props Definitions
//------------------------------------------------------------------------------------------

interface UploadDocInformationProps {
  inspectionDocs?: string | null | undefined;
  miscDocs?: string | null | undefined;
  disclosureDocs?: string | null | undefined;
}

//------------------------------------------------------------------------------------------

const UploadDocInformation = ({
  inspectionDocs,
  miscDocs,
  disclosureDocs,
}: UploadDocInformationProps): React.ReactElement => {
  return (
    <Layout>
      <Row justify="center">
        <Col
          xs={20}
          md={20}
          lg={16}
          xl={12}
          xxl={12}
          style={{
            marginTop: "3rem",
          }}
        >
          <h2
            style={{
              fontWeight: "bolder",
            }}
          >
            Documents
          </h2>
        </Col>
      </Row>
      <div id="document-information">
        <Row justify="center">
          <Col xs={20} md={20} lg={16}
            xl={12} xxl={12}>
            <Divider
              style={{
                marginTop: 0,
              }}
            />
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xs={20}
            md={20}
            lg={16}
            xl={12}
            xxl={12}
            style={{
              marginTop: "1rem",
            }}
          >
            <FormItem
              name="inspectionDocuments"
              label={<ListingFormLabel text="Upload Inspection Document" />}
            >
              <DocUploader
                name="homeInspectionDocs"
                onChange={no_op}
                uploadedFiles={
                  inspectionDocs !== undefined && inspectionDocs !== null
                    ? [
                        {
                          uid: "inspection",
                          name: "inspection.pdf",
                          status: "done",
                          url: inspectionDocs,
                        },
                      ]
                    : []
                }
              />
            </FormItem>
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xs={20}
            md={20}
            lg={16}
            xl={12}
            xxl={12}
            style={{
              marginTop: "1rem",
            }}
          >
            <FormItem
              name="disclosureDocuments"
              label={<ListingFormLabel text="Upload Disclosure Document" />}
            >
              <DocUploader
                name="homeDisclosureDocs"
                onChange={no_op}
                uploadedFiles={
                  disclosureDocs !== undefined && disclosureDocs !== null
                    ? [
                        {
                          uid: "disclosure",
                          name: "disclosure.pdf",
                          status: "done",
                          url: disclosureDocs,
                        },
                      ]
                    : []
                }
              />
            </FormItem>
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xs={20}
            md={20}
            lg={16}
            xl={12}
            xxl={12}
            style={{
              marginTop: "1rem",
            }}
          >
            <FormItem
              name="miscDocuments"
              label={<ListingFormLabel text="Upload Misc Document" />}
            >
              <DocUploader
                name="homeMiscDocuments"
                onChange={no_op}
                uploadedFiles={
                  miscDocs !== undefined && miscDocs !== null
                    ? [
                        {
                          uid: "misc",
                          name: "misc.pdf",
                          status: "done",
                          url: miscDocs,
                        },
                      ]
                    : []
                }
              />
            </FormItem>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default UploadDocInformation;
