import { Button, Result } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { YivadoRoute } from "utils/constants/YivadoRoute";

const NotFound = (): React.ReactElement => {
  const history = useHistory();
  return (
    <Result
      status="404"
      title="404"
      subTitle="This url was not found."
      extra={(
        <Button
          type="primary"
          onClick={() => {
            history.push(YivadoRoute.FRONT_PAGE);
          }}
        >
          Back Home
        </Button>
      )}
    />
  );
};

export default NotFound;
