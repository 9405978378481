import { Col, Image, Row, Typography } from "antd";
import React from "react";

const { Title, Text } = Typography;

const SellingFAQInformation = (): React.ReactElement => {
  return (
    <>
      {" "}
      <Row
        justify="center"
        style={{
          backgroundColor: "#1DA57A",
          paddingTop: "120px",
        }}
      >
        <Col
          xs={20}
          md={20}
          lg={14}
          xl={12}
          xxl={12}
          style={{
            backgroundColor: "#FFFFFF",
            textAlign: "center",
            paddingBottom: "40px",
            borderRadius: "50px",
          }}
        >
          <Row justify="center">
            <Col span={18}>
              <Title
                level={1}
                style={{
                  backgroundColor: "#FFFFFF",
                  marginTop: "50px",
                  fontWeight: "bolder",
                  fontSize: "50px",
                }}
              >
                How Yivado Selling Works
              </Title>
            </Col>
          </Row>

          <Row justify="center">
            <Col span={20} style={{ textAlign: "left", marginTop: "20px" }}>
              <b style={{ fontSize: "20px" }}>Yivado </b>
              <span
                style={{
                  backgroundColor: "#FFFFFF",
                  marginTop: "20px",
                  fontWeight: "lighter",
                  fontSize: "20px",
                }}
              >
                simplifies the home offer process and gives the seller a much
                better picture of all their home offers. Unlike the traditional
                home purchasing process, Yivado allows the buyers and sellers to
                see all of the offers on a particular property. With this
                visibility, the seller receives the most fair and competitive
                price for their property.
              </span>
            </Col>
          </Row>

          <Title
            level={2}
            style={{
              backgroundColor: "#FFFFFF",
              marginTop: "60px",
              fontWeight: "bolder",
              fontSize: "40px",
              textAlign: "center",
            }}
          >
            Why Yivado?
          </Title>

          <Row justify="center">
            <Col
              span={20}
              style={{
                textAlign: "left",
                marginTop: "20px",
                marginBottom: "40px",
              }}
            >
              <Text
                style={{
                  backgroundColor: "#FFFFFF",
                  marginTop: "20px",
                  fontWeight: "lighter",
                  fontSize: "20px",
                }}
              >
                Sellers can ask for exactly what they want from the get-go and
                buyers can match that because they can see what the seller wants
                for their property. This much more transparent process saves
                everyone time and stress, including buyers, sellers, and agents.
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        justify="center"
        style={{
          backgroundColor: "#1DA57A",
          paddingTop: "120px",
          paddingBottom: "120px",
        }}
      >
        <Col
          xs={24}
          md={20}
          lg={14}
          xl={12}
          xxl={12}
          style={{
            backgroundColor: "#FFFFFF",
            textAlign: "center",
            borderRadius: "50px",
          }}
        >
          <Row
            justify="space-around"
            style={{
              paddingLeft: "60px",
              paddingRight: "60px",
              paddingTop: "60px",
            }}
          >
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 4 }}
              xxl={{ span: 4 }}
            >
              <Row justify="center" style={{ marginBottom: "20px" }}>
                <Image
                  preview={false}
                  width={160}
                  src="https://static-assets-platform.s3.amazonaws.com/HowItWorksPage/question.png"
                />
              </Row>
            </Col>
            <Col
              xs={24}
              md={20}
              lg={20}
              xl={18}
              xxl={18}
              style={{ textAlign: "left", marginTop: "10px" }}
            >
              <Title
                level={2}
                style={{
                  fontWeight: "bolder",
                  fontSize: "32px",
                }}
              >
                Why Did the Seller Choose Yivado?
              </Title>
              <Text
                style={{
                  marginTop: "20px",
                  fontWeight: "lighter",
                  fontSize: "20px",
                }}
              >
                The home seller chooses to work with Yivado so that they can see
                all offers and receive a fair price for their property based on
                how valuable it is to all potential buyers.
              </Text>
            </Col>
          </Row>

          <Row
            justify="space-around"
            style={{
              paddingLeft: "60px",
              paddingRight: "60px",
              paddingTop: "60px",
            }}
          >
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 4 }}
              xxl={{ span: 4 }}
            >
              <Row justify="center" style={{ marginBottom: "20px" }}>
                <Image
                  preview={false}
                  width={160}
                  src="https://static-assets-platform.s3.amazonaws.com/HowItWorksPage/transparency.png"
                />
              </Row>
            </Col>
            <Col
              xs={24}
              md={20}
              lg={20}
              xl={18}
              xxl={18}
              style={{ textAlign: "left", marginTop: "10px" }}
            >
              <Title
                level={2}
                style={{
                  fontWeight: "bolder",
                  fontSize: "32px",
                }}
              >
                Total Transparency
              </Title>
              <Text
                style={{
                  marginTop: "20px",
                  fontWeight: "lighter",
                  fontSize: "20px",
                }}
              >
                No more guessing what the seller wants or what other buyers are
                willing to pay. As a buyer, you can see any terms the seller has
                listed as well as any offers that have been made on the
                property. This means you can make your offers with complete
                confidence.
              </Text>
            </Col>
          </Row>

          <Row
            justify="space-around"
            style={{
              paddingLeft: "60px",
              paddingRight: "60px",
              paddingTop: "60px",
            }}
          >
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 4 }}
              xxl={{ span: 4 }}
            >
              <Row justify="center" style={{ marginBottom: "20px" }}>
                <Image
                  preview={false}
                  width={160}
                  src="https://static-assets-platform.s3.amazonaws.com/HowItWorksPage/everyone-benefits.png"
                />
              </Row>
            </Col>
            <Col
              xs={24}
              md={20}
              lg={20}
              xl={18}
              xxl={18}
              style={{ textAlign: "left", marginTop: "10px" }}
            >
              <Title
                level={2}
                style={{
                  fontWeight: "bolder",
                  fontSize: "32px",
                }}
              >
                Everyone Benefits
              </Title>
              <Text
                style={{
                  marginTop: "20px",
                  fontWeight: "lighter",
                  fontSize: "20px",
                }}
              >
                The benefits for the buyer and seller are clear, but Yivado
                works wonders for their chosen real estate professionals as
                well. By providing the buyer with the most recent information
                around the sale, real estate professionals have more time to
                help you win.
              </Text>
            </Col>
          </Row>

          <Row
            justify="space-around"
            style={{
              paddingLeft: "60px",
              paddingRight: "60px",
              paddingTop: "60px",
            }}
          >
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 4 }}
              xxl={{ span: 4 }}
            >
              <Row justify="center" style={{ marginBottom: "20px" }}>
                <Image
                  preview={false}
                  width={160}
                  src="https://static-assets-platform.s3.amazonaws.com/HowItWorksPage/easy.png"
                />
              </Row>
            </Col>
            <Col
              xs={24}
              md={20}
              lg={20}
              xl={18}
              xxl={18}
              style={{ textAlign: "left", marginTop: "10px" }}
            >
              <Title
                level={2}
                style={{
                  fontWeight: "bolder",
                  fontSize: "32px",
                }}
              >
                Getting Started is Easy
              </Title>
              <Text
                style={{
                  marginTop: "20px",
                  fontWeight: "lighter",
                  fontSize: "20px",
                }}
              >
                Once the buyer finds the property they are interested in,
                listing agent has informed the buyer’s agent that the seller is
                using Yivado, the buyer can
                <a href="/authentication"> sign up for Yivado </a>
                and find the property. It takes just a few minutes to get
                started on Yivado. The listing agent will connect directly with
                the buyer’s agent to get the ball rolling.
              </Text>
            </Col>
          </Row>

          <Row
            justify="space-around"
            style={{
              paddingLeft: "60px",
              paddingRight: "60px",
              paddingTop: "60px",
            }}
          >
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 4 }}
              xxl={{ span: 4 }}
            >
              <Row justify="center" style={{ marginBottom: "20px" }}>
                <Image
                  preview={false}
                  width={160}
                  src="https://static-assets-platform.s3.amazonaws.com/HowItWorksPage/make-offer.png"
                />
              </Row>
            </Col>
            <Col
              xs={24}
              md={20}
              lg={20}
              xl={18}
              xxl={18}
              style={{ textAlign: "left", marginTop: "10px" }}
            >
              <Title
                level={2}
                style={{
                  fontWeight: "bolder",
                  fontSize: "32px",
                }}
              >
                Make Your Offer
              </Title>
              <Text
                style={{
                  marginTop: "20px",
                  fontWeight: "lighter",
                  fontSize: "20px",
                }}
              >
                Once you’ve completed your registration signed up, you’ll be
                able to make your offer and view other offers in real-time. With
                up-to-date information on how much others are proposing (and how
                they’re sweetening the deal), you can adjust your strategy
                accordingly and make as many new offers as you’d like.
              </Text>
            </Col>
          </Row>

          <Row
            justify="space-around"
            style={{
              paddingLeft: "60px",
              paddingRight: "60px",
              paddingTop: "60px",
            }}
          >
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 4 }}
              xxl={{ span: 4 }}
            >
              <Row justify="center" style={{ marginBottom: "20px" }}>
                <Image
                  preview={false}
                  width={160}
                  src="https://static-assets-platform.s3.amazonaws.com/HowItWorksPage/offer-win.png"
                />
              </Row>
            </Col>
            <Col
              xs={24}
              md={20}
              lg={20}
              xl={18}
              xxl={18}
              style={{
                textAlign: "left",
                marginTop: "10px",
                marginBottom: "80px",
              }}
            >
              <Title
                level={2}
                style={{
                  fontWeight: "bolder",
                  fontSize: "32px",
                }}
              >
                Best Offer Wins!
              </Title>
              <Text
                style={{
                  marginTop: "20px",
                  fontWeight: "lighter",
                  fontSize: "20px",
                }}
              >
                The buyer with the top offer price who wins will submit their
                written offer on the state purchase contract within 24 hours.
                Upon contract acceptance, everything proceeds as a typical home
                purchase. At this point, the seller has the right to accept,
                counter, or reject the offer. Upon contract acceptance,
                everything proceeds as a typical home purchase.
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SellingFAQInformation;
