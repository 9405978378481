//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { useMutation } from "@apollo/client";
import { Col, Form, Layout, Modal, Row, Select } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { Mutations } from "api/mutations";
import AddressInformation from "components/ListingFormItems/AddressInformation";
import HomeInformation from "components/ListingFormItems/HomeInformation";
import ListingFormLabel from "components/ListingFormItems/ListingFormLabel";
import OfferInformation from "components/ListingFormItems/OfferInformation";
import SellerTermsInformation from "components/ListingFormItems/SellerTermsInformation";
import UploadDocInformation from "components/ListingFormItems/UploadDocInformation";
import UploadImageInformation from "components/ListingFormItems/UploadImageInformation";
import SubmitAndReset from "components/SubmitAndReset";
import { UserContext } from "context/UserContext/UserContext";
import { ListingInput } from "model/Listing/inputs";
import ListingUtils from "model/Listing/ListingUtils";
import moment from "moment-timezone";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Timezone, TimezoneMap } from "utils/constants/Timezone";
import { UploadDocType } from "utils/constants/UploadDocType";
import Logger from "utils/logging/Logger";
import S3Listing from "utils/storage/S3Listing";
import { v4 as uuidv4 } from "uuid";
import ListingPageHeader from "./ListingPageHeader";

//------------------------------------------------------------------------------------------
// Component wide constants
//------------------------------------------------------------------------------------------

const { useForm } = Form;
const listingS3 = new S3Listing();

//------------------------------------------------------------------------------------------

const ListingCreationPage = (): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to hooks
  //------------------------------------------------------------------------------------------

  const userContext = useContext(UserContext);
  const [createListingForm] = useForm();
  const history = useHistory();
  const [formChanged, setFormChanged] = useState(false);
  const [listingBeingCreated, setListingBeingCreated] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState<Timezone | "">("");
  const [createListing] = useMutation(Mutations.CREATE_LISTING);

  const [listingImages, setListingImages] = useState<UploadFile<any>[]>([]);

  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  /**
   * Save all of the documents to the S3 bucket
   * @param parentBucketId the s3 bucket for the listing
   */
  const uploadDocuments = (parentBucketId: string): void => {
    createListingForm
      .getFieldValue("disclosureDocuments")
      ?.fileList.forEach((doc: UploadFile<any>) =>
        listingS3.documentUpload(doc, parentBucketId, UploadDocType.DISCLOSURE)
      );

    createListingForm
      .getFieldValue("miscDocuments")
      ?.fileList.forEach((doc: UploadFile<any>) =>
        listingS3.documentUpload(doc, parentBucketId, UploadDocType.MISC)
      );

    createListingForm
      .getFieldValue("inspectionDocuments")
      ?.fileList.forEach((doc: UploadFile<any>) =>
        listingS3.documentUpload(doc, parentBucketId, UploadDocType.INSPECTION)
      );
  };

  //------------------------------------------------------------------------------------------

  /**
   * Saving all of the images to S3 and returning the key of the feature image
   *
   * @param parentBucketId The s3 bucket for the listing
   * @returns The feature image key
   */
  const saveImages = async (parentBucketId: string): Promise<string> => {
    let featureImageKey = "";

    const listingFolder = `listings/${parentBucketId}`;
    await listingS3.emptySpecificFolder(listingFolder, "images");

    await Promise.all(
      listingImages.map(async (imageFile: UploadFile<any>, index) => {
        const imageKey = await listingS3.imagesUpload(
          imageFile,
          parentBucketId,
          index
        );
        if (index === 0) featureImageKey = imageKey;
      })
    );

    return featureImageKey;
  };

  const changeTimezone = (value: string) => {
    setSelectedTimezone(value as Timezone);
    moment.tz.setDefault(TimezoneMap[value]!.value);
  };

  //------------------------------------------------------------------------------------------

  /**
   * Create the listing
   */
  const submitForm = async () => {
    // We want to be sure the agent uploads at least one
    // image to avoid rendering errors
    if (listingImages.length < 1) {
      Modal.error({
        title: "Please upload at least 1 image",
      });
      document.getElementById("photo-upload")?.scrollIntoView();
      return;
    }
    const dateValidation = ListingUtils.validateDates(
      createListingForm.getFieldsValue()
    );

    if (dateValidation === "lastRegistration") {
      Modal.error({
        title: "Please make Last Registration Date within Offer Period",
      });
      document.getElementById("last-registration-day")?.scrollIntoView();
      return;
    }

    setListingBeingCreated(true);
    const parentBucketUUID = uuidv4();

    // Saving the images and document
    uploadDocuments(parentBucketUUID);

    const featureImageKey = await saveImages(parentBucketUUID);

    const createListingInput: ListingInput =
      ListingUtils.createListingInputFromForm(
        createListingForm.getFieldsValue(),
        Number(userContext?.user?.agent.id),
        parentBucketUUID,
        featureImageKey
      );

    createListing({ variables: { listingData: createListingInput } })
      .then((response) => {
        setListingBeingCreated(false);

        Modal.success({
          title: "Your listing was created successfully!",
        });

        createListingForm.resetFields();
        history.push(`/listing/${response.data.createNewListing.id}`);
      })
      .catch((err) => {
        Logger.error(JSON.stringify(err, null, 2));
        setListingBeingCreated(false);
        Modal.error({
          title: "Something went wrong. Please Try again.",
        });
      });
  };

  //------------------------------------------------------------------------------------------

  const resetForm = () => {
    setFormChanged(false);
    createListingForm.resetFields();
  };

  const usersCurrentTimezone = moment.tz.guess();

  const timezones = [
    Timezone.PACIFIC,
    Timezone.MOUNTAIN,
    Timezone.CENTRAL,
    Timezone.EASTERN,
  ];

  const selectOptions = timezones.map((tz) => {
    return (
      <Select.Option value={tz} key={tz}>
        {TimezoneMap[tz]?.label}{" "}
        {TimezoneMap[tz]?.value === usersCurrentTimezone &&
          "- Your current timezone"}
      </Select.Option>
    );
  });

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <Layout
      id="listing-create"
      style={{ marginBottom: "100px", paddingTop: "40px" }}
    >
      <ListingPageHeader />
      <Form
        hideRequiredMark
        layout="vertical"
        form={createListingForm}
        id="createListingForm"
        onChange={() => {
          setFormChanged(true);
        }}
        onFinish={submitForm}
        scrollToFirstError={{
          behavior: (actions) => {
            actions.forEach(({ top }) => {
              window.scrollTo(0, top - 128);
            });
          },
        }}
        initialValues={{
          addressTwo: "",
          numberOfBedrooms: 1,
          numberOfBathrooms: 1,
          numberOfFloors: 1,
          garageSpaces: 1,
          hasFridge: false,
          hasWasherAndDryer: false,
          hasAboveGroundSpa: false,
          openHouseDates: [],
          otherPersonalProperty: [],
          otherHomeFeatures: [],
        }}
      >
        <Row justify="center">
          <Col xs={14} sm={12} md={10}>
            <Form.Item
              name="timezone"
              label={
                <ListingFormLabel text="Choose timezone to manage this listing" />
              }
            >
              <Select placeholder="Timezone" onChange={changeTimezone}>
                {selectOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {selectedTimezone !== "" && (
          <>
            <AddressInformation />
            <OfferInformation form={createListingForm} />
            <SellerTermsInformation />
            <UploadImageInformation
              fileList={listingImages}
              setFileList={setListingImages}
            />
            <UploadDocInformation />
            <HomeInformation />
            <SubmitAndReset
              formChanged={formChanged}
              formIsSaving={listingBeingCreated}
              resetForm={resetForm}
            />
            <Row justify="center" style={{ marginTop: "5px" }}>
              <Col>
                <span style={{ color: "red" }}>*</span> indicates a required
                field
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Layout>
  );
};

export default ListingCreationPage;
