//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Result,
  Row,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AuthUtils from "utils/auth/AuthUtils";
import { YivadoRoute } from "utils/constants/YivadoRoute";
import { useQueryParams } from "utils/hooks";

//------------------------------------------------------------------------------------------
/// Component Wide Constants
//------------------------------------------------------------------------------------------

const authUtils = new AuthUtils();
const { Item: FormItem } = Form;
const { Title } = Typography;

//------------------------------------------------------------------------------------------
/// Rendering
//------------------------------------------------------------------------------------------

const ForgotPassword = (): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  /// Calls to hooks
  //------------------------------------------------------------------------------------------

  const [form] = Form.useForm();
  const queryParams = useQueryParams();
  const [resetPending, setResetPending] = useState(false);
  const history = useHistory();

  //------------------------------------------------------------------------------------------
  /// Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const submitPasswordReset = (): void => {
    setResetPending(true);
    const confirmationCode = queryParams.get("code");
    const username = queryParams.get("username");
    const formValues = form.getFieldsValue();

    authUtils
      .submitPasswordReset(username, confirmationCode, formValues.password)
      .then((response) => {
        setResetPending(false);
        if (response.resetSuccessful) {
          Modal.success({
            title: response.message,
          });
          history.push(YivadoRoute.AUTHENTICATION);
        } else {
          Modal.error({
            title: response.message,
          });
        }
      })
      .catch(() => {
        Modal.error({ title: "Something went wrong, please try again" });
        setResetPending(false);
      });
  };

  //------------------------------------------------------------------------------------------
  /// The component
  //------------------------------------------------------------------------------------------

  const confirmation = queryParams.get("code");
  const username = queryParams.get("username");

  const isValidRecovery = confirmation !== null && username !== null;

  return (
    <Layout>
      {isValidRecovery ? (
        <>
          <Row justify="center">
            <Col>
              <Title
                level={1}
                style={{ marginTop: "2rem", fontWeight: "bolder" }}
              >
                Reset Password
              </Title>
            </Col>
          </Row>
          <Form form={form} onFinish={submitPasswordReset}>
            <Row justify="center" gutter={16}>
              <Col
                xs={11}
                lg={8}
                xl={5}
                style={{
                  textAlign: "left",
                }}
              >
                <Title
                  level={4}
                  style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
                >
                  Password
                </Title>
              </Col>
              <Col
                xs={11}
                lg={8}
                xl={5}
                style={{
                  textAlign: "left",
                }}
              >
                <Title
                  level={4}
                  style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
                >
                  Confirm Password
                </Title>
              </Col>
            </Row>
            <Row justify="center" gutter={16}>
              <Col xs={11} lg={8} xl={5}>
                <FormItem
                  name="password"
                  id="register-password"
                  rules={[
                    { required: true, message: "Please enter password" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password").length >= 8) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Password must be at least 8 characters")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password size="large" id="register-password" />
                </FormItem>
              </Col>
              <Col xs={11} lg={8} xl={5}>
                <FormItem
                  name="password-confirm"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm password",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Passwords Dont Match")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password size="large" />
                </FormItem>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Button loading={resetPending} type="primary" htmlType="submit">
                  Reset Password
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited doesn't exist"
          extra={(
            <Button
              type="primary"
              onClick={() => history.push(YivadoRoute.FRONT_PAGE)}
            >
              Back Home
            </Button>
          )}
        />
      )}
    </Layout>
  );
};

export default ForgotPassword;
