import { gql } from "@apollo/client";

export class Mutations {
  static readonly CREATE_USER = gql`
    mutation CreateUser(
      $firstName: String!
      $lastName: String!
      $isAgent: Boolean!
      $email: String!
      $password: String!
      $agentBroker: String
      $agentEmail: String
      $agentFirstName: String
      $agentLastName: String
      $agentLicenseNumber: String
      $agentPhoneNumber: String
      $isCashPurchase: Boolean!
      $isPreQualified: Boolean!
      $storageBucketId: String
      $phoneNumber: String
      $wantsNotifications: Boolean!
    ) {
      createUser(
        data: {
          firstName: $firstName
          lastName: $lastName
          isAgent: $isAgent
          email: $email
          password: $password
          agentBroker: $agentBroker
          agentEmail: $agentEmail
          agentFirstName: $agentFirstName
          agentLastName: $agentLastName
          agentLicenseNumber: $agentLicenseNumber
          agentPhoneNumber: $agentPhoneNumber
          isCashPurchase: $isCashPurchase
          isPreQualified: $isPreQualified
          storageBucketId: $storageBucketId
          phoneNumber: $phoneNumber
          wantsNotifications: $wantsNotifications
        }
      ) {
        firstName
        lastName
      }
    }
  `;

  static readonly CREATE_LISTING = gql`
    mutation CreateListing($listingData: CreateListingInput!) {
      createNewListing(data: $listingData) {
        id
        address
      }
    }
  `;

  static readonly MAKE_OFFER = gql`
    mutation MakeAnOffer($offerData: MakeBidInput!) {
      makeBid(data: $offerData) {
        outBidByAuto
        success
        message
      }
    }
  `;

  static readonly UPDATE_USER_INFORMATION = gql`
    mutation UpdateUserInformation(
      $userId: Float!
      $updatedUserInfo: UpdateUserInput!
    ) {
      updateUser(id: $userId, data: $updatedUserInfo) {
        id
        firstName
        lastName
        email
        contactEmail
        phoneNumber
        wantsOfferEmails
        wantsOfferTexts
        wantsOtherEmails
        wantsOtherTexts
        isAgent
        referralCode
        storageBucketId
        isAdmin
        agent {
          id
          isCertified
          broker
          licenseNumber
        }
        partneredAgent {
          id
          broker
          licenseNumber
          user {
            id
            firstName
            lastName
            contactEmail
            phoneNumber
          }
        }
      }
    }
  `;

  static readonly UPDATE_LISTING = gql`
    mutation UpdateListing(
      $listingId: Float!
      $updatedListingData: UpdateListingInput!
    ) {
      updateListingInfo(id: $listingId, data: $updatedListingData) {
        id
        address
      }
    }
  `;

  static readonly DELETE_LISTING = gql`
    mutation DeleteListing($listingId: Float!) {
      deleteListing(id: $listingId)
    }
  `;

  static readonly SAVE_NOTES_FOR_APPROVAL = gql`
    mutation SaveNotes($notesData: AddNotesForBuyerInput!) {
      addNotesForBuyer(data: $notesData) {
        id
        approvalStatus
        agentNotes
      }
    }
  `;

  static readonly UPDATE_APPROVAL_STATUS = gql`
    mutation UpdateApprovalStatus(
      $approvalStatusData: UpdateApprovalStatusInput!
    ) {
      updateApprovalStatus(data: $approvalStatusData) {
        approvalStatus
        id
      }
    }
  `;

  static readonly CREATE_NEW_APPROVAL = gql`
    mutation CreateNewApproval($approvalRecordData: CreateApprovalRecord!) {
      createNewApproval(data: $approvalRecordData) {
        id
        listing {
          id
        }
      }
    }
  `;

  static readonly AGENT_LINKS_BUYER = gql`
    mutation AgentLinksBuyer($buyerEmail: String!, $agentId: Float!) {
      agentLinksBuyer(buyerEmail: $buyerEmail, agentId: $agentId) {
        linked
        message
      }
    }
  `;

  static readonly BUYER_LINKS_AGENT = gql`
    mutation BuyerLinksAgent($buyerId: Float!, $agentEmail: String!) {
      buyerLinksAgent(buyerId: $buyerId, agentEmail: $agentEmail) {
        id
        broker
        licenseNumber
        user {
          firstName
          lastName
          contactEmail
          phoneNumber
        }
      }
    }
  `;

  static readonly UNLINK_BUYER = gql`
    mutation UnlinkBuyer($buyerId: Float!) {
      unlinkBuyer(buyerId: $buyerId)
    }
  `;

  static readonly SEND_EMAIL_BLAST = gql`
    mutation SendEmailBlast($listingId: Float!, $message: String!) {
      sendEmailBlast(listingId: $listingId, message: $message)
    }
  `;

  static readonly UPDATE_PARTNERED_AGENT_NOTES = gql`
    mutation UpdatePartneredAgentNotes($buyerId: Float!, $notes: String!) {
      takeNotesForBuyer(buyerId: $buyerId, notes: $notes)
    }
  `;

  static readonly DELETE_OFFERS = gql`
    mutation DeleteBids($bids: String!) {
      deleteBids(bids: $bids)
    }
  `;

  static readonly UPDATE_MAX_OFFER = gql`
    mutation UpdateMaxBid($autoBidId: Float!, $newMaxBid: Float!) {
      updateAutoBid(autoBidId: $autoBidId, newMaxBid: $newMaxBid)
    }
  `;

  static readonly CONTACT_AGENT = gql`
    mutation ContactAgent($contactData: ContactAgentInput!) {
      contactAgent(contactData: $contactData)
    }
  `;

  static readonly CONTACT_YIVADO = gql`
    mutation ContactYivado($contactData: ContactYivadoInput!) {
      contactYivado(contactData: $contactData)
    }
  `;

  static readonly LOG_TO_SERVER = gql`
    mutation LogToServer($data: LogInput!) {
      log(data: $data)
    }
  `;

  static readonly VOID_OFFER = gql`
    mutation VoidOffer($bidId: Float!) {
      voidBid(bidId: $bidId)
    }
  `;

  static readonly UNVOID_OFFER = gql`
    mutation UnVoidOffer($bidId: Float!) {
      unVoidBid(bidId: $bidId)
    }
  `;
}
