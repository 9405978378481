//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { Button, Col, Layout, Result, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AuthUtils from "utils/auth/AuthUtils";
import { YivadoRoute } from "utils/constants/YivadoRoute";
import { useQueryParams } from "utils/hooks";
import { statusConfig } from "./constants";

const authUtils = new AuthUtils();

const Confirmation = (): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Calls to hooks
  //------------------------------------------------------------------------------------------

  const [confirmationInProgress, setConfirmationInProgress] = useState(false);
  const [confirmationSuccessful, setConfirmationSuccessful] = useState<
    boolean | null
  >(null);
  const queryParams = useQueryParams();
  const history = useHistory();

  const isValidConfirmation =
    queryParams.get("code") !== null && queryParams.get("username") !== null;

  useEffect(() => {
    if (!isValidConfirmation) return;
    setConfirmationInProgress(true);

    authUtils
      .confirmSignUp(queryParams.get("username"), queryParams.get("code"))
      .then((response) => {
        setConfirmationInProgress(false);
        setConfirmationSuccessful(response.confirmationSuccessful);
      });
  }, [queryParams, isValidConfirmation]);

  //------------------------------------------------------------------------------------------
  // The Component
  //------------------------------------------------------------------------------------------

  const config = confirmationSuccessful
    ? statusConfig.success
    : statusConfig.error;

  const notValid = (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={(
        <Button
          type="primary"
          onClick={() => history.push(YivadoRoute.FRONT_PAGE)}
        >
          Back Home
        </Button>
      )}
    />
  );

  return (
    <Layout>
      {!isValidConfirmation ? (
        notValid
      ) : confirmationInProgress ? (
        <Row>
          <Col>
            <Spin size="large" />
          </Col>
        </Row>
      ) : (
        <Result
          status={config.status}
          title={config.title}
          subTitle={config.subTitle}
          extra={[
            <Button
              key="route"
              type="primary"
              onClick={() => history.push(config.buttonRoute)}
            >
              {config.buttonText}
            </Button>,
            confirmationSuccessful ? null : (
              <Button
                key="contact"
                onClick={() => history.push(YivadoRoute.CONTACT)}
              >
                Contact Us
              </Button>
            ),
          ]}
        />
      )}
    </Layout>
  );
};

export default Confirmation;
