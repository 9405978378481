import { Button } from "antd";
import { RcFile } from "antd/lib/upload";
import AESEncryption from "crypto-js/aes";
import { Bid } from "model/Bid";
import { User } from "model/User";
import moment from "moment";
import React from "react";
import { NumberMap, OfferForUser } from "types";
import Color from "./constants/Color";
import { ListingStatus } from "./constants/ListingStatus";
import Regex from "./constants/Regex";

//------------------------------------------------------------------------------------------------------------

/**
 *
 * @param numberOfMarks Number of marks to put on the slider select
 * @returns
 */
export const generateMarkMap = (numberOfMarks: number): NumberMap<string> => {
  const returnMap: NumberMap<string> = {};

  for (let i = 0; i < numberOfMarks; i++) {
    returnMap[i + 1] = `${i + 1}`;
  }

  return returnMap;
};

//------------------------------------------------------------------------------------------------------------

export const getChildrenToRender = (
  item: any, // eslint-disable-line @typescript-eslint/explicit-module-boundary-types
  i: number
): React.ReactElement => {
  let tag = item.name.indexOf("title") === 0 ? "h1" : "div";
  tag = item.href ? "a" : tag;
  let children =
    typeof item.children === "string" && item.children.match(Regex.IS_IMG)
      ? React.createElement("img", { src: item.children, alt: "img" })
      : item.children;
  if (item.name.indexOf("button") === 0 && typeof item.children === "object") {
    children = React.createElement(Button, {
      ...item.children,
    });
  }
  return React.createElement(tag, { key: i.toString(), ...item }, children);
};

//------------------------------------------------------------------------------------------------------------

/**
 * Determine the maximum offer that has been made along with the user that it is for.
 * If no offers have been made, then we return the initial listing price
 *
 * @param startingPrice The initial listing price of a listing
 * @param bids All of the offers that have currently been made
 * @returns The max offer and user that made it
 */
export const determineMaxOffer = (
  startingPrice: number,
  bids: Bid[]
): OfferForUser => {
  if (bids.length === 0) return { offer: startingPrice, email: "", userId: "" };

  const maxOffer = bids.reduce((currMax, bid) =>
    bid.amountBid > currMax.amountBid ? bid : currMax
  );

  return {
    offer: maxOffer.amountBid,
    email: maxOffer.buyerUser.email,
    userId: maxOffer.buyerUser.id,
  };
};

//------------------------------------------------------------------------------------------------------------

/**
 * Formatting a date in the form MM/DD/YYYY
 *
 * @param date The date to be formatted
 * @returns The formatted date
 */
export const formatDate = (date: Date | string): string => {
  return moment(date).format("MM/DD/YYYY");
};

//------------------------------------------------------------------------------------------------------------

/**
 * Generic used to get the value of a property within an object by a string key
 *
 * @param o The object
 * @param propertyName The name of the property we want
 * @returns The value of the property
 */
export function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
  return o[propertyName]; // o[propertyName] is of type T[K]
}

//------------------------------------------------------------------------------------------------------------

export function sortByProperty<T, K extends keyof T>(
  array: Array<T>,
  property: K,
  order = "desc"
): Array<T> {
  const copyOfArr = Array.from(array);

  return copyOfArr.sort((a, b) => {
    if (getProperty(a, property) < b[property])
      return order === "desc" ? -1 : 1;
    if (a[property] > b[property]) return order === "desc" ? 1 : -1;
    return 0;
  });
}

//------------------------------------------------------------------------------------------------------------

/**
 * Determine the difference in time between two dates in milliseconds. To determine, it will be of the form
 *     dateTwo - dateOne
 * If dateOne is later than dateTwo, the time difference will be negative
 *
 * @param dateOne The first date
 * @param dateTwo The second date
 * @returns Time difference in milliseconds
 */
export function determineTimeDifference(dateOne: Date, dateTwo: Date): number {
  const timeDiffInMillis = dateTwo.getTime() - dateOne.getTime();

  if (timeDiffInMillis <= 0) return -1;

  return timeDiffInMillis;
}

//------------------------------------------------------------------------------------------------------------

/**
 * Formatting a string to title case
 *   input: the shAwshank ReDemptiOn
 *   output: The Shawshank Redemption
 *
 * @param str The string to be formatted
 * @returns The title cause of the string
 */
export function toTitleCase(str: string): string {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

//------------------------------------------------------------------------------------------------------------

/**
 * Formatting a phone number string to take the form:
 *   (xxx) xxx-xxxx
 *
 * @param phoneNumberString The string that should represent a phone number
 * @returns Formatted phone number
 */
export function formatPhoneNumber(
  phoneNumberString: string | undefined
): string {
  if (phoneNumberString === undefined) return "";
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(Regex.PHONE_NUMBER);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return "";
}

//------------------------------------------------------------------------------------------------------------

/**
 * Calculating the buyer's premium amount for an offer. If no percentage is provided
 * for the buyer's premium percentage, it will default to 3 percent.
 *
 * @param offerValue The amount of the offer
 * @param buyersPremiumPercentage The percentage that buyers premium is
 * @returns The amount to be paid as buyer's premium
 */
export function calculateBuyersPremium(
  offerValue: number,
  buyersPremiumPercentage = 0.03
): number {
  return offerValue * buyersPremiumPercentage;
}

//------------------------------------------------------------------------------------------------------------

export function valueOrDefault<T>(
  value: T,
  defaultValue: T | null | undefined
): T | null | undefined {
  if (value === undefined) {
    return defaultValue;
  }

  if (value === null) {
    return defaultValue;
  }

  return value;
}

//------------------------------------------------------------------------------------------------------------

export function cloneArray<T>(arr: T[]): T[] {
  return arr.map((val) => Object.assign({}, val));
}

//------------------------------------------------------------------------------------------------------------

const MILLIS_IN_DAYS = 24 * 60 * 60 * 1000;
export function determineCountdownColor(
  time: string | undefined,
  status: string
): string {
  // We only care if it is an active listing
  // If not active just set it to the base green
  if (status !== ListingStatus.ACTIVE || time === undefined)
    return Color.GREEN_RIBBON;

  const date = new Date(time);

  const timeDiffInDays =
    (date.getTime() - new Date().getTime()) / MILLIS_IN_DAYS;

  if (timeDiffInDays > 5) {
    return Color.GREEN_RIBBON;
  } else if (timeDiffInDays < 2) {
    return Color.RED_RIBBON;
  } else {
    return Color.ORANGE;
  }
}

//------------------------------------------------------------------------------------------------------------

/**
 * A no op function that can be used when something needs to be declared
 * but nothing needs to happen
 */
export const no_op = (): void => undefined;

//------------------------------------------------------------------------------------------------------------

export const cacheUserData = (user: User): void => {
  const encryptedUser = AESEncryption.encrypt(
    JSON.stringify(user),
    process.env.REACT_APP_ENCRYPTION_KEY!
  ).toString();

  localStorage.setItem("user", encryptedUser);
};

//------------------------------------------------------------------------------------------------------------

/**
 * Downloading a file
 * @param blob the blob that we want to download
 * @param filename the name of the downloaded file
 */
export function downloadBlob(blob: Blob, filename: string): void {
  const tempLink = document.createElement("a");
  tempLink.href = URL.createObjectURL(blob);
  tempLink.setAttribute("download", filename);
  tempLink.click();
}

//------------------------------------------------------------------------------------------------------------

export async function createFileFromUrl(
  url: string,
  name: string
): Promise<RcFile> {
  const response = await fetch(url);
  const data = await response.blob();

  return new File([data], name, {}) as RcFile;
}

//------------------------------------------------------------------------------------------------------------

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}
