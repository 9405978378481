//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import update from "immutability-helper";
import React, { useCallback, useState } from "react";
import UploadListItem from "./UploadListItem";

//------------------------------------------------------------------------------------------
// Interfaces/Props
//------------------------------------------------------------------------------------------

interface ImageUploaderProps {
  name: string;
  fileList: UploadFile<any>[];
  setFileList: (images: UploadFile<any>[]) => void;
}

function getBase64(file: Blob): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

//------------------------------------------------------------------------------------------

const ImageUploader = ({
  name,
  fileList,
  setFileList,
}: ImageUploaderProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handlePreview = async (file: UploadFile<any>) => {
    if (!file.url && !file.preview) {
      file.preview = (await getBase64(file.originFileObj as Blob)) as string;
    }

    setPreviewImage(file.url || file.preview!);
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = fileList[dragIndex];
      setFileList(
        update(fileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [fileList, setFileList]
  );

  const change = ({ fileList: newFileList }: UploadChangeParam<any>) => {
    setFileList(newFileList);
  };

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <>
      <Upload
        onChange={change}
        multiple
        name={name}
        beforeUpload={() => false}
        fileList={fileList}
        listType="picture-card"
        onPreview={handlePreview}
        itemRender={(originNode, file, currFileList) => (
          <UploadListItem
            originNode={originNode}
            file={file}
            fileList={currFileList}
            moveRow={moveRow}
          />
        )}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      </Upload>
      <Modal
        visible={previewVisible}
        onCancel={handleCancel}
        footer={null}
        title="Image Preview"
      >
        <img src={previewImage} style={{ width: "100%" }} alt="" />
      </Modal>
    </>
  );
};

export default ImageUploader;
