import { useQuery } from "@apollo/client";
import { Col, Row, Skeleton, Typography } from "antd";
import { Queries } from "api/queries";
import { ListingResponse } from "api/types/ResponseTypes";
import React from "react";
import { HomeFeaturesConfig } from "utils/constants/HomeFeatures";
import { PersonalPropertyConfig } from "utils/constants/PersonalProperty";

interface HomeDetailsProps {
  listingId: string;
}

const { Text } = Typography;

const HomeDetails = ({ listingId }: HomeDetailsProps): React.ReactElement => {
  const { data } = useQuery<ListingResponse>(Queries.GET_HOME_DETAILS, {
    variables: { listingId: parseFloat(listingId) },
  });

  const allHomeFeaturesIncluded: JSX.Element[] = [];
  const allPersonalPropertyIncluded: JSX.Element[] = [];

  if (data !== undefined) {
    data.listing.personalProperty?.split(",").forEach((property) =>
      allPersonalPropertyIncluded.push(
        <React.Fragment key={property}>
          {PersonalPropertyConfig.get(property)!.displayString}
          <br />
        </React.Fragment>
      )
    );

    data.listing.otherPersonalProperty.split("&&").forEach((property) =>
      allPersonalPropertyIncluded.push(
        <React.Fragment key={property}>
          {property}
          <br />
        </React.Fragment>
      )
    );
    data.listing.homeFeatures?.split(",").forEach((feature) =>
      allHomeFeaturesIncluded.push(
        <React.Fragment key={feature}>
          {HomeFeaturesConfig.get(feature)!.displayString}
          <br />
        </React.Fragment>
      )
    );

    data.listing.otherHomeFeatures.split("&&").forEach((feature) =>
      allHomeFeaturesIncluded.push(
        <React.Fragment key={feature}>
          {feature}
          <br />
        </React.Fragment>
      )
    );
  }

  return data === undefined ? (
    <Skeleton />
  ) : (
    <>
      <Row
        justify="center"
        style={{
          marginTop: "5px",
          paddingTop: "15px",
          backgroundColor: "#F0F0F0",
        }}
      >
        <Col>
          <Text
            style={{
              color: "rgba(0, 0, 0, .95)",
              fontSize: "22px",
              marginBottom: "0px",
            }}
            strong
          >
            Home Details
          </Text>
        </Col>
      </Row>
      <Col
        style={{
          backgroundColor: "#F0F0F0",
          paddingBottom: "15px",
        }}
      >
        <HomeDetailsSection
          title="Beds:"
          text={data.listing.numberOfBedrooms}
        />
        <HomeDetailsSection
          title="Baths:"
          text={data.listing.numberOfBathrooms}
        />
        <HomeDetailsSection
          title="Sq Ft:"
          text={data.listing.houseSizeInSquareFeet.toLocaleString() + " Sq Ft"}
        />
        <HomeDetailsSection title="Home Type:" text={data.listing.homeType} />
        <HomeDetailsSection title="Year Built:" text={data.listing.yearBuilt} />
        <HomeDetailsSection
          title="Lot Size:"
          text={data.listing.lotSizeInSquareFeet.toLocaleString() + " Sq Ft"}
        />
        <HomeDetailsSection
          title="Floors:"
          text={data.listing.numberofFloors}
        />
        <HomeDetailsSection
          title="Garage Spaces:"
          text={data.listing.garageSpaces}
        />
        {allHomeFeaturesIncluded.length > 0 && (
          <HomeDetailsSection
            title="Home Features:"
            text={allHomeFeaturesIncluded}
          />
        )}
        {allPersonalPropertyIncluded.length > 0 && (
          <HomeDetailsSection
            title="Personal Property:"
            text={allPersonalPropertyIncluded}
          />
        )}
        <HomeDetailsSection
          title="HOA:"
          text={`${data.listing.hasHoa ? "Yes" : "No"}`}
        />
        {data.listing.hasHoa && (
          <HomeDetailsSection
            title="HOA Cost/Month:"
            text={"$ " + data.listing.hoaMonthlyCost}
          />
        )}
      </Col>
    </>
  );
};

export default HomeDetails;

interface HomeDetailsSectionProps {
  title: string;
  text: number | string | boolean | JSX.Element[] | null;
}

const HomeDetailsSection = ({
  title,
  text,
}: HomeDetailsSectionProps): React.ReactElement => (
  <Row justify="center">
    <Col
      span={11}
      style={{
        paddingTop: "15px",
        backgroundColor: "#F0F0F0",
      }}
    >
      <Text
        style={{
          color: "rgba(50, 50, 50, .6)",
          fontSize: "15px",
        }}
        strong
      >
        {title}
      </Text>
    </Col>
    <Col
      span={10}
      style={{
        paddingTop: "15px",
        paddingLeft: "10px",
        backgroundColor: "#F0F0F0",
      }}
    >
      <Text
        style={{
          color: "rgba(25, 25, 25, .8)",
          fontSize: "15px",
        }}
        strong
      >
        {text}
      </Text>
    </Col>
  </Row>
);
