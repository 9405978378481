import { Layout } from "antd";
import React from "react";
import ApplicationFooter from "../../../../components/ApplicationFooter";
import SellingFAQInformation from "./SellingFAQInformation";

const SellingFAQPage = (): React.ReactElement => {
  return (
    <Layout style={{ overflowX: "hidden" }}>
      <SellingFAQInformation />
      <ApplicationFooter />
    </Layout>
  );
};

export default SellingFAQPage;
