import { Col, Divider, Layout, Row, Typography } from "antd";
import React from "react";

const { Title } = Typography;

interface EditListingPageHeaderProps {
  address: string;
}

const EditListingPageHeader = ({
  address,
}: EditListingPageHeaderProps): React.ReactElement => (
  <Layout>
    <Row justify="center">
      <Col>
        <Title
          level={1}
          style={{
            textAlign: "center",
            marginTop: "2rem",
            fontWeight: "bolder",
          }}
        >
          Edit Listing:{" "}
          <p>
            <em>{address}</em>
          </p>
        </Title>
      </Col>
    </Row>
    <Row justify="center">
      <Col xs={20} md={20} lg={16}
        xl={12} xxl={12}>
        <Divider
          style={{
            marginTop: 0,
            color: "red",
          }}
        />
      </Col>
    </Row>
  </Layout>
);

export default EditListingPageHeader;
