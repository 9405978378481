import GoogleAnalytics from "components/GoogleAnalytics";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { useAnalytics } from "utils/hooks";
import NotFound from "./NotFound";
import RoutingConfig from "./RoutingConfig";

const Routing = (): React.ReactElement => {
  const { initialized } = useAnalytics();

  return (
    <GoogleAnalytics initialized={initialized}>
      <Switch>
        {RoutingConfig.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              exact
              component={route.component}
            />
          );
        })}
        <Route path="*" component={NotFound} />
      </Switch>
    </GoogleAnalytics>
  );
};

export default Routing;
