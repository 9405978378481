import { Button, Col, Divider, Row, Typography } from "antd";
import React, { useState } from "react";
import PasswordChangeModal from "./PasswordChangeModal/PasswordChangeModal";

const { Title } = Typography;

const PasswordInformation = (): React.ReactElement => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const setModalVisibility = (): void => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <>
      <Divider />
      <Row justify="center">
        <Col
          xs={16}
          lg={14}
          xl={10}
          xxl={6}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            Password
          </Title>
        </Col>
      </Row>
      <Row
        justify="center"
        style={{
          marginBottom: "10rem",
        }}
      >
        <Col xs={16} lg={14} xl={10}
          xxl={6}>
          <Button
            type="primary"
            shape="round"
            onClick={setModalVisibility}
            style={{
              float: "left",
              width: "100%",
              marginTop: "0.25rem",
            }}
          >
            {" "}
            Change Password{" "}
          </Button>
        </Col>
      </Row>
      <PasswordChangeModal isOpen={modalIsOpen} onCancel={setModalVisibility} />
    </>
  );
};

export default PasswordInformation;
