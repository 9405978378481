import { Col, Form, Input, Row, Typography } from "antd";
import React from "react";

const { Title } = Typography;

const Passwords = (): React.ReactElement => {
  return (
    <>
      <Row justify="center" gutter={16}>
        <Col
          xs={11}
          lg={8}
          xl={5}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            Password
          </Title>
        </Col>
        <Col
          xs={11}
          lg={8}
          xl={5}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            Confirm Password
          </Title>
        </Col>
      </Row>
      <Row justify="center" gutter={16}>
        <Col xs={11} lg={8} xl={5}>
          <Form.Item
            name="password"
            id="register-password"
            validateTrigger="onBlur"
            rules={[
              { required: true, message: "Please enter password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password").length >= 8) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Password must be at least 8 characters")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              id="register-password"
              autoComplete="new-password"
            />
          </Form.Item>
        </Col>
        <Col xs={11} lg={8} xl={5}>
          <Form.Item
            name="password-confirm"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: "Please confirm password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords Dont Match"));
                },
              }),
            ]}
          >
            <Input.Password size="large" autoComplete="new-password" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default Passwords;
