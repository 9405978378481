import { Form, Input, Modal } from "antd";
import React from "react";
import AuthUtils from "utils/auth/AuthUtils";
import Logger from "utils/logging/Logger";

const authUtils = new AuthUtils();

interface AskForUsernameProps {
  isOpen: boolean;
  toggle: VoidFunction;
}

const AskForUsername = ({
  isOpen,
  toggle,
}: AskForUsernameProps): React.ReactElement => {
  const [form] = Form.useForm();

  const resetPassword = (): void => {
    const formValues = form.getFieldsValue();

    authUtils
      .userForgotPassword(formValues.email)
      .then(() => {
        Modal.success({
          title:
            "Check email for password recovery link! If an account exists with the provided email. You will be receiving it shortly.",
        });
        form.resetFields();
        toggle();
      })
      .catch((err) => {
        Logger.error(JSON.stringify(err, null, 2));
        Modal.error({ title: "Something went wrong" });
      });
  };

  return (
    <Modal
      title="Provide the email you used to register"
      visible={isOpen}
      onCancel={() => {
        form.resetFields();
        toggle();
      }}
      onOk={() => {
        form.validateFields().then(() => {
          resetPassword();
        });
      }}
      okText="Reset"
      cancelText="Cancel"
    >
      <Form form={form} onFinish={resetPassword}>
        <Form.Item label="Email" rules={[{ required: true }]} name="email">
          <Input type="email" placeholder="Email you used to register" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AskForUsername;
