import { Col, Form, Input, Row, Typography } from "antd";
import MaskedInput from "antd-mask-input";
import React from "react";

const { Item: FormItem } = Form;
const { Title } = Typography;

const RegistrationNames = (): React.ReactElement => (
  <>
    <Row justify="center" gutter={16}>
      <Col
        xs={11}
        lg={8}
        xl={5}
        style={{
          textAlign: "left",
        }}
      >
        <Title level={4} style={{ marginTop: "1.5rem", fontWeight: "bolder" }}>
          Email
        </Title>
      </Col>
      <Col
        xs={11}
        lg={8}
        xl={5}
        style={{
          textAlign: "left",
        }}
      >
        <Title level={4} style={{ marginTop: "1.5rem", fontWeight: "bolder" }}>
          Phone Number
        </Title>
      </Col>
    </Row>
    <Row justify="center" gutter={16}>
      <Col xs={11} lg={8} xl={5}>
        <FormItem
          name="email"
          rules={[{ required: true, message: "Please enter email" }]}
        >
          <Input
            size="large"
            id="register-email"
            type="email"
            autoComplete="new-password"
          />
        </FormItem>
      </Col>
      <Col xs={11} lg={8} xl={5}>
        <FormItem
          name="phoneNumber"
          id="register-phonenumber"
          rules={[{ required: true, message: "Please enter phone number" }]}
        >
          <MaskedInput
            style={{
              textAlign: "left",
              width: "100%",
              marginTop: "0.25rem",
            }}
            mask="(111) 111-1111"
            name="card"
            autoComplete="new-password"
          />
        </FormItem>
      </Col>
    </Row>
  </>
);

export default RegistrationNames;
