//------------------------------------------------------------------------------------------------------------

import { useEffect, useState } from "react";
import S3Access from "utils/storage";

//------------------------------------------------------------------------------------------------------------

export function useProfilePicture(storageBucketId: string | undefined): string {
  const [profilePictureUrl, setProfilePictureUrl] = useState("");

  useEffect(() => {
    const s3 = new S3Access();
    s3.getSingleUrlFromS3(`users/${storageBucketId}/profilePicture.png`).then(
      (val) => {
        setProfilePictureUrl(val);
      }
    );
  }, [storageBucketId]);

  return profilePictureUrl;
}
