type PropertyConfig = {
  displayString: string;
};

export const PersonalPropertyConfig: Map<string, PropertyConfig> = new Map<
  string,
  PropertyConfig
>([
  ["hasWasherAndDryer", { displayString: "Washer and Dryer" }],
  ["hasFridge", { displayString: "Refrigerator" }],
  ["hasAboveGroundSpa", { displayString: "Above Ground Spa" }],
]);
