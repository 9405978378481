import { Col, Empty, Row } from "antd";
import ListingCard from "components/ListingCard/ListingCard";
import { ListingCardInfo } from "pages/ListingPages/BrowseListings/ListingsPageTypes";
import React from "react";
import { ListingStatus } from "utils/constants/ListingStatus";
import { sortByProperty } from "utils/utils";

interface ListingTabsProps {
  cardInfo: Array<ListingCardInfo>;
}

const ListingTabs = ({ cardInfo }: ListingTabsProps): React.ReactElement => {
  const listingCardInfoCallback = (
    card: ListingCardInfo,
    status: string
  ): React.ReactElement => (
    <Col xxl={6} sm={12} xs={24}
      key={card.id}>
      <ListingCard
        id={card.id}
        listingStatus={status}
        imageUrl={card.imageUrl}
      />
    </Col>
  );

  const sortedByEndDate = sortByProperty(cardInfo, "endDate");

  return (
    <>
      {sortedByEndDate.length === 0 ? (
        <Row justify="center">
          <Empty description="There are no active listings" />
        </Row>
      ) : (
        <Row gutter={[15, 15]} justify="start">
          {sortedByEndDate.map((card) =>
            listingCardInfoCallback(card, ListingStatus.ACTIVE)
          )}
        </Row>
      )}
    </>
  );
};

export default ListingTabs;
