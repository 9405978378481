import { Col, Form, Input, Row, Typography } from "antd";
import React, { CSSProperties } from "react";
import { FormSection } from "../FormSection";

const { Item: FormItem } = Form;
const { Title } = Typography;

const IsARealtor = ({
  display,
  fieldsRequired,
}: FormSection): React.ReactElement => {
  const style: CSSProperties = {
    display: display ? "" : "none",
  };

  return (
    <div style={style}>
      <Row justify="center" gutter={16}>
        <Col
          xs={11}
          lg={8}
          xl={5}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            Broker Office
          </Title>
        </Col>
        <Col
          xs={11}
          lg={8}
          xl={5}
          style={{
            textAlign: "left",
          }}
        >
          <Title
            level={4}
            style={{ marginTop: "1.5rem", fontWeight: "bolder" }}
          >
            MLS ID #
          </Title>
        </Col>
      </Row>
      <Row justify="center" gutter={16}>
        <Col xs={11} lg={8} xl={5}>
          <FormItem
            name="agentBroker"
            rules={[
              {
                required: fieldsRequired,
                message: "Please provide your broker office",
              },
            ]}
          >
            <Input size="large" />
          </FormItem>
        </Col>
        <Col xs={11} lg={8} xl={5}>
          <FormItem
            name="agentLicenseNumber"
            rules={[
              {
                required: fieldsRequired,
                message: "Please provide your mls id number",
              },
            ]}
          >
            <Input size="large" />
          </FormItem>
        </Col>
      </Row>
    </div>
  );
};

export default IsARealtor;
