import { Button, Modal, Popconfirm, Spin } from "antd";
import { ApprovedStatus } from "model/Approved";
import { AutoBid } from "model/AutoBid";
import { User } from "model/User";
import React from "react";
import { useHistory } from "react-router-dom";
import { OfferForUser, VoidFunction } from "types";
import { YivadoRoute } from "utils/constants/YivadoRoute";

interface MakeOfferButtonProps {
  approvalsLoading: boolean;
  canMakeOffers: boolean;
  currentUser?: User;
  currentUserMadeOffer: VoidFunction;
  toggleOffer: VoidFunction;
  offerMakingApproval: ApprovedStatus | "none" | "canNoLongerRegister";
  createNewApprovalRecord: VoidFunction;
  newApprovalLoading: boolean;
  currentMaxOffer: OfferForUser;
  listingAgentId: string;
  listingId: string;
  toggleUpdateMaxBid: VoidFunction;
  currentUserAutoBidRecord?: AutoBid;
}

const MakeOfferButton = ({
  approvalsLoading,
  canMakeOffers,
  currentUser,
  currentUserMadeOffer,
  toggleOffer,
  offerMakingApproval,
  createNewApprovalRecord,
  newApprovalLoading,
  currentMaxOffer,
  listingAgentId,
  listingId,
  currentUserAutoBidRecord,
  toggleUpdateMaxBid,
}: MakeOfferButtonProps): React.ReactElement => {
  const history = useHistory();

  const isCurrentAgentsListing =
    currentUser?.isAgent && currentUser?.agent.id === listingAgentId;
  const currentUserIsAgent = currentUser?.isAgent;

  const buttonStyle = {
    width: "fit-content",
    height: "65px",
    fontSize: "20px",
  };

  const noBuyersAgent = () => {
    Modal.error({
      title:
        "Sorry you must have a linked buyer's agent to register to make offers.",
      content: (
        <div
          style={{ cursor: "pointer", color: "#1DA57A" }}
          onClick={() => {
            history.push(YivadoRoute.PROFILE);
            Modal.destroyAll();
          }}
        >
          <u>Go to your profile settings to link a buyer&apos;s agent</u>
        </div>
      ),
    });
  };

  //------------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------------

  // The approvals are still loading for the listing so we just want to return a spinner
  if (approvalsLoading) {
    return <Spin style={{ marginTop: "20px", marginRight: "10px" }} />;
  }

  // This is the current logged in agent's listing
  // We want to send them to the manage listing page
  if (isCurrentAgentsListing) {
    return (
      <Button
        style={buttonStyle}
        type="primary"
        onClick={() => history.push(`/listing/manage/${listingId}`)}
      >
        Manage Listing
      </Button>
    );
  }

  // The current user does not have a partnered agent
  // And the user is NOT an agent
  // We want to tell them they need a buyers agent in order to register
  if (currentUser?.partneredAgent === null && !currentUserIsAgent) {
    return (
      <Button style={buttonStyle} type="primary" onClick={noBuyersAgent}>
        Register to Make Offers
      </Button>
    );
  }

  // The current user is an agent
  // We just want to open up the make offer modal if the current user is an agent
  if (currentUserIsAgent) {
    return (
      <Button style={buttonStyle} type="primary" onClick={toggleOffer}>
        Make an Offer
      </Button>
    );
  }

  // The user can maker offers
  if (canMakeOffers) {
    return (
      <Button
        style={buttonStyle}
        type="primary"
        onClick={
          currentUserAutoBidRecord
            ? toggleUpdateMaxBid // If the current user has an auto bid record set then we want to open the update max bid modal
            : currentUser?.email === currentMaxOffer.email
            ? currentUserMadeOffer // If the current user has the highest offer, then we tell them
            : toggleOffer // Otherwise we open the regular make offer modal
        }
      >
        Make an Offer
      </Button>
    );
  }

  // Allow the user to register to make offers if they do
  // not have an approval and the user exists
  if (offerMakingApproval === "none" && currentUser !== undefined) {
    return (
      <Popconfirm
        title="Are you sure you want to register to make offers on this property?"
        okText="Yes"
        cancelText="No"
        onConfirm={createNewApprovalRecord}
      >
        <Button style={buttonStyle} type="primary" loading={newApprovalLoading}>
          Register to Make Offers
        </Button>
      </Popconfirm>
    );
  }

  // If the user is still pending we want the button to be disabled
  if (offerMakingApproval === ApprovedStatus.PENDING) {
    return (
      <Button style={buttonStyle} disabled>
        Registration Pending
      </Button>
    );
  }

  // If the user is denied to make offers we want to give them a message
  // stating this when they click the button to make offers
  if (offerMakingApproval === ApprovedStatus.DENIED) {
    return (
      <Button
        danger
        style={buttonStyle}
        onClick={() => {
          Modal.error({
            title: "Not Approved",
            content:
              "You have not been approved to make offers on this listing. Please contact the listing agent for more details",
          });
        }}
      >
        Not Approved
      </Button>
    );
  }

  // Let users know the registration window is closed
  if (offerMakingApproval === "canNoLongerRegister") {
    return (
      <Button type="primary" size="large" style={buttonStyle}>
        Registration window closed
      </Button>
    );
  }

  // If none of the conditions have been met that means the
  // user is not logged in. We then want to redirect them to the login page

  return (
    <Button
      type="primary"
      size="large"
      style={buttonStyle}
      onClick={() => history.push(YivadoRoute.AUTHENTICATION)}
    >
      Make an Offer
    </Button>
  );
};

export default MakeOfferButton;
