import { Col, ColProps, Layout, Row } from "antd";
import ApplicationFooter from "components/ApplicationFooter";
import { User } from "model/User";
import React from "react";
import Color from "utils/constants/Color";
import Actions from "../SubComponents/Actions";
import CurrentOfferInfo from "../SubComponents/CurrentOfferInfo";
import HomeDetails from "../SubComponents/HomeDetails";
import ListingImages from "../SubComponents/ListingImages";
import ListingInfo from "../SubComponents/ListingInfo";
import OverviewInformation from "../SubComponents/OverviewInformation";
import SellerTerms from "../SubComponents/SellerTerms";
import SingleListingHeader from "../SubComponents/SingleListingHeader";

const contentLayoutColumnSpans: ColProps = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 24 },
  lg: { span: 24 },
  xl: { span: 22 },
  xxl: { span: 16 },
};

const mainContentLayoutColumnSpans: ColProps = {
  xs: { span: 24, order: 1 },
  sm: { span: 24, order: 1 },
  md: { span: 24, order: 1 },
  lg: { span: 24, order: 1 },
  xl: { span: 16 },
  xxl: { span: 16 },
};

const sideContentLayoutColumnSpans: ColProps = {
  xs: { span: 24, order: 2 },
  sm: { span: 24, order: 2 },
  md: { span: 16, order: 2 },
  lg: { span: 12, order: 2 },
  xl: { span: 8 },
  xxl: { span: 8 },
};

interface SingleListingPageContentProps {
  listingId: string;
  currentUser?: User;
}

const SingleListingPageContent = ({
  listingId,
  currentUser,
}: SingleListingPageContentProps): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Private Components
  //------------------------------------------------------------------------------------------

  const Content: React.FC = (props) => (
    <Col {...contentLayoutColumnSpans} style={{ maxWidth: "1300px" }}>
      {props.children}
    </Col>
  );

  const MainContent: React.FC = (props) => (
    <Col {...mainContentLayoutColumnSpans}>{props.children}</Col>
  );

  const SideContent: React.FC = (props) => (
    <Col
      style={{ marginTop: "0px", padding: "20px" }}
      {...sideContentLayoutColumnSpans}
    >
      {props.children}
    </Col>
  );

  return (
    <Layout
      style={{
        justifyContent: "center",
        background: Color.WHITE,
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingBottom: "15px",
        overflowX: "hidden",
        overflowY: "hidden",
        marginTop: "5px",
        paddingTop: "20px",
      }}
      id="desktoplayout"
    >
      <Row justify="center" style={{ marginBottom: "150px" }}>
        <Content>
          <SingleListingHeader
            listingId={listingId}
            currentUser={currentUser}
          />
          <CurrentOfferInfo listingId={listingId} />
          <Row justify="center">
            <MainContent>
              <ListingImages listingId={listingId} />
              <OverviewInformation listingId={listingId} />
            </MainContent>
            <SideContent>
              <ListingInfo listingId={listingId} />
              <HomeDetails listingId={listingId} />
              <Actions listingId={listingId} />
              <SellerTerms listingId={listingId} />
            </SideContent>
          </Row>
        </Content>
      </Row>
      <ApplicationFooter />
    </Layout>
  );
};

export default SingleListingPageContent;
