//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import { MinusCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  Button,
  Col,
  Descriptions,
  Modal,
  Popconfirm,
  Row,
  Tooltip,
} from "antd";
import { Mutations } from "api/mutations";
import { UserContext } from "context/UserContext/UserContext";
import { Agent } from "model/Agent";
import { User } from "model/User";
import React, { useContext, useState } from "react";
import ContextError from "utils/errors/ContextError";
import { cacheUserData } from "utils/utils";

//------------------------------------------------------------------------------------------
/// Interfaces/Props Definitions
//------------------------------------------------------------------------------------------

interface HasAgentProps {
  myAgent: Agent;
  userId: string;
}

//------------------------------------------------------------------------------------------

const HasAgent = ({ myAgent, userId }: HasAgentProps): React.ReactElement => {
  const userContext = useContext(UserContext);
  if (userContext === undefined)
    throw new ContextError(ContextError.CONTEXT_UNDEFINED);

  const { user, setUser } = userContext;

  const [unlinkAgent] = useMutation(Mutations.UNLINK_BUYER);
  const [unlinkInProgress, setUnlinkInProgress] = useState(false);

  const unlinkConfirmed = (): void => {
    setUnlinkInProgress(true);
    unlinkAgent({ variables: { buyerId: parseFloat(userId) } })
      .then(() => {
        Modal.success({ title: "Agent successfully unlinked" });
        setUnlinkInProgress(false);

        const updatedUser: User = {
          ...user,
          partneredAgent: null,
        } as User;

        setUser(updatedUser);
        cacheUserData(updatedUser);
      })
      .catch(() => setUnlinkInProgress(false));
  };

  return (
    <>
      <Row justify="center">
        <Col xs={16} lg={14} xl={10}
          xxl={6}>
          <Descriptions bordered column={{ xs: 1, sm: 1 }}>
            <Descriptions.Item label={<b>Name</b>}>
              {myAgent.user?.firstName} {myAgent.user?.lastName}
            </Descriptions.Item>
            <Descriptions.Item label={<b>Email</b>}>
              <Tooltip title="Send them an email">
                <a href={`mailto:${myAgent.user?.contactEmail}`}>
                  {myAgent.user?.contactEmail}
                </a>
              </Tooltip>
            </Descriptions.Item>
            <Descriptions.Item label={<b>Phone Number</b>}>
              {myAgent.user?.phoneNumber}
            </Descriptions.Item>
            <Descriptions.Item label={<b>Broker</b>}>
              {myAgent.broker}
            </Descriptions.Item>
            <Descriptions.Item label={<b>MLS ID</b>}>
              {myAgent.licenseNumber}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: "10px" }}>
        <Col>
          <Popconfirm
            title="Are you sure you no longer want this agent to represent you?"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            okButtonProps={{ danger: true }}
            okText="Unlink"
            onConfirm={unlinkConfirmed}
          >
            <Button danger loading={unlinkInProgress}>
              {" "}
              <MinusCircleOutlined />
              Unlink this agent
            </Button>
          </Popconfirm>
        </Col>
      </Row>
    </>
  );
};

export default HasAgent;
