//------------------------------------------------------------------------------------------
/// Imports
//------------------------------------------------------------------------------------------

import { Col, Divider, Form, Input, Row, Select } from "antd";
import React from "react";
import { State } from "utils/constants/State";
import ListingFormLabel from "../ListingFormLabel";

//------------------------------------------------------------------------------------------
/// Component Wide Constants
//------------------------------------------------------------------------------------------

const { Item: FormItem } = Form;

//------------------------------------------------------------------------------------------

const AddressInformation = (): React.ReactElement => {
  //------------------------------------------------------------------------------------------
  // Helpers/Handlers
  //------------------------------------------------------------------------------------------
  const stateOptions = Object.values(State).map((stateCode) => (
    <Select.Option key={stateCode} value={stateCode}>
      {stateCode}
    </Select.Option>
  ));

  //------------------------------------------------------------------------------------------
  // Rendering
  //------------------------------------------------------------------------------------------

  return (
    <>
      <Row justify="center">
        <Col
          xs={20}
          md={20}
          lg={16}
          xl={12}
          xxl={12}
          style={{
            marginTop: "2rem",
          }}
        >
          <h2
            style={{
              fontWeight: "bolder",
            }}
          >
            Location Information
          </h2>
        </Col>
      </Row>
      <div id="location-information">
        <Row justify="center">
          <Col xs={20} md={20} lg={16}
            xl={12} xxl={12}>
            <Divider
              style={{
                marginTop: 0,
              }}
            />
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={20} md={20} lg={16}
            xl={12} xxl={12}>
            <Row justify="start">
              <Col style={{ marginRight: "36px" }}>
                <FormItem
                  rules={[{ required: true, message: "Please set address" }]}
                  label={<ListingFormLabel required text="Address" />}
                  name="addressOne"
                >
                  <Input
                    style={{ width: "325px" }}
                    placeholder="Address"
                    autoComplete="new-password"
                  />
                </FormItem>
              </Col>
              <Col>
                <FormItem
                  label={<ListingFormLabel text="Address Line 2" />}
                  name="addressTwo"
                >
                  <Input
                    style={{ width: "180px" }}
                    placeholder="Address Line 2"
                    autoComplete="new-password"
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={20} md={20} lg={16}
            xl={12} xxl={12}>
            <Row justify="start">
              <Col style={{ marginRight: "36px" }}>
                <FormItem
                  rules={[{ required: true, message: "Please set city" }]}
                  label={<ListingFormLabel required text="City" />}
                  name="city"
                >
                  <Input
                    style={{ width: "300px" }}
                    placeholder="City"
                    autoComplete="new-password"
                  />
                </FormItem>
              </Col>
              <Col style={{ marginRight: "36px" }}>
                <FormItem
                  rules={[{ required: true, message: "Please choose state" }]}
                  label={<ListingFormLabel required text="State" />}
                  name="state"
                >
                  <Select style={{ width: "100px" }} placeholder="State">
                    {stateOptions}
                  </Select>
                </FormItem>
              </Col>
              <Col>
                <FormItem
                  label={<ListingFormLabel required text="Zip Code" />}
                  name="zipcode"
                  rules={[
                    { required: true, message: "Please set zipcode" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          (getFieldValue("zipcode").length === 5 &&
                            !isNaN(getFieldValue("zipcode")))
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Zipcode must be a 5 digit number")
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    style={{ width: "150px" }}
                    placeholder="Zipcode"
                    autoComplete="new-password"
                  />
                </FormItem>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AddressInformation;
