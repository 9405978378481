enum Color {
  // Specifically for ribbons
  GREEN_RIBBON = "#228B22",
  RED_RIBBON = "volcano",
  YELLOW_RIBBON = "gold",

  // Global Colors to be used in inline styless
  WHITE = "#fff",
  ORANGE = "orange",
}

export default Color;
