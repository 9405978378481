import { Card, Col, Layout, Row, Typography } from "antd";
import ApplicationFooter from "components/ApplicationFooter";
import { UserContext } from "context/UserContext/UserContext";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ContextError from "utils/errors/ContextError";
import {
  CERTIFIED_DASHBOARD_CONFIGS,
  UNCERTIFIED_DASHBOARD_CONFIGS,
} from "./config";

const { Title, Text } = Typography;

const { Meta } = Card;

const AgentDashboardPage = (): React.ReactElement => {
  const userContext = useContext(UserContext);
  //const [show, setShow] = useState(true);

  if (userContext === undefined || userContext.user === undefined) {
    throw new ContextError(ContextError.CONTEXT_UNDEFINED);
  }

  const config = userContext.user.agent.isCertified
    ? CERTIFIED_DASHBOARD_CONFIGS
    : UNCERTIFIED_DASHBOARD_CONFIGS;

  const cards = config.map((item) => (
    <Link to={item.cardUrl} key={item.cardCover.alt}>
      <Card
        hoverable
        cover={<img {...item.cardCover} />}
        style={{ marginLeft: "80px", marginRight: "80px", marginTop: "30px" }}
      >
        <Meta {...item.cardMeta} style={{ backgroundColor: "#1da57a" }} />
      </Card>
    </Link>
  ));

  // We need to loop through the cards 2 at a time for this
  const cardRows: JSX.Element[] = [];
  for (let i = 0; i < cards.length; i += 2) {
    cardRows.push(
      <Row justify="center" style={{ marginBottom: i === 2 ? "100px" : "" }}>
        {cards[i]}
        {cards[i + 1]}
      </Row>
    );
  }

  return (
    <Layout
      id="dashboard"
      style={{ backgroundColor: "#1da57a", overflowX: "hidden" }}
    >
      <Row
        justify="center"
        style={{
          paddingTop: "60px",
          paddingBottom: "120px",
          backgroundColor: "#1da57a",
        }}
      >
        <Col
          style={{
            border: "1px solid #888888",
            boxShadow: "1px 1px #888888",
            borderRadius: "5px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Row justify="center">
            <Col>
              <Title
                level={1}
                style={{
                  textAlign: "center",
                  marginTop: "2rem",
                  marginBottom: "1rem",
                }}
              >
                Dashboard
              </Title>
              <Row justify="center" style={{ padding: "25px" }}>
                <Text>
                  <i>
                    Click a button below to navigate to different Agent site
                    locations
                  </i>
                </Text>
              </Row>
            </Col>
          </Row>
          <div id="dashboard-cards">{cardRows}</div>
        </Col>
      </Row>
      <ApplicationFooter />
    </Layout>
  );
};

export default AgentDashboardPage;
