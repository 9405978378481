import { Col, Form, Row, Select, Typography } from "antd";
import React from "react";
import { VoidFunction } from "types";

const { Title } = Typography;
const { Option } = Select;
const { Item: FormItem } = Form;

interface RealtorProps {
  onSelectChange: VoidFunction;
}

const Realtor = ({ onSelectChange }: RealtorProps): React.ReactElement => (
  <>
    <Row justify="center">
      <Col
        xs={22}
        lg={16}
        xl={10}
        style={{
          textAlign: "left",
        }}
      >
        <Title level={4} style={{ marginTop: "1.5rem", fontWeight: "bolder" }}>
          Are you working with a realtor?
        </Title>
      </Col>
    </Row>

    <Row>
      <Col
        xs={{ span: 14, offset: 1 }}
        lg={{ span: 12, offset: 4 }}
        xl={{ span: 4, offset: 7 }}
      >
        <FormItem
          name="workingWithRealtor"
          rules={[
            {
              required: true,
              message:
                "Please tell us whether or not you are working with a realtor.",
            },
          ]}
        >
          <Select size="large" allowClear onChange={onSelectChange}>
            <Option value="Y">Yes</Option>
            <Option value="N">No</Option>
            <Option value="realtor">I am a realtor</Option>
          </Select>
        </FormItem>
      </Col>
    </Row>
  </>
);

export default Realtor;
