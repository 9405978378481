//------------------------------------------------------------------------------------------
// Imports
//------------------------------------------------------------------------------------------

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  TimePicker,
  Typography,
} from "antd";
import moment from "moment";
import React from "react";
import ListingFormLabel from "../ListingFormLabel";

//------------------------------------------------------------------------------------------
// Component Wide Constants
//------------------------------------------------------------------------------------------

const { Item: FormItem } = Form;
const { Text } = Typography;

//------------------------------------------------------------------------------------------

const OpenHouseDates = (): React.ReactElement => (
  <>
    <ListingFormLabel text="Open House Dates" />
    <Form.List name="openHouseDates">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name }) => {
            return (
              <Row key={key} style={{ marginBottom: "10px" }}>
                <Col style={{ minWidth: "1000px" }}>
                  <Row justify="start">
                    <Col span={7} style={{ marginLeft: "20px" }}>
                      <Row justify="end" style={{ marginBottom: "10px" }}>
                        <Text
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            marginRight: "10px",
                          }}
                        >
                          Date:
                        </Text>
                        <FormItem
                          name={[name, "date"]}
                          rules={[
                            { required: true, message: "Provide the date" },
                          ]}
                        >
                          <DatePicker
                            use12Hours
                            disabledDate={(current) => {
                              const customDate = moment().format("YYYY-MM-DD");
                              return (
                                current &&
                                current < moment(customDate, "YYYY-MM-DD")
                              );
                            }}
                            format="MM/DD/YYYY"
                          />
                        </FormItem>
                      </Row>
                    </Col>
                    <Col span={7} style={{ marginLeft: "20px" }}>
                      <Row justify="end" style={{ marginBottom: "10px" }}>
                        <Text
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            marginRight: "10px",
                          }}
                        >
                          Start Time:
                        </Text>
                        <FormItem
                          name={[name, "startTime"]}
                          rules={[
                            {
                              required: true,
                              message: "Provide the start time",
                            },
                          ]}
                        >
                          <TimePicker
                            use12Hours
                            format="h:mm A"
                            minuteStep={30}
                          />
                        </FormItem>
                      </Row>
                    </Col>
                    <Col span={7} style={{ marginLeft: "10px" }}>
                      <Row justify="end" style={{ marginBottom: "10px" }}>
                        <Text
                          style={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            marginRight: "10px",
                          }}
                        >
                          End Time:
                        </Text>
                        <FormItem
                          name={[name, "endTime"]}
                          rules={[
                            { required: true, message: "Provide the end time" },
                          ]}
                        >
                          <TimePicker
                            use12Hours
                            format="h:mm A"
                            minuteStep={30}
                          />
                        </FormItem>
                      </Row>
                    </Col>
                    <Col span={1}>
                      <Row justify="start" style={{ marginBottom: "10px" }}>
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(name)}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
              Add Open House Date
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  </>
);

export default OpenHouseDates;
